.textalignment__center,
.textalignment__right {
    text-align: center;
}

.textcolor {
    @each $brand-color, $color-val in $brand-colors {
        &__#{$brand-color} .ms-content-block__details {
            color: $color-val;
            transition: all 0.25s;
        }
    }
}

.backgroundcolor {
    &__transparent .ms-content-block__details {
        background: transparent;
    }

    @each $brand-color, $color-val in $brand-colors {
        &__#{$brand-color} {
            .ms-content-block__details {
                background: $color-val;
            }

            &.textcolor {
                @each $text-color, $text-color-val in $brand-colors {
                    &__#{$text-color} .msc-cta__primary {
                        color: $text-color-val;

                        &:before {
                            background: $text-color-val;
                        }

                        &:hover,
                        &:focus {
                            color: $color-val;
                            padding-bottom: 1rem;
                            margin-bottom: 0.5rem;
                            transition: none;

                            &:before {
                                transition: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &__white .ms-content-block__details {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

.ms-content-block {
    &__text p {
        margin-bottom: 2rem;
    }

    &__cta {
        flex-wrap: wrap;
        position: relative;
        z-index: 1;

        a {
            margin-right: $layout-gutter;
        }
    }

    &__details {
        background: $msv-white;
    }
}

.category-page-subheader-containter .ms-content-block__text p {
    line-height: 1.2em;
    font-size: 1.125rem !important;
    font-weight: 400;
    margin-bottom: 1rem;
    display: block;
    word-break: break-word;
    overflow-wrap: anywhere;
    margin-block-start: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.category-new-2023 {
    .ms-content-block__image img {
        aspect-ratio: 6;
        object-fit: cover;
        width: 100%;
        height: auto;
    }
    .ms-content-block__details {
        display: none;
    }
}

.ms-content-block[style*='background-color'].force-transparent-bg .ms-content-block__details {
    background: none;
}
