$buybox-spacer: 32px;
$product-description--color: $primary;
$price__strikethrough--color: $beige;
$price--font-size: $msv-font-size-xl;
$buybox-wishlist-width: 48px;
$buybox-product-status-padding-top: 18px;
$gutter-width: 40px;
$media-gallery-width: 50%;
$buybox-content-width: 50%;

$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;

$carousel-control-prev-icon-bg: str-replace(
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$msv-black}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"),
    '#',
    '%23'
);
$carousel-control-next-icon-bg: str-replace(
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$msv-black}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"),
    '#',
    '%23'
);

.ms-buybox {
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
    padding-top: $buybox-spacer;
    padding-bottom: $buybox-spacer;
    font-weight: $msv-font-weight-normal;
    position: relative;

    @media (max-width: $msv-breakpoint-m) {
        margin-bottom: 0;
        padding-top: 0 !important;
    }

    @media (min-width: 769px) {
        /* & .ms-buybox__content {
      flex: 0 0 $buybox-content-width;
      max-width: $buybox-content-width;
    } */
        /* & .ms-buybox__content {
      flex: 0 0 $buybox-content-width;
      max-width: $buybox-content-width;
    } */
        & .right-container,
        .left-container {
            flex: 0 0 $buybox-content-width;
            max-width: $buybox-content-width;
        }
    }
    // @media screen and (min-width: 769px) and (max-width: 991px) {
    //   & .left-container,
    //   & .right-container {
    //     width: 50%;
    //   }
    // }
    @media (max-width: $msv-breakpoint-m) {
        flex-direction: column;
    }
    justify-content: flex-end;
    /* margin-bottom: 50px; */

    & .left-container {
        display: flex;

        @media (max-width: $msv-breakpoint-m) {
            height: inherit;
            flex-direction: column;
            min-height: 400px;
            //height: 60vh;
        }

        @media (min-width: 769px) {
            height: calc(100vh - 12rem);
            position: sticky;
            top: 10rem;
            align-self: flex-start;
        }
    }

    & .ms-buybox__media-gallery {
        /* position: absolute;
    left: 0;
    z-index: 10; */
        position: static;
        flex-grow: 1;
        max-height: 90vh;
        padding-left: 0;
        padding-right: 32px;

        /* & .ms-media-gallery  {
      background-color: lightcyan;
    } */
        @media (max-width: $msv-breakpoint-m) {
            position: static;
            padding-right: 0 !important;
        }

        & .msc-carousel__item {
            text-align: center;
        }
        & .ms-media-gallery__carousel {
            margin-left: 18px;

            /* & .msc-main_image.msc_image.ms-media-gallery__item {
        max-height: 50vh;
      } */
        }
        & .msc-carousel__item {
            justify-content: flex-start !important;
        }
    }

    &__product-title {
        color: $msv-black;
        font-size: 2.2rem;
    }
    &__product-description {
        color: $product-description--color;
        margin-bottom: $spacer;
        font-size: $msv-font-size-l;
    }
    .msc-ss-carousel .msc-flipper {
        display: none;
    }
    .msc-product__extra-data {
        display: none;
    }
    .msc-price {
        font-size: $price--font-size;
        font-weight: $msv-font-weight-normal;
        margin-bottom: $spacer-l;
        margin-top: 15px;
        display: block;

        & > span {
            vertical-align: middle;
        }
    }
    .msc-price__actual {
        color: $orange2;
        font-weight: bold;
    }
    .msc-price__strikethrough {
        color: $msv-text-color;
        margin-right: 0;
        @media screen and (max-width: $msv-breakpoint-s) {
            display: block;
        }
    }
    .msc-price__discount {
        color: $white;
        background-color: $orange2;
        margin: 0 16px;
        padding: 2px 6px;
        border-radius: 2px;
        font-weight: normal;
        font-size: $msv-font-size-m;
        @media screen and (max-width: $msv-breakpoint-s) {
            margin: 0 16px 0 0;
            max-width: 50px;
            text-align: center;
        }
    }
    .msc-price__recent-price {
        font-size: 1rem;
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .omnibus-details {
        &-btn {
            background-color: $secondary;
            color: white;
            font-size: 0.75rem;
            padding: 0.125rem 0.5rem;
            margin-left: 0.25rem;
        }

        &-popover {
            background-color: white;
            border: 1px solid $secondary;
            padding: 0.25rem;
        }

        &-text__subtext {
            font-size: 0.9rem;
            font-weight: 300;
        }
    }
    .msc-rating {
        margin-bottom: $spacer;
    }
    &__configure {
        @include font-content-l(var(--msv-font-weight-normal));
    }

    &__dropdown {
        margin-bottom: $spacer-l;
    }
    &__content {
        @extend %col;
        /* @include media-breakpoint-up(md) {
      flex: 0 0 $buybox-content-width;
      max-width: $buybox-content-width;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    } */
    }
    &__media-gallery {
        display: block;
        @extend %col;
        flex-grow: 1;

        @media screen and (max-width: 991px) {
            max-height: none !important;
        }
        /*  @include media-breakpoint-up(md) {
      flex: 0 0 $media-gallery-width;
      max-width: $media-gallery-width;
    } */
        & .ms-media-gallery {
            width: 100%;
            /* height: calc(100vh - 188px); */
            height: calc(100vh - 320px);
            flex-direction: column-reverse;

            @media screen and (max-width: 1199px) {
                height: calc(100vh - 50px - 50px);
            }
            @media screen and (min-width: 992px) and (max-width: 1199px) {
                height: auto;
                justify-content: flex-end;

                & .ms-media-gallery__carousel.msc-carousel {
                    flex: initial;
                    height: auto !important;

                    & .msc-carousel__inner {
                        height: auto;

                        & .msc-carousel__item {
                            height: auto;

                            & .ms-inline-zoom {
                                height: auto;
                            }
                        }
                    }
                }
            }
            &.-fixed {
                position: fixed;
                max-width: calc(50% - 15px - 32px);

                @media screen and (min-width: 1200px) {
                    max-width: calc(705px - 32px);
                }
            }
            &.-absolute {
                position: absolute;
                max-width: calc(100% - 32px);

                @media screen and (max-width: 1200px) {
                    max-width: calc(100% - 32px);
                }
            }
            & .ms-media-gallery__carousel.msc-carousel {
                margin-left: 0 !important;
                height: 0;

                @media screen and (max-width: 991px) {
                    /* height: auto; */
                    max-height: 400px;
                }
                & .msc-carousel__control__prev,
                & .msc-carousel__control__next {
                    display: none;

                    @media screen and (max-width: 425px) {
                        display: block;
                    }
                }
                & .msc-carousel__inner {
                    height: 100%;

                    @media screen and (max-width: 991px) {
                        height: auto;
                    }
                    & .msc-carousel__item {
                        justify-content: center !important;
                        height: 100%;

                        /* @media screen and (max-width: 1200px) {
              justify-content: flex-start !important;
            } */
                        @media screen and (max-width: 991px) {
                            width: 100%;
                            max-width: 100%;
                            height: auto;
                        }
                        & .ms-fullscreen-section {
                            width: 100%;

                            & picture {
                                width: 100%;
                                max-width: 100%;
                                height: auto;

                                & img {
                                    width: 100%;
                                    max-width: 100%;
                                    height: auto;
                                    max-height: none;
                                }
                            }
                        }
                        & .ms-inline-zoom {
                            height: 100%;

                            & picture {
                                &:first-of-type {
                                    display: flex;
                                    height: 100%;
                                    /* max-height: 100%; */
                                    width: auto;
                                    /* max-width: 100%; */

                                    & img {
                                        /* max-width: 100%; */
                                        height: 100%;
                                        /* max-height: 100%; */
                                        width: auto;
                                    }
                                    @media screen and (min-width: 992px) and (max-width: 1199px) {
                                        height: auto;
                                        max-height: none;
                                        width: 100%;

                                        & img {
                                            height: auto;
                                            max-height: none;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &__thumbnails-container {
                margin-top: 18px;

                & .msc-ss-carousel-strip {
                    display: flex;
                    justify-content: center;

                    & ul.msc-ss-carousel-slide {
                        /* justify-content: flex-start; */

                        & li.msc-ss-carousel-item.ms-media-gallery__thumbnail-item {
                            width: calc(20% - ((18px * 4) / 5));
                            height: auto;
                            max-height: 100px;
                            & picture {
                                height: auto;
                                width: 100%;
                                display: block;
                            }
                        }
                    }
                }
                & .button.msc-flipper {
                    display: none;
                }
                & ul.msc-ss-carousel-slide {
                    width: 100%;
                    justify-content: center;
                    left: 0 !important;

                    & li.msc-ss-carousel-item.ms-media-gallery__thumbnail-item {
                        width: 100px;
                        height: 100px;
                        margin-right: 18px;
                        margin-bottom: 0;
                        overflow: hidden;
                    }
                    &li.msc-ss-carousel-item:last-child {
                        margin-right: 0;
                    }
                }
            }
            @media screen and (max-width: $msv-breakpoint-m) {
                .msc-carousel__indicators {
                    & > li {
                        height: 4px;
                    }
                }
            }
        }
    }
    .msc-dropdown__select {
        font-size: $msv-font-size-l;
        width: calc(90% - 5.6em);
    }

    &__dropdown-quantity-label {
        font-size: $msv-font-size-l;
        color: $secondary;
        margin-right: $layout-gutter;
        width: 100%;
        margin-bottom: 4px;
    }

    &__product-quantity-label {
        display: none;
    }

    & .vnc-quantity {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-bottom: 1rem;
        flex-wrap: wrap;

        & .error-block {
            flex-basis: 100%;
            width: 100%;
        }

        & .product-add-to-cart {
            margin-left: 0;
            margin-bottom: 0;

            & .buttons-wrapper {
                display: flex;
                flex-direction: row;
                width: 100%;
            }

            & .buttons {
                & .msc-add-to-cart {
                    padding: 0.9rem 1.25rem;
                    word-break: break-word;

                    @media screen and (max-width: map-get($container-max-widths, 'xl')) {
                        padding: 0.9rem 0.8rem;
                    }
                }
            }

            @media screen and (max-width: map-get($container-max-widths, 'xl')) {
                flex-direction: column;
            }
        }
        & .vcn-addCart {
            display: flex;
            flex-direction: row;
            align-items: center;
            //position: relative;

            /* @media screen and (max-width: $msv-breakpoint-l) {
        flex-direction: column;
      } */
            /* @media screen and (max-width: map-get($container-max-widths, 'xl')) {
        flex-direction: column;
      } */
        }
        & .product-add-to-cart-pm {
            margin-bottom: $spacer;
            & .border-button.msc-add-to-pm {
                font-size: 1rem;
            }
            @media screen and (max-width: map-get($container-max-widths, 'xl')) {
                margin-top: $spacer;
            }
        }

        & .quantity-input {
            @media screen and (max-width: map-get($container-max-widths, 'xl')) {
                font-size: 1.3rem !important;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            flex-direction: column;
            & .product-add-to-cart {
                /* width: 100%; */
                margin-left: 0 !important;

                /* & .buttons, & .msc-add-to-cart  {
          width: 100%;
        } */
            }
        }
    }
    .vnc-addwishlist {
        position: relative;

        & .msc-add-to-wishlist {
            @extend %border-button;
            border: 0;
            min-width: auto;
            height: 100%;
            //padding-right: 1em;
            //padding-left: 0;
            //padding-top: 0;

            @include media-breakpoint-down(sm) {
                font-size: $msv-font-size-m;
            }
            @media screen and (max-width: $msv-breakpoint-s) {
                margin: auto;
            }
            @media screen and (max-width: map-get($container-max-widths, 'xl')) {
                padding: calc(1rem - 4px) 0;
            }
            &:before {
                display: inline-block;
                vertical-align: middle;
                //transform: scale(1.25);
                font-size: 23px;
                margin-left: 10px;

                @media screen and (max-width: map-get($container-max-widths, 'xl')) {
                    margin-left: 0;
                }
            }
            & .wishlist-button-text {
                &.-remove {
                    display: none;
                }
            }
            &__removing {
                & .wishlist-button-text {
                    &.-add {
                        display: none;
                    }
                }
                & .wishlist-button-text {
                    &.-remove {
                        display: inline-block;
                    }
                }
            }
        }
        &__text {
            text-transform: uppercase;
            text-decoration: underline;
            text-underline-offset: 4px;
            padding-left: 10px;
        }

        .ms-text-block {
            margin-top: 0px;
            margin-bottom: 1rem;
        }
    }

    & .ms-text-block {
        &.shipping_info {
            margin-bottom: 16px;
        }
    }

    &__availibility_row {
        display: flex;
        align-items: center;
        margin-bottom: $spacer-m;

        button {
            text-transform: uppercase;
            outline: none;
            cursor: pointer;
        }
        > div {
            margin-right: $layout-gutter;
            border-right: 1px solid $secondary-10;
            padding-right: $layout-gutter;
            &:last-child {
                margin-right: 0;
                padding-right: 0;
                border: none;
            }
        }
    }
    &__inventory-info {
        text-transform: uppercase;
    }
    &__inventory-label {
        font-size: 1.25rem;
        font-weight: 500;

        display: inline-block;
        &:before {
            content: '';
            width: 1rem;
            height: 1rem;
            display: inline-block;
            border-radius: 100%;
            border: 4px solid $secondary;
            margin-right: 0.5rem;
            vertical-align: top;
            display: none;
        }
    }
    &__inventory-code {
        &-avail {
            color: $green;
            &:before {
                border-color: $green;
            }
        }
        &-oos {
            color: $red;
            &:before {
                border-color: $red;
            }
        }
        &-low {
            color: $orange2;
            &:before {
                border-color: $orange2;
            }
        }
    }
    &__backinstock-btn {
        @include border-button($secondary);
        text-decoration: none;
    }

    .shipping_info {
        text-transform: uppercase;
        p {
            font-size: $msv-font-size-m;
        }
        a {
            font-weight: $msv-font-weight-normal;
            color: $secondary;
            display: inline-block;
            padding: 0.5rem 0;
            text-decoration: none;
            @include add-icon-icomoon($chevron, after);
            &:after {
                margin-left: 8px;
            }
            &:hover {
                &:after {
                    @extend %move-down-animation;
                }
            }
        }
    }
    .product-add-to-cart {
        margin-bottom: $spacer;

        .buttons {
            display: flex;
            align-items: center;
            /* width: 100%; */
            flex-wrap: wrap;
            /* margin-bottom: $spacer; */

            /*  @include media-breakpoint-down(sm) {
          width: calc(100% + 2rem);
        }
        @media screen and (max-width: $msv-breakpoint-s) {
          width: 100%;
        } */
        }

        .msc-add-to-cart {
            margin-right: 0;
            margin-left: 0;
            // padding: 14px 1.25rem !important;
            width: auto !important;
            vertical-align: top;
            //font-weight: 400 !important;

            @include media-breakpoint-down(lg) {
                margin-right: 0;
                width: calc(100% - 5em);
            }

            @include media-breakpoint-down(sm) {
                font-size: $msv-font-size-m;
                width: calc(100% - 4em);
            }
            @media screen and (max-width: $msv-breakpoint-s) {
                /* margin-bottom: $spacer; */
                width: 100%;
            }
        }
        .msc-add-to-pm {
            width: calc(90% - 5em);
            @include media-breakpoint-down(lg) {
                width: calc(100% - 5em);
            }
            @include media-breakpoint-down(sm) {
                font-size: $msv-font-size-m;
            }
            @include add-icon-icomoon($pm-cart-btn, $selector: after, $next-to-text: true, $margin: $layout-gutter, $scale: true);
        }

        .ms-wishlist-items__product-status {
            position: absolute;
            right: -25px;

            /* width: 100%; */
            padding-top: $buybox-product-status-padding-top;
            flex-basis: 90%;
            white-space: nowrap;
            background: transparent;
            text-align: right;
            @include media-breakpoint-down(lg) {
                flex-basis: 100%;
            }

            > div {
                &:before {
                    content: '';
                    display: inline-block;
                    width: 0;
                    position: absolute;
                    height: 0;
                    border-style: solid;
                    border-width: 0 1rem 1rem 1rem;
                    border-color: transparent transparent $secondary-10 transparent;
                    right: 2.3rem;
                    top: -1rem;
                    /* @include media-breakpoint-down(xs) {
              right: 1.3rem;
            } */
                }

                position: relative;
                background: $secondary-10;
                display: inline-block;
                padding-right: 3rem;
            }
        }

        //.msc-add-to-wishlist {
        //  @extend %border-button;
        //  border:0;
        //  min-width: auto;
        //  padding-right: 1em;
        //   padding-left: 1em;

        //  @include media-breakpoint-down(sm) {
        //     font-size: $msv-font-size-m;
        //  }
        //  @media screen and (max-width: $msv-breakpoint-s) {
        //    margin: auto;
        //  }
        //  &:before {
        //    display: inline-block;
        //     transform: scale(1.25);
        //   }
        // }
    }

    &__find-in-store {
        width: auto;
    }

    &__find-in-store-heading {
        color: $secondary;
        margin-bottom: 0;
        display: none;
    }

    &__find-in-store-description {
        color: $secondary;
        display: none;
    }

    &__find-in-store-button {
        @include text-link($secondary, after);
        @include add-icon-icomoon($marker, before, true, 10px);
        text-decoration: none;
    }

    &__dialog {
        max-width: $msv-dialog-width;

        .msc-modal__content {
            padding: $msv-dialog-modal-content-padding;
        }

        &__header {
            .msc-modal__title {
                @include font-content-l();

                span {
                    &:nth-child(1) {
                        font-weight: var(--msv-font-weight-bold);
                    }
                }
            }
        }

        &__body {
            @include font-content-l();
            text-align: center;

            .msc-empty_image {
                @include image-placeholder($msv-dialog-msc-empty_image-size);
                padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
            }
        }

        &__image {
            margin-right: auto;
            margin-left: auto;
        }

        &__product-title {
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-variants {
            @include font-content-m();
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-price {
            font-weight: var(--msv-font-weight-bold);
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__go-to-cart {
            @include primary-button();
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }

        &__back-to-shopping {
            @include secondary-button();
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }
    }

    &__badges {
        display: flex;
        flex-direction: row;
        & .badge {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 10px;
            max-width: 80px;
            img {
                width: 40px;
                height: 40px;
            }
            span {
                font-size: 10px;
                font-weight: $msv-font-weight-bold;
                text-transform: uppercase;
                text-align: center;
                padding: 10px 0;
                &.grey {
                    color: #b7bec5;
                }
                &.dark_green {
                    color: #6a8f5b;
                }
                &.dark_slate_gray {
                    color: #155158;
                }
                &.green {
                    color: #729f29;
                }
                &.dark_yellow {
                    color: #d6b075;
                }
                &.yellow {
                    color: #dfae4a;
                }
                &.brown {
                    color: #86704d;
                }
                &.dark_brown {
                    color: $primary;
                }
                &.blue {
                    color: #67aed1;
                }
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        &__media-gallery {
            display: block;
            flex-basis: $media-gallery-width;
            min-width: $media-gallery-width;
            flex-grow: 1;
            flex-wrap: nowrap;
            // padding-right: $gutter-width;
        }

        .ms-media-gallery {
            padding-bottom: 54px;
            max-height: 400px;

            .msc-carousel {
                &__indicators {
                    top: auto;
                    bottom: -$spacer;
                    margin-left: 5px;

                    & li {
                        height: 4px;
                    }
                }

                &__control {
                    &__prev,
                    &__next {
                        background-color: transparent;
                        bottom: -40px;
                        height: auto;
                        top: auto;
                        width: auto;
                    }

                    &__prev__icon {
                        background-image: $carousel-control-prev-icon-bg;
                        margin-left: 0;
                    }

                    &__next__icon {
                        background-image: $carousel-control-next-icon-bg;
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .privacyForBackInStock {
        display: none;
        font-size: $msv-font-size-s;
    }
    .ms-buybox__backInStock {
        .msc-alert {
            position: absolute;
            z-index: 10;
            padding-right: 3rem;
        }
    }

    //mobile vp
    @media (max-width: $msv-breakpoint-m) {
        flex-flow: column;
    }
    .ms-buybox__backInStock,
    .ms-buybox__find-in-store {
        display: none;
    }

    .availibility {
        &__OOS {
            .quantity {
                display: none;
            }
            .ms-buybox__find-in-store,
            .ms-buybox__backInStock,
            .privacyForBackInStock {
                display: block;
            }
            .ms-buybox__backInStock + .msc-add-to-cart {
                display: none;
            }
        }
        &__AVAIL {
        }
    }
    .ms-buybox__quantity {
        flex: 0 0 auto;
    }
    .product-add-to-cart {
        display: flex;
        /* flex-direction: column-reverse; */
        flex-direction: column;
    }
    .msc-alert.msc-alert-danger {
        /* margin-left: -330px;
    margin-top: 80px;
    width: calc(100% + 196px); */

        @media screen and (max-width: 992px) {
            margin-left: 0 !important;
            width: 100%;
        }

        @media screen and (max-width: 768px) {
            margin-left: 0 !important;
            width: 100%;
        }
    }

    & .buybox-product-specification {
        padding: 0 16px;

        .specifications_rows {
            display: flex;
            flex-direction: column;
            margin-bottom: $spacer-m;

            @media screen and (max-width: $msv-breakpoint-m) {
                width: 100%;
                margin-left: 0;
            }

            align-items: flex-end;

            > div {
                &.Productdescription {
                    &.-accordion {
                        border-top: 1px solid rgba(0, 79, 113, 0.25);

                        @media screen and (max-width: $msv-breakpoint-m) {
                            border-top: 0;
                        }
                    }
                }

                &.Productdescription,
                &.Description {
                    /* order: 1; */
                    color: $blue;
                }

                &.Reassurance {
                    /* order: 2; */
                    color: $blue;
                }

                &.Ingredients {
                    color: $blue;
                    /* order: 3; */
                    padding: $spacer;

                    @include media-breakpoint-up(md) {
                        padding: $spacer-xl;
                    }
                }

                &.NutritionalValuesJSON,
                &.NutritionalValuesJSON {
                    /* order: 4; */
                    color: $blue;
                }

                &.Productlabels {
                    /* order: 5; */
                    color: $blue;
                }

                &.ms-social-share {
                    /* order: 6; */

                    & .ms-social-share-ul.caption:before {
                        font-weight: $msv-font-weight-heavy;
                    }
                }

                h4 {
                    text-transform: uppercase;
                    font-size: $msv-icon-size-small;
                    font-family: $secondary-font-family;
                    font-weight: $msv-font-weight-heavy;
                }

                .content {
                    font-size: $msv-font-size-m;
                    font-family: $msv-primary-font-family;
                    font-weight: $msv-font-weight-normal;
                    max-height: 0;
                    height: 0;
                    /* visibility: hidden; */
                    overflow: hidden;
                    /* opacity: 0; */
                    color: $primary;
                    text-align: left;
                    transition: max-height 2s;

                    & a {
                        font-size: $msv-font-size-m;
                        font-family: $msv-primary-font-family;
                        font-weight: $msv-font-weight-normal;
                        color: $primary;
                        text-decoration: underline;
                    }

                    &.-expanded {
                        height: auto;
                        max-height: 1000px;
                        /* visibility: visible; */
                        /* opacity: 1; */
                        padding: 1rem 0 0 0;
                        &.-nopadding {
                            padding: 0;
                        }
                    }

                    & .reassurance-container {
                        //border: 2px solid $blue;
                        & .reassurance-row {
                            // margin: 0 20px;
                            padding: 15px 0;
                            display: flex;
                            flex-direction: row;

                            &.-border {
                                border-bottom: 1px solid $secondary-20;
                            }

                            & .reassurance-col {
                                &.-image {
                                    min-width: 50px;
                                    display: flex;
                                    align-items: center;

                                    & .msc_image {
                                        width: 30px;
                                        height: auto;
                                    }
                                }

                                &.-text {
                                    display: flex;
                                    flex-direction: column;
                                    //padding-left: 20px;
                                    color: $primary;

                                    & .title {
                                        text-transform: uppercase;
                                        font-size: 13px;
                                        font-family: $primary-font-family;
                                        font-weight: $msv-font-weight-heavy;
                                    }

                                    & .description {
                                        font-size: $msv-icon-size-small;
                                        font-family: $primary-font-family;
                                        font-weight: $msv-font-weight-normal;
                                    }
                                }
                            }
                        }
                    }

                    & .nutritional-values-container {
                        width: 50%;

                        @media screen and (max-width: map-get($container-max-widths, 'xl')) {
                            width: 100%;
                        }
                    }

                    & .nutritional-values-row,
                    .nutritional-values-row .-subcategory {
                        display: flex;
                        flex-direction: row;

                        &.-subcategory {
                            padding-left: 0.5rem;
                        }

                        & .nutritional-values-col {
                            color: $primary;
                            font-size: $msv-font-size-m;
                            font-weight: $msv-font-weight-normal;
                            line-height: $msv-line-height-m;

                            &.-spacer {
                                flex: 1;
                                margin: 0 0.3rem;

                                &.-line {
                                    border-bottom: 1px solid $light-beige;
                                }
                            }

                            &.-pr {
                                padding-right: 0.3rem;
                            }

                            &.-subcategory {
                                color: $beige;
                                font-size: $msv-font-size-s;
                            }
                        }
                    }
                }

                &.-accordion {
                    border-bottom: 1px solid rgba(0, 79, 113, 0.25);
                    padding: 24px 0;
                    width: 100%;
                }

                & .accordion-header {
                    & > button {
                        border: none;
                        background-color: transparent;
                        padding: 0;
                        color: $blue;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        line-height: 1rem;
                        cursor: pointer;
                        & h2 {
                            margin-bottom: 0;
                            text-transform: uppercase;
                            font-size: $msv-icon-size-small;
                            font-family: $secondary-font-family;
                            font-weight: $msv-font-weight-heavy;
                        }
                    }

                    & .accordion-button {
                        &.-expanded {
                            @include add-icon($msv-ChevronUp);
                        }

                        &.-collapsed {
                            @include add-icon($msv-ChevronDown);
                        }

                        border: none;
                        background-color: transparent;
                        padding: 0;
                        height: 20px;
                        width: 20px;
                        color: $blue;
                    }
                }
            }
        }
    }

    .msc-alert__header {
        & > * {
            display: none;
        }

        & > .msi-exclamation-triangle {
            display: block;
        }
    }
}
#renderPage {
    #main {
        .ms-buybox {
            .left-container {
                .ms-media-gallery {
                    height: auto;
                    max-height: none;

                    &__carousel {
                        height: auto;
                        flex: initial;
                        max-height: none;

                        & .msc-carousel__inner {
                            height: auto;

                            .msc-carousel__item {
                                height: auto;

                                & .ms-inline-zoom {
                                    width: auto;
                                    height: auto;
                                    text-align: center;
                                }
                                & picture {
                                    &:first-of-type {
                                        display: inline-block;
                                        // height: 60vh;
                                        max-height: 60vh;
                                        width: auto;
                                        height: 100%;

                                        @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                                            display: flex;
                                            justify-content: center;
                                        }

                                        img {
                                            height: 100%;
                                            width: auto;
                                            max-height: 100%;

                                            @media screen and (max-width: $msv-breakpoint-m) {
                                                width: 100%;
                                                height: auto;
                                            }
                                            @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                                                max-width: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 1199px) {
                        & .msc-ss-carousel-strip {
                            & .msc-ss-carousel-slide {
                                & .msc-ss-carousel-item.ms-media-gallery__thumbnail-item {
                                    max-height: 100px;

                                    // & picture {
                                    //   & img {
                                    //     width: auto;
                                    //     max-width: none;
                                    //     max-height: 100%;
                                    //     height: 100%;
                                    //   }
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* .ms-product-collection__heading {
  text-transform: capitalize;
  text-align: left;
} */
