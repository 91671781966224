.ms-refine-submenu {
    &__list {
        border: 0;
        margin: 0;
        min-width: 0;
        padding: 0;

        &[aria-label='Percent Sale'] > li:first-child {
            display: none;
        }
    }
    &.Price button {
        position: relative;

        &:before {
            html[lang='it'] &,
            html[lang='fr'] &,
            html[lang='de'] & {
                background: $white;
                display: block;
                position: absolute;
                top: 0;
                text-align: left;
                width: 8em;
            }
            html[lang='it'] & {
                content: 'Prezzo';
            }
            html[lang='fr'] & {
                content: 'Prix';
            }
            html[lang='de'] & {
                content: 'Preis';
            }
        }
    }
    &-item {
        display: block;

        &.multi-select {
            @include add-icon-icomoon($check);

            &:before {
                width: 1.4em;
                height: 1.4em;
                line-height: 1.4em;
                background: $white;
                color: transparent;
                border: 1px solid $secondary;
                display: inline-block;
                margin-right: $layout-gutter;
                text-align: center;
                margin-top: -3px;
            }
        }
        &.multi-select-checked {
            @include add-icon-icomoon($check);

            &:before {
                width: 1.4em;
                height: 1.4em;
                line-height: 1.4em;
                background: $white;
                color: $secondary;
                border: 1px solid $secondary;
                display: inline-block;
                margin-right: $layout-gutter;
                text-align: center;
                margin-top: -3px;
            }
        }
        &.single-select {
            @include add-icon($msv-RadioBtnOff, before, 400);

            &-checked {
                @include add-icon($msv-RadioBtnOn, before, 400);
            }
        }
        &.single-select:before,
        &.single-select-checked:before {
            font-size: var(--msv-body-font-size-xl);
            padding-top: 2px;
            vertical-align: top;
        }
        &#Specialties_0 {
            display: none;
        }
        &__rating {
            @include font-content-m(var(--msv-font-weight-light));
            display: inline-block;
            padding-left: $layout-gutter;
        }
        &__range-refiner .slider__labels-item {
            color: $msv-gray-900;
            font-size: var(--msv-body-font-size-s);
            line-height: $msv-line-height-s;
        }
        .msc-rating {
            @include font-content-m(var(--msv-font-weight-light));
            margin-bottom: $spacer;
        }
    }
    &__toggle {
        &_collapsed {
            @include add-icon-icomoon($more, after);
        }
        &_expanded {
            @include add-icon-icomoon($less, after);
        }
    }
}
.msc-choice-summary__clear-all {
    @include add-icon($msv-Cancel, after);
}
