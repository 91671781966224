.ms-store-select {
    &__found-locations {
        margin-bottom: $spacer;
    }

    &__search {
        display: flex;
        margin-bottom: 1rem;
        min-height: 58px;
        position: relative;
        z-index: 1;

        &:after {
            border-bottom: 1px solid rgba(0, 79, 113, 0.5);
            content: '';
            width: calc(100% - 1rem);
            display: block;
            position: absolute;
            height: 10px;
            background: #fff;
            bottom: -1rem;
            z-index: 1;
        }

        &-form {
            flex: auto;
            display: flex;
            height: 58px;
            margin-bottom: $spacer;
        }

        &-input {
            flex: auto;
            font-size: $msv-font-size-l;
            margin-top: 0;
            margin-bottom: 0;
        }

        &-button {
            align-items: center;
            order: 2;
            display: flex;
            @extend %solid-button;
            @include add-icon-icomoon($search);
        }

        .search-btn-disabled {
            background-color: $secondary-20;
        }
    }
    //hide because there is only one store
    &__search-see-all-stores {
        display: none;
    }
    &__location-line-item-store-index {
        margin-right: 0.5rem;
        &:after {
            content: '.';
        }
    }
    &__store-hours-details {
        display: flex;
        flex-wrap: wrap;

        .ms-store-select__store-hours-day {
            // flex: 0 0 40%;
            display: inline-block;
        }

        .ms-store-select__store-hours-time {
            // flex: 0 0 60%;
            display: inline-block;
            &:before {
                content: ', ';
            }
        }
    }

    &__locations {
        border-top: $border-separator;
        @include scrollbar-light;
    }

    &__location {
        padding: $spacer 0;
        border-bottom: $border-separator;
        &:last-child {
            border: 0;
        }
        &-line-item-header {
            margin-bottom: 0.5rem;
            color: $primary;
        }

        &-line-item {
            &.location-type__dealer {
                .ms-store-select__location-line-item-header {
                    color: $secondary;
                }
            }
        }

        &-line-item-store-name {
            font-size: $msv-font-size-xl;
            font-weight: $msv-font-weight-bold;
        }
        &-line-header {
            color: $msv-black;
            font-size: 1.25rem;
            font-weight: $msv-font-weight-bold;
        }
        &-line-store-distance {
        }

        &-line-item-content {
            display: flex;
            flex-direction: column;
            font-size: 1.25rem;
            white-space: pre-wrap;
        }

        &-line-stock-status {
        }

        &-line-shop-address {
            &-section {
                display: flex;
            }
            &-glyph-icon {
                @include add-icon-icomoon($pick-up, before, true, $layout-gutter, false);
                width: 4rem;
                text-align: center;
                &:before {
                    font-size: 2rem;
                    color: $primary;
                }
            }
        }

        &-line-item.location-type__dealer {
            .ms-store-select__location-line-shop-address-glyph-icon {
                &:before {
                    color: $secondary;
                }
            }
        }

        &-line-shop-phone {
            &-section {
                display: flex;
            }
            &-text {
                margin-left: 4rem;
            }
            &-icon {
                display: none;
            }
        }

        &-line-select-store {
            cursor: pointer;

            &:hover,
            &:focus {
            }
        }
    }

    &__terms-link {
    }
}

@media screen and (min-width: $msv-breakpoint-m) {
    .ms-store-select {
        &__location {
            &-line-item-content {
                flex-direction: row;
            }
            &-line-item-contact-info,
            &-line-item-store-hours {
                width: 33%;
                margin-right: 1%;
            }
            &-line-item-store-hours {
                padding-left: 1rem;
            }
            &-line-item-store-availability {
                width: 32%;
                text-align: right;
            }
        }
        &__locations {
            padding-right: 20px;
        }
    }
}
