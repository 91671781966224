$msv-cart-border: rgba($secondary, 0.5);
$cart-cartline-product-width: 75%;
$cart-cartline-product-variant-name-padding-left: 5px;
$cartline-item-bopis-shipping-margin-bottom: 10px;
$cartline-item-bopis-fulfillment-store-margin-top: 3px;
$cartline-item-bopis-btn-margin-top: 8px;
$cartline-item-discount-price-margin-right: 5px;
$cartline-item-product-savings-width: 10rem;
$cart-cartline-product-quantity-width: 13rem;
$cart-empty-cart-width: 180px;
$cart-line-padding-top: 10px;
$cart-line-card-height: 220px;

//style presets
:root {
    --msv-cart-font-color: #{$msv-gray-900};
}
.msc-cart {
    &-lines-item {
        border-bottom: 1px solid $msv-cart-border;
        min-height: $cart-line-card-height;
        padding: $spacer-l 0;

        @media screen and (max-width: $msv-breakpoint-l) {
            padding: $spacer-s 0;
        }

        &__inner {
            display: flex;
            flex-direction: column;
            min-height: 12rem;
            overflow: hidden;
            position: relative;
        }
    }
    &-line {
        display: flex;
        flex-grow: 1;

        &__content {
            display: flex;
            width: 100%;
        }
        &-item {
            &-product-discount {
                color: var(--msv-success-color);
                font-weight: $msv-font-weight-normal;
                margin-bottom: $spacer;
                word-break: break-word;
            }
        }
        &__product {
            flex: 1 1 $cart-cartline-product-width;
            padding-left: $layout-gutter;
            padding-right: $layout-gutter;

            &-image {
                margin-right: $layout-gutter;
            }
            &-variant {
                &-size,
                &-color,
                &-style,
                &-config {
                    .name {
                        color: var(--msv-cart-font-color);
                        padding-left: $cart-cartline-product-variant-name-padding-left;
                    }
                }
            }
        }
        &__promo-codes {
            display: inline-block;
        }
        &__product-quantity {
            flex: 0 0 $cart-cartline-product-quantity-width;
            padding-left: $layout-gutter;
            padding-right: $layout-gutter;

            &-label {
                display: none;
            }
            .quantity {
                white-space: nowrap;

                .quantity-input {
                    border: none;
                    color: $secondary;
                    font-size: $msv-font-size-xl;
                    display: inline-block;
                    outline: none;
                    padding: 0;
                    vertical-align: middle;
                    width: 3rem;
                    -moz-appearance: textfield;

                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                    }
                }
                .quantity__controls {
                    background: var(--msv-buybox-btn-bg);
                    background-position: center;
                    background-repeat: no-repeat;
                    border: 1px solid $secondary;
                    color: $secondary;
                    cursor: pointer;
                    height: 3rem;
                    min-width: auto;
                    outline: none;
                    padding-right: 0px;
                    padding-left: 0px;
                    vertical-align: middle;
                    width: 3rem;

                    &:disabled {
                        border-color: $secondary-40;
                        color: $secondary-40;
                    }
                }
                .increment {
                    @include add-icon-icomoon($more);
                }
                .decrement {
                    @include add-icon-icomoon($less);
                }
            }
        }
        &__product-price,
        &__product-savings {
            .msc-price .discount-value.msc-price__strikethrough {
                @include font-content-m-strikethrough();
                display: inline;
                margin-right: $cartline-item-discount-price-margin-right;
            }
        }
        // hide extra price component
        &__product-price {
            display: none;
        }
        &__product-savings {
            font-weight: $msv-font-weight-normal;
            color: var(--msv-success-color);
            text-align: right;
            width: $cartline-item-product-savings-width;
            padding-left: 15px;

            &-actual {
                display: block;
                margin-bottom: $spacer;
                min-height: 3.8rem;

                .msc-price__actual {
                    color: $msv-black;
                    display: block;
                    font-size: $msv-font-size-xl;
                }
            }
        }
        &__product-title {
            @extend %h3;
            color: $msv-black;
            font-size: $msv-font-size-xl;
            min-height: 3.5rem;

            a {
                @include a-reverse;
                color: $msv-black;
                font-weight: $msv-font-weight-light;
            }
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                font-weight: $msv-font-weight-light;
                color: $primary;
                margin-top: 1rem;
            }
            .subproduct--pm {
                margin-bottom: $spacer/2;
                font-size: $msv-font-size-m;
                line-height: 1.2em;

                &_price {
                    font-weight: $msv-font-weight-bold;
                    font-size: 0.95em;
                }
            }
        }
        &__product-variants {
            display: none;
        }
        &__tools {
            justify-self: flex-end;
            padding-right: $layout-gutter;
            padding-left: $layout-gutter;
        }
        &__remove-item,
        &__add-to-wishlist {
            @include text-link($secondary, after);
            cursor: pointer;
            margin-right: $layout-gutter;
            margin-left: $layout-gutter;
            text-decoration: none;

            &:before {
                transform: scale(1.4);
            }
        }
        &__remove-item {
            @include add-icon-icomoon($trash, before, true, 10px);
        }
        &__add-to-wishlist {
            @include add-icon-icomoon($heart-btn, before, true, 10px);
        }
        &__bopis-container {
            float: right;
            padding-top: 3rem;
            text-align: right;
        }
        &__bopis-method {
            @include font-glyph-small();

            .ship {
                display: none;
            }
            .pick-up {
                font-weight: $msv-font-weight-normal;
            }
        }
        &__bopis-shipping {
            align-self: baseline;
            margin-bottom: $cartline-item-bopis-shipping-margin-bottom;

            input {
                @include vfi();
            }
        }
        &__bopis-store-pickup {
            align-self: baseline;
        }
        &__bopis-fullfilment {
            text-align: right;
        }
        &__bopis-changestore {
            @extend %text-link;
            display: block;
            text-align: right;
            width: 100%;
        }
        &__bopis-fullfilment {
            &-store {
                @include font-content-m(var(--msv-font-weight-bold));
                color: var(--msv-cart-font-color);
                margin-top: $cartline-item-bopis-fulfillment-store-margin-top;
            }
        }
        &__bopis-btn {
            @include border-button($secondary, true, true);
            margin-top: $cartline-item-bopis-btn-margin-top;
        }
    }
    &__empty-cart {
        width: $cart-empty-cart-width;

        .msc-cart-line {
            padding-top: $cart-line-padding-top;
        }
    }
}
