.layout2021 {
    [data-m-t='country-selector'] {
        margin-right: $spacer/2;
        margin-left: $spacer;
        opacity: 1;
        position: relative;
        transform: translateY(0);
        transition: all 0.5s;

        &:focus,
        *:focus {
            outline: none !important;
        }

        .mobile & {
            margin-bottom: 0.25rem;
        }

        img {
            display: inline-block;
            margin-left: $spacer/2;
            height: 1.6rem;

            .mobile & {
                height: 1.2rem;
            }
        }

        a,
        button {
            @include underline-link-animation($white);
            -webkit-appearance: none;
            appearance: none;
            border: 0;
            color: $secondary;
            cursor: pointer;
            display: flex;
            font-size: $menu-top--font-size;
            font-family: $primary-font-family;
            align-items: center;
            flex-wrap: nowrap;
            line-height: 2rem;
            margin-top: 0;
            margin-bottom: 0;
            outline: none;
            position: relative;
            text-decoration: none;
            white-space: nowrap;
            background: none;

            &.ms-nav__list__item__button,
            &.drawer__button {
                text-transform: uppercase;
            }

            @include media-breakpoint-up(xl) {
                color: $menu-top--color;
                line-height: $topbar--height;
            }
        }

        .ms-nav__list__item__button {
            @include add-icon-icomoon($chevron, after);
            background: none;
            color: $secondary;
            cursor: pointer;
            display: flex;
            align-items: center;
            outline: none;
            padding: 0;
            text-transform: uppercase;

            @include media-breakpoint-up(xl) {
                color: $menu-top--color;
            }

            &:after {
                display: inline-block;
                font-size: 0.8em;
                margin-left: 0.3rem;
            }

            &:hover,
            &:focus {
                &:after {
                    @extend %move-down-animation;
                }
            }

            + div {
                background: $msv-white;
                color: $secondary;
                text-align: left;
                padding-bottom: $spacer;
                z-index: 1;

                @include media-breakpoint-up(xl) {
                    border: 2px solid $secondary;
                    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.13);
                    min-width: 20rem;
                    position: absolute;
                    top: 80%;
                    right: 0;
                }

                li {
                    margin-bottom: 0.3rem;
                    padding: 0 $spacer;

                    @include media-breakpoint-up(xl) {
                        padding: 0 $spacer-m;
                    }
                }

                a {
                    color: $secondary;
                    text-transform: none;
                    font-weight: $msv-font-weight-bold;
                    padding: 0.5rem 0;
                    display: inline-flex;

                    &:before {
                        background: $orange;
                    }

                    img {
                        margin-right: $spacer/2;
                        margin-left: 0;
                    }

                    &.current {
                        @include add-icon-icomoon($check, after);
                        background: rgba(70, 145, 94, 0.1);
                        display: flex;
                        margin-left: -#{$spacer};
                        margin-right: -#{$spacer};
                        padding-left: $spacer;
                        padding-right: $spacer;

                        @include media-breakpoint-up(xl) {
                            margin-left: -#{$spacer-m};
                            margin-right: -#{$spacer-m};
                            padding-left: $spacer-m;
                            padding-right: $spacer-m;
                        }

                        &:before {
                            content: none;
                        }

                        &:after {
                            flex-grow: 1;
                            text-align: right;
                            transform: scale(1.5);
                            transform-origin: right;
                            color: rgb(70, 145, 94);
                        }
                    }
                }
            }
        }

        .menuIntroText {
            color: $secondary;
            margin: 0;
            padding: $spacer;

            @include media-breakpoint-up(xl) {
                padding: $spacer-m;
            }
        }

        .drawer {
            &__button {
                display: flex;
                align-items: center;
                white-space: nowrap;

                &text.__start {
                    display: flex;
                    align-items: center;
                    line-height: 2.2em;

                    img {
                        margin-left: 10px;
                        margin-right: 0;
                    }
                }

                + div {
                    width: 100%;

                    a {
                        display: flex;
                        width: 100%;
                        flex-basis: 100%;
                        padding: $spacer/2 $spacer;

                        &:before {
                            background: $orange;
                        }

                        img {
                            margin-left: 0;
                            margin-right: 0.5rem;
                        }

                        &.current {
                            @include add-icon-icomoon($check, after);
                            background: rgba(70, 145, 94, 0.1);

                            &:after {
                                color: rgb(70, 145, 94);
                                flex-grow: 1;
                                text-align: right;
                                transform: scale(1.5);
                                transform-origin: right;
                            }
                        }
                    }
                }
            }
        }

        .drawer__glyph {
            @include add-icon-icomoon($chevron);
            font-size: $msv-font-size-m;
            margin-left: 0.75rem;

            &.ms-nav__drawer-open:before {
                transform: rotate(180deg) translateY(-5px);
            }
        }
    }
}

.layout2022 {
    [data-m-t='country-selector'] {
        margin-right: $spacer/2;
        margin-left: $spacer;
        opacity: 1;
        position: relative;
        transform: translateY(0);
        transition: all 0.5s;

        &:focus,
        *:focus {
            outline: none !important;
        }

        .mobile & {
            margin-bottom: 0.25rem;
        }

        img {
            display: inline-block;
            margin-left: $spacer/2;
            height: 1.5rem;

            .mobile & {
                height: 1.2rem;
            }
        }

        a,
        button {
            -webkit-appearance: none;
            appearance: none;
            border: 0;
            color: $primary;
            cursor: pointer;
            display: flex;
            font-size: $menu-top--font-size;
            font-family: $primary-font-family;
            align-items: center;
            flex-wrap: nowrap;
            line-height: 2rem;
            margin-top: 0;
            margin-bottom: 0;
            outline: none;
            position: relative;
            text-decoration: none;
            white-space: nowrap;

            &.ms-nav__list__item__button,
            &.drawer__button {
                text-transform: uppercase;
            }
        }

        .ms-nav__list__item__button {
            @include add-icon-icomoon($chevron, after);
            background: none;
            color: $primary;
            cursor: pointer;
            display: flex;
            align-items: center;
            outline: none;
            padding: 0;
            text-transform: uppercase;

            &:after {
                display: inline-block;
                font-size: 0.8em;
                margin-left: 0.3rem;
            }

            &:not([aria-expanded='true']) {
                &:hover,
                &:focus {
                    font-weight: $msv-font-weight-bold;

                    &:after {
                        //@extend %move-down-animation-once;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transform: translateY(0.3em);
                    }
                }
            }

            &[aria-expanded='true'] {
                font-weight: $msv-font-weight-bold;

                &:after {
                    transform: rotate(180deg);
                    transition-property: transform;
                    transition-duration: 0.3s;
                }
            }

            + div {
                background: $msv-white;
                color: $primary;
                text-align: left;
                padding: 20px;
                z-index: 1;

                @include media-breakpoint-up(l) {
                    border: 1px solid #d1d1d1;
                    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
                    min-width: 20rem;
                    position: absolute;
                    left: 0;
                }
                h4 {
                    border-bottom: 1px solid rgba(0, 79, 113, 0.5);
                }
                + li {
                    margin-top: 0.3rem;
                }
                li {
                    margin-bottom: 0.3rem;
                }

                a {
                    @include underline-link-animation($white);
                    color: $secondary;
                    text-transform: none;
                    font-weight: $msv-font-weight-bold;
                    padding: 0.5rem 0;
                    display: inline-flex;

                    &:before {
                        background: $orange;
                    }

                    img {
                        margin-right: $spacer/2;
                        margin-left: 0;
                    }

                    &.current {
                        @include add-icon-icomoon($check, after);
                        background: rgba(70, 145, 94, 0.1);
                        display: flex;
                        margin-left: -#{$spacer};
                        margin-right: -#{$spacer};
                        padding-left: $spacer;
                        padding-right: $spacer;

                        @include media-breakpoint-up(xl) {
                            margin-left: -#{$spacer-m};
                            margin-right: -#{$spacer-m};
                            padding-left: $spacer-m;
                            padding-right: $spacer-m;
                        }

                        &:before {
                            content: none;
                        }

                        &:after {
                            flex-grow: 1;
                            text-align: right;
                            transform: scale(1.5);
                            transform-origin: right;
                            color: rgb(70, 145, 94);
                        }
                    }
                }
            }
        }

        .menuIntroText {
            color: $secondary;
            margin: 0;

            @include media-breakpoint-up(xl) {
                padding-bottom: $spacer-m;
            }
        }

        .drawer {
            &__button {
                display: flex;
                align-items: center;
                white-space: nowrap;

                &text.__start {
                    display: flex;
                    align-items: center;
                    line-height: 2.2em;

                    img {
                        margin-left: 10px;
                        margin-right: 0;
                    }
                }

                + div {
                    width: 100%;

                    a {
                        display: flex;
                        width: 100%;
                        flex-basis: 100%;
                        padding: $spacer/2 0px;

                        &:before {
                            background: $orange;
                        }

                        img {
                            margin-left: 0;
                            margin-right: 0.5rem;
                        }

                        &.current {
                            @include add-icon-icomoon($check, after);
                            background: rgba(70, 145, 94, 0.1);

                            &:after {
                                color: rgb(70, 145, 94);
                                flex-grow: 1;
                                text-align: right;
                                transform: scale(1.5);
                                transform-origin: right;
                            }
                        }
                    }
                }
            }
        }

        .drawer__glyph {
            @include add-icon-icomoon($chevron);
            font-size: $msv-font-size-m;
            margin-left: 0.75rem;

            &.ms-nav__drawer-open:before {
                transform: rotate(180deg) translateY(-5px);
            }
        }
    }

    .mobile.menus {
        padding-bottom: 2rem;
    }

    .drawer__submenu {
        width: calc(100% - 1rem);
    }
}
