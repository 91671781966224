:root {
    --msv-checkout-loyalty-font-size: var(--msv-body-font-size-l);
    --msv-checkout-loyalty-btn-bg: var(--msv-accent-brand-color);
    --msv-checkout-loyalty-btn-font-color: var(--msv-font-secondary-color);
    --msv-checkout-loyalty-btn-border: var(--msv-accent-brand-color);
    --msv-checkout-loyalty-btn-font-size: var(--msv-body-font-size-m);
}
.ms-checkout-loyalty {
    border: $border-separator;
    color: $secondary;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: $spacer-l;
    padding: $layout-gutter;
    max-width: 600px;

    &__heading {
        color: $secondary;
        flex-basis: 100%;
        font-size: 1.25rem;
        font-weight: normal;
        margin-bottom: 0;
    }
    &__applied {
        .ms-checkout-loyalty__heading {
            font-size: $msv-text-size;
            line-height: $msv-text-line-height;
        }
        &-amount {
            padding-top: $spacer;
            width: 100%;
        }
        &-value {
            font-weight: var(--msv-font-weight-bold);
            margin-left: 0;
        }
    }
    &__card-number {
        display: block;
        font-size: 2rem;
        font-weight: $msv-font-weight-light;
        margin-bottom: $spacer;
    }
    &__drawer {
        background: $secondary-10;
        color: $secondary;
        flex-basis: 100%;
        padding: $spacer;

        .collapse {
            display: none;

            &.show {
                display: initial;
            }
        }
    }
    &-remove-btn {
        @extend %hyperlink;
        margin-left: $layout-gutter;
    }
    .drawer__button {
        @include add-icon-icomoon($chevron, after, 10px);
        background-color: transparent;
        border: 0;
        color: $secondary;
        cursor: pointer;
        font-family: 'Brandon Grotesque', sans-serif;
        padding: 0;

        p {
            margin-bottom: 0;
        }
    }
    .drawer__button[aria-expanded='true']:after {
        transform: scale(1.25) rotate(180deg);
    }
    &__program {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 320px;

        span {
            margin-top: 0.5rem;
        }
        &-title {
            @include font-content(
                $font-weight: var(--msv-font-weight-light),
                $font-size: var(--msv-checkout-loyalty-btn-font-size),
                $line-height: $msv-line-height-m
            );
            @include add-icon($msv-file, before, $msv-outline-icon-weight);
            display: block;
            margin-left: 1.5rem;
            position: relative;

            &:before {
                position: absolute;
                left: -#{1.5rem};
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &-available,
        &-expiring {
            @include font-content-s();
            display: inline-block;
            flex-basis: 51%;
            flex-grow: 1;
        }
        &-points {
            @include font-content-s(var(--msv-font-weight-bold));
            display: inline-block;
        }
    }
    &__covered-text {
        display: inline-block;
        font-weight: $msv-font-weight-bold;
        margin-right: 4px;
    }
    &__amount {
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
        margin-top: 1.25rem;

        &-label {
            color: $msv-black;
            display: block;
            margin-bottom: 0.25rem;
            width: 100%;
        }
        &-input {
            font-size: $msv-font-size-l;
            width: 100%;
        }
        &-button {
            @extend %solid-button;
            margin-top: 1.25rem;
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
            width: 100%;
        }
    }
    @media screen and (min-width: $msv-breakpoint-l) {
        &__amount {
            &-input {
                flex-grow: 1;
                max-width: 610px;
                width: auto;
            }
            &-button {
                margin-top: 0;
                width: auto;
            }
        }
    }
}
