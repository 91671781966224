@mixin make-container-padding($paddings: $grid-gutter-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $padding in $paddings {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            padding-left: $padding;
            padding-right: $padding;
        }
    }
}

@mixin basicTruncation {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin basic-outline-offset($outline-width: 1px, $outline-style: dashed, $outline-color: $black, $outline-offset: -1px) {
    outline: $outline-width $outline-style $outline-color;
    outline-offset: $outline-offset;
}

@mixin vfi {
    &:focus {
        outline: none;
    }
}

@mixin vfi-psuedo($selector: after, $offset: -2px, $position: relative) {
    position: $position;

    &:focus {
        outline: 1px dashed $white;

        &:#{$selector} {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            outline: 1px dashed $black;
            outline-offset: $offset;
        }
    }
}

@mixin validation($bg-color, $border, $color) {
    background-color: $bg-color;
    border: 1px solid $border;
    color: $color;
}

@mixin validation-success($bg-color: $msv-gray-300, $border: $msv-gray-900, $color: $msv-gray-900) {
    @include validation($bg-color, $border, $color);
}

@mixin validation-error($bg-color: var(--msv-error-color), $border: var(--msv-error-color), $color: var(--msv-font-secondary-color)) {
    @include validation($bg-color, $border, $color);
}

@mixin scrollbar {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button {
        width: 4px;
    }

    &::-webkit-scrollbar-button {
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $secondary-10;
        border: thin solid $secondary;
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: $secondary-80;
        border: thin solid $secondary-20;
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $secondary;
    }
}

@mixin scrollbar-light {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button {
        width: 6px;
    }

    &::-webkit-scrollbar-button {
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: $secondary-10;
        border: none;
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: $secondary-20;
        border: none;
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $secondary-40;
    }
}
