$msv-breakpoint-xs: 0px;
$msv-breakpoint-s: 320px;
$msv-breakpoint-sm: 667px;
$msv-breakpoint-m: 768px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-xl: 1440px;

$msv-gutter-width-s: 16px;
$msv-gutter-width-m: 20px;
$msv-gutter-width-l: 60px;

$msv-container-max-width-s: 768px;
$msv-container-max-width-m: 992px;
$msv-container-max-width-l: 1440px;

$msv-container-min-width-m: 769px;

$grid-breakpoints: (
    xxxs: 0,
    xxs: 320px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px
);
$container-max-widths: (
    xxxs: 0,
    xxs: 320px,
    xs: 568px,
    sm: 667px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px
);
