.checkout-payment-instrument {
    min-height: 80px;
    position: relative;

    &__loading-background {
        background-color: var(--msv-checkout-payment-instrument-bg);
        display: flex;
        height: 100%;
        position: absolute;
        width: 100%;

        .waiting {
            display: block;
            margin: auto;
        }
    }
    &__iframe {
        border: 0;
        width: 100%;
        max-width: 700px;
        min-height: 80px;
    }
    &__show {
        &-card-title {
            font-weight: var(--msv-font-weight-bold);
            margin-bottom: 0.5rem;
        }
        &-billing-address-title {
            margin-top: 1.25rem;
            margin-bottom: 0.5rem;
        }
        &-card-name,
        &-card-number,
        &-card-expiration,
        &-billing-address-1,
        &-billing-address-2,
        &-billing-address-3 {
            margin-bottom: 0;
        }
    }
}
.ms-checkout-section-container {
    padding-right: 0;
    padding-left: 0;
}
.ms-checkout__guided-card.ready {
    .paypal-caption {
        display: none;
    }
    .ms-checkout-payment-instrument {
        margin-bottom: $spacer;

        &__show p {
            margin-bottom: 0;
        }
        &__card-title,
        &__billing-address-title {
            color: $secondary;
            font-weight: $msv-font-weight-bold;
            margin-bottom: 0;
        }
    }
}
