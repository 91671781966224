$msv-account-profile-heading-margin-bottom: 32px;

$msv-account-profile-section-margin-bottom: 30px;
$msv-account-profile-section-heading-margin-bottom: 4px;
$msv-account-profile-section-description-margin-bottom: 6px;

$msv-account-profile-preference-margin-top: 20px;
$msv-account-profile-preference-heading-margin-bottom: 10px;
$msv-account-profile-preference-description-margin-bottom: 0px;

$msv-account-profile-toggle-button-icon-size: 28px;
$msv-account-profile-toggle-button-padding: 0 5px;

//style presets
:root {
    --msv-account-profile-font-size: var(--msv-body-font-size-m);
    --msv-account-profile-font-color: #{$msv-gray-900};

    // heading
    --msv-account-profile-heading-font-color: #{$msv-gray-900};
    --msv-account-profile-heading-font-size: var(--msv-body-font-size-l);

    // button
    --msv-account-profile-toggle-btn-font-color: var(--msv-accent-brand-color);
    --msv-account-profile-toggle-btn-font-size: #{$msv-account-profile-toggle-button-icon-size};
}

.ms-account-profile {
    padding-top: $spacer-xl;
    padding-bottom: $spacer-xl;
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
        cursor: pointer;
    }

    h3 {
        color: $secondary;
        line-height: 1.2em;
        font-weight: 300;
        margin-bottom: 1rem;
        display: block;
        overflow-wrap: break-word;
        word-break: break-word;
        overflow-wrap: anywhere;
        text-transform: none;
        font-size: 2rem;
    }

    &__main-info {
        margin-bottom: $spacer;

        > div {
            // border: 2px solid $secondary;
            background-color: $light-secondary;
            padding: $spacer;
            height: 100%;

            @include media-breakpoint-up(md) {
                padding: $spacer-l;
            }
        }

        button,
        a {
            font-size: 1.25rem;
            div {
                text-decoration: underline;
            }
        }

        input {
            border: 1px solid $secondary;
            font-size: 1.25rem;
        }
    }

    &__preferences {
        margin-bottom: $spacer;

        > div {
            // border: 2px solid $secondary;
            background-color: $light-secondary;
            padding: $spacer;
            height: 100%;

            @include media-breakpoint-up(md) {
                padding: $spacer-l;
            }
        }

        .ms-account-profile__toggle-enable-text,
        .ms-account-profile__toggle-disable-text {
            text-transform: capitalize;
        }
    }

    &__section-name {
        margin-top: $spacer-l;
    }

    &__section-phone {
        margin-top: $spacer-l;
    }

    &__section {
        &-heading {
            color: $secondary;
        }

        &-description {
            overflow-wrap: break-word; //safari
            word-break: break-word; //safari
            overflow-wrap: anywhere; //others
        }

        &-link {
            @extend %hyperlink;
        }
    }

    &__toggle-wrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }

    &__toggle-button {
        font-size: 1.4em;
        background-color: transparent;
        padding: 0 $spacer/2;
        color: $secondary;
        line-height: 1;

        &:focus {
            @include basic-outline-offset();
        }
    }

    &__toggle-enable {
        .ms-account-profile__toggle-enable-text {
            font-weight: $msv-font-weight-bold;
        }
    }

    &__toggle-disable {
        .ms-account-profile__toggle-disable-text {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    // &__attributes__section__toggle-disable-text {
    //   font-weight: var(--msv-font-weight-bold);
    // }

    // &__attributes__section__toggle-enable-text {
    //   font-weight: $msv-font-weight-bold;
    // }

    &__toggle-enable-button {
        @include add-icon($msv-ToggleRight);

        &::before {
            display: block;
        }
    }

    &__toggle-disable-button {
        @include add-icon($msv-ToggleRight);

        &::before {
            transform: scale(-1, 1);
            display: block;
        }
    }

    &__attributes {
        &-RegionCRM {
            display: none;
        }

        // &-Birthday {
        //   html[lang='en-US'] & {
        //     display: none;
        //   }
        // }

        &-updating {
            .ms-account-profile__attributes-edit-button.msc-btn {
                color: $secondary-40;
                pointer-events: none;

                &:before,
                &:after {
                    display: inline-block;
                    content: '';
                    background: $secondary-10;
                }

                &:before {
                    animation: bh 1s infinite linear;
                    position: absolute;
                }

                &:after {
                    animation: bv 1s infinite linear;
                    position: absolute;
                }
            }
        }

        &-edit-button,
        &-cancel-button {
            @extend %hyperlink;
        }

        &__section {
            margin-bottom: $spacer;

            &-heading {
                @extend %h5;
            }

            &__toggle-button {
                font-size: 1.4em;
                background-color: transparent;
                padding: 0 $spacer/2;
                color: $secondary;
                line-height: 1;

                &.disabled {
                    opacity: 0.4;
                }
            }

            &__toggle-enable-button {
                @include add-icon($msv-ToggleRight);

                &::before {
                    display: block;
                }
            }

            &__toggle-disable-button {
                @include add-icon($msv-ToggleRight);

                &::before {
                    transform: scale(-1, 1);
                    display: block;
                }
            }
        }

        &_input-edit-false,
        &_select-edit-false {
            border: none;
        }

        &-save-button {
            @include solid-button($secondary, true);
            margin-right: $layout-gutter;
        }
    }
}

.ms-account-profile__main-info {
    .ms-account-profile-wrapper {
        .ms-account-profile__attributes-wrapper {
            margin-top: 2rem;
        }

        .ms-account-profile__attributes {
            .ms-account-profile__attributes__section:not(.ms-account-profile__attributes-Birthday),
            .ms-account-profile__attributes-tile__heading {
                display: none;
            }

            // button,
            // .ms-account-profile__attributes__section.ms-account-profile__attributes-Birthday {
            //   html[lang='en-US'] & {
            //     display: none;
            //   }
            // }
        }
    }
}

// The following sections are there to """move""" the Birthday section to the first column

.ms-account-profile__main-info.extra {
    .ms-account-profile__attributes-wrapper {
        .ms-account-profile__attributes {
            .ms-account-profile__attributes__section,
            .ms-account-profile__attributes-edit-button {
                display: block;
            }

            .ms-account-profile__attributes-Birthday,
            .ms-account-profile__attributes-RegionCRM {
                display: none;
            }
        }
    }
}
