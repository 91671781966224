:root {
    --msv-reviews-list-font-size: var(--msv-body-font-size-m);
    --msv-reviews-list-heading-font-size: var(--msv-body-font-size-xl);
    --msv-reviews-list-heading-font-color: #{$msv-gray-900};
    --msv-reviews-list-btn-link-color: #{$msv-link-color};
    --msv-reviews-list-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-reviews-list-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-reviews-list-primary-btn-border: var(--msv-accent-brand-color);
    --msv-reviews-list-secondary-btn-bg: var(--msv-bg-color);
    --msv-reviews-list-secondary-btn-font-color: #{$msv-gray-900};
    --msv-reviews-list-secondary-btn-border: var(--msv-accent-brand-color);
}
.ms-reviews-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-left: $layout-gutter;
    padding-right: $layout-gutter-s;

    & button.show-reviews {
        border: 1px solid $blue;
        background-color: $white;
        width: 100%;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $secondary-font-family;
        font-size: $msv-font-size-l;
        font-weight: $msv-font-weight-heavy;
        color: $blue;
        text-transform: uppercase;
        cursor: pointer;
        margin-top: 16px;
        margin-bottom: 10px;
        &:disabled {
            opacity: 0.4;
            cursor: default;
        }
    }

    & .modal {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        z-index: 10;

        & .modal-content {
            width: 700px;
            padding: 2.5rem;
            margin-top: 13rem;
            @media screen and (max-width: $msv-breakpoint-m) {
                /* width: 400px; */
                width: 95%;
                min-width: 265px;
                padding: 0.5rem;
                margin-top: 8rem;
            }
            height: 75%;
            background-color: #ffffff;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            & .modal-header {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                & .title {
                    font-family: $msv-primary-font-family;
                    font-size: $msv-title-font-size-s;
                    font-weight: $msv-font-weight-light;
                    line-height: $msv-title-line-height-s;
                    color: $primary;
                }

                button {
                    @include add-icon-icomoon($close);
                    @include vfi();
                    background-color: transparent;
                    border: 0;
                    color: $secondary;
                    font-size: 20px;
                    position: absolute;
                    right: 20px;
                    cursor: pointer;
                    @media screen and (max-width: $msv-breakpoint-m) {
                        right: 10px;
                    }
                }
            }

            /* & .summary {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                & .value {
                    color: $blue;
                    font-weight: $msv-font-weight-normal;
                    line-height: $msv-title-line-height-xs;
            
                    &__avg {
                        font-size: $msv-title-font-size-s;
                        line-height: $msv-title-line-height-s;
                        font-weight: $msv-font-weight-light;
                    }
                }
                & .msc-rating__group {
                    padding-top: 8px;
                }
            } */
            & .msc-average-rating-container {
                & .stars {
                    text-align: right;
                    padding-top: 12px;
                }
            }
        }
    }

    &__empty-message {
        /* padding-bottom: 1.5rem; */
        display: none;
    }
    &__refiners {
        margin-bottom: 0.75rem;

        .msc-l-dropdown__label {
            display: inline-block;
            font-weight: var(--msv-font-weight-light);
            margin-right: 5px;
            margin-left: 20px;
            vertical-align: middle;
        }
        .msc-l-dropdown {
            background-color: transparent;
            border-color: transparent;
            display: inline-block;
            font-size: $msv-font-size-s;
            padding: 6px 5px;
            text-transform: uppercase;
            vertical-align: middle;
        }
        @media screen and (max-width: $msv-breakpoint-m) {
            margin-top: 5px;

            .msc-l-dropdown__label {
                margin-left: 2px;
            }
            .msc-l-dropdown {
                padding: 0;
            }
        }
    }
    &__reviews {
        margin-bottom: 1.25rem;
    }
    &__user-review .ms-reviews-list__like,
    &__user-review .ms-reviews-list__dislike {
        pointer-events: none;
    }
    &__edit,
    &__report {
        @extend %hyperlink;
    }
    &__submit,
    &__success {
        @include solid-button($primary, true);
        margin-right: 0.5rem;
    }
    &__cancel {
        @include border-button($primary, true);
    }
    &__like {
        @include add-icon-icomoon($thumbs-up);
        background: transparent;
        padding: 0.2rem 0.5rem;
        text-decoration: none;

        &.disabled {
            &:before {
                content: $thumbs-up-full;
            }
            &:hover {
                text-decoration: none;
            }
        }
        &:before {
            margin-right: 0.25rem;
        }
        &:not(.disabled):hover {
            text-decoration: none;

            &:before {
                @extend %move-up-animation;
            }
        }
    }
    &__dislike {
        @include add-icon-icomoon($thumbs-down);
        background: transparent;
        border-right: 1px solid var(--msv-accent-brand-color);
        margin-right: $layout-gutter;
        padding: 0.2rem $layout-gutter * 2 0.2rem 5px;
        text-decoration: none;

        &.disabled {
            &:before {
                content: $thumbs-down-full;
            }
            &:hover {
                text-decoration: none;
            }
        }
        &:before {
            margin-right: 0.25rem;
        }
        &:not(.disabled):hover {
            text-decoration: none;

            &:before {
                @extend %move-down-animation;
            }
        }
    }
    &__rating {
        height: 2rem;
        width: 100%;

        /*  @media (max-width: $msv-breakpoint-l) {
            margin-left: auto;
            order: 3;
            width: auto;
        } */
    }
    &__card-header {
        width: 100% !important;
        flex-direction: column;
    }
    & .list-container {
        height: 55vh;
        @media screen and (max-width: $msv-breakpoint-m) {
            height: 50vh;
        }
        overflow-y: auto;
        overflow-x: hidden;

        & .list-wrapper {
            height: 100%;
        }
    }
    &__card {
        border-top: 1px solid rgba($blue, 0.4);
        display: flex;
        padding: $spacer 0;
        flex-direction: column;

        @media (min-width: $msv-breakpoint-m) {
            display: flex;

            &-controls {
                text-align: right;
                font-weight: var(--msv-font-weight-normal);
            }
        }
        @media (max-width: $msv-breakpoint-l) {
            flex-wrap: wrap;
            /* flex-direction: column; */
        }
        &-header {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            margin-right: 1.5rem;
            min-width: 280px;
            width: 280px;

            @media (max-width: $msv-breakpoint-l) {
                margin-right: 0;
                min-width: auto;
                width: 100%;
            }
        }
        &-body {
            width: 100%;
        }
        &:last-child {
            border-bottom: 1px solid rgba($blue, 0.4);
        }
        .msc-btn.ms-reviews-list__dislike {
            padding-right: 1.5rem;
        }
    }
    &__name {
        /* overflow: hidden;
        text-overflow: ellipsis; */
        font-size: $msv-title-font-size-xs !important;
        font-weight: $msv-font-weight-normal;
        color: $primary;
    }
    &__name,
    &__date {
        display: inline;
        font-size: $msv-font-size-m;
        margin: 0 1rem 0 0;
        padding: 0 0 0.5rem 0;

        @media screen and (max-width: $msv-breakpoint-l) {
            padding-top: 0;
        }
    }
    &__date {
        font-weight: $msv-font-weight-light;
    }
    &__title {
        font-weight: $msv-font-weight-normal;
        font-size: $msv-font-size-xl;
        margin-bottom: 0.5rem;
    }
    &__helpful {
        display: block;
        font-size: $msv-font-size-m;
        margin-right: $layout-gutter;
    }
    &__review {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        line-height: $msv-line-height-l;
    }
    &__text {
        font-weight: $msv-font-weight-normal;
        font-size: $msv-font-size-l;
        line-height: $msv-line-height-l;
        margin-top: 1.25rem;
        color: $primary;
        overflow-wrap: break-word; //safari
        word-break: break-word; //safari
        overflow-wrap: anywhere; //others

        &:after {
            content: '';
        }
    }
    &__response {
        display: none;
        /* display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: .75rem 0;
        padding-left: 70px;
        position: relative;
        width: calc(100% - 70px);

        &:before {
            background-color: var(--msv-accent-brand-color);
            border-radius: 110px;
            content: '';
            height: 100%;
            position: absolute;
            left: -1.25rem;
            width: .5rem;
        }
        &-name {
            color: $primary;
            font-weight: var(--msv-font-weight-bold);
        }
        &-text {
            flex-basis: 100%;
            margin-top: .25rem;
        }
        &-date {
            color: $msv-gray-500;
        }
        @media (min-width: $msv-breakpoint-m) {
            width: 500px;
        } */
    }
    &__page-controls {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.25rem;
        width: 100%;
    }
    &__next {
        margin-left: auto;
    }
    &__next,
    &__previous {
        @include vfi();
        @include font-content-l();
        background-color: transparent;
        border: none;

        &:hover {
            text-decoration: underline;
        }
    }
    &__previous:before {
        @include msv-icon();
        content: $msv-ChevronLeft;
        margin-right: 3px;
    }
    &__next:after {
        @include msv-icon();
        content: $msv-ChevronRight;
        margin-left: 3px;
    }
    &__report-error {
        margin-bottom: 0;
    }
    &__report-message {
        @include font-content(var(--msv-font-weight-light), var(--msv-reviews-list-font-size), $msv-line-height-m);
        margin-bottom: 1.25rem;
    }

    &__reported {
        color: var(--msv-success-color);
        display: inline-block;
        font-weight: var(--msv-font-weight-bold);
    }
    @media screen and (min-width: $msv-breakpoint-m) {
        &__helpful {
            display: inline-block;
            margin-right: $layout-gutter;
            margin-bottom: 0;
            vertical-align: middle;
        }
        &__card-body {
            margin-top: 0;
        }
    }
}
.ms-report-review-modal {
    .ms-reviews-list__report-header {
        @extend %h1;
    }
    fieldset {
        border-color: transparent;
        padding: 0;
        margin: 0;
    }
    &-pair {
        margin-bottom: 1.25rem;
    }
    .msc-radio-label {
        @include form-input-radio-label();
    }
    .msc-radio-input {
        @include form-input-radio();
    }
}
