$msv-font-path: 'https://fonts.venchi.com';
$layout-gutter: 16px;
$layout-gutter-s: 12px;
$spacer-s: 0.5rem;
$spacer: 1rem;
$spacer-m: 1.5rem;
$spacer-l: 2rem;
$spacer-xl: 3rem;

$section-v-space--xl: $spacer-xl;
$section-v-space: $spacer;

$section-space: $spacer-l;
$border-separator: 1px solid rgba($secondary, 0.5);
$border-separator-color: rgba($secondary, 0.5);

%row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$layout-gutter;
    margin-left: -$layout-gutter;
}
%col {
    position: relative;
    width: 100%;
    padding-right: $layout-gutter;
    padding-left: $layout-gutter;
}
%col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
%col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
%col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
%col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

%move-down-animation {
    animation-name: icon-hang-sink, icon-hang;
    animation-duration: 0.3s, 1.5s;
    animation-delay: 0s, 0.3s;
    animation-timing-function: ease-out, ease-in-out;
    animation-iteration-count: 1, infinite;
    animation-fill-mode: forwards;
    animation-direction: normal, alternate;
}
@keyframes icon-hang {
    0% {
        transform: translateY(0.3em);
    }
    50% {
        transform: translateY(0.1em);
    }
    100% {
        transform: translateY(0.3em);
    }
}

@keyframes icon-hang-sink {
    100% {
        transform: translateY(0.3em);
    }
}
