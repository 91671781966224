%icon-font-style {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    //line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%icon-button {
    border: 0;
    outline: none;
    background: transparent;
}

$msv-CommentSolid: '\f27a';
$msv-ChevronDown: '\f078';
$msv-ChevronUp: '\f077';
$msv-Edit: '\f303';
$msv-Add: '\f067';
$msv-Cancel: '\f00d';
$msv-More: '\f141';
$msv-Settings: '\f013';
$msv-Phone: '\f095';
$msv-Shop: '\f290';
$msv-Shop-Address: '\f3c5';
$msv-Filter: '\f0b0';
$msv-Search: '\f002';
$msv-FavoriteStar: '\f005';
$msv-FavoriteStarFill: '\f005';
$msv-half-star: '\f089';
$msv-Delete: '\f2ed';
$msv-Save: '\f0c7';
$msv-Play: '\f04b';
$msv-Pause: '\f04c';
$msv-ChevronLeft: '\f053';
$msv-ChevronRight: '\f054';
$msv-Contact: '\f007';
$msv-Warning: '\f071';
$msv-IncidentTriangle: '\f071';
$msv-Dislike: '\f165';

$msv-DislikeSolid: '\f165';
$msv-LikeSolid: '\f164';
$msv-Comment: '\f27a';

$msv-IdCard: '\f2c2';
$msv-DeliveryTruck: '\f0d1';
$msv-SolidPlay: '\f04b';
$msv-Mute: '\f6a9';
$msv-UnMute: '\f028';
$msv-Fullscreen: '\f065';
$msv-ExitFullscreen: '\f066';
$msv-ClosedCaptions: '\f20a';
$msv-Selected: '\f00c';
$msv-Error: '\f06a';
$msv-Checkbox: '\f0c8';
$msv-Checkbox-Checked: '\f14a';
$msv-RadioBtnOff: '\f111';
$msv-RadioBtnOn: '\f192';
$msv-SignIn: '\f2f6';
$msv-SignOut: '\f2f5';
$msv-History: '\f1da';
$msv-MapPin: '\f276';
$msv-ContactCard: '\f2c2';
$msv-GlobalNavButton: '\f0c9';
$msv-Remove: '\f068';
$msv-ShoppingCart: '\f07a';
$msv-ToggleLeft: '\f204';
$msv-ToggleRight: '\f205';
$msv-arrow-right: '\f061';
$msv-Spinner: '\f110';
$msv-file: '\f15c';
$msv-StoreAlt: '\f54f';

$msv-magnifying-glass: $msv-Search;
$msv-heart: $msv-Heart;
$msv-shopping-bag: $msv-Shop;
$msv-x-shape: $msv-Cancel;

//icomoon

$search: '\e901';
$user: '\e903';
$arrow: '\e906';
$chevron: '\e907';
$close: '\e90a';
$more: '\e90b';
$less: '\e90c';
$error: '\e90d';
$star-half: '\e900';
$star-full: '\e902';
$star: '\e904';
$youtube: '\e905';
$instagram: '\e908';
$facebook: '\e909';
$twitter: '\e927';
$pinterest: '\e90e';
$shipping: '\e90f';
$free-next-day: '\e910';
$pick-up: '\e911';
$cart: '\e912';
$cart-btn: '\e913';
$heart: '\e914';
$heart-full: '\e915';
$heart-wishlist: '\e916';
$heart-btn: '\e917';
$trash: '\e918';

$thumbs-up: '\e919';
$thumbs-up-full: '\e91a';
$thumbs-down: '\e91b';
$thumbs-down-full: '\e91c';
$check: '\e91d';
$newsletter: '\e91e';
$user-profile: '\e91f';
$loyalty: '\e920';
$marker: '\e921';
$info: '\e922';
$chevron-b: '\e923';

$pm-cart: '\e924';
$pm-cart-btn: '\e925';
$email: '\e926';

$store-prefered: '\e928';
$store-prefered-selected: '\e929';

$gift: '\e92a';

@mixin add-icon-icomoon($icon, $selector: before, $next-to-text: false, $margin: $layout-gutter, $scale: true) {
    &:#{$selector} {
        @extend %icon-font-style;
        content: $icon;
        display: inline-block;
        line-height: 1.3em;
        @if $next-to-text and $scale==true {
            transform: scale(1.25);
            vertical-align: -1px;
        }
        @if $next-to-text and $selector == 'before' {
            margin-right: $margin;
        }
        @if $next-to-text and $selector == 'after' {
            margin-left: $margin;
        }
    }
}
