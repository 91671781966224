:root {
    --msv-wishlist-font-size: var(--msv-body-font-size-m);
    --msv-wishlist-heading-font-size: var(--msv-body-font-size-xl);
    --msv-wishlist-error-bg: var(--msv-error-color);
    --msv-wishlist-error-border: var(--msv-error-color);
    --msv-wishlist-error-font-color: var(--msv-font-secondary-color);
    --msv-wishlist-secondary-btn-bg: var(--msv-bg-color);
    --msv-wishlist-secondary-btn-font-color: #{$msv-gray-900};
    --msv-wishlist-secondary-btn-border: var(--msv-accent-brand-color);
}
.ms-wishlist-items {
    padding-top: $spacer-xl;
    padding-bottom: $spacer-xl;

    &__message {
        @include font-content-l();
        padding: 10px;

        &-failed {
            color: var(--msv-wishlist-error-font-color);
            background-color: var(--msv-wishlist-error-bg);
            border-color: var(--msv-wishlist-error-border);
        }
    }
    &__products {
        @include make-row();
        display: flex;
        flex-wrap: wrap;
    }
    &__product {
        display: flex;
        flex: 0 0 50%;
        flex-direction: column;
        margin-bottom: $spacer-xl;
        max-width: 50%;
        padding-left: $spacer-l;
        padding-right: $spacer-l;
        position: relative;

        @include media-breakpoint-down(xs) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: $spacer;
            padding-right: $spacer;
        }
        @include media-breakpoint-up(lg) {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
        @include media-breakpoint-up(xl) {
            flex: 0 0 25%;
            max-width: 25%;
            padding-left: $spacer-xl;
            padding-right: $spacer-xl;
        }
        &-image {
            margin-bottom: 1.25rem;
            width: 310px;

            &.msc-empty_image {
                @include image-placeholder(310px);
            }
        }
        &-link {
            font-size: 1.375rem;
            font-weight: $msv-font-weight-light;
            flex-grow: 1;
            margin-bottom: $spacer;
        }
        &-price {
            .msc-price__strikethrough,
            .msc-price__actual {
                font-weight: $msv-font-weight-bold;
            }
            .price-strikethrough {
                color: $msv-gray-300;
                line-height: 1rem;
            }
            .price-actual {
                color: var(--msv-font-primary-color);
            }
            .price-text-screen-reader {
                display: none;
            }
        }
        &-dimensions {
            color: $msv-text-color;
            display: flex;
        }
        &-dimension {
            margin-right: 10px;

            .msc-wishlist-dimension__label {
                margin-right: 5px;
            }
        }
        &-add-button {
            @include solid-button($secondary, true);
            margin-top: $spacer;
        }
        &-remove-button {
            @include vfi();
            @include add-icon-icomoon($close);
            background-color: transparent;
            border: 1px solid transparent;
            color: $secondary;
            cursor: pointer;
            padding: $spacer;
            position: absolute;
            right: $spacer;
        }
        &-status .msc-alert button.close {
            background-color: transparent;
            border: none;
            position: absolute;
            top: 0.1rem;
            right: 0;
        }
    }
    .msc-empty_image {
        @include image-placeholder(310);
    }
    @media screen and (max-width: $msv-breakpoint-m) {
        .msc-empty_image {
            @include image-placeholder(310);
        }
    }
}
