.ms-iframe__block {
    height: 0;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
}
