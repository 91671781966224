$lw: 1px;
$lh: 1rem;
.loading_ctn {
    /*
  background: rgba(255,255,255,0.9);

  position:absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;*/
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacer;
}
.loading {
    &:before,
    &:after {
        display: inline-block;
        content: '';
        background: $secondary-10;
    }
    &:before {
        animation: bh 1s infinite linear;
        position: absolute;
    }
    &:after {
        animation: bv 1s infinite linear;
        position: absolute;
    }
    height: $lh;
    width: $lh;
    overflow: hidden;
    display: block;
    position: relative;
}

@keyframes bh {
    0% {
        top: 0;
        left: 0;
        width: 0;
        height: $lw;
    }
    12.5% {
        top: 0;
        left: 0;
        width: $lh;
        height: $lw;
    }
    48% {
        top: 0;
        left: $lh;
        width: $lh;
        height: $lw;
    }
    48.1% {
        top: auto;
        bottom: 0;
        left: $lh;
        width: $lh;
        height: $lw;
    }
    65% {
        top: auto;
        bottom: 0;
        left: 0;
        width: $lh;
        height: $lw;
        background: #e87722;
    }
    85% {
        top: auto;
        bottom: 0;
        left: -$lh;
        width: $lh;
        height: $lw;
    }
    100% {
        top: auto;
        bottom: 0;
        left: -$lh;
        width: $lh;
        height: $lw;
    }
}
@keyframes bv {
    0% {
        top: 0;
        left: auto;
        right: 0;
        width: $lw;
        height: 0;
        background: $beige;
    }
    25% {
        top: 0;
        left: auto;
        right: 0;
        width: $lw;
        height: 0;
        background: $beige;
    }
    50% {
        top: 0;
        left: auto;
        right: 0;
        width: $lw;
        height: $lh;
        background: $beige;
    }
    65% {
        top: $lh;
        left: auto;
        right: 0;
        width: $lw;
        height: $lh;
    }
    70% {
        top: auto;
        bottom: -$lh;
        left: 0;
        width: $lw;
        height: $lh;
        background: $secondary-20;
    }
    100% {
        top: auto;
        bottom: $lh;
        left: 0;
        width: $lw;
        height: $lh;
        background: $secondary-20;
    }
}
