.recallbackgroundcolor {
    &__transparent {
        background: transparent;
    }

    @each $brand-color, $color-val in $brand-colors {
        &__#{$brand-color} {
            background-color: $color-val;
        }
    }
}

.recallbordercolor {
    &__none {
        border-color: transparent;
        border-style: none;
    }

    @each $brand-color, $color-val in $brand-colors {
        &__#{$brand-color} {
            border-color: $color-val;
            border-style: solid;
        }
    }
}

.recalltextcolor {
    @each $brand-color, $color-val in $brand-colors {
        &__#{$brand-color} .venchi-recall__text {
            color: $color-val;
        }

        &__#{$brand-color} .venchi-recall__close-button {
            color: $color-val;
        }
    }
}

.venchi-recall {
    position: fixed;
    bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    z-index: 800;
    max-width: 100%;
    min-height: 3.125rem;
    max-height: 4rem;

    &.slide-in {
        -webkit-animation: slide-in 0.5s forwards;
        animation: slide-in 0.5s forwards;
    }

    @keyframes slide-in {
        100% {
            transform: translateX(0%);
            transform: translateY(0%);
        }
    }

    @-webkit-keyframes slide-in {
        100% {
            -webkit-transform: translateX(0%);
            -webkit-transform: translateY(0%);
        }
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    &.recallposition {
        &__left {
            left: 0px;

            @media screen and (min-width: 500px) {
                transform: translateX(-100%);
                -webkit-transform: translateX(-100%);
                flex-flow: row;
            }

            @media screen and (max-width: 500px) {
                transform: translateY(100%);
                -webkit-transform: translateY(100%);
                flex-flow: row;
                justify-content: space-between;
            }
        }

        &__right {
            right: 0px;

            @media screen and (min-width: 500px) {
                transform: translateX(100%);
                -webkit-transform: translateX(100%);
                flex-flow: row-reverse;
            }

            @media screen and (max-width: 500px) {
                transform: translateY(100%);
                -webkit-transform: translateY(100%);
                flex-flow: row;
                justify-content: space-between;
            }
        }
    }

    &__text {
        background-color: transparent;
        border: none;
        font-size: $msv-font-size-l;
        font-family: 'DIN 2014', sans-serif;
        text-transform: uppercase;
        font-style: normal;
        font-weight: $msv-font-weight-bold;
        cursor: pointer;
        padding-left: 4rem;
        padding-right: 4rem;

        @media screen and (max-width: 500px) {
            font-size: $msv-font-size-m;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    &__close-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        @include add-icon-icomoon($close);
        @include vfi();
        vertical-align: middle;
        font-size: $msv-font-size-m;
        padding-left: 1rem;
        padding-right: 1rem;
        @media screen and (max-width: 500px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    &__close-button__label {
        display: none;
    }
}
