$menu-main--dropdown-min-width: 20rem;
$menu-main--font-size: 1.375rem;
$menu-top--font-size: $msv-font-size-m;
$menu-main--color: $primary;
$menu-top--color: $white;
$topbar--bg: $secondary;
$topbar--color: $white;
$topbar--height: 2.5rem;
$header-sticky--height: 4rem;
$promo_banner--border-color: $topbar--bg;

//NEW
$msv-default-container-padding-bottom: 25px;
$divider-margin-left: 8px;
$divider-margin-right: 8px;
$divider-margin-width: 1px;
$divider-margin-height: 20px;
$divider-padding-m: 10px 0;
$nav-padding-top-m: 20px;
$header-list-item-link-padding: 0 20px;
$header-myaccount-margin-left: 8px;
$header-logo-margin-right: 20px;
$header-logo-margin: auto auto auto 12px;
$header-icon-padding-right: 10px;
$header-icon-padding-left: 10px;
$header-cart-icon-margin-left: 4px;
$header-list-item-link-padding: 0 20px;

body.headerIsSticky {
    @media screen and (min-width: 1201px) {
        & #renderPage {
            padding-top: calc(40px + 108px);

            // & .ms-header {
            //     & .ms-header__desktop-view {
            //         min-height: 68px !important;
            //     }
            // }
        }
    }

    @media screen and (min-width: 769px) and (max-width: 1200px) {
        & #renderPage {
            padding-top: 52px;
        }
    }
}

.header--checkout {
    &.default-container {
        padding-top: $spacer;
        padding-bottom: $spacer;
        background: $msv-white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .logo_bg {
        display: block;
        background: transparent url('../../../images/logo_venchi.png') no-repeat left center;
        background-size: contain;
        height: 3rem;
        margin-top: 0;
        text-decoration: none;
        width: 10rem;
    }
}

.header--simplified {
    &.default-container {
        padding-top: $spacer;
        padding-bottom: $spacer;
        background: $msv-white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    }

    .logo_bg_left {
        display: block;
        background: transparent url('../../../images/logo_venchi.png') no-repeat left center;
        background-size: contain;
        height: 3rem;
        margin-top: 0;
        text-decoration: none;
        width: 10rem;
    }

    .logo_bg_center {
        display: block;
        background: transparent url('../../../images/logo_venchi.png') no-repeat center center;
        background-size: contain;
        height: 3rem;
        margin-top: 0;
        text-decoration: none;
        width: 100%;
    }
}

#renderPage {
    padding-top: 80px;

    & #main {
        /* padding-top: 40px; */

        & .breadcrumbs_ctn {
            min-height: 21px;

            @media screen and (max-width: $msv-container-max-width-s) {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        //padding-top: 9.5rem;
        padding-top: 10rem;
    }

    // @include media-breakpoint-up(xl) {
    //     //padding-top: 9.5rem;
    //     padding-top: 160px;
    // }

    .page--checkout & {
        padding-top: 0;
    }

    > header {
        font-weight: $msv-font-weight-normal;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: $zindex-dropdown;
        background: none;

        .no-scroll & {
            @include media-breakpoint-down(xl) {
                height: 100%;
                overflow: hidden;
            }
        }

        // .default-container {
        //     &.layout2021 {
        //         background: $msv-white;
        //         box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        //     }

        //     //TODO fix in future
        //     &.layout2022 {
        //         background: none;
        //         box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        //     }

        // }

        // .ms-promo-banner {
        //     //TODO fix in future

        //     &:not(.layout2022) {
        //         border-bottom: 1px solid $promo_banner--border-color;
        //     }

        //     position: relative;
        //     z-index: 10;

        //     .no-scroll & {
        //         display: none;
        //     }
        // }
    }
}

@include media-breakpoint-down(lg) {
    .no-scroll-pick-mix {
        .layout2022 {
            .ms-promo-banner {
                display: none;
            }
        }
    }
}

.headerIsSticky > div {
    @include media-breakpoint-down(lg) {
        .ms-promo-banner {
            display: none;
        }
    }

    > header .default-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }

    .layout2021 {
        .ms-header--main-nav {
            @include media-breakpoint-up(xl) {
            }

            @include media-breakpoint-up(md) {
                z-index: 2;
            }
        }

        .ms-header .ms-header__logo img {
            @include media-breakpoint-up(xl) {
                height: 2.6rem;
            }
        }
    }

    .layout2022 {
        .desktop-TopBar {
            height: 3rem;

            .ms-header__logo img {
                height: 2.5rem;
            }
        }

        .ms-header {
            &__collapsible-hamburger {
                background-color: $msv-white;
                border-top: 1px solid rgb(187, 171, 153);

                height: calc(100vh - 3em);
                min-height: calc(100vh - 3em) !important;
                overflow: hidden auto;
                padding-bottom: 5em;
            }
        }
    }
}

.layout2021 {
    &.default-container {
        background: $msv-white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .ms-promo-banner {
        //TODO fix in future

        border-bottom: 1px solid $promo_banner--border-color;

        position: relative;
        z-index: 10;

        .no-scroll & {
            display: none;
        }
    }

    .ms-header {
        background: $bg_main_color;

        .no-scroll & {
            @include media-breakpoint-down(xl) {
                box-shadow: none;
            }
        }

        .shop_tools {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            > a {
                padding: 0.5rem 0.7rem;
                text-decoration: none;

                &:last-child {
                    padding-right: 0;
                }

                &:before {
                    @extend %icon-font-style;
                    display: inline-block;
                    font-size: 1.7em;
                    color: $primary;
                    transition: all 0.2s;
                }

                &:hover,
                &:focus {
                    &:before {
                        transform: scale(1.1);
                    }
                }

                span {
                    display: none;
                }
            }

            .msc-cart-icon {
                @include add-icon-icomoon($cart);
                color: $primary;
                font-size: 1.7rem;
                margin-left: 10px;
                text-align: center;
                padding: 0;

                div {
                    background: $primary;
                    border: 3px solid #fff;
                    border-radius: 100%;
                    color: $white;
                    display: inline-block;
                    font-size: $msv-font-size-s;
                    font-weight: $msv-font-weight-bold;
                    height: 2.2em;
                    line-height: 1.9em;
                    margin-left: -0.7rem;
                    overflow: hidden;
                    transform: translateY(-0.2rem);
                    width: 2.2em;
                    white-space: nowrap;

                    // &:before,
                    // &:after {
                    //     background: $primary;
                    //     color: $primary;
                    //     display: inline-block;
                    //     height: 1em;
                    //     margin-top: -8px;
                    //     margin-left: -5px;
                    //     transform: translateX(100%);
                    //     vertical-align: middle;
                    // }

                    // &:before {
                    //     content: "(";
                    // }

                    // &:after {
                    //     content: ")";
                    //     margin-right: -0.4em;
                    //     margin-left: 0em;
                    //     transform: translateX(-100%);
                    // }
                }
            }

            .msc-wishlist-icon {
                @include add-icon-icomoon($heart);
            }
        }

        /* &__desktop-view {
        
        @media screen and (min-width: 1201px) {
            min-height: 82px;
        }
        & .menu--main {
            & .ms-nav.desktop-vp {
                & .ms-nav__list {
                    & .ms-nav__list__item {
                        &:first-of-type,
                        &:nth-of-type(8) ~ * {
                            display: none;
                        }
                    }
                }
            }
        }
    } */
        &__topbar {
            background: $topbar--bg;
            color: $msv-white;
            height: $topbar--height;
            opacity: 1;
            position: relative;
            // transition: all 0.4s;
            z-index: 10;

            a {
                font-weight: $msv-font-weight-normal;
            }

            @include media-breakpoint-down(lg) {
                display: none;
            }

            .user_tools {
                display: flex;
                justify-content: flex-end;
                text-align: right;

                > div {
                    margin-left: $layout-gutter;
                }
            }

            .ms-search__label,
            .ms-header__account-info {
                opacity: 1;
                transform: translateY(0);
                transition: all 0.5s;
            }

            .ms-search,
            .ms-header__account-info {
                color: $topbar--color;

                a.ms-header__signin-button,
                button {
                    @include underline-link-animation($white);
                    border: 0;
                    color: inherit;
                    font-size: $msv-font-size-s;
                    height: calc(2.5rem - 1px);
                    line-height: $topbar--height;
                    padding: 0;
                    position: relative;
                    text-decoration: none;
                    text-transform: uppercase;

                    .ms-profile-button-text {
                        display: inline-block;
                    }

                    &:after {
                        margin-left: 10px;
                        display: inline-block;
                        transform: scale(1.3);
                    }
                }
            }

            .ms-header__signin-button {
                @include add-icon-icomoon($user, after);
            }

            button.ms-search__form-submitSearch,
            button.ms-search__form-submitSearch:focus {
                @include add-icon-icomoon($search, after);
                cursor: pointer;
                padding: 0 15px;
                width: auto;

                &:after {
                    margin-left: 0;
                }
            }

            button.ms-search__form-cancelSearch {
                @include add-icon-icomoon($close, after);
                background: transparent;
                color: $secondary;
                cursor: pointer;
                line-height: 1.3rem;
                margin-left: -2.5rem;
                width: 2.45rem;
            }
        }

        &__account-info-content.bottom {
            background: $secondary;
            border: 1px solid #d1d1d1;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.132);
            text-align: right;
            padding: 0.5rem 0;
            left: auto !important;
            right: -5px;
            transform: translate(0, 100%) !important;
            top: auto !important;
            bottom: 5px;

            a {
                white-space: nowrap;
                display: block;
                text-align: right;
                color: $white;
                padding: 0 $layout-gutter;

                > div,
                > span {
                    @include underline-link-animation($white);
                    padding: 0.5rem 0;
                    display: inline-block;
                }
            }
        }

        &__profile-button {
            @include add-icon-icomoon($user, after, true);
        }

        &__nav-icon,
        &__nav-icon:focus {
            align-items: center;
            background: $white;
            border-top: 2px solid $secondary;
            border-bottom: 2px solid $secondary;
            cursor: pointer;
            display: inline-flex;
            height: 12px;
            margin-right: $layout-gutter;
            outline: none;
            padding: 0;
            width: 1.2rem;

            @include media-breakpoint-up(xl) {
                display: none;
            }

            &:before {
                content: '';
                width: 100%;
                height: 2px;
                background: $secondary;
            }
        }

        nav {
            &:focus {
                outline: none;
            }

            button {
                appearance: none;
                background: none;
                border: 0;
                outline: none;
                padding: 0;

                &:focus {
                    appearance: none;
                    border: 0;
                    outline: none;
                }
            }

            ul:focus,
            ul:focus-within {
                appearance: none;
                border: 0;
                outline: none;
            }
        }

        a {
            display: inline-block;
        }

        &--main-nav {
            background: $white;
            position: relative;
            transition-duration: 0.25s !important;
            width: 100%;
        }

        .ms-header__logo {
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 10px;
            vertical-align: middle;

            a {
                display: block;

                &:focus {
                    outline: 1px dashed $msv-black;
                }
            }

            img {
                display: inline-block;
                height: 3.5rem;
                transition-duration: 0.25s !important;
                width: auto;

                @include media-breakpoint-down(lg) {
                    display: block;
                    height: 2rem;
                }

                .isSticky & {
                    height: calc(#{$header-sticky--height} - 20px);
                }
            }
        }

        .menu--corporate {
            font-size: $msv-font-size-m;
        }

        .menu--main {
            color: $menu-main--color;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $menu-main--font-size;

            @include media-breakpoint-down(lg) {
                display: none;
            }

            &.mobile {
                border-bottom: 1px solid $primary;
                margin-right: $layout-gutter;
                margin-bottom: $spacer;
                margin-left: $layout-gutter;
                padding: $layout-gutter 0;

                @include media-breakpoint-down(lg) {
                    display: block;
                }

                .drawer__glyph {
                    @include add-icon-icomoon($chevron);
                    font-size: $msv-font-size-m;
                    margin-left: 0.75rem;

                    &.ms-nav__drawer-open:before {
                        transform: rotate(180deg) translateY(-5px);
                    }
                }

                .collapse.show {
                    border-bottom: 1px solid $primary-40;
                }

                nav {
                    a {
                        display: block;
                    }

                    li a {
                        @include media-breakpoint-down(lg) {
                            display: inline-block;
                            position: relative;
                        }
                    }
                }
            }

            @include media-breakpoint-down(xl) {
                font-size: $msv-font-size-m;
            }

            a,
            button {
                color: $menu-main--color;
                font-family: $primary-font-family;
                font-size: $msv-font-size-l;
                font-weight: $msv-font-weight-normal;
                position: static;

                @include media-breakpoint-down(lg) {
                    display: inline-block;
                    position: relative;
                }
            }

            nav {
                height: 100%;
                width: 100%;

                > ul {
                    height: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    outline: none;

                    @include media-breakpoint-down(lg) {
                        display: block;
                    }

                    > li {
                        align-items: center;
                        display: flex;
                        margin-right: 0.6em;
                        margin-left: 0.6em;
                        height: 100%;
                        position: relative;

                        @include media-breakpoint-down(lg) {
                            display: block;
                            margin-left: 0;
                            margin-right: 0;
                            margin-bottom: 0.5rem;
                        }
                    }

                    ul {
                        background: $white;
                        border: 1px solid $msv-gray-300;
                        box-shadow: 0px 8px 15px $msv-box-shadow-color;
                        font-size: $msv-font-size-l;
                        min-width: $menu-main--dropdown-min-width;
                        padding: 1rem $layout-gutter * 2;
                        position: absolute;
                        top: 80%;
                        z-index: 1;

                        @include media-breakpoint-down(lg) {
                            border: none;
                            box-shadow: none;
                            position: relative;
                        }

                        a {
                            margin-bottom: 0.7rem;
                            padding: 0;
                            position: relative;
                        }
                    }
                }
            }
        }

        .account-mobile {
            button {
                background: transparent;
                color: $secondary;
                cursor: pointer;
                font-size: 1em;
                padding: 0.3rem 0;
                text-transform: uppercase;
            }
        }
    }

    .menu--main {
        .ms-nav__list__item__button {
            @include add-icon-icomoon($chevron, after);
            @include underline-link-animation($orange);
            cursor: pointer;

            &:after {
                display: inline-block;
                font-size: 0.6em;
                margin-left: 0.5rem;
            }

            &:hover,
            &:focus {
                &:after {
                    @extend %move-down-animation;
                }
            }
        }

        .ms-nav__list__item__link {
            @include underline-link-animation($orange);
            text-decoration: none;
        }
    }

    .menu--top {
        @extend %row;
        opacity: 1;
        padding-top: 0;
        padding-bottom: 0;
        transform: translateY(0);
        transition: all 0.5s;

        button,
        button:focus {
            appearance: none;
            background: none;
            border: 0;
            outline: none;
            padding: 0;
        }

        a {
            display: inline-block;
        }

        .ms-nav__list__item__button {
            @include add-icon-icomoon($chevron, after);
            cursor: pointer;

            &:after {
                display: inline-block;
                font-size: 0.8em;
                margin-left: 0.3rem;
            }

            &:hover,
            &:focus {
                &:after {
                    @extend %move-down-animation;
                }
            }
        }

        li {
            display: inline-block;
            height: $topbar--height;
            padding: 0 $layout-gutter;
            position: relative;

            @include media-breakpoint-down(lg) {
                display: block;
                position: relative;
                padding: 0;
                height: auto;
            }

            a,
            button {
                @include underline-link-animation($white);
                color: $menu-top--color;
                cursor: pointer;
                display: inline-block;
                font-family: $primary-font-family;
                font-size: $menu-top--font-size;
                line-height: $topbar--height;
                position: relative;
                text-decoration: none;
                text-transform: uppercase;

                @include media-breakpoint-down(lg) {
                    line-height: 1.3em;
                }
            }

            ul {
                background: $secondary;
                border: 1px solid $msv-gray-300;
                box-shadow: 0px 8px 15px $msv-box-shadow-color;
                margin-top: -6px;
                min-width: $menu-main--dropdown-min-width;
                padding: 0.5rem 0;
                position: absolute;
                z-index: 3;

                @include media-breakpoint-down(lg) {
                    background: $msv-white;
                    border: 0;
                    box-shadow: none;
                    margin-left: 1rem;
                    position: relative;
                }

                li {
                    display: block;
                    height: auto;
                }

                a {
                    @include underline-link-animation($white);
                    font-size: $msv-font-size-m;
                    line-height: 1.3rem;
                    padding: 0.5rem 0;
                    text-transform: none;

                    @include media-breakpoint-up(xl) {
                        &:before {
                            background: $white;
                        }
                    }
                }
            }
        }

        &.mobile {
            margin: 0;
            opacity: 1 !important;
            transform: none !important;
            padding: 0;
            margin: 0 $layout-gutter 6rem $layout-gutter;

            a,
            button {
                color: $secondary;
                display: block;
                flex-basis: 100%;
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-normal;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                text-decoration: none;

                &.ms-nav__list__item__button,
                &.drawer__button {
                    text-transform: uppercase;
                }
            }

            > ul > li > a,
            > ul > li > button,
            .drawer button,
            > a {
                font-size: 0.875rem;
                text-transform: uppercase;
                text-decoration: none;
            }

            > a:hover {
                text-decoration: underline;
            }

            .drawer {
                width: 100%;

                a {
                    padding: 0.5rem $layout-gutter;
                    @include underline-link-animation($orange);
                }
            }

            .drawer__glyph {
                @include add-icon-icomoon($chevron);
                font-size: $msv-font-size-m;
                margin-left: 0.75rem;

                &.ms-nav__drawer-open {
                    &:before {
                        transform: rotate(180deg) translateY(-5px);
                    }
                }
            }
        }
    }

    .ms-header {
        &__collapsible-hamburger {
            height: calc(100vh - 50px);
            min-height: calc(100vh - 50px) !important;
            overflow: hidden auto;
            padding-bottom: 66px;

            @include media-breakpoint-up(xl) {
                display: none;
            }

            .ms-search {
                display: block;
                height: 2.8rem;
                margin-top: $spacer;
                padding: 0.7rem 0;
                position: relative;

                &__label {
                    display: none;
                }

                .ms-search__form {
                    display: block;
                }

                &__searchForm {
                    padding-right: $layout-gutter;
                    padding-left: $layout-gutter;
                }

                &__form-submitSearch {
                    @include add-icon-icomoon($search);
                    padding: 0.7rem;
                    width: auto;

                    &:before {
                        font-size: 1.25rem;
                    }
                }

                &__icon-text {
                    display: inline-block;
                }

                &__form {
                    top: 0;
                    left: 0;
                }

                &__form-cancelSearch {
                    display: none;
                }
            }
        }

        &__account-info.account-mobile {
            a {
                display: block;
                font-weight: $msv-font-weight-normal;
                padding: 0.5rem 0;
            }

            > a:hover {
                text-decoration: underline;
            }
        }

        &__wishlist-mobile {
            @include add-icon-icomoon($heart);
            padding: 0.7rem 0;

            &:before {
                margin-right: 10px;
            }
        }

        .mobile.menu--main nav {
            > a,
            > .drawer {
                padding: 0.5rem 0;
            }

            .drawer a {
                font-size: $msv-font-size-l !important;
                padding: 0.3rem $layout-gutter;
            }
        }
    }

    .ms-header__account-info.account-mobile {
        color: $secondary;
        font-size: $msv-font-size-s;
        padding-right: $layout-gutter;
        padding-left: $layout-gutter;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            margin-bottom: $spacer/2;
        }

        > div {
            margin-left: $spacer;
        }

        .drawer__button {
            cursor: pointer;
            line-height: 1.4rem;
        }

        .drawer__buttontext.__start {
            @include add-icon-icomoon($user, after, true, 8px);
        }

        .drawer__glyph {
            @include add-icon-icomoon($chevron);
            display: inline-block;

            font-size: 1rem;
            margin-left: 0.75rem;

            @include media-breakpoint-up(lg) {
                font-size: 0.8em;
                margin-left: 0.5rem;
            }

            &.ms-nav__drawer-open {
                &:before {
                    transform: rotate(180deg) translateY(-5px);
                }
            }
        }

        > a {
            text-transform: uppercase;
            font-size: 0.875rem;
            @include add-icon-icomoon($user, after, true, 8px);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .collapse.show a:hover {
            text-decoration: underline;
        }
    }

    .col-12 > .menu--top {
        display: none;
    }
}

.layout2022 {
    &.default-container {
        background-color: $msv-bg-color-header;
        -webkit-backdrop-filter: $msv-bg-color-backdrop;
        backdrop-filter: $msv-bg-color-backdrop;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    }

    button,
    a {
        border: none;
        background: none;
        padding: 0;
        text-decoration: none;
    }

    .ms-promo-banner {
        //TODO fix in future

        position: relative;
        z-index: 2400;
    }

    .ms-header {
        .shop_tools {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            > a {
                padding: 0.5rem 0.7rem;
                text-decoration: none;

                &:last-child {
                    padding-right: 0;
                }

                &:before {
                    @extend %icon-font-style;
                    display: inline-block;
                    font-size: 1.5em;
                    color: $primary;
                    transition: all 0.2s;
                }

                span {
                    display: none;
                }
            }

            .msc-cart-icon {
                @include add-icon-icomoon($cart);
                white-space: nowrap;

                color: $primary;
                font-size: 1.5rem;
                margin-left: 10px;
                text-align: center;
                padding: 0;

                &:hover,
                &:focus {
                    &:before {
                        content: $cart-btn;
                    }
                }

                div {
                    background: $primary;
                    border: 3px solid #fff;
                    border-radius: 100%;
                    color: $white;
                    display: inline-block;
                    font-size: $msv-font-size-s;
                    font-weight: $msv-font-weight-bold;
                    height: 2.2em;
                    line-height: 1.9em;
                    margin-left: -0.7rem;
                    overflow: hidden;
                    transform: translateY(-0.2rem);
                    width: 2.2em;
                    white-space: nowrap;

                    // &:before,
                    // &:after {
                    //     background: $primary;
                    //     color: $primary;
                    //     display: inline-block;
                    //     height: 1em;
                    //     margin-top: -8px;
                    //     margin-left: -5px;
                    //     transform: translateX(100%);
                    //     vertical-align: middle;
                    // }

                    // &:before {
                    //     content: "(";
                    // }

                    // &:after {
                    //     content: ")";
                    //     margin-right: -0.4em;
                    //     margin-left: 0em;
                    //     transform: translateX(-100%);
                    // }
                }
            }

            .msc-wishlist-icon {
                @include add-icon-icomoon($heart);

                &:hover,
                &:focus {
                    &:before {
                        content: $heart-full;
                    }
                }
            }
        }

        /* &__desktop-view {
        
        @media screen and (min-width: 1201px) {
            min-height: 82px;
        }
        & .menu--main {
            & .ms-nav.desktop-vp {
                & .ms-nav__list {
                    & .ms-nav__list__item {
                        &:first-of-type,
                        &:nth-of-type(8) ~ * {
                            display: none;
                        }
                    }
                }
            }
        }
    } */
        &__topbar {
            background: transparent;
            color: $msv-primary;
            opacity: 1;
            position: relative;
            transition: all 0.4s;
            z-index: 2510;

            a {
                font-weight: $msv-font-weight-normal;
            }

            &.desktop-TopBar {
                height: 4.5rem;

                .ms-header__logo {
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: 16px;

                    a {
                        display: block;
                    }

                    img {
                        display: inline-block;
                        height: 3.5rem;
                        transition-duration: 0.25s !important;
                        width: auto;
                    }
                }

                @include media-breakpoint-down(lg) {
                    display: none;
                }

                @include media-breakpoint-up(lg) {
                    display: flex;
                }
            }

            &.mobile-TopBar {
                height: 3rem;

                .ms-header__logo {
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: 8px;

                    a {
                        display: block;
                    }

                    img {
                        display: inline-block;
                        height: 2rem;
                        transition-duration: 0.25s !important;
                        width: auto;
                    }
                }

                @include media-breakpoint-down(lg) {
                    display: flex;
                }

                @include media-breakpoint-up(lg) {
                    display: none;
                }

                a.ms-header__storelocator-button {
                    width: 3rem;
                    height: 3rem;
                    padding: 0.5rem 0.7rem;

                    &:after {
                        display: none;
                    }

                    &:before {
                        display: inline-block;
                        margin-left: 4px;
                    }
                }
            }

            a.ms-header__storelocator-button {
                color: $primary;

                border: 0;
                padding: 0px 1rem;
                font-size: $msv-font-size-m;
                height: 2.5rem;
                line-height: 2.5rem;
                position: relative;
                text-decoration: none;
                text-transform: uppercase;
                white-space: nowrap;
                @include add-icon-icomoon($marker, after);
                @include add-icon-icomoon($marker, before);

                &:hover,
                &:focus {
                    //text-shadow: 0 0 .65px $primary, 0 0 .65px $primary;
                    font-weight: $msv-font-weight-bold;
                }

                &:after {
                    margin-left: 10px;
                    display: inline-block;
                    transform: scale(1.3);
                }

                &:before {
                    display: none;
                }
            }

            .ms-search__label,
            .ms-header__account-info {
                opacity: 1;
                transform: translateY(0);
                transition: all 0.5s;
                padding: 0px 1rem;
            }

            .ms-search,
            .ms-header__account-info {
                color: $primary;

                a.ms-header__signin-button,
                button {
                    border: 0;
                    color: inherit;
                    font-size: $msv-font-size-m;
                    height: 2.5rem;
                    line-height: 2.5rem;
                    position: relative;
                    text-decoration: none;
                    text-transform: uppercase;
                    white-space: nowrap;

                    .ms-profile-button-text {
                        display: inline-block;
                    }

                    &:not([aria-expanded='true']) {
                        &:hover,
                        &:focus {
                            //text-shadow: 0 0 .65px $primary, 0 0 .65px $primary;
                            font-weight: $msv-font-weight-bold;
                        }
                    }

                    &[aria-expanded='true'] {
                        //text-shadow: 0 0 .65px $primary, 0 0 .65px $primary;
                        font-weight: $msv-font-weight-bold;
                    }

                    &:after {
                        margin-left: 10px;
                        display: inline-block;
                        transform: scale(1.3);
                    }
                }
            }

            .ms-header__signin-button {
                @include add-icon-icomoon($user, after);
            }

            button.ms-search__form-submitSearch,
            button.ms-search__form-submitSearch:focus {
                @include add-icon-icomoon($search, after);
                cursor: pointer;
                padding: 0 1em;
                width: auto;
                background-color: $secondary;
                color: $msv-white !important;

                &:after {
                    margin-left: 0;
                }
            }

            button.ms-search__form-cancelSearch {
                @include add-icon-icomoon($close, before);
                background: transparent;
                color: $primary;
                cursor: pointer;
                line-height: 1.3rem;
                margin-left: -2.5rem;
                width: 2.45rem;
            }
        }

        &__account-info-content.bottom {
            background: $msv-white;
            border: 1px solid #d1d1d1;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
            text-align: right;
            padding: 0.5rem 0;
            left: auto !important;
            right: 1rem;
            transform: translate(0, 100%) !important;
            top: auto !important;
            bottom: 0;

            a {
                white-space: nowrap;
                display: block;
                text-align: right;
                color: $primary;
                padding: 0 $layout-gutter;

                > div,
                > span {
                    @include underline-link-animation($orange);
                    padding: 0.5rem 0;
                    display: inline-block;
                }
            }
        }

        &__profile-button {
            @include add-icon-icomoon($user, after, true);
        }

        &__nav-icon,
        &__nav-icon:focus {
            align-items: center;
            background: transparent;
            border-top: 2px solid $secondary;
            border-bottom: 2px solid $secondary;
            cursor: pointer;
            display: inline-flex;
            height: 12px;
            margin-right: $layout-gutter;
            outline: none;
            padding: 0;
            width: 1.2rem;

            &:before {
                content: '';
                width: 100%;
                height: 2px;
                background: $secondary;
            }
        }

        a {
            display: inline-block;
        }

        // &--main-nav {
        //     background:$white;
        //     position: relative;
        //     transition-duration: .25s !important;
        //     width: 100%;
        // }

        // .account-mobile {
        //     button {
        //         background: transparent;
        //         color: $secondary;
        //         cursor: pointer;
        //         font-size: 1em;
        //         padding: 0.3rem 0;
        //         text-transform: uppercase;
        //     }
        // }
    }

    .ms-header {
        &__collapsible-hamburger {
            background-color: $msv-white;
            border-top: 1px solid rgb(187, 171, 153);

            height: calc(100vh - 5em);
            min-height: calc(100vh - 5em) !important;
            overflow: hidden auto;
            padding-bottom: 5em;

            @include media-breakpoint-up(xl) {
                display: none;
            }

            .menu--main {
                color: $menu-main--color;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $menu-main--font-size;

                @include media-breakpoint-down(lg) {
                    display: none;
                }

                &.mobile {
                    border-bottom: 1px solid $primary;
                    margin-right: $layout-gutter;
                    margin-bottom: $spacer;
                    margin-left: $layout-gutter;
                    padding: $layout-gutter 0;

                    @include media-breakpoint-down(lg) {
                        display: block;
                    }

                    // .drawer__glyph {
                    //     @include add-icon-icomoon($chevron);
                    //     font-size: $msv-font-size-m;
                    //     margin-left: 0.75rem;

                    //     &.ms-nav__drawer-open:before {
                    //         transform: rotate(180deg) translateY(-5px);
                    //     }
                }

                //.collapse.show {
                //border-bottom: 1px solid $primary-40;
                //}

                nav {
                    a {
                        display: block;
                    }

                    li a {
                        @include media-breakpoint-down(lg) {
                            display: inline-block;
                            position: relative;
                        }
                    }
                }

                @include media-breakpoint-down(xl) {
                    font-size: $msv-font-size-m;
                }

                a {
                    @include underline-link-animation($orange);
                    cursor: pointer;
                    height: 48px;
                    text-align: left;
                    width: 100%;
                    padding: 12px 4px;
                    background: $msv-white;
                    color: $menu-main--color;
                    font-family: $primary-font-family;
                    font-size: $msv-font-size-l;
                    font-weight: $msv-font-weight-normal;
                    text-decoration: none;
                    border-bottom: 1px solid #e9e5e2;

                    &:hover,
                    &:focus {
                        font-weight: $msv-font-weight-bold;
                    }
                }

                button {
                    @include underline-link-animation-not-expanded($orange);
                    @include add-icon-icomoon($chevron, after);
                    cursor: pointer;
                    height: 48px;
                    text-align: left;
                    width: 100%;
                    padding: 12px 4px;
                    background: $msv-white;
                    color: $menu-main--color;
                    font-family: $primary-font-family;
                    font-size: $msv-font-size-l;
                    font-weight: $msv-font-weight-normal;
                    text-decoration: none;
                    position: sticky;
                    top: 0px;
                    z-index: 2500;
                    border-bottom: 1px solid #e9e5e2;

                    &:after {
                        display: inline-block;
                        font-size: 0.6em;
                        margin-left: 0.5rem;
                    }

                    &:not([aria-expanded='true']) {
                        &:hover,
                        &:focus {
                            font-weight: $msv-font-weight-bold;

                            &:after {
                                //@extend %move-down-animation-once;
                                transition-property: transform;
                                transition-duration: 0.3s;
                                transform: translateY(0.3em);
                            }
                        }
                    }

                    &[aria-expanded='true'] {
                        font-weight: $msv-font-weight-bold;
                        box-shadow: 0 5px 4px -7px $beige;

                        &:after {
                            transform: rotate(180deg);
                            transition-property: transform;
                            transition-duration: 0.3s;
                        }
                    }
                }

                > nav {
                    height: 100%;
                    width: 100%;

                    .collapse,
                    .collapsing {
                        margin-left: 1rem;
                        padding-left: 4px;
                        border-left: 2px solid rgb(187, 171, 153);

                        button {
                            top: 48px;
                            z-index: 2490;
                        }
                    }
                }

                // >ul {
                //     height: 100%;
                //     display: flex;
                //     flex-wrap: nowrap;
                //     justify-content: space-between;
                //     outline: none;

                //     @include media-breakpoint-down(lg) {
                //         display: block;
                //     }

                //     >li {
                //         align-items: center;
                //         display: flex;
                //         margin-right: 0.6em;
                //         margin-left: 0.6em;
                //         height: 100%;
                //         position: relative;

                //         @include media-breakpoint-down(lg) {
                //             display: block;
                //             margin-left: 0;
                //             margin-right: 0;
                //             margin-bottom: 0.5rem;
                //         }

                //     }

                //     ul {
                //         background: none;
                //         border: 1px solid $msv-gray-300;
                //         box-shadow: 0px 8px 15px $msv-box-shadow-color;
                //         font-size: $msv-font-size-l;
                //         min-width: $menu-main--dropdown-min-width;
                //         padding: 1rem $layout-gutter*2;
                //         position: absolute;
                //         top: 80%;
                //         z-index: 1;

                //         @include media-breakpoint-down(lg) {
                //             border: none;
                //             box-shadow: none;
                //             position: relative;
                //         }

                //         a {
                //             margin-bottom: 0.7rem;
                //             padding: 0;
                //             position: relative;
                //         }
                //     }
                // }

                //}
            }

            .ms-search {
                display: block;
                height: 2.8rem;
                margin-top: $spacer;
                padding: 0.7rem 0;
                position: relative;

                button {
                    background-color: $secondary;
                }

                &__label {
                    display: none;
                }

                .ms-search__form {
                    display: block;
                }

                .msc-autoSuggest__productResults-title {
                    padding: 2rem 1rem 1rem 1rem;
                }

                &__autoSuggest {
                    width: 100%;
                    border: none;
                    margin-top: 0;
                }

                &__searchForm {
                    padding-right: $layout-gutter;
                    padding-left: $layout-gutter;
                }

                &__form-submitSearch {
                    @include add-icon-icomoon($search);
                    cursor: pointer;
                    padding: 0.7rem;
                    width: auto;
                    background-color: $secondary;
                    $color: $msv-white;

                    &:before {
                        font-size: 1.25rem;
                    }
                }

                &__icon-text {
                    display: inline-block;
                }

                &__form {
                    top: 0;
                    left: 0;
                }

                &__form-cancelSearch {
                    @include add-icon-icomoon($close, before);
                    background: none !important;
                    color: $primary;
                    cursor: pointer;
                    margin-left: -2.5rem;
                    width: 2.45rem;
                }
            }

            [data-m-t='country-selector'] {
                a {
                    @include underline-link-animation($orange);
                    cursor: pointer;
                    height: 48px;
                    text-align: left;
                    width: 100%;
                    padding: 12px 4px;
                    background: $msv-white;
                    color: $menu-main--color;
                    font-family: $primary-font-family;
                    font-size: $msv-font-size-l;
                    font-weight: $msv-font-weight-normal;
                    text-decoration: none;
                    border-bottom: 1px solid #e9e5e2;

                    &:hover,
                    &:focus {
                        font-weight: $msv-font-weight-bold;
                    }
                }

                .collapse,
                .collapsing {
                    min-height: auto;
                    margin-left: 1rem;
                    padding-left: 4px;
                    border-left: 2px solid rgb(187, 171, 153);

                    button {
                        top: 48px;
                        z-index: 2490;
                    }
                }

                button {
                    @include underline-link-animation-not-expanded($orange);
                    @include add-icon-icomoon($chevron, after);
                    cursor: pointer;
                    height: 48px;
                    text-align: left;
                    width: 100%;
                    padding: 12px 4px;
                    background: $msv-white;
                    color: $menu-main--color;
                    font-family: $primary-font-family;
                    font-size: $msv-font-size-l;
                    font-weight: $msv-font-weight-normal;
                    text-decoration: none;
                    position: sticky;
                    top: 0px;
                    z-index: 2500;
                    border-bottom: 1px solid #e9e5e2;

                    &:after {
                        display: inline-block;
                        font-size: 0.6em;
                        margin-left: 0.5rem;
                    }

                    &:not([aria-expanded='true']) {
                        &:hover,
                        &:focus {
                            font-weight: $msv-font-weight-bold;

                            &:after {
                                //@extend %move-down-animation-once;
                                transition-property: transform;
                                transition-duration: 0.3s;
                                transform: translateY(0.3em);
                            }
                        }
                    }

                    &[aria-expanded='true'] {
                        font-weight: $msv-font-weight-bold;
                        box-shadow: 0 5px 4px -7px $beige;

                        &:after {
                            transform: rotate(180deg);
                            transition-property: transform;
                            transition-duration: 0.3s;
                        }
                    }
                }
            }
        }

        // &__account-info.account-mobile {
        //     a {
        //         display: block;
        //         font-weight: $msv-font-weight-normal;
        //         padding: .5rem 0;
        //     }

        //     >a:hover {
        //         text-decoration: underline
        //     }
        // }

        &__wishlist-mobile {
            @include add-icon-icomoon($heart);
            padding: 0.7rem 0;

            &:before {
                margin-right: 10px;
            }
        }

        &__desktop-view {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
                //height: 3.5rem;
            }
        }
    }

    // .ms-header__account-info.account-mobile {
    //     color: $secondary;
    //     font-size: $msv-font-size-s;
    //     padding-right: $layout-gutter;
    //     padding-left: $layout-gutter;
    //     text-transform: uppercase;

    //     @include media-breakpoint-down(md) {
    //         margin-bottom: $spacer/2;
    //     }

    //     >div {
    //         margin-left: $spacer;
    //     }

    //     .drawer__button {
    //         cursor: pointer;
    //         line-height: 1.4rem;
    //     }

    //     .drawer__buttontext.__start {
    //         @include add-icon-icomoon($user, after, true, 8px);
    //     }

    //     .drawer__glyph {
    //         @include add-icon-icomoon($chevron);
    //         display: inline-block;

    //         font-size: 1rem;
    //         margin-left: 0.75rem;

    //         @include media-breakpoint-up(lg) {
    //             font-size: 0.8em;
    //             margin-left: 0.5rem;
    //         }

    //         &.ms-nav__drawer-open {
    //             &:before {
    //                 transform: rotate(180deg) translateY(-5px);
    //             }
    //         }
    //     }

    //     >a {
    //         text-transform: uppercase;
    //         font-size: 0.875rem;
    //         @include add-icon-icomoon($user, after, true, 8px);
    //         text-decoration: none;

    //         &:hover {
    //             text-decoration: underline;
    //         }
    //     }

    //     .collapse.show a:hover {
    //         text-decoration: underline;
    //     }
    // }

    .ms-header__account-info.account-mobile {
        display: unset;
        margin-left: 0;
        color: $primary !important;
        font-size: $msv-font-size-l;
        padding-right: $layout-gutter;
        padding-left: $layout-gutter;
        text-transform: uppercase;

        // button {
        //     background: transparent;
        //     color: $secondary;
        //     cursor: pointer;
        //     font-size: 1em;
        //     padding: 0.3rem 0;
        //     text-transform: uppercase;
        // }

        a {
            @include underline-link-animation($orange);
            cursor: pointer;
            height: 48px;
            text-align: left;
            width: 100%;
            padding: 12px 4px;
            background: $msv-white;
            color: $menu-main--color;
            font-family: $primary-font-family;
            font-size: $msv-font-size-l;
            font-weight: $msv-font-weight-normal;
            text-decoration: none;
            border-bottom: 1px solid #e9e5e2;

            &:hover,
            &:focus {
                font-weight: $msv-font-weight-bold;
            }
        }

        button {
            @include underline-link-animation-not-expanded($orange);
            @include add-icon-icomoon($chevron, after);
            cursor: pointer;
            height: 48px;
            text-align: left;
            width: 100%;
            padding: 12px 4px;
            background: $msv-white;
            color: $menu-main--color;
            font-family: $primary-font-family;
            font-size: $msv-font-size-l;
            font-weight: $msv-font-weight-normal;
            text-decoration: none;
            position: sticky;
            top: 0px;
            z-index: 2500;
            border-bottom: 1px solid #e9e5e2;

            &:after {
                display: inline-block;
                font-size: 0.6em;
                margin-left: 0.5rem;
            }

            &:not([aria-expanded='true']) {
                &:hover,
                &:focus {
                    font-weight: $msv-font-weight-bold;

                    &:after {
                        //@extend %move-down-animation-once;
                        transition-property: transform;
                        transition-duration: 0.3s;
                        transform: translateY(0.3em);
                    }
                }
            }

            &[aria-expanded='true'] {
                font-weight: $msv-font-weight-bold;
                box-shadow: 0 5px 4px -7px $beige;

                &:after {
                    transform: rotate(180deg);
                    transition-property: transform;
                    transition-duration: 0.3s;
                }
            }
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .drawer__button {
            // cursor: pointer;
            // line-height: 1.4rem;

            &::before {
                padding-right: $header-icon-padding-right;
            }

            // background: transparent;
            // text-align: left;
            // width: 100%;
        }

        .drawer__buttontext.__start {
            @include add-icon-icomoon($user, after, true, 8px);
        }

        .collapse,
        .collapsing {
            min-height: auto;
            margin-left: 1rem;
            padding-left: 4px;
            border-left: 2px solid rgb(187, 171, 153);

            button {
                top: 48px;
                z-index: 2490;
            }
        }

        // .drawer__glyph {
        //     @include add-icon-icomoon($chevron);
        //     display: inline-block;

        //     font-size: 1rem;
        //     margin-left: 0.75rem;

        //     @include media-breakpoint-up(lg) {
        //         font-size: 0.8em;
        //         margin-left: 0.5rem;
        //     }

        //     &.ms-nav__drawer-open {
        //         &:before {
        //             transform: rotate(180deg) translateY(-5px);
        //         }
        //     }
        // }
    }

    //     >a {
    //         text-transform: uppercase;
    //         font-size: 1rem;
    //         @include add-icon-icomoon($user, after, true, 8px);
    //         text-decoration: none;

    //         &:hover {
    //             text-decoration: underline;
    //         }
    //     }

    //     .collapse.show a:hover {
    //         text-decoration: underline;
    //     }

    //     .ms-header__signin-button {
    //         display: flex;
    //         justify-content: flex-start;
    //     }

    //     /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    //     .msc-btn {
    //         outline-offset: -1px;
    //     }
    // }
}
