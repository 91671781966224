$cards: amex, paypal, apple-pay, visa, google-pay, satispay, master-card, discover, diners;
.payment_methods {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;

    @media screen and (max-width: $msv-container-max-width-m) {
        .ms-text-block.alignment__right & {
            justify-content: start;
        }
    }
    .icons--bigger & {
        justify-content: center;
    }

    span {
        width: 2.5rem;
        height: 1.5rem;
        display: inline-block;
        background: transparent no-repeat center;
        background-size: contain;
        background-position: right;
        margin-bottom: $spacer;
        flex-basis: 25%;

        @media screen and (max-width: $msv-container-max-width-m) {
            background-position: left;
        }

        .icons--bigger & {
            background-position: center;
            height: 2.5rem;
            width: 3.5rem;
        }

        &.img {
            @each $card in $cards {
                &_#{$card} {
                    background-image: url('../../../images/#{$card}.png');
                }
            }
        }
    }
}
