.ms-content-block .ms-content-block__image {
    img {
        width: 100%;
        height: auto;
    }
}
.ms-content-block {
    &[class*='backgroundcolor__'] {
        @media screen and (max-width: $msv-breakpoint-m) {
            .ms-content-block__details {
                padding: $spacer-m;
            }
        }
    }
    &[data-m-layout='tile'] {
        margin: 0 auto;
        width: 100%;

        .ms-content-block__details {
            padding-top: $spacer-l;
        }
        .ms-content-block__details,
        &.textplacement__left .ms-content-block__details {
            .ms-content-block__cta {
                padding-top: 10px;

                a[class^='msc-cta__'] {
                    margin-left: 1.5rem;
                    text-decoration: underline;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
        &.textplacement__right .ms-content-block__details {
            text-align: right;

            .ms-content-block__cta a[class^='msc-cta__'] {
                margin-right: 1.5rem;

                @media screen and (max-width: 375px) {
                    margin-right: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        &.textplacement__center .ms-content-block__details {
            text-align: center;

            .ms-content-block__cta a[class^='msc-cta__'] {
                margin-right: 1.5rem / 2;
                margin-left: 1.5rem / 2;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
        &.textverticalplacement {
            &__middle {
                display: flex;
                align-items: center;
            }
            &__top {
                display: flex;
                align-items: flex-start;
            }
            &__bottom {
                display: flex;
                align-items: flex-end;
            }
        }
        @media (max-width: $msv-breakpoint-m) {
            padding-bottom: 3rem;
            width: 100%;

            & .ms-content-block__image img {
                width: 100%;
            }
        }
    }
}
.col-sm:first-child .ms-content-block[data-m-layout='tile']:first-child,
.col-md:first-child .ms-content-block[data-m-layout='tile']:first-child {
    margin-left: 0;
}
.col-sm:last-child .ms-content-block[data-m-layout='tile']:last-child,
.col-md:last-child .ms-content-block[data-m-layout='tile']:last-child {
    margin-right: 0;
}
@media (max-width: $msv-breakpoint-m) {
    &.col-sm:last-child .ms-content-block[data-m-layout='tile']:last-child {
        padding-bottom: 0;
    }
}
