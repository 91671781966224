:root {
    --msv-search-result-font-color: #{$msv-gray-900};
    --msv-search-result-font-size: var(--msv-body-font-size-s);
}

.ms-search-result-container a {
    font-weight: $msv-font-weight-normal;
    text-decoration: none;
}

.ms-search-result-container__refine-product-section {
    display: inline-flex;
    align-items: start;
    margin-bottom: $spacer-xl;
    width: 100%;
}

.ms-search-result-container__refiner-section {
    display: block;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    // padding-right: $spacer-xl;
    // width: 25%;

    .collapse:not(.show) {
        display: none;
    }

    // @include media-breakpoint-up(lg) {
    //     padding-right: $spacer-l;
    //     margin-right: $spacer-m;
    //     position: sticky;
    //     top: 10rem;
    //     align-self: flex-start;
    //     height: calc(100vh - 12rem);
    //     overflow-y: auto;
    // }

    button.ms-refine-submenu__toggle_collapsed,
    .ms-refine-submenu__toggle_expanded {
        background: transparent;
        color: $secondary;
        display: flex;
        justify-content: space-between;
        font-size: 1.25rem;
        font-weight: $msv-font-weight-bold;
        margin-bottom: $spacer;
        padding: 0;
        width: 100%;
        cursor: pointer;
    }

    .ms-search-result-container__refine-menu {
        border-top: $border-separator;
        display: flex;
        flex-direction: column;
    }

    .ms-refine-submenu.list-group {
        border-bottom: $border-separator;
        display: flex;
        flex-direction: column;
        padding: $spacer 0;

        &.Category,
        &.Categoria,
        &.Catégorie,
        &.Kategorie,
        &.Rating,
        &.Valutazione,
        &.Bewertung,
        &.Classement {
            display: none;
        }

        // &.Recipe {
        //     order: 1;
        // }

        // &.Product.Type {
        //     order: 2;
        // }

        // &.Percent.Sale {
        //     order: 3;
        // }

        &.Price,
        &.Prezzo,
        &.Prix,
        &.Preis {
            order: 4;
        }

        // &.Product.Line {
        //     order: 5;
        // }

        .ms-refine-submenu__list {
            margin-left: $layout-gutter;
            margin-right: $layout-gutter;
            margin-top: $layout-gutter/2;
            font-weight: $msv-font-weight-normal;
        }

        .ms-refine-submenu-item {
            margin-bottom: $spacer;

            > a {
                @include vfi();
                color: $secondary;
            }

            &:has(a[aria-label*='Nessuno']) {
                display: none;
            }

            .msc-rating {
                display: inline-block;
            }
        }

        .multi-select,
        .multi-select-checked,
        .single-select,
        .single-select-checked {
            @include vfi();
            display: block;
            border: 1px solid transparent;

            span.ms-refine-submenu-item__label {
                vertical-align: top;
            }
        }
    }

    .list-group-item {
        border: 1px solid rgba(0, 0, 0, 0);
        display: block;
        outline-offset: 2px;
        padding: $spacer $layout-gutter;
        position: relative;

        @media screen and (max-width: $msv-breakpoint-l) {
            margin-top: 2rem;
        }
    }

    .slider-horizontal {
        background-color: $secondary-20;
        height: 0.25rem;
    }

    .slider {
        cursor: pointer;
        display: block;
        padding: 0;
        position: relative;
        touch-action: none;

        .slider__track {
            background-color: $secondary;
            height: 100%;
            position: absolute;
            top: 0;
        }

        .slider__thumb {
            @include vfi();
            background: $white;
            border: 4px solid $secondary;
            border-radius: 100%;
            display: inline-block;
            height: 1rem;
            outline-offset: 2px;
            position: absolute;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 1rem;

            @media screen and (max-width: $msv-breakpoint-l) {
                height: 1.3125rem;
                width: 1.3125rem;

                & + div {
                    display: none !important;
                    opacity: 0 !important;
                }
            }
        }

        .slider__thumb:before {
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            outline-offset: calc(0px + 0.0625rem);
            position: absolute;
            top: 0;
            right: 0;
        }

        .slider__labels-item {
            cursor: pointer;
            display: inline-block;
            font-size: $msv-font-size-s;
            margin-top: 25px;
            color: $secondary;
        }

        .slider__labels-item.start {
            float: left;
        }

        .slider__labels-item.end {
            float: right;
        }
    }
}

.ms-search-result-container__product-section {
    display: flex;
    flex-wrap: wrap;
    width: 75%;
}

.ms-search-result-container__title h1 {
    color: $primary;
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;
    @media (max-width: $msv-breakpoint-m) {
        margin-bottom: 1rem;
    }
}

@media (max-width: $msv-breakpoint-l) {
    .ms-search-result-container__product-section {
        width: 100%;
    }

    .ms-search-result-container__refiner-section {
        display: none;
    }
}

.hide-search-prefix-from-category-page .ms-search-result__collection-title-prefix {
    display: none !important;
}

.msc-choice-summary {
    list-style-type: none;
    vertical-align: top;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
        text-align: center;
    }

    .msc-choice-summary__list {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-wrap: wrap;
        padding: 0 0.5rem 0.5rem 0px;
    }

    .msc-choice-summary__list-item {
        display: inline-block;
        background: $secondary-20;
        border-radius: 16px;
    }

    .msc-choice-summary__item {
        color: $secondary;
        display: inline-block;
        font-weight: $msv-font-weight-normal;
        letter-spacing: 0.5px;
        margin: 0;
        padding: 0 0.5rem;
        line-height: 1.8rem;
        height: 1.8rem;
        text-decoration: none;
        word-break: break-word;
    }

    .msc-choice-summary__clear-all {
        @extend %hyperlink;
        line-height: 1.8rem;
    }

    .msc-choice-summary__glyph {
        @include add-icon-icomoon($close, $selector: after, $next-to-text: true, $margin: $layout-gutter, $scale: false);
        vertical-align: middle;

        &:after {
            font-size: 0.8rem;
            font-weight: $msv-font-weight-bold;
        }
    }

    .msc-choice-summary__clear-all:after {
        display: none;
    }
}

.ms-search-result-container__Sort-by-category {
    @include media-breakpoint-down(md) {
        margin-bottom: $spacer;
    }

    .msc-l-dropdown__label {
        color: $secondary;
        font-size: $msv-font-size-m;
        font-weight: $msv-font-weight-normal;
        margin-right: $layout-gutter;
        margin-bottom: 0;
        vertical-align: middle;
        line-height: 1.8rem;
    }

    .msc-l-dropdown {
        background: transparent;
        color: $secondary;
        font-size: $msv-font-size-m;
        font-weight: $msv-font-weight-normal;
        padding: 0;
        vertical-align: middle;
        height: 1.8rem;
        max-width: calc(100% - 16px);
        text-overflow: ellipsis;

        option {
            padding-left: 0;
        }
    }
}

.msc-product__text {
    display: none;
}

.ms-search-result-container__Products {
    flex: 0 0 100%;
    margin-top: $spacer;
    margin-bottom: $spacer;
    max-width: 100%;
    width: 100%;

    .list-unstyled {
        display: grid;
        //grid-column-gap: $layout-gutter;
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        //margin-right: -15px;
        //margin-left: -15px;
        width: 100%;

        @media (max-width: 370px) {
            grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));
        }

        @include media-breakpoint-up(xs) {
            grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
        }

        // @include media-breakpoint-up(md) {
        //     grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
        // }

        // @include media-breakpoint-up(xl) {
        //     grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        // }

        @include media-breakpoint-down(md) {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.ms-product-search-result__item {
    position: relative;
    margin: $layout-gutter/2;
    @extend %basic-product-card;

    @include media-breakpoint-down(md) {
        margin: $layout-gutter/4;
    }
}

.ms-product-search-result__item a.msc-product {
    .msc-rating {
        display: block;
        margin-top: 0.25rem;
    }

    &:focus {
        outline: none;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.ms-search-result-container__category-hierarchy {
    text-align: center;
    padding: 0 0 $spacer/2 0;
    a {
        color: $primary;
        font-weight: $msv-font-weight-normal;
    }
}

nav.ms-search-result-container__pagination {
    border-top: $border-separator;
    padding: $spacer 0;
    width: 100%;
}

.msc-pagination {
    color: $secondary;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.25em;
    align-items: center;
    font-family: $secondary-font-family;
    font-weight: $msv-font-weight-normal;
    list-style: none;
    padding-left: 0;
    text-transform: uppercase;
    width: 100%;

    .msc-page-item {
        margin-left: 2px;
        margin-right: 2px;
        border: 1px solid $secondary;
        color: $secondary;
        font-family: $secondary-font-family;
        text-transform: uppercase;
        padding: 0.2rem;
        font-size: $msv-font-size-m;
        text-decoration: none;
        text-align: center;
        min-width: 2.75em;
        min-height: 2.75em;
        max-height: 2.75em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: $msv-font-weight-bold;
        background: transparent;
        transition: all 0.25s;

        a {
            font-weight: inherit;
            font-family: $secondary-font-family;
        }

        &:focus,
        &:hover {
            background-color: $secondary;
            color: $white;
            cursor: pointer;
            text-decoration: none;

            a {
                color: $white;
            }
        }

        &.active {
            cursor: default;
            background-color: $secondary-20;
            color: $secondary;
            border-color: $secondary;
        }

        &:disabled,
        &.disabled {
            font-weight: $msv-font-weight-normal;
            cursor: default;
            background: transparent;
            color: #33728d;
            border-color: #33728d;
        }
    }
}

ul.msc-pagination {
    display: flex;
    justify-content: center;
}

.previous.msc-page-item {
    margin-right: auto;
    margin-left: 0;

    @media (max-width: $msv-breakpoint-m) {
        order: -2;
        flex: 50%;
    }

    .msc-pagination__prev {
        display: inline-block;
        padding-left: 4px;
    }

    .msc-page-link {
        display: block;
        padding-left: 0;

        .ms-search-result__pagination-left {
            @include add-icon-icomoon($chevron, $selector: before, $next-to-text: true, $margin: 8px, $scale: false);

            &:before {
                transform: rotate(90deg);
            }
        }
    }
}

.msc-page-item.disabled .msc-page-link {
    color: $secondary-80;
    cursor: auto;
    pointer-events: none;
}

.next.msc-page-item {
    margin-left: auto;
    margin-right: 0;

    background-color: $secondary;
    color: $white;

    a {
        color: $white;
    }

    &:focus,
    &:hover {
        background-color: $secondary-hover;
        border-color: $secondary-hover;
        color: $white;
        cursor: pointer;
        text-decoration: none;

        a {
            color: $white;
        }
    }

    &:disabled,
    &.disabled {
        font-weight: $msv-font-weight-normal;
        cursor: default;
        background: transparent;
        color: #33728d;
        border-color: #33728d;
    }

    @media (max-width: $msv-breakpoint-m) {
        order: -1;
        flex: 50%;
    }

    a {
        padding-right: 0;
    }

    .msc-pagination__next {
        display: inline-block;
    }

    .msc-page-link .ms-search-result__pagination-right {
        @include add-icon-icomoon($chevron);

        &:before {
            transform: rotate(-90deg);
        }
    }
}

.msc-page-link {
    display: block;
    padding: 8px;
}

.active {
    text-decoration: none;
}

.prev-text {
    padding: 0 0.25rem;
}

.next-text {
    padding: 0 0.25rem;
}

// modal
.msc-search-result-modal {
    height: 100%;
    position: absolute;
    top: 0px;
    margin: 0;
    width: 576px;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    .msc-modal__content {
        height: 100%;
        padding: 1.25rem 1.5rem;
    }

    .msc-modal__header {
        justify-content: space-around;
    }

    .msc-review-modal-body {
        overflow-y: auto;
    }

    .ms-sort-and-filter-modal-close.msc-btn {
        @extend %solid-button;
        min-width: 160px;
    }

    .ms-search-result-container__refiner-section {
        overflow-wrap: break-word;
        display: block;
        padding-right: 0;
        width: 100%;
    }
}

.desktop_products_top_bar.row {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 1em;
    width: 100%;
    padding: 0 0.5em;
    display: none;

    .ms-search-result__collection-title-count {
        flex: 1;
        font-weight: $msv-font-weight-normal;
        font-size: $msv-font-size-m;
        line-height: 1.8rem;
    }
}

.desktop_choice-summary {
    display: none;
    padding: 0 0.5rem;
}

@media (max-width: $msv-breakpoint-l) {
    .ms-search-result-container__Sort-by-category {
        position: relative;
        top: 0;
        right: auto;
        z-index: 1;
    }

    .ms-search-result-container {
        padding-left: 0;
        padding-right: 0;
    }

    .ms-sort-and-filter-modal-close.msc-btn {
        width: 100%;
    }

    .ms-product-search-result__item .msc-product__details .msc-price {
        display: flex;
        flex-direction: column;
    }
}

.desktop-refiners-left-container {
    display: block;
    padding-right: $spacer-xl;
    width: 25%;
    padding-right: 2rem;
    margin-right: 1.5rem;
    position: sticky;
    top: 10rem;
    align-self: flex-start;
    height: calc(100vh - 12rem);
    overflow-y: auto;

    @media (max-width: $msv-breakpoint-l) {
        display: none;
    }
}

.ms-search-result__sticky-top-bar {
    white-space: nowrap;
    background-color: white;
    display: flex;
    // min-height: 3.5rem;
    position: sticky;
    top: 3rem;
    z-index: 100;
    // padding-top: 0.5rem;
    box-shadow: rgba(120, 120, 120, 0.05) 0px 3px 2px 0px, rgba(0, 0, 0, 0.06) 0px 2px 1px 0px;
    margin-left: -15px;
    margin-right: -15px;
    overflow-x: hidden;

    .mobile-refiners-row {
        flex-wrap: nowrap;
        white-space: nowrap;
        display: flex;
        align-items: center;
        width: 100%;
        overflow-x: auto;

        &__button {
            display: inline-flex;
            align-items: center;
            order: 0;
            flex: 1;
            .msc-button {
                @include border-button();
                display: block;
                padding: 0.5rem;
                font-size: 1rem;
                border-width: 1px;
                &:not(:disabled):hover,
                &:not(:disabled):not(.disabled):active:focus {
                    background-color: $secondary-hover;
                    color: $white;
                }
            }
            .mobile-refiners-row__refiners-count {
                display: block;
                padding: 0.5rem;
                font-size: 1rem;
                font-family: $secondary-font-family;
                font-weight: $msv-font-weight-bold;
                color: $secondary;
            }
        }

        &__count {
            order: 1;
            align-self: center;
            font-size: 1.125rem;
        }

        #search-result-modal {
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0;
            padding: 0;
            margin-left: 10px;
            background: transparent;
        }

        #search-result-modal::after {
            content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30" fill="none" x="0px" y="0px"%3E%3Cg%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M9 7C8.44772 7 8 7.44772 8 8C8 8.55228 8.44772 9 9 9C9.55228 9 10 8.55228 10 8C10 7.44772 9.55228 7 9 7ZM6.17071 7C6.58254 5.83481 7.69378 5 9 5C10.3062 5 11.4175 5.83481 11.8293 7L21 7C21.5523 7 22 7.44772 22 8C22 8.55229 21.5523 9 21 9L11.8293 9C11.4175 10.1652 10.3062 11 9 11C7.69378 11 6.58254 10.1652 6.17071 9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7H6.17071ZM12.1707 15C12.5825 13.8348 13.6938 13 15 13C16.3062 13 17.4175 13.8348 17.8293 15H21C21.5523 15 22 15.4477 22 16C22 16.5523 21.5523 17 21 17H17.8293C17.4175 18.1652 16.3062 19 15 19C13.6938 19 12.5825 18.1652 12.1707 17L3 17C2.44772 17 2 16.5523 2 16C2 15.4477 2.44772 15 3 15L12.1707 15ZM15 15C14.4477 15 14 15.4477 14 16C14 16.5523 14.4477 17 15 17C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15Z" fill="%23004F71"%3E%3C/path%3E%3C/g%3E%3C/svg%3E');
            width: 30px;
            height: 33px;
        }
    }
}

@media (min-width: $msv-breakpoint-l) {
    .ms-search-result__sticky-top-bar {
        display: none;
    }

    .desktop_products_top_bar.row {
        display: flex;
    }
    .desktop_choice-summary {
        display: block;
    }
}

.category-description-text {
    h2 {
        @include heading;
        font-size: 2.5rem;
        color: $primary;

        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }

        text-transform: none;
        text-align: left;
    }
}

.ms-search-result__collection-title-count {
    visibility: hidden;
}

.ms-search-result-container__category-nav-section {
    @media screen and (max-width: $msv-container-max-width-s) {
        display: none;
    }
}
