.ms-content-card {
    padding: $spacer * 2 $layout-gutter * 2;

    .ms-content-card__details {
        .card-title {
            @include font-content-xl(var(--msv-font-weight-normal));
            display: flex;
            align-items: center;
            margin: 1.25rem;
        }
        .card-text {
            @include font-content-s(var(--msv-font-weight-normal));
        }
    }
}
