:root {
    --msv-promo-banner-bg: #{$msv-gray-1000};
    --msv-promo-banner-border: #{$msv-gray-1000};
    --msv-promo-banner-font-color: #{$msv-white};
    --msv-promo-banner-font-size: var(--msv-body-font-size-m);
}

.ms-promo-banner {
    background: $msv-white;
    border-color: var(--msv-promo-banner-border);
    display: flex;
    flex-wrap: wrap;
    min-height: 40px;
    line-height: $msv-text-line-height;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;

    &.alignment__center {
        text-align: center;
    }

    &.alignment__left {
        text-align: left;
    }

    &.alignment__right {
        text-align: right;
    }

    &__text,
    &__link {
        color: $secondary;
        font-size: $msv-font-size-m;
        font-style: normal;
        font-weight: $msv-font-weight-bold;

        @media screen and (max-width: 500px) {
            font-size: $msv-font-size-s;
        }
    }

    &__link {
        margin-left: 0.25rem;
        text-decoration: underline;
    }

    &__carousel {
        width: 98%;

        @media screen and (max-width: $msv-breakpoint-m) {
            width: 95%;
        }
    }

    &__close-button {
        background-color: transparent;
        border: none;
        color: var(--msv-font-secondary-color);
        cursor: pointer;

        &:after {
            @include msv-icon();
            content: $msv-x-shape;
        }
    }

    &__close-button__label,
    .msc-carousel__indicators {
        display: none;
    }

    .msc-carousel__inner {
        color: var(--msv-font-primary-color);

        .msc-carousel__item.active {
            text-decoration: none;
        }
    }
}

.layout2022 {
    .ms-promo-banner {
        background: $secondary;
        display: flex;
        flex-wrap: wrap;
        min-height: 2rem;
        line-height: $msv-text-line-height;
        align-items: center;
        width: 100%;
        padding: 0;

        &.alignment__center {
            text-align: center;
        }

        &.alignment__left {
            text-align: left;
        }

        &.alignment__right {
            text-align: right;
        }

        &__text,
        &__link {
            color: $msv-white;
            font-size: $msv-font-size-m;
            font-style: normal;
            font-weight: $msv-font-weight-bold;

            @media screen and (max-width: 500px) {
                font-size: $msv-font-size-s;
            }
        }

        &__link {
            margin-left: 0.25rem;
            text-decoration: underline;
        }

        &__carousel {
            width: 98%;

            @media screen and (max-width: $msv-breakpoint-m) {
                width: 95%;
            }
        }

        &__close-button {
            background-color: transparent;
            border: none;
            color: var(--msv-font-secondary-color);
            cursor: pointer;

            &:after {
                @include msv-icon();
                content: $msv-x-shape;
            }
        }

        &__close-button__label,
        .msc-carousel__indicators {
            display: none;
        }

        .msc-carousel__inner {
            color: var(--msv-font-primary-color);

            .msc-carousel__item.active {
                text-decoration: none;
            }
        }
    }
}
