$name-top-margin: 4px;
$rating-top-margin: 4px;
$price-top-margin: 4px;
$original-price-color: #616365;
$original-price-right-margin: 4px;
$product-font: $msv-primary-font-family;
$product-title-color: #000;

%basic-product-card {
    // PLP product card
    a.msc-product {
        //padding: $layout-gutter;
        position: static;
        color: $msv-black;
        text-decoration: none;
        border: 1px solid #e7e7e7;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // box-shadow:
        // 0px 0px 0.3px rgba(0, 0, 0, 0.016),
        // 0px 0px 0.7px rgba(0, 0, 0, 0.019),
        // 0px 0px 1.3px rgba(0, 0, 0, 0.021),
        // 0px 0px 2.2px rgba(0, 0, 0, 0.027),
        // 0px 0px 4.2px rgba(0, 0, 0, 0.043),
        // 0px 0px 16px rgba(0, 0, 0, 0.08);
        // box-shadow:
        // 0px 0px 2.9px rgba(0, 0, 0, 0.009),
        // 0px 0px 5.1px rgba(0, 0, 0, 0.009),
        // 0px 0px 7.5px rgba(0, 0, 0, 0.015),
        // 0px 0px 16px rgba(0, 0, 0, 0.04);

        &:hover {
            border: 1px solid $primary;
            // box-shadow:
            // 0px 0px 0.4px rgba(0, 0, 0, 0.048),
            // 0px 0px 1.1px rgba(0, 0, 0, 0.059),
            // 0px 0px 2px rgba(0, 0, 0, 0.066),
            // 0px 0px 3.6px rgba(0, 0, 0, 0.079),
            // 0px 0px 6.7px rgba(0, 0, 0, 0.104),
            // 0px 0px 16px rgba(0, 0, 0, 0.14);
            box-shadow: 0px 0px 2.9px rgba(0, 0, 0, 0.009), 0px 0px 5.1px rgba(0, 0, 0, 0.009), 0px 0px 7.5px rgba(0, 0, 0, 0.015),
                0px 0px 16px rgba(0, 0, 0, 0.04);
        }
    }

    // PM product card
    .msc-product__mix-item {
        //padding: $layout-gutter;
        position: static;
        color: $msv-black;
        text-decoration: none;
        border: 1px solid #e7e7e7;
        // box-shadow:
        // 0px 0px 0.3px rgba(0, 0, 0, 0.016),
        // 0px 0px 0.7px rgba(0, 0, 0, 0.019),
        // 0px 0px 1.3px rgba(0, 0, 0, 0.021),
        // 0px 0px 2.2px rgba(0, 0, 0, 0.027),
        // 0px 0px 4.2px rgba(0, 0, 0, 0.043),
        // 0px 0px 16px rgba(0, 0, 0, 0.08);
        // box-shadow:
        // 0px 0px 2.9px rgba(0, 0, 0, 0.009),
        // 0px 0px 5.1px rgba(0, 0, 0, 0.009),
        // 0px 0px 7.5px rgba(0, 0, 0, 0.015),
        // 0px 0px 16px rgba(0, 0, 0, 0.04);

        .msc-product__details__pm {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .msc-price {
            // margin: 0.5rem 0 1rem 0;
            min-height: 2.6em;
        }

        .msc-product__title {
            text-align: left;
            display: inline-block;
            flex: 1;
            font-size: 1.125rem;
            font-weight: 300;
            text-transform: none;
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 16px;
        }
    }

    .msc-add-to-cart {
        z-index: 3;
        // height: 2.8em;
        width: 100%;
        background-color: white;
        color: $secondary;
        border: 1px solid $secondary;
        // font-size: calc(clamp(12px, 90%, 15px)) !important;
        font-size: 1rem !important;
        overflow-wrap: normal;
        // white-space: nowrap;
        // padding: 0 0.5rem 0.25rem 0.5rem;
        padding: 0.5rem;
        // margin: -1px;
        // border: 1px solid $secondary;
        // -webkit-box-shadow: inset 0px 0px 0px 1px $secondary;
        // -moz-box-shadow: inset 0px 0px 0px 1px $secondary;
        // box-shadow: inset 0px 0px 0px 1px $secondary;

        // @media screen and (min-width: 371px) and (max-width: 390px) {
        // 	font-size: 0.625rem !important;
        // 	padding: 0 0.5rem;
        // }
        // @media screen and (min-width: 391px) and (max-width: 425px) {
        // 	font-size: 0.75rem !important;
        // 	padding: 0 0.5rem;
        // }

        &:after {
            margin-left: 8px;
        }

        &:hover {
            background-color: #004f71;
            color: $white;
        }
    }

    .msc-product__image {
        background-color: #f7f7f7;

        img {
            width: 100%;
            mix-blend-mode: multiply;
        }
    }

    .msc-price {
        font-size: 1.125rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        &__actual {
            color: black;
            font-weight: 500;
            // padding-top: 0.1875rem;
        }

        &-discounted {
            font-size: 1.125rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;

            // ?
            // @media screen and (max-width: 425px) {
            // 	width: 100%;
            // }

            // @media screen and (min-width: 426px) {
            // 	width: 80%;
            // }

            &-mobile {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: flex-start;
                // width: 100%;

                // ?
                @media screen and (max-width: 425px) {
                    width: 100%;
                }

                @media screen and (min-width: 426px) and (max-width: 767px) {
                    width: 85%;
                }

                @media screen and (min-width: 768px) {
                    width: 61.8%;
                }
            }

            &__strikethrough {
                color: black;
                text-decoration: line-through;
                font-weight: 400;
                margin-right: 0px;
            }

            &__percent-discount {
                color: white;
                background: $orange2;
                text-decoration: none;
                font-weight: 400;
                font-size: 0.9rem;
                padding: 0.1rem 0.375rem;
            }

            &__actual {
                color: black;
                font-weight: 500;

                &-post-discount {
                    color: $orange2;
                    font-weight: 500;
                }
            }

            // Omnibus
            &__recent-price {
                font-weight: 300;
                font-size: 12px; // Minimum set by Google Search Console
                margin-top: 0.1rem;
                display: block;
                width: 100%;
                text-align: center;
            }
        }
    }

    .msc-product {
        @include vfi();
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        white-space: normal;

        &:hover {
            text-decoration: none;
        }

        &__title {
            display: inline-block;
            flex: 1;
            font-size: 1.125rem;
            font-weight: $msv-font-weight-light;
            text-transform: none;
            text-align: center;
            padding-left: $layout-gutter;
            padding-right: $layout-gutter;
            padding-top: $layout-gutter;
            margin-bottom: $spacer;

            @media screen and (max-width: 425px) {
                padding-left: $layout-gutter / 2;
                padding-right: $layout-gutter / 2;
                padding-top: $layout-gutter / 2;
            }
        }

        &__price-and-avail {
            padding: 0px;
        }

        &__extra-data {
            display: none;
        }

        // &__price-container {
        // 	display: flex;
        // 	flex-direction: column;
        // 	align-items: center;
        // 	margin: 0.25rem 0;

        // TODO smaller font size for base price when discount?

        &__features {
            display: flex;
            height: 100%;
            flex-direction: column;
        }

        &__details {
            // flex: 1;
            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;
            // background-color: $white;
            // z-index: 2;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        // &__min-height-avail-row {
        // 	// min-height: 3.8rem;
        // 	display: flex;
        // 	flex-direction: column-reverse;
        // }

        &__avail-row {
            padding: 0.5rem;
            display: flex;
            flex-direction: column-reverse;
        }

        &__features {
            display: flex;
            height: 100%;
            flex-direction: column;
        }

        &__details {
            // flex: 1;
            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;
            // background-color: $white;
            // z-index: 2;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        &.status_OOS .ms-buybox__inventory-info {
            background-color: #fffffff7;
            line-height: 1em;
            padding-right: $layout-gutter;
            padding-left: $layout-gutter;
            min-height: 2.9rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .ms-buybox__inventory-label:before {
                display: none;
            }

            span {
                line-height: 1em;
                font-size: 0.9em;

                &:before {
                    height: 0.8rem;
                    width: 0.8rem;
                }
            }
        }
    }

    .msc-empty_image {
        @include image-placeholder(290px);
    }

    // }
}

.msc-product__extra-data {
    display: none;
}

// What is this?
.msc-product-card {
    font-size: var(--msv-body-font-size-s);

    &__image {
        width: 100%;
    }

    &__name {
        margin-top: $name-top-margin;
    }

    &__price-container {
        margin-top: $price-top-margin;
    }

    &__original-price {
        color: $original-price-color;
        margin-right: $original-price-right-margin;
    }

    &__rating {
        margin-top: $rating-top-margin;
    }
}
