:root {
    --msv-checkout-bg: var(--msv-bg-color);
    --msv-checkout-font-size: var(--msv-body-font-size-l);
    --msv-checkout-error-message-bg: var(--msv-error-color);
    --msv-checkout-error-message-border: var(--msv-error-color);
    --msv-checkout-error-message-color: var(--msv-font-secondary-color);
    --msv-checkout-heading-font-color: var(--msv-font-secondary-color);
    --msv-checkout-heading-font-size: var(--msv-body-font-size-xl);
}
.ms-checkout {
    padding-top: $section-space;
    padding-bottom: $section-space;

    &__extratext {
        display: none;
    }
    &__guided-card.updating .ms-checkout__extratext {
        display: block;
        margin-bottom: $spacer;

        p {
            color: $msv-black;
        }
    }
    [aria-invalid='true'] {
        label {
            @extend %invalid-label;
        }
        input,
        select,
        textarea {
            @extend %invalid-input;
        }
    }
    .error-message {
        color: $red;
        font-weight: $msv-font-weight-normal;
    }
    &__error-message {
        background-color: var(--msv-checkout-error-message-bg);
        border: 1px solid var(--msv-checkout-error-message-border);
        color: var(--msv-checkout-error-message-color);
        margin-bottom: 1.25rem;
        padding: 18px;
        width: 100%;

        &:before {
            @include msv-icon();
            content: $msv-IncidentTriangle;
            margin-right: 0.5rem;
        }
    }
    &-section-container {
        padding-left: 0;

        &__item.hidden {
            display: none;
        }
    }
    &__title {
        @extend %h1;
    }
    &__main-control {
        .ms-checkout__btn-place-order {
            margin-right: 0px;
        }

        button {
            min-width: 12rem;
        }
    }
    .msc-order-summary-wrapper {
        padding-bottom: 0;

        .msc-order-summary__line-total {
            margin-bottom: 0;
        }
    }
    &__side-control-first {
        background-color: mix($secondary, $white, 10%);
        display: block;
        justify-content: center;
        margin: 0;

        @include media-breakpoint-up(md) {
            padding: 0 $spacer-xl $spacer-l $spacer-xl;
        }
        .ms-checkout__btn-place-order {
            margin: $spacer auto;
            width: 100%;
        }
    }
    &__side-control-second {
        display: none;
    }
    &__main-control {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-top: $layout-gutter;
    }
    &__btn-place-order {
        @extend %solid-button;
        margin-left: 10px;

        &.is-busy {
            cursor: progress;

            &:before {
                @include msv-icon();
                content: $msv-Spinner;
                margin-right: 0.5rem;
                -webkit-animation: spin 1s steps(8) infinite;
                animation: spin 1s steps(8) infinite;
            }
        }
    }
    &__btn-keep-shopping {
        @extend %hyperlink;
        margin-top: $spacer;
        min-width: 0;
        padding-right: 0;
        padding-left: 0;
    }
    &__main {
        margin-bottom: $spacer;
    }
    &-payment-instrument__error {
        padding: 0.5rem;

        &-title {
            @include add-icon-icomoon($error, before, true);
            color: $red;
            display: block;
            font-weight: $msv-font-weight-normal;
            margin-top: 2px;
            margin-bottom: 10px;
        }
        &-message {
            color: $red;
            display: block;
            font-weight: $msv-font-weight-normal;
            margin-bottom: $spacer;
        }
    }
    &__guided-card {
        padding-top: $spacer;
        padding-bottom: $spacer;

        &:last-child .ms-checkout__guided-card-body {
            border-bottom: 1px solid $secondary;
            padding-bottom: $spacer;
        }
        &-header {
            border-bottom: 4px solid $secondary;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
        }
        &.hidden {
            display: none;
        }
        &.closed {
            opacity: 0.5;
        }
        &.visted,
        &.active {
            text-decoration: none;
        }
        &.updating {
            color: $secondary;
        }
        &-title {
            @extend %h2;
            color: $secondary;
            display: flex;
            margin-bottom: $spacer/2;
            text-align: left;
        }
        &-title-step {
            width: 2rem;
        }
        &-body {
            font-size: $msv-font-size-l;
            letter-spacing: 0.5px;
            margin-top: $spacer;
            padding-right: 2rem;
            padding-left: 2rem;

            &.hidden {
                display: none;
            }
        }
        &-btn-cancel {
            @extend %hyperlink;
            padding-right: 0;
            padding-left: 0;
        }
        &-btn-save {
            @extend %border-button;
        }
        &-btn-cancel,
        &-btn-save {
            @include media-breakpoint-up(md) {
                transform: translateX(2rem);
                margin-left: $layout-gutter;
            }
            &.is-submitting {
                cursor: progress;

                &:before {
                    @include msv-icon();
                    content: $msv-Spinner;
                    margin-right: 0.5rem;
                    -webkit-animation: spin 1s steps(8) infinite;
                    animation: spin 1s steps(8) infinite;
                }
            }
        }
        &-btn-edit {
            @extend %hyperlink;
            justify-self: end;
            margin-left: $layout-gutter;
            padding-right: 0;
        }
        &-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: $spacer;
        }
    }
    &__side-control {
        &-first,
        &-second {
            margin-bottom: 1.25rem;
            text-align: center;
        }
    }
    &__line-item {
        padding: $layout-gutter 0;
        border-bottom: $border-separator;

        &:last-child {
            border: 0;
        }
    }
    &__line-items {
        background-color: var(--msv-checkout-bg);
        border: 4px solid $secondary;
        padding: $spacer;

        @include media-breakpoint-up(md) {
            padding: $spacer-xl;
        }
        &-edit-cart-link {
            @extend %hyperlink;
        }
        &-header {
            margin-bottom: $spacer-l;
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            .ms-checkout__line-items-heading {
                display: inline;
                @extend %h1;
                font-size: 2rem;
                color: $secondary;
                margin-bottom: 0;
            }
        }
        &-delivery-group {
            .ms-checkout__line-items-group-title {
                font-weight: $msv-font-weight-bold;
                font-size: 1.25rem;
                color: $secondary;
            }
            .ms-checkout__pick-up-at-store {
                margin-left: 128px;
                margin-top: 10px;
                margin-bottom: 1.25rem;

                .ms-checkout__store-location {
                    font-weight: var(--msv-font-weight-bold);
                }
            }
            .msc-cart-line {
                &__product {
                    padding: 0;
                }
                &__product-image {
                    margin-right: unset;
                    height: 108px;
                    position: relative;
                    width: 108px;
                }
                &__content {
                    display: block;
                    margin-left: $layout-gutter;
                    width: calc(100% - 128px);

                    .msc-cart-line__product-title {
                        font-weight: $msv-font-weight-normal;
                        font-size: 1.25rem;
                        margin-bottom: $spacer/2;
                        min-height: 0;
                    }
                    .msc-cart-line-item-product-discount {
                        color: var(--msv-success-color);
                        margin-bottom: $spacer/2;
                        word-break: break-word;
                    }
                    .msc-cart-line__quantity {
                        font-weight: $msv-font-weight-normal;

                        .quantity-label {
                            display: inline-block;
                            font-size: var(--msv-body-font-size-s);
                            line-height: $msv-text-line-height;
                            margin-right: 5px;
                        }
                        .quantity-value {
                            display: inline;
                            text-align: left;
                        }
                    }
                    .msc-cart-line__product-price {
                        display: block;
                        font-weight: $msv-font-weight-bold;
                        margin-bottom: $spacer/2;

                        .msc-price__strikethrough {
                            display: inline;
                            margin-right: 10px;
                        }
                    }
                    .msc-cart-line__product-savings {
                        display: none;
                        margin-left: 0px;
                        text-align: left;
                    }
                }
            }
        }
    }
    &__greeting {
        background-color: mix($secondary, $white, 10%);
        color: $secondary;
        margin-bottom: $spacer-m;
        padding: $spacer-m $spacer-xl;

        h3 span {
            margin-right: 0.5rem;
        }
        label {
            font-weight: $msv-font-weight-bold;
        }
    }
    .msc-empty_image {
        @include image-placeholder(108px);
    }
    &__info-message {
        line-height: 1.3em;
        background-color: #f7f8f9;
        padding: 15px;
        border: 1px solid #e6e9eb;
        border-radius: 12px;
        margin-bottom: 1rem;
        &-body {
            text-align: justify;
            font-size: 16px;
            font-weight: 600;
        }
    }
    @media screen and (max-width: $msv-breakpoint-m) {
        margin-right: 0;
        margin-left: 0;

        &__guided-card {
            &-body {
                padding-left: 0;
                padding-right: 0;
            }
            &-btn-save {
                width: 100%;
            }
            &-btn-cancel {
                width: 100%;
            }
        }
        &__body {
            width: 100%;
        }
        &__side-control-first {
            display: none;
        }
        &__side-control-second {
            display: block;

            .ms-checkout__btn-place-order {
                width: 100%;
            }
            .ms-checkout__btn-place-order {
                margin-top: 10pxp;
                margin-left: 0;
            }
            .ms-checkout__btn-keep-shopping {
                margin-top: 1.25rem;
            }
        }
    }
    .alert--uk {
        color: $red;
        font-weight: $msv-font-weight-bold;
    }
}
