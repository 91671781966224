.unsubscribe-newsletter-block_ctn {
    color: $primary;
    padding-top: $section-v-space--xl;
    padding-bottom: $section-v-space--xl;

    .msc-alert {
        display: inline-block;
        margin-top: $spacer;
        max-width: 80%;

        @include media-breakpoint-up(xl) {
            max-width: 50%;
        }

        &-error {
            color: $msv-error-color;
        }

        &-success {
            color: $msv-success-color;
        }
    }
}

.unsubscribe-newsletter-block {
    text-align: center;

    button {
        @include solid-button($secondary, true);
        transition: all 0.25s;

        &.status {
            &__success {
                @include add-icon-icomoon($check);
                background: $msv-success-color;

                span {
                    display: none;
                }
            }
        }
    }

    [aria-invalid='true'] label:after {
        content: none;
    }

    .email_row,
    .privacy_row {
        display: flex;
        text-align: left;
        justify-content: center;

        &.hidden {
            display: none;
        }

        > div {
            flex: 0 0 80%;
            max-width: 80%;

            @include media-breakpoint-up(xl) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }

    .email_row > div {
        display: flex;
        margin-bottom: $spacer;

        input {
            flex-grow: 1;
            width: 100%;
        }
    }

    .privacy_row input[type='checkbox'] ~ *:nth-child(2) {
        display: flex;
    }

    .privacy_field_ctn p {
        font-size: $msv-font-size-m;
    }
}
