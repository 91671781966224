$max-height-m: 545px;
$max-height-l: 772px;
$carousel-padding-left: 20px;
$thumbnail-height: 100px;
$thumbnail-flipper-height: 30px;
$carousel-tooltip-padding: 4px;
$carousel-indicators-border: 1px;
$carousel-thumb-image-margin: 5px;
:root {
    --msv-media-gallery-bg: #{$msv-black};
    --msv-media-gallery-thumbnail-flipper-bg: var(--msv-bg-color);
    --msv-media-gallery-thumbnail-flipper-border: var(--msv-border-color);
    --msv-media-gallery-thumbnail-flipper-font-color: #{$msv-gray-500};
}
.ms-media-gallery {
    display: inline-flex;

    .ms-media-gallery__thumbnails-container {
        display: none;
        order: 0;
        flex-grow: 0;
    }
    // .ms-media-gallery__thumbnail-item{
    //     border: solid 1px red;
    // }
    .msc-carousel__inner {
        align-items: flex-start !important;
        // border: solid 2px blue;
    }
    .ms-media-gallery__thumbnail-item:not(:last-child) {
        margin-bottom: $carousel-thumb-image-margin;
    }
    .ms-media-gallery__carousel {
        align-items: start;
        order: 1;
        flex-grow: 1;
    }
    .msc-ss-carousel-vert__flipper {
        background: $msv-white;
        border: 1px solid var(--msv-media-gallery-thumbnail-flipper-border);
        border-radius: 0;
        color: var(--msv-media-gallery-thumbnail-flipper-font-color);
        flex: 0 1 $thumbnail-flipper-height;
        height: $thumbnail-flipper-height;
        left: auto;
        position: relative;
        top: auto;
        transform: none;
        width: 100%;

        &.disabled {
            display: none;
        }
    }
    .msc-carousel__control__prev,
    .msc-carousel__control__next {
        background-color: $secondary;
    }
    .msc-ss-carousel-vert__flipper--next {
        bottom: auto;
    }
    .msc-tooltip-inner {
        background-color: $secondary;
        color: $msv-white;
        padding: $carousel-tooltip-padding $carousel-tooltip-padding;
    }
    .msc-carousel__indicators li {
        background-color: $secondary-20;
        border: 0;

        &.active {
            background-color: $secondary;
        }
    }
}
.ms-media-gallery {
    .msc-carousel__item {
        display: flex;
        justify-content: center;
        //border: solid 2px greenyellow;

        // @media (min-width: $msv-breakpoint-m) {
        //     max-height: $max-height-m;
        // }
        // max-height: $max-height-m;
    }
}
@media (min-width: $msv-breakpoint-l) {
    .ms-media-gallery {
        .ms-media-gallery__carousel {
            margin-left: $carousel-padding-left;
        }
        .msc-carousel__indicators {
            display: none;
        }
        .msc-carousel__control__prev {
            display: none;
        }
        .msc-carousel__control__next {
            display: none;
        }
        .ms-media-gallery__thumbnails-container {
            display: flex;
        }
        .ms-media-gallery__thumbnail-item {
            height: $thumbnail-height;
            img {
                height: 100%;
                margin: 0 auto;
            }
        }
    }
}
@media (max-width: $msv-breakpoint-m) {
    .ms-media-gallery {
        padding-right: 0;
    }
}
.ms-containerZoom {
    &__container {
        cursor: zoom-in;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    &__result {
        background-color: $msv-white;
        background-repeat: no-repeat;
        border: 1px solid rgb(148, 148, 148);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 8px 2px;
        overflow: hidden;
        position: fixed;
        right: 10px;
        width: 580px;
        z-index: 1140;

        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }
        @media (max-width: $msv-breakpoint-xl) {
            height: 400px;
            width: 390px;
        }
    }
    &__image {
        border: none;
        left: 0;
        max-height: none;
        max-width: none;
        position: absolute;
        top: 0;
    }
    &__zoom-lens {
        background-color: $msv-white;
        background-position: 0 0;
        border: 1px solid $msv-black;
        cursor: zoom-out;
        float: right;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        height: 180px;
        top: -200px;
        right: -200px;
        width: 200px;
        zoom: 1;

        &__opacity {
            opacity: 0.4;
        }
        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }
    }
}
.ms-inline-zoom {
    cursor: zoom-in;
    display: block;
    float: left;
    overflow: hidden;
    position: relative;

    // overflow-y: auto !important;
    // &::-webkit-scrollbar {
    //     width: 5px;
    // }
    // &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 5px #004f718f;
    //     border-radius: 10px;
    // }
    // &::-webkit-scrollbar-thumb {
    //     background: #004F71ed;
    //     border-radius: 10px;
    // }
    // &::-webkit-scrollbar-thumb:hover {
    //     background: #768b96;
    // }

    &.zoomed {
        cursor: zoom-out;
    }
    &__zoomedImg {
        border: none;
        height: 0;
        max-height: none;
        max-width: none;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;

        @media (max-width: $msv-breakpoint-m) {
            display: none;
            opacity: 0;
        }
    }
}
img.msc-main_image.msc_image.ms-media-gallery__item {
    // margin: 0 auto;
    max-height: $max-height-m;
}
