.lbc {
    .row {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1rem;

        .text-paragraph {
            padding-bottom: 2rem;
        }

        .points {
            display: none;
            padding-top: 1rem;
        }
    }
}
