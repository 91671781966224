.ms-text-block {
    &.alignment__center {
        text-align: center;
    }
    &.alignment__left {
        text-align: left;
    }
    &.alignment__right {
        text-align: right;
    }
}
