$video-player-width: 100%;

// What are these two?
:root {
    --msv-footer-heading-font-color: #{$video-player-width};
}
.msc-videoplayer {
    .msc-videoplayer__wrapper {
        .msc-videoplayer__controls {
            .msc-videoplayer__controls__wrapper {
                .msc-videoplayer__controls__menu {
                    border-radius: 1em;
                    background: transparent;
                    .msc-videoplayer__controls__menu__list li {
                        background: rgba(0, 0, 0, 0.6);
                    }
                }
                .msc-videoplayer__controls__slider__container .slider-horizontal .msc-progress {
                    background-color: var(--msv-accent-brand-color);

                    .msc-progress__bar {
                        background-color: grey;
                    }
                }
                .msc-videoplayer__controls__volumeslider__container {
                    background-color: rgba(0, 0, 0, 0.6);
                    border-radius: 1em;
                }

                .slider {
                    .slider__thumb {
                        background: transparent;
                    }
                    &.slider-horizontal,
                    &.slider-vertical {
                        .slider__track {
                            background-color: white;
                        }
                    }
                }
            }
        }
    }
}
.ms-video-player {
    &.row-layout {
        > .row {
            display: flex;
            flex-wrap: wrap;
            padding-left: 15px;
            padding-right: 15px;

            @media screen and (max-width: 991px) {
                flex-direction: column-reverse;
            }

            @media screen and (min-width: 992px) {
                flex-direction: row;
            }

            .text-side {
                background-color: $light-beige;

                @media screen and (min-width: 992px) {
                    padding: 3rem;
                }

                @media screen and (min-width: 769px) and (max-width: 991px) {
                    padding: 2rem;
                }

                @media screen and (max-width: 768px) {
                    padding: 1.5rem;
                }

                &__title {
                    color: $primary;
                    text-align: left;
                    text-transform: capitalize;
                }

                &__paragraph {
                    margin-bottom: 2rem;
                }
            }

            .video-side {
                background-color: $light-beige;
                padding-left: 0px;
                padding-right: 0px;

                @media screen and (min-width: 992px) {
                    margin-left: 0rem;
                }

                .video-player-container {
                    &.horizontal {
                        overflow: hidden;

                        > .msc-videoplayer__video {
                            width: 100%;
                            height: 100%;
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    // Unused and old
                    &.vertical {
                        position: relative;
                        padding-bottom: 177.78%; // 16:9 aspect ratio
                        height: 0;
                        overflow: hidden;

                        > .msc-videoplayer__video {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
        }
    }

    &.column-layout {
        > .row {
            display: flex;
            flex-wrap: wrap;

            @media screen and (max-width: 991px) {
                flex-direction: column-reverse;
            }

            @media screen and (min-width: 992px) {
                flex-direction: column;
            }

            .text-side {
                background-color: $light-beige;

                @media screen and (min-width: 992px) {
                    padding: 2.5rem;
                }

                @media screen and (max-width: 991px) {
                    padding: 1.5rem;
                }

                .ms-content-block__cta {
                    padding-bottom: 0.5rem;
                }
            }

            .video-side {
                background-color: $light-beige;
                padding-left: 0px;
                padding-right: 0px;

                @media screen and (max-width: 767px) {
                    margin-top: 1rem;
                }

                .video-player-container {
                    // Unused and old
                    &.horizontal {
                        // position: relative;
                        // max-height: 60vh;
                        padding-bottom: 56.25%; // 16:9 aspect ratio
                        height: 0;
                        overflow: hidden;

                        > .msc-videoplayer__video {
                            // max-height: 60vh;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    &.vertical {
                        max-height: 61.8vh; // 100/Golden ratio
                        overflow: hidden;
                        text-align: center;

                        > .msc-videoplayer__video {
                            width: 100%;
                            max-height: 61.8vh;
                            max-width: 100%;
                        }

                        // @media screen and (max-width: 425px) {
                        //     max-height: 70vh;
                        //     overflow: hidden;
                        //     text-align: center;

                        //     >.msc-videoplayer__video {
                        //         max-height: 70vh;
                        //     }
                        // }
                    }
                }
            }
        }
    }
}

.msc-videoplayer .msc-videoplayer__wrapper .msc-videoplayer__controls {
    max-height: 3.5rem !important;
    height: 3.5rem;
    background: linear-gradient(to bottom, transparent, black);
    align-items: flex-end;
    display: flex;

    .msc-videoplayer__controls__wrapper {
        position: relative;
        bottom: 0;
        width: 100%;
    }
}

.msc-videoplayer .msc-videoplayer__wrapper .msc-videoplayer__controls {
    button {
        background: transparent !important;
        color: white !important;
        border: none !important;
    }
}

.vertical .msc-videoplayer .msc-videoplayer__wrapper video.msc-videoplayer__video {
    max-height: 61.8vh;
    background-color: unset;
}
