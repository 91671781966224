.page--checkout {
    margin-top: 65px;

    .msc-express-payment-container {
        .msc-express-payment-buttons-container {
            border: 1px solid rgba(0, 79, 113, 0.5);
            padding-top: 15px;
        }
        .heading.container-heading {
            font-size: 1.5rem;
            color: $blue;
        }
        .ms-checkout-express {
            .ms-checkout-express__add {
                .checkout-express__iframe {
                    border: none;
                    width: 100%;
                    max-width: 250px;
                    height: 60px;
                }
            }
        }
        .ms-checkout-express.ms-checkout-express-paypal {
            .ms-checkout-express__add {
                text-align: right;
                transform: scaleY(1.07);
                margin-top: 2px;
                @media screen and (max-width: map-get($container-max-widths, 'md')) {
                    text-align: center;
                }
            }
        }
        .ms-checkout-express.ms-checkout-express-googlepay {
            .ms-checkout-express__add {
                text-align: left;
                @media screen and (max-width: map-get($container-max-widths, 'md')) {
                    text-align: center;
                }
            }
        }
    }
    .msc-express-payment-spacer {
        .spacer-line {
            height: 1px;
            margin-top: 50px;
            border-top: 1px solid rgba(0, 79, 113, 0.5);
        }
        .spacer-text {
            position: relative;
            max-height: 20px;
            top: -39px;
            div {
                display: flex;
                justify-content: center;
            }
            h5 {
                width: fit-content;
                background-color: $white;
                padding: 0 10px;
                color: $blue;
            }
        }
    }
    .ms-promo-banner {
        background: rgba($primary, 0.1);
        font-size: $msv-font-size-s;
        &__text,
        &__link {
            color: $primary;
            text-transform: uppercase;
            font-weight: $msv-font-weight-light;
        }
    }
    footer {
        padding-top: $spacer;
        padding-bottom: $spacer;
        nav {
            ul {
                padding-top: $spacer;
                padding-bottom: $spacer;
                font-weight: $msv-font-weight-normal;
                li {
                    display: inline-block;
                }
                a {
                    padding: $spacer/2 $layout-gutter;
                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                }
            }
        }
    }
}
