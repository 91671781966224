:root {
    --msv-password-reset-verification-font-size: var(--msv-body-font-size-m);
    --msv-password-reset-verification-heading-font-size: var(--msv-body-font-size-xl);
    --msv-password-reset-verification-heading-font-color: #{$msv-gray-900};
    --msv-password-reset-verification-error-font-color: #{$msv-red};
    --msv-password-reset-verification-error-font-size: var(--msv-body-font-size-m);
    --msv-password-reset-verification-success-alert-bg: #{$msv-gray-300};
    --msv-password-reset-verification-success-alert-font-color: #{$msv-gray-900};
    --msv-password-reset-verification-success-alert-border: #{$msv-gray-900};
    --msv-password-reset-verification-error-alert-bg: var(--msv-error-color);
    --msv-password-reset-verification-error-alert-font-color: var(--msv-font-secondary-color);
    --msv-password-reset-verification-error-alert-border: var(--msv-error-color);
    --msv-password-reset-verification-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-password-reset-verification-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-password-reset-verification-primary-btn-border: var(--msv-accent-brand-color);
    --msv-password-reset-verification-secondary-btn-bg: var(--msv-bg-color);
    --msv-password-reset-verification-secondary-btn-font-color: #{$msv-gray-900};
    --msv-password-reset-verification-secondary-btn-border: var(--msv-accent-brand-color);
}
.ms-password-reset-verification {
    @include font-content(var(--msv-font-weight-normal), var(--msv-password-reset-verification-font-size), $msv-line-height-m);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &__container {
        width: 40%;
    }
    &__heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-password-reset-verification-heading-font-size), $msv-line-height-xl);
        color: var(--msv-password-reset-verification-heading-font-color);
    }
    &__account-item {
        margin-top: 1.25rem;

        &-emailVerificationCode {
            margin-top: 0.25rem;
        }

        &-input {
            @include form-input-el();
            margin-top: 0.25rem;
            width: 100%;
        }
    }

    &__email-verification-buttons {
        margin-top: 12px;
    }
    /*Override inline style inject by AAD so we need important*/
    &__page-success[aria-hidden='false'],
    &__page-error[aria-hidden='false'] {
        display: block !important;
    }
    &__page-success {
        color: var(--msv-password-reset-verification-success-alert-font-color);
        background-color: var(--msv-password-reset-verification-success-alert-bg);
        border: 1px solid var(--msv-password-reset-verification-success-alert-border);
        display: none;
        margin-top: 1.25rem;
        padding: 1.25rem;
    }
    &__page-error {
        color: var(--msv-password-reset-verification-error-alert-font-color);
        background-color: var(--msv-password-reset-verification-error-alert-bg);
        border: 1px solid var(--msv-password-reset-verification-error-alert-border);
        display: none;
        margin-top: 1.25rem;
        padding: 1.25rem;
    }
    &__item-error {
        @include font-content(var(--msv-font-weight-normal), var(--msv-password-reset-verification-error-font-size), $msv-line-height-m);
        color: var(--msv-password-reset-verification-error-font-color);
    }
    &__email-verification-button,
    &__continue-button {
        @include primary-button(
            var(--msv-password-reset-verification-primary-btn-bg),
            var(--msv-password-reset-verification-primary-btn-font-color),
            var(--msv-password-reset-verification-primary-btn-border)
        );
        margin-top: 1.25rem;
        width: 100%;
    }
    &__cancel-button {
        @include secondary-button(
            var(--msv-password-reset-verification-secondary-btn-bg),
            var(--msv-password-reset-verification-secondary-btn-font-color),
            var(--msv-password-reset-verification-secondary-btn-border)
        );
        margin-top: 1.25rem;
        width: 100%;
    }
    &__verify-email-verify,
    &__verify-email-resend,
    &__continue-button:disabled {
        display: none;
    }
    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            width: 80%;
        }
    }
}
