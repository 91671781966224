.region-tile-selector {
    padding-left: 16px;
    padding-right: 16px;

    .row {
        display: flex;
        justify-content: center;
        // {/* TODO CSS style: mouse pointer, darker border color and slight shadow on hover */}
        .region-tile {
            border: 1px solid #b3e7ff;
            cursor: pointer;
            text-align: center;
            background-color: #effaff;
            padding: 1rem;
            margin-bottom: 1.25rem;

            &-img {
                display: inline-block;
                align-items: center;
                padding-bottom: 1rem;
                height: 100px;
                object-fit: cover;
            }

            &-language {
                font-size: 1.5rem;
            }

            &-region {
                font-size: 1.25rem;
            }

            &:hover {
                border: 1px solid $secondary;
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
                transition: 0.3s ease-in-out;
            }

            // More efficient method apparently (https://tobiasahlin.com/blog/how-to-animate-box-shadow/)
            //box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);

            // &::after {
            //     box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
            //     opacity: 0;
            //     transition: opacity 0.5s ease-in-out;
            //     // Find and apply the animation of the homepage's product card on hover
            // }

            // // PROTIP: ":hover" alone applies to ALL elements INSIDE of the parent (it applies to tiles in the row in this case, not to the parent itself)
            // &::after:hover {
            //     border: 1px solid $secondary;
            //     opacity: 1;
            // }
        }
    }
}
