:root {
    --msv-order-history-border: #{$msv-gray-300};
    --msv-order-history-heading-font-color: #{$msv-gray-900};
    --msv-order-history-heading-font-size: var(--msv-body-font-size-xl);
    --msv-order-history-secondary-btn-bg: var(--msv-bg-color);
    --msv-order-history-secondary-btn-font-color: #{$msv-gray-900};
    --msv-order-history-secondary-btn-border: var(--msv-accent-brand-color);
}
.ms-order-history {
    padding-bottom: 1.75rem;

    &__heading {
        display: inline-block;
        margin-bottom: 0.5rem;
    }
    &__header {
        padding-bottom: 1.25rem;
    }
    &__order-count {
        color: $primary;
        display: inline-block;
        font-size: 2rem;
        margin-left: 5px;
    }
    &__sales-order {
        background: $secondary-10;
        margin-bottom: $spacer;
        padding: $spacer-xl;
        position: relative;

        &.order--return {
            background: #eeeae5;
        }
    }
    &__order-information {
        border-bottom: $border-separator;
        margin-bottom: $spacer;

        span {
            display: block;
            margin-bottom: $spacer;

            &.ms-order-history__order-information-channel-reference-id,
            &.ms-order-history__order-information-count {
                display: none;
            }
            &.ms-order-history__order-information-channel-name,
            &.ms-order-history__order-information-created-date {
                font-size: $msv-font-size-l;
                margin-bottom: $spacer/2;
            }
            &.ms-order-history__order-information-sales-id,
            &.ms-order-history__order-information-amount {
                font-size: 2rem;
                margin-bottom: $spacer;
            }
        }
    }
    &__order-information-channel-name {
        font-size: $msv-font-size-l;
        margin-bottom: 0.25rem;
    }
    &__order-information-sales-id {
        font-size: 2rem;
    }
    &__sales-lines {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0.5rem;
    }
    &__sales-line-picture {
        height: 81px;
        margin-right: $layout-gutter;
        margin-bottom: $spacer;
        width: 81px;
    }
    &__btn-order-details {
        @extend %border-button;
    }
    &__order-information-created-date {
        font-size: 1.25rem;
        margin-bottom: $spacer/2;
    }
    &__order-information-amount {
        font-size: 2rem;
    }
    &__groups {
        display: flex;
    }
    &__group {
        &-delivery {
            &-heading {
                font-weight: var(--msv-font-weight-bold);
                margin-right: 3px;
                display: none;
            }
            &-total-items {
                display: none;
            }
            &-processing-label {
                display: none;
            }
        }
    }
    &__tracking-info {
        display: none;
    }
    &__address {
        display: none;
    }
    &__empty-message,
    &__alert {
        display: block;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }
    @media screen and (max-width: $msv-breakpoint-m) {
        &__btn-order-details {
            position: unset;
            width: 100%;
        }
        &__btn-keep-shopping {
            width: 100%;
        }
    }
    .msc-empty_image {
        @include image-placeholder(81px);
    }
    &__btn-more {
        @include primary-button();
        margin-top: 1.25rem;

        &.is-busy {
            cursor: progress;

            &:before {
                @include msv-icon();
                content: $msv-Spinner;
                margin-right: 0.5rem;
                -webkit-animation: spin 1s steps(8) infinite;
                animation: spin 1s steps(8) infinite;
            }
        }
    }
}
