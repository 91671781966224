:root {
    --msv-loyalty-terms-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-loyalty-terms-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-loyalty-terms-primary-btn-border: var(--msv-accent-brand-color);
    --msv-loyalty-terms-secondary-btn-bg: var(--msv-bg-color);
    --msv-loyalty-terms-secondary-btn-font-color: #{$msv-gray-900};
    --msv-loyalty-terms-secondary-btn-border: var(--msv-accent-brand-color);
}
.ms-loyalty-terms {
    background-color: $light-secondary;

    &__checkbox {
        @include add-icon($msv-Checkbox);
        height: 14px;
        margin-right: 8px;
        position: relative;
        vertical-align: middle;
        -webkit-appearance: none;
        width: 14px;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &:checked {
            @include add-icon($msv-Checkbox-Checked);
        }
    }
    &__agree {
        display: inline-block;
        vertical-align: middle;
    }
    &__toggle {
        // @include primary-button(var(--msv-loyalty-terms-primary-btn-bg), var(--msv-loyalty-terms-primary-btn-font-color), var(--msv-loyalty-terms-primary-btn-border));
        background-color: $light-secondary;
        color: black;
        border: 1px solid $secondary;
        padding: 1rem 16px 1rem 16px;
        font-family: 'DIN', sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;

        &:hover {
            background-color: $secondary;
            color: white;
        }
    }
    &__submit {
        // @include primary-button(var(--msv-loyalty-terms-primary-btn-bg), var(--msv-loyalty-terms-primary-btn-font-color), var(--msv-loyalty-terms-primary-btn-border));
        margin-right: 8px;

        background-color: $light-secondary;
        color: black;
        border: 1px solid $secondary;
        padding: 1rem 16px 1rem 16px;
        font-family: 'DIN', sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;

        &:hover {
            background-color: $secondary;
            color: white;
        }
    }
    &__cancel {
        // @include secondary-button(var(--msv-loyalty-terms-secondary-btn-bg), var(--msv-loyalty-terms-secondary-btn-font-color), var(--msv-loyalty-terms-secondary-btn-border));
        background-color: $light-secondary;
        color: black;
        border: 1px solid $light-secondary;
        padding: 1rem 16px 1rem 16px;
        font-family: 'DIN', sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;

        &:hover {
            border: 1px solid $secondary;
        }
    }

    .with-images,
    .without-images {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .marketing-text {
            margin-bottom: 0rem;
            margin-right: 2rem;

            @media screen and (min-width: 993px) {
                font-size: 1.75rem;
            }

            @media screen and (min-width: 889px) and (max-width: 992px) {
                font-size: 1.5rem;
            }

            @media screen and (max-width: 888px) {
                font-size: 1.25rem;
            }
        }

        @media screen and (max-width: 888px) {
            padding: 1rem;
            flex-direction: column;
        }
    }

    .terms-card-layout {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 62.5%;
            padding-bottom: 1rem;
        }
    }

    .row {
        @media screen and (max-width: 666px) {
            margin-right: 15px;
        }

        .second-image {
            @media screen and (max-width: 666px) {
                display: none;
            }
        }
    }

    &__modal {
        &-header {
            margin-bottom: 0px;
            min-height: 2rem;

            .msc-modal {
                &__close-button {
                    padding-top: 0rem;
                }
            }
        }
    }
}

.loyalty-join {
    padding-left: 2rem;
    padding-right: 2rem;

    @media screen and (max-width: $msv-breakpoint-m) {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &-header {
        padding-bottom: 2rem;

        @media screen and (max-width: $msv-breakpoint-m) {
            padding-bottom: 1rem;
        }
    }

    &-paragraph {
        padding-bottom: 1rem;

        > p {
            font-size: 1.5rem;

            @media screen and (max-width: $msv-breakpoint-m) {
                font-size: 1rem;
            }
        }
    }

    &-balance-check-link {
        padding-bottom: 2.5rem;
        font-size: 1.5rem;

        @media screen and (max-width: $msv-breakpoint-m) {
            font-size: 1rem;
        }
    }
}
