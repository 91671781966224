:root {
    --msv-write-review-font-size: var(--msv-body-font-size-m);
    --msv-write-review-heading-font-size: var(--msv-body-font-size-xl);
    --msv-write-review-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-write-review-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-write-review-primary-btn-border: var(--msv-accent-brand-color);
    --msv-write-review-secondary-btn-bg: var(--msv-bg-color);
    --msv-write-review-secondary-btn-font-color: #{$msv-gray-900};
    --msv-write-review-secondary-btn-border: var(--msv-accent-brand-color);
}
.ms-write-review {
    margin-bottom: $spacer-l;
    padding-left: $layout-gutter;
    padding-right: $layout-gutter;

    .msc-modal & {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .msc-btn {
        @include solid-button($primary, true);
        padding: 0.5rem 1rem;

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }

    .ms-write-review__sign-in-btn {
        visibility: hidden;
        border: none;
        background-color: transparent;
        color: $blue;
        text-decoration: underline;
        text-underline-offset: 4px;
        display: inline-flex;
        &:hover {
            background-color: transparent;
            color: $blue;
            text-decoration: underline;
        }
        &.msc-btn:before {
            content: url('../../../images/pen.png');
            padding-right: 10px;
            vertical-align: top;
        }
    }
    .ms-write-review__toggle {
        border: none;
        background-color: transparent;
        color: $blue;
        text-decoration: underline;
        text-underline-offset: 4px;
        &:hover {
            background-color: transparent;
            color: $blue;
            text-decoration: underline;
        }
        &.msc-btn:before {
            content: url('../../../images/pen.png');
            padding-right: 10px;
            vertical-align: top;
        }
        &.msc-btn:focus {
            background-color: transparent;
            color: $blue;
            text-decoration: underline;
        }
    }
    &.msc-modal-input-required {
        & .ms-write-review .msc-btn:hover,
        .ms-write-review .msc-btn:focus {
            background-color: transparent;
            color: $blue;
            text-decoration: underline;
        }
    }
}
.ms-review-modal {
    &-submit {
        @include solid-button($primary, true);
        margin-right: $layout-gutter;
    }
    &-cancel {
        @include border-button($primary, true);
    }
    &-submit,
    &-cancel {
        @media (max-width: $msv-breakpoint-m) {
            margin-right: 0;
            margin-bottom: 0.75rem;
            margin-left: 0;
            width: 100%;
        }
    }
    &-rating-label {
        color: $primary;
        font-size: $msv-font-size-m;
        font-weight: $msv-font-weight-bold;
    }
    &-text,
    &-title {
        font-size: $msv-font-size-l;
        width: 100%;
    }
    &-text {
        height: 162px;
    }
    &-text-label,
    &-title-label {
        display: block;
        font-size: $msv-font-size-l;
        margin-bottom: 0.25rem;
        text-transform: uppercase;
    }
    &-policy {
        font-size: $msv-font-size-l;

        a {
            color: $primary;
        }
    }
    &-row {
        margin-bottom: $spacer-m;

        .msc-rating {
            display: inline-block;
            margin-right: 0.5rem;
        }
    }
    &-error {
        margin-top: 1.25rem;
        margin-bottom: 0;
    }
    .msc-link {
        @include vfi();
        font-weight: $msv-font-weight-normal;
        text-decoration: underline;
    }
}
