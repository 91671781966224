nav button[aria-expanded='false'] + ul {
    display: none;
}

@import '../00-settings/colors.scss';
@import '../00-settings/font.scss';
@import '../00-settings/responsive-breakpoints.scss';
@import '../00-settings/icons.scss';
@import '../01-tools/mixins.scss';
@import '../02-generic/image.scss';

$navigation-parent-menu-margin-left: 24px;
$navigation-parent-menu-margin-right: 24px;
$navigation-parent-menu-margin-top: 20px;
$navigation-menu-list-min-width: 250px;
$navigation-sub-menu-padding-left: 20px;
$navigation-sub-menu-padding-right: 20px;
$navigation-menu-active-item-font-weight: 600;
$navigation-menu-item-height: 48px;
$navigation-menu-item-line-height: 45px;
$navigation-menu-button-icon-margin-left: 8px;
$navigation-menu-icon-line-height: 40px;
$navigation-menu-item-underline: 6px;
$navigation-menu-wrapper-height: auto;
$navigation-menu-wrapper-width: 100%;
$navigation-menu-wrapper-left: 0;
$navigation-menu-image-height: 400px;
$navigation-menu-max-image-width: 370px;
$navigation-menu-max-image-height: 330px;
$navigation-menu-min-image-height: 300px;
$navigation-menu-image-width: 50%;
$navigation-menu-image-padding: 40px;
$navigation-menu-image-bottom: 14px;
$navigation-ms-nav-list-width: 100%;
$navigation-ms-nav-submenu-margin-left: 15px;
$navigation-ms-nav-area-width: 63%;
$navigation-ms-category-img-padding: 20px;
$navigation-menu-list-min-width: 180px;
$navigation-ms-category-width: 37%;
$navigation-ms-category-feature-img-title-width: 280px;
$navigation-ms-category-feature-img-title-line-height: 19px;
$navigation-ms-category-feature-img-title-line-padding-top: 5px;
$navigation-menu-placeholder-image-width: 283px;
$navigation-menu-placeholder-image-height: 330px;

.layout2022 {
    .ms-header {
        //from venchi old

        // nav {
        //     &:focus {
        //         outline: none;
        //     }

        //     button {
        //         appearance: none;
        //         background: none;
        //         border: 0;
        //         outline: none;
        //         padding: 0;

        //         &:focus {
        //             appearance: none;
        //             border: 0;
        //             outline: none;
        //         }
        //     }

        //     ul:focus,
        //     ul:focus-within {
        //         appearance: none;
        //         border: 0;
        //         outline: none;
        //     }
        // }

        // a {
        //     display: inline-block;
        // }

        // &--main-nav {
        //     background: $white;
        //     position: relative;
        //     transition-duration: .25s !important;
        //     width: 100%;
        // }

        //from fabrikam

        //     .ms-header__collapsible-hamburger{

        //     .ms-nav {
        //         background: var(--msv-nav-bg);
        //         border: 1px solid var(--msv-nav-border);
        //         color: var(--msv-nav-font-color);
        //         width: 100%;

        //         &__list {
        //             z-index: 1000;

        //             &.level-2 {
        //                 width: 100%;
        //             }

        //             .level-2 {
        //                 > li {
        //                     font-weight: $navigation-menu-active-item-font-weight;
        //                 }

        //                 &.navmenu-multi-level {
        //                     li.ms-nav__list__item {
        //                         .havesubmenu {
        //                             font-weight: normal;
        //                         }
        //                     }
        //                 }
        //             }

        //             &__item {
        //                 font-size: var(--msv-nav-font-size);
        //                 font-style: normal;
        //                 line-height: $navigation-menu-item-line-height;
        //                 white-space: nowrap;

        //                 /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        //                 .havesubmenu {
        //                     font-weight: $navigation-menu-active-item-font-weight;
        //                 }

        //                 .level-3 {
        //                     /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        //                     .havesubmenu {
        //                         .ms-nav__list {
        //                             margin-left: $navigation-ms-nav-submenu-margin-left;
        //                         }
        //                     }
        //                 }

        //                 &__image {
        //                     position: absolute;
        //                     right: 0;
        //                 }
        //             }
        //         }

        //         /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        //         >.ms-nav__list {
        //             background: var(--msv-nav-bg);

        //             >.ms-nav__list__item {
        //                 background: var(--msv-nav-bg);

        //                 >.ms-nav__list {
        //                     background: var(--msv-nav-bg);
        //                     min-width: $navigation-menu-list-min-width;
        //                 }

        //                 .ms-nav__deafult {
        //                     >.ms-nav__list {
        //                         border: 1px solid var(--msv-nav-dropdown-border);
        //                     }
        //                 }

        //                 >.ms-nav__feature {
        //                     background: var(--msv-nav-bg);
        //                     border: 1px solid var(--msv-nav-dropdown-border);
        //                     height: $navigation-menu-wrapper-height;
        //                     left: $navigation-menu-wrapper-left;
        //                     min-width: $navigation-menu-list-min-width;
        //                     position: absolute;
        //                     outline: none;
        //                     overflow: hidden;
        //                     width: $navigation-menu-wrapper-width;

        //                     >.category-image-container {
        //                         display: inline-block;

        //                         >.category-image {
        //                             display: table;
        //                             padding: $navigation-ms-category-img-padding;

        //                             .category-image-title {
        //                                 text-align: center;
        //                                 display: none;
        //                             }

        //                             picture {
        //                                 + div.category-image-title {
        //                                     display: block;
        //                                 }

        //                                 & ~ a.ms-nav__list__item__link,
        //                                 & ~ span.ms-nav__list__item__span {
        //                                     text-align: center;
        //                                     text-decoration: underline;
        //                                     width: $navigation-ms-category-feature-img-title-width;
        //                                     word-break: break-all;
        //                                     white-space: break-spaces;
        //                                     line-height: $navigation-ms-category-feature-img-title-line-height;
        //                                     padding-top: $navigation-ms-category-feature-img-title-line-padding-top;
        //                                     height: auto;
        //                                 }
        //                             }

        //                             img {
        //                                 min-height: $navigation-menu-min-image-height;
        //                                 max-height: $navigation-menu-max-image-height;
        //                                 max-width: $navigation-menu-max-image-width;

        //                                 >.ms-nav-image__item {
        //                                     display: block;
        //                                     padding: $navigation-menu-image-padding;
        //                                 }
        //                             }

        //                             @include image($navigation-menu-placeholder-image-width, $navigation-menu-placeholder-image-height);
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }

        //     @media screen and (min-width: $msv-breakpoint-xs) {
        //         .ms-nav {
        //             &__list {
        //                 display: block;
        //                 flex-wrap: nowrap;

        //                 &__mobile__container {
        //                     border-bottom: 1px solid var(--msv-nav-dropdown-border);
        //                     padding-bottom: 13px;
        //                     display: flex;

        //                     &__button {
        //                         @include vfi();
        //                         @include add-icon($msv-ChevronLeft);
        //                         background-color: var(--msv-nav-bg);
        //                         border: none;
        //                         color: var(--msv-nav-font-color);
        //                         font-family: var(--msv-nav-font-family);
        //                         padding-left: 0;
        //                         padding-right: 0;

        //                         &:focus {
        //                             outline-offset: -1px;
        //                         }
        //                     }

        //                     &__span {
        //                         margin-left: 21px;
        //                         font-weight: 600;
        //                         color: var(--msv-nav-font-color);
        //                         font-family: var(--msv-nav-font-family);
        //                         font-size: var(--msv-nav-font-size);
        //                     }
        //                 }

        //                 &__item {
        //                     &__image {
        //                         display: none;
        //                     }

        //                     &__button {
        //                         color: var(--msv-nav-font-color);
        //                         padding-left: 0;
        //                         padding-right: 0;
        //                         text-align: left;
        //                         width: 100%;

        //                         &::after {
        //                             @include msv-icon();
        //                             content: $msv-ChevronRight;
        //                             display: inline-flex;
        //                             float: right;
        //                             line-height: $navigation-menu-item-line-height;
        //                             text-align: right;
        //                         }
        //                     }

        //                     &__button,
        //                     &__link,
        //                     &__span {
        //                         background-color: var(--msv-nav-bg);
        //                         border: none;
        //                         color: var(--msv-nav-font-color);
        //                         display: block;
        //                         font-family: var(--msv-nav-font-family);
        //                         height: $navigation-menu-item-height;
        //                         line-height: $navigation-menu-item-line-height;

        //                         @include vfi();

        //                         &:focus {
        //                             outline-offset: -1px;
        //                         }
        //                     }
        //                 }
        //             }

        //             >.ms-nav__list {
        //                 outline: none;
        //                 width: 100%;

        //                 .ms-nav__list {
        //                     height: 100vh;
        //                 }

        //                 .havecateImage {
        //                     float: left;
        //                     position: relative;
        //                     max-width: $navigation-ms-nav-area-width;
        //                 }

        //                 >.ms-nav__list__item {
        //                     >.ms-nav__list__item__button {
        //                         color: var(--msv-nav-font-color);
        //                     }
        //                 }
        //             }
        //         }

        //         .ms-nav.child {
        //             position: absolute;
        //             top: 0;
        //         }
        //     }
        // }

        .ms-header__desktop-view {
            .ms-nav {
                position: relative;
                transition-duration: 0.25s !important;
                color: $menu-main--color;
                display: flex;
                font-size: $msv-font-size-l;

                &:focus {
                    outline: none;
                }

                button {
                    -webkit-appearance: none;
                    appearance: none;
                    background: none;
                    border: 0;
                    outline: none;

                    &:focus {
                        -webkit-appearance: none;
                        appearance: none;
                        border: 0;
                        outline: none;
                    }
                }

                ul:focus,
                ul:focus-within {
                    -webkit-appearance: none;
                    appearance: none;
                    border: 0;
                    outline: none;
                }

                &__list {
                    display: flex;
                    flex-wrap: wrap;
                    outline: none;
                    position: relative;
                    justify-content: center;

                    a,
                    button {
                        display: block;
                        color: $menu-main--color;
                        font-family: $primary-font-family;
                        font-size: $msv-font-size-l;
                        font-weight: $msv-font-weight-normal;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }

            > nav {
                > ul {
                    align-items: center;
                    width: 100%;

                    > li {
                        > a {
                            @include underline-link-animation-expanded($orange);
                            cursor: pointer;
                            padding: 16px;

                            &:hover,
                            &:focus {
                                font-weight: $msv-font-weight-bold;
                            }
                        }

                        > button {
                            @include underline-link-animation-expanded($orange);
                            @include add-icon-icomoon($chevron, after);
                            cursor: pointer;
                            padding: 16px;

                            &:after {
                                display: inline-block;
                                font-size: 0.6em;
                                margin-left: 0.5rem;
                            }

                            &:not([aria-expanded='true']) {
                                &:hover,
                                &:focus {
                                    font-weight: $msv-font-weight-bold;

                                    &:after {
                                        //@extend %move-down-animation-once;
                                        transition-property: transform;
                                        transition-duration: 0.3s;
                                        transform: translateY(0.3em);
                                    }
                                }
                            }

                            &[aria-expanded='true'] {
                                font-weight: $msv-font-weight-bold;

                                &:after {
                                    transform: rotate(180deg);
                                    transition-property: transform;
                                    transition-duration: 0.3s;
                                }
                            }

                            + div {
                                position: absolute;
                                left: 0;
                                background: #fff;
                                box-shadow: 0px 0px 6px #00000029;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                z-index: 1010;
                                flex-wrap: wrap;

                                > ul {
                                    margin: auto;
                                    width: auto;
                                    display: flex;
                                    align-items: baseline;
                                    flex-wrap: wrap;
                                    padding: 1rem 0;
                                    justify-content: start;

                                    > .ms-nav__list__item {
                                        margin: 0 1.5rem;
                                        padding-bottom: 0.4rem;

                                        > .ms-nav__list__item__span {
                                            color: $menu-main--color;
                                            font-family: $primary-font-family;
                                            font-size: $msv-font-size-l;
                                            font-weight: $msv-font-weight-bold;
                                            display: inline-block;
                                            padding-bottom: 0.8rem;
                                        }

                                        .ms-nav__list__item__link {
                                            @include underline-link-animation($orange);
                                            color: $menu-main--color;
                                            font-family: $primary-font-family;
                                            font-size: $msv-font-size-l;
                                            font-weight: $msv-font-weight-normal;
                                            line-height: 1.75rem;
                                        }

                                        > div > ul > li {
                                            padding-bottom: 0.25rem;

                                            .ms-nav__list__item__link {
                                                @include underline-link-animation($orange);
                                                color: $menu-main--color;
                                                font-family: $primary-font-family;
                                                font-size: $msv-font-size-l;
                                                font-weight: $msv-font-weight-normal;
                                                line-height: 1.75rem;
                                            }
                                        }
                                    }

                                    .ms-nav__list {
                                        display: block;
                                        margin-bottom: 1rem;
                                    }
                                }

                                .bottomlink-menu-section {
                                    .ms-nav__list__item__link {
                                        @include underline-link-animation($orange);
                                        @include add-icon-icomoon(
                                            $chevron-b,
                                            $selector: after,
                                            $next-to-text: true,
                                            $margin: $layout-gutter/2,
                                            $scale: false
                                        );
                                        color: $menu-main--color;
                                        font-family: $primary-font-family;
                                        font-size: $msv-font-size-l;
                                        font-weight: $msv-font-weight-bold;
                                        line-height: 2rem;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .bottomlink-menu-section {
        padding-top: 0.25rem;
        padding-right: 1.125em;
        padding-bottom: 1.25em;
        padding-left: 1.125em;
    }
}
