.ms-checkout {
    &-billing-address {
        &__heading {
            color: $secondary;
            font-weight: $msv-font-weight-bold;
            margin-bottom: 0;
        }
        &__shipping-address-label {
            display: flex;
            margin-bottom: 12px;
        }
        &__shipping-address-checkbox-text {
            margin-left: $layout-gutter;
            align-self: center;
        }
        .msc-address-detail {
            margin-left: 0;
        }
    }
    &-shipping-address {
        .msc-address-select__button-add {
            @extend %solid-button;
            @include add-icon-icomoon($more, after, $layout-gutter);
            align-self: end;
            order: 100;

            &:after {
                transform: none;
            }
        }
        .msc-address-detail {
            font-size: 1.25rem;

            &__item-name {
                font-weight: $msv-font-weight-normal;
            }
        }
    }
}
.updating .ms-checkout-billing-address {
    &__heading {
        color: $secondary;
        margin-bottom: $spacer;
    }
    &__shipping-address-checkbox-text {
        color: $secondary;
        font-weight: $msv-font-weight-normal;
    }
}
