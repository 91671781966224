.ms-cart-icon__flyout-container {
    background: var(--msv-bg-color);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
    border: 1px solid #d1d1d1;
    display: block;
    margin: 0;
    margin-top: -5px;
    opacity: 0;
    position: absolute;
    width: 400px;
    z-index: 109060;

    @include media-breakpoint-down(md) {
        display: none !important;
    }
    &.show {
        opacity: 1;
        padding: 20px;
    }

    .msc-flyout__close-button {
        @include add-icon-icomoon($close);
        @include vfi();
        background-color: transparent;
        border: 0;
        color: $secondary;
        font-size: 20px;
        position: absolute;
        right: 20px;
        cursor: pointer;
    }
    .msc-flyout-inner {
        .ms-cart-icon__subtotal {
            border-top: $border-separator;
            color: $secondary;
            font-size: 1.25rem;
            font-weight: $msv-font-weight-bold;
            margin: $spacer 0;
            padding: $spacer;
            text-align: right;
        }
        .ms-cart-icon__btn-gotocart {
            @include solid-button($primary, true);
            text-align: center;
        }
        .ms-cart-icon__btn-checkout {
            // @include solid-button($primary,true);
            // margin-bottom: $spacer;
            // text-align: center;
            display: none;
        }
    }
    .msc-cart-line__product-image {
        width: 120px;
    }
    .msc-empty_image {
        @include image-placeholder(80px);
    }
    .ms-cart-icon__btn-checkout,
    .ms-cart-icon__btn-gotocart {
        width: 100%;
    }
    .msc-cart-line__product-savings-actual {
        min-height: 0;
        margin-bottom: 0;
    }
    .msc-cart-line__product-savings-actual .msc-price__actual {
        font-size: 1.25rem;
    }
    .msc-cart-line__product-savings-label,
    .msc-cart-line__product-savings-text,
    .msc-cart-line-item-product-discount {
        display: none;
    }
    .msc-cart-line__remove-item {
        margin-left: 119px;
    }
    .msc-cart-line__bopis-method {
        margin-left: 123px;
    }
    .msc-cart-line__remove-item,
    .msc-cart-line__bopis-method {
        cursor: pointer;
        height: auto;
        padding: 0.6rem 0;

        &:hover,
        &:focus {
            padding: 0.6rem 0;
        }
    }
    .msc-cart-line__content {
        display: block;
    }
    .msc-cart-line__product-savings {
        text-align: left;
        margin: 0;
    }
    .msc-cart-line__product-savings,
    .msc-cart-line__quantity,
    .msc-cart-line__product-variants {
        padding: 0 $layout-gutter;
    }
    .msc-cart-line__quantity {
        margin-bottom: 0.5rem;

        .quantity-label {
            font-size: $msv-font-size-m;
        }
    }
    .msc-cart-line__product-variants > .msc-cart-line__product-variant-item {
        display: inline;

        &::after {
            content: ',';
        }
        &:last-child::after {
            content: '';
        }
    }
    .msc-cart-line__product-variant-size,
    .msc-cart-line__product-variant-style,
    .msc-cart-line__product-configuration,
    .msc-cart-line__product-variant-color {
        font-size: 0;

        .name {
            font-size: var(--msv-body-font-size-m);
            line-height: $msv-line-height-m;
        }
    }
    .msc-cart-line__product-title {
        @include a-reverse;
        color: $msv-black;
        font-size: $msv-font-size-l;
        font-weight: $msv-font-weight-light;
        margin-bottom: 0.5rem;
        min-height: 0;
    }
    .ms-cart-icon__flyout-title {
        @extend %h3;
        color: $primary;
        margin-bottom: 0.5rem;
    }
    .ms-cart-icon__cart-lines {
        margin-right: -15px;
        max-height: 30vh;
        overflow: auto;
        padding-right: 15px;

        .msc-cart-lines-item {
            min-height: 0;
            padding: $spacer 0 $spacer/2 0;

            &:last-child {
                border: none;
                padding-bottom: 0;
            }
        }
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-button {
            width: 4px;
        }
        &::-webkit-scrollbar-button {
            height: 4px;
        }
        &::-webkit-scrollbar-track {
            background: $secondary-10;
            border: thin solid $secondary;
            border-radius: 0;
        }
        &::-webkit-scrollbar-thumb {
            background: $secondary-80;
            border: thin solid $secondary-20;
            border-radius: 0;
        }
        &::-webkit-scrollbar-thumb:hover,
        &::-webkit-scrollbar-thumb:focus {
            background: $secondary;
        }
    }
}
