// Form elements

@mixin error-message() {
    display: block;
    color: var(--msv-error-color);
}

@mixin form-control() {
    display: block;
    width: 100%;
    color: #495057;
    background-color: $msv-white;
    border: 1px solid #6b727a;
    border-radius: 0;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: $msv-font-size-l;
    line-height: $msv-line-height-l;
    text-transform: none;
    height: 48px;
    padding: 4px 8px;
    background-clip: border-box;
    border-color: transparent;
    position: relative;
    outline-offset: 1px;
}

@mixin form-input-label() {
    @include font-content-l();
    display: block;
    margin-bottom: 4px;
}

@mixin form-input-checkbox($color: $secondary) {
    vertical-align: middle;
    padding: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    width: 1.6em;
    height: 1.6em;
    text-align: center;
    ~ *:nth-child(2) {
        margin-left: 0;
        display: flex;
        @include add-icon-icomoon($check);
        &:before {
            width: 1.4em;
            height: 1.4em;
            min-width: 1.4em;
            line-height: 1.4em;
            background: $white;
            color: transparent;
            border: 1px solid $color;
            display: inline-block;
            margin-right: $layout-gutter;
            text-align: center;
        }
    }
    &:checked ~ *:nth-child(2) {
        &:before {
            color: $white;
            background: $color;
        }
    }
}

@mixin form-input-radio {
    @include vfi();
    vertical-align: middle;
    padding: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    z-index: 10;
    cursor: pointer;
    width: 1.6em;
    height: 1.6em;
    ~ *:nth-child(2) {
        margin-left: 0;
        &:before {
            content: '';
            width: 1.2em;
            height: 1.2em;
            background: $white;
            color: $secondary;
            border: 4px solid $white;
            border-radius: 100%;
            display: inline-block;
            box-shadow: 0 0 0 1px $secondary;
            margin-right: $layout-gutter;
            transform: translateY(0.25em);
        }
    }
    &:checked ~ *:nth-child(2) {
        &:before {
            background: $secondary;
        }
    }
}

@mixin form-input-radio-label {
    @include font-content-m(var(--msv-font-weight-light));
    vertical-align: middle;
    margin-left: 20px;
}

@mixin form-input-el($bg-color: $white, $color: $msv-black, $border: rgba(0, 79, 113, 0.8)) {
    @include vfi();
    height: auto;
    padding: 0.6rem $layout-gutter;
    background-color: $bg-color;
    color: $color;
    border-color: $border;
}

@mixin form-input-el-error() {
    border: 1px dashed var(--msv-error-color);
    border-left: 3px solid var(--msv-error-color);
}

@mixin form-input-error-message() {
    @include error-message();
    @include add-icon($msv-IncidentTriangle);
    margin-bottom: 4px;
    &:before {
        margin-right: 8px;
    }
}

@mixin form-input-alert-message() {
    @include font-content-s-height-l();
    color: $msv-gray-800;
    margin-bottom: 4px;
}

@mixin alert {
    background-color: $pink;
    border: 1px solid $red;
    max-width: 512px;
    padding: 8px;
}

@mixin alert-title {
    @include error-message();
    margin-bottom: 10px;
    margin-top: 2px;
    &:before {
        @include font-awesome-icon($alert-icon);
        margin-right: 8px;
    }
}
::placeholder {
    color: $msv-black;
    font-weight: $msv-font-weight-light;
}

%form-input-error-message {
    @include error-message();
    @include add-icon-icomoon($error);
    margin-bottom: 4px;
    &:before {
        margin-right: 8px;
    }
}
label {
    font-size: $msv-font-size-l;
    margin-bottom: 4px;
    display: inline-block;
    color: $msv-black;
}

input,
textarea {
    font-family: $primary-font-family;
    border: 1px solid $secondary-80;
    line-height: 1em;
    padding: 0.75rem $layout-gutter;
    outline: none;
    font-size: $msv-font-size-m;
    &:focus {
        border: 1px solid $secondary;
    }
}

[type='button'] {
    background: $secondary;
    padding: 0.5rem 1rem;
}
select {
    font-family: $primary-font-family;
    border: 1px solid $secondary-80;
    padding: 1rem $layout-gutter 0.75rem $layout-gutter;
    background: #fff;
    line-height: 1em;
    &:focus {
        border: 1px solid $secondary;
    }
}
textarea {
    line-height: 1.5em;
}
input[type='checkbox'],
%input-checkbox {
    @include form-input-checkbox();
}
input[type='radio'],
%input-radio {
    @include form-input-radio();
}

%error-message {
    color: $red;
    font-weight: $msv-font-weight-normal;
}

%invalid-input {
    border-color: $red;
}
%invalid-label {
    @include add-icon-icomoon($error, after);
    :before {
        border-color: $red !important;
    }
    &:after {
        color: $red;
        transform: translate(-1rem, 2.5rem);
        float: right;
    }
}

[aria-invalid='true'] {
    label {
        @extend %invalid-label;
    }
    input,
    select,
    textarea {
        @extend %invalid-input;
    }
}

.grecaptcha-badge {
    position: static !important;
    margin-bottom: $spacer-m;
}
