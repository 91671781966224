.pick-mix {
    .ms-search-result-container__Products .list-unstyled {
        //grid-column-gap: 38px;
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        margin: 0;

        @media (max-width: 370px) {
            grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));
        }

        @include media-breakpoint-up(xs) {
            grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
        }

        // @include media-breakpoint-up(md) {
        // 	grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
        // }
        @include media-breakpoint-up(xxl) {
            grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        }
    }

    .category-intro__ctn {
        @include media-breakpoint-down(md) {
            margin-top: $spacer * 2;
        }
    }

    .msc-product__mix-item {
        height: 100%;
        display: flex;
        flex-direction: column;
        // 	height: 100%;

        // 	a {
        // 		padding:0;
        // 	}
        .add-to-mix-cart {
            @include add-icon-icomoon($pm-cart-btn, after, $next-to-text: true, $margin: $layout-gutter);
            width: 100%;
        }

        // 	.msc-price {
        // 		margin-bottom:$spacer;
        // 	}
        .quantity {
            width: 100%;
            white-space: nowrap;
            display: flex;
            justify-content: center;
            overflow: hidden;
        }

        .view_details {
            padding: 0 $layout-gutter;
            cursor: pointer;
            margin-bottom: $spacer/2;

            &:after {
                content: none;
            }
        }
    }

    .quantity .quantity__controls {
        // margin: 6px 0 7px 0;
        margin: 5px 0 5px 0;
        padding: 1px 0 2px 0;
    }

    .pm-cart--ctn {
        border: 4px solid $secondary;
        padding: $spacer-m;

        h3 {
            color: $secondary;
        }

        .add-to-cart {
            @include add-icon-icomoon($cart-btn, after, $next-to-text: true, $margin: $layout-gutter);
            margin-bottom: $spacer;
            width: 100%;
        }

        .remove-from-pmcart {
            @include add-icon-icomoon($trash, after);
            @include text-link($secondary);
            cursor: pointer;
            outline: none;
            position: absolute;
            right: 0;
            bottom: 5px;

            &:after {
                transform: scale(1.4);
            }
        }
    }

    .pm-cart {
        background: $secondary-10;
        padding: $spacer-l $spacer;

        &--top {
            margin-bottom: $spacer;
            text-align: center;

            .reset:disabled,
            .reset[disabled] {
                color: $secondary-80;
            }

            .empty_cart__text {
                border-top: $border-separator;
                color: $secondary;
                margin-top: $spacer;
                padding: $spacer;

                &.success {
                    color: $green;
                    font-size: $msv-font-size-xl;
                }
            }
        }

        &--total {
            display: flex;
            align-items: center;
        }

        &--total-items {
            margin-right: $layout-gutter;
            margin-bottom: $spacer;
            position: relative;
            text-align: center;

            span.icon {
                display: inline-block;

                &:before {
                    font-size: 3.6rem;
                    color: $secondary;
                }
            }

            span.text {
                background: $secondary;
                border-radius: 100%;
                color: $white;
                display: inline-block;
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-bold;
                line-height: 1.6em;
                height: 1.6em;
                overflow: hidden;
                position: absolute;
                bottom: $spacer;
                left: 58%;
                transform: translateX(-50%);
                width: 1.6em;
                z-index: 1;
            }
        }

        &--total-price {
            color: $secondary;
            font-size: $msv-font-size-xl;
            font-weight: $msv-font-weight-bold;
            margin-bottom: $spacer;
        }

        &--product-list {
            max-height: calc(100vh - 33rem);
            overflow: auto;
            padding-right: $layout-gutter * 2;

            @include media-breakpoint-down(md) {
                height: 100vh;
                max-height: calc(100vh - 6rem);
            }

            @media screen and (max-width: 375px) {
                max-height: calc(100vh - 9rem);
            }

            &::-webkit-scrollbar,
            &::-webkit-scrollbar-button {
                width: 4px;
            }

            &::-webkit-scrollbar-button {
                height: 4px;
            }

            &::-webkit-scrollbar-track {
                background: $secondary-10;
                border: thin solid $secondary;
                border-radius: 0;
            }

            &::-webkit-scrollbar-thumb {
                background: $secondary-80;
                border: thin solid $secondary-20;
                border-radius: 0;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: $secondary;
            }
        }

        .pm-product {
            border-top: $border-separator;
            display: flex;
            flex-wrap: nowrap;
            padding: $spacer 0;
            position: relative;
            animation: opacity 0.5s forwards;

            @include media-breakpoint-down(md) {
                &:last-child {
                    border-bottom: $border-separator;
                    margin-bottom: 3.5rem;
                }
            }

            > div div {
                margin-bottom: $spacer/2;
            }

            .image {
                flex: 0 0 4rem;
                margin-right: $layout-gutter;
                width: 4rem;
            }

            .name {
                font-weight: $msv-font-weight-bold;
                font-size: 1.25rem;
            }

            .quantity {
                border: none;
                font-size: $msv-font-size-l;
            }

            .price {
                font-size: $msv-font-size-s;
                font-weight: $msv-font-weight-bold;
            }

            .msc-product__extra-data {
                display: none;
            }
        }
    }

    .mobile_open-cart {
        display: none;
    }

    @include media-breakpoint-down(md) {
        .pm-cart {
            background: $msv-white;
            border: none;
            margin: 0;
            order: -1;
            padding: 0;
            position: -webkit-sticky;
            position: -moz-sticky;
            position: -o-sticky;
            position: -ms-sticky;
            position: sticky;
            top: 3.5rem;

            &--outer {
                background: $msv-white;
                border: none;
                display: flex;
                flex-grow: 1;
                //margin: -2.7rem 0 0 0;
                order: -1;
                padding: 0;
                transform: translateY(-1px);
                z-index: 100;

                .headerIsSticky & {
                    position: sticky;
                    top: 3rem;
                }
            }

            &--ctn {
                border: 0;
                flex-grow: 1;
                margin: 0;
                padding: 0;

                h3 {
                    display: none;
                }

                .pm-cart {
                    padding: 0;
                }
            }

            &--top {
                display: flex;
                margin: 0;
                overflow: hidden;
                box-shadow: rgba(120, 120, 120, 0.05) 0px 3px 2px 0px, rgba(0, 0, 0, 0.06) 0px 2px 1px 0px;

                @media screen and (max-width: 375px) {
                    flex-direction: column;

                    .pm-cart--total {
                        justify-content: center;
                    }
                }

                &[data-show_pm_cart='false'] button.reset {
                    display: none;
                }
            }

            &--total {
                flex: 1;
                padding: 5px $layout-gutter 0 $layout-gutter;
            }

            &--total-price {
                margin: 0;
            }

            &--total-items {
                margin-bottom: 0;

                span.icon:before {
                    font-size: 3rem;
                }

                span.text {
                    top: 50%;
                    transform: translate(-50%, -40%);
                }
            }

            &--product-list {
                display: none;
                margin-left: $layout-gutter;
                padding-bottom: 3rem;

                .pm-product:first-child {
                    border: 0;
                }

                &[data-show_pm_cart='true'] {
                    display: block;
                }
            }

            button {
                &.reset {
                    position: absolute;
                    right: $layout-gutter * 2;
                    bottom: $spacer * 3;
                }

                &.add-to-cart {
                    align-self: flex-end;
                    margin: 0;
                    width: auto;

                    @media screen and (max-width: 375px) {
                        width: 100%;
                    }
                }
            }

            .empty_cart__text {
                clear: both;
                display: none;
                width: 100%;
            }

            .mobile_open-cart {
                height: 2.75em;
                width: 2.75em;
                @include add-icon-icomoon($chevron-b);
                background: none;
                border: 0;
                display: block;
                color: $secondary;
                margin-left: $layout-gutter/2;
                outline: none;
                padding: $spacer $layout-gutter/2;
                transform: rotate(90deg);

                &:hover {
                    cursor: pointer;
                }

                &[data-show_pm_cart='true'] {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .pm-cart {
            button {
                font-size: $msv-font-size-m;

                &.add-to-cart {
                    &:after {
                        content: none;
                    }
                }
            }

            &--total-price {
                font-size: $msv-font-size-m;
            }

            .mobile_open-cart {
                padding: $spacer/2 $layout-gutter/2;
            }

            &--total {
                padding-top: 0;
            }

            &--total-items {
                span.icon {
                    @include add-icon-icomoon($pm-cart-btn);

                    &:before {
                        font-size: 2.5rem;
                    }
                }

                span.text {
                    border: 2px solid $msv-white;
                    line-height: 1.125rem;
                }
            }
        }

        .ms-content-block[data-m-layout='tile'] {
            padding-bottom: 0;
        }
    }
}

.headerIsSticky .pm-cart--ctn {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 10rem;
}

.msc-add-to-pmcart__dialog {
    &__header {
        margin: 0;
    }

    &__product-info__ctn {
        > div {
            margin-bottom: $spacer;
        }

        .specifications_rows {
            display: flex;
            flex-direction: column;
            margin-top: $spacer-xl;

            > div {
                margin-bottom: $spacer-l;

                h4 {
                    text-transform: uppercase;
                }

                .content {
                    font-size: 1.25rem;
                }

                &.Productdescription,
                &.Description {
                    order: 1;
                }

                &.Ingredients {
                    background: $primary-10;
                    color: $primary;
                    order: 2;
                    padding: $spacer;

                    @include media-breakpoint-up(md) {
                        padding: $spacer-xl;
                    }
                }

                &.NutritionalValues,
                &.Nutritionalvalues {
                    order: 3;
                }
            }
        }
    }

    &-title {
        color: $msv-black;
    }

    &-description {
        color: $primary;
        font-size: 1.25rem;
        font-weight: $msv-font-weight-normal;
    }

    &-price {
        font-size: $msv-font-size-xl;
        font-weight: $msv-font-weight-bold;
    }
}

@include media-breakpoint-down(md) {
    .no-scroll-pick-mix .pick-mix .pm-cart--outer {
        position: fixed;
        top: 3rem;
    }
}
