.ms-checkout-guest-profile {
    &__add-contact-info {
        width: 100%;
    }
    &__input {
        display: flex;
        flex-wrap: wrap;

        &.is-invalid {
            label {
                @include add-icon-icomoon($error, after);

                &:after {
                    color: $red;
                    float: right;
                    transform: translate(-1rem, 2.5rem);
                }
            }
            input,
            select {
                border-color: $red;
            }
        }
    }
    &__input-text {
        @include vfi();
        display: block;
        flex-grow: 1;
        font-size: 1.25rem;
    }
    &__input-label {
        @include font-content-l();
        margin-bottom: 0.25rem;
        width: 100%;
    }
    &__input-error {
        color: $red;
        display: block;
        font-weight: $msv-font-weight-bold;
        order: 3;
        width: 100%;
    }
}
