.quantity {
    border: 1px solid $secondary;
    width: fit-content;
    margin-right: 1rem;

    .quantity-input {
        border: none;
        color: $secondary;
        display: inline-block;
        font-size: $msv-font-size-xl;
        outline: none;
        padding: 0;
        text-align: center;
        vertical-align: middle;
        width: 2.6rem;
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
    }
    .quantity__controls {
        background: var(--msv-buybox-btn-bg);
        background-position: center;
        background-repeat: no-repeat;
        border: none;
        color: $secondary;
        cursor: pointer;
        height: 3rem;
        min-width: auto;
        outline: none;
        vertical-align: middle;
        width: 3rem;

        &:disabled {
            border-color: $secondary-40;
            color: $secondary-40;
        }
    }
    .increment {
        @include add-icon-icomoon($more);
    }
    .decrement {
        @include add-icon-icomoon($less);
    }
}
