$msv-adventure-icon-font-family: 'Remix Icon';
$msv-adventure-solid-icon-weight: normal;
$msv-adventure-outline-icon-weight: 400;

//footer icons
$msv-adventure-Mobile: '\f3cd';
$msv-adventure-Envelope: '\f0e0';
$msv-adventure-Comment: '\f086';

$msv-adventure-linkedin: '\f0e1';
$msv-adventure-vimeo: '\f27d';

$msv-adventure-icon-size-m: 20px;
$msv-adventure-icon-size-xs: 24px;
$msv-adventure-icon-size-small: 28px;
$msv-adventure-icon-size-medium: 40px;
$msv-adventure-icon-size-large: 48px;

// Adventure works icons
$msv-adventure-facebook: '\ecbb';
$msv-adventure-facebook-circle: '\ecba';
$msv-adventure-microsoft: '\ef57';
$msv-adventure-instagram: '\ee66';
$msv-adventure-twitter: '\f23b';
$msv-adventure-youtube: '\f2d5';
$msv-adventure-Remove: '\f1af';
$msv-adventure-Add: '\ea13';
$msv-adventure-Shop-Address: '\ef14';
$msv-adventure-list-line: '\eeba';
$msv-adventure-location: '\ef0a';
$msv-adventure-Phone: '\efec';
$msv-adventure-Chat: '\eb51';
$msv-adventure-question-circle: '\f045';
$msv-adventure-signin: '\ea09';
$msv-adventure-shopping-bag: '\f118';
$msv-adventure-GlobalNavButton: '\ef3e';
$msv-adventure-Search: '\f0d1';
$msv-adventure-Cancel: '\eb99';
$msv-adventure-ChevronLeft: '\ea64';
$msv-adventure-ChevronRight: '\ea6e';
$msv-adventure-ChevronDown: '\ea4e';
$msv-adventure-ChevronUp: '\ea78';
$msv-adventure-FavoriteStarFill: '\f186';
$msv-adventure-half-star: '\f188';
$msv-adventure-empty-star: '\f18b';
$msv-adventure-Fullscreen: '\ed9c';
$msv-adventure-information: '\ee59';
$msv-adventure-Heart: '\ee0f';
$msv-adventure-Heart-Fill: '\ee0e';
$msv-adventure-InStore-Pickup: '\f1a5';
$msv-adventure-eye: '\ecb5';
$msv-adventure-file: '\ed0f';
$msv-adventure-Dislike: '\f205';
$msv-adventure-Like: '\f207';
$msv-adventure-Dislike-Solid: '\f204';
$msv-adventure-Like-Solid: '\f206';
$msv-adventure-store: '\f1a5';
$msv-adventure-More: '\ef77';
$msv-adventure-Pause: '\efd5';
$msv-adventure-IncidentTriangle: '\eca1';
$msv-adventure-DeliveryTruck: '\f231';
$msv-adventure-SolidPlay: '\f008';
$msv-adventure-Mute: '\f29d';
$msv-adventure-UnMute: '\f2a1';
$msv-adventure-ExitFullscreen: '\ed9a';
$msv-adventure-ClosedCaptions: '\eb9a';
$msv-adventure-Selected: '\eb7b';
$msv-adventure-Error: '\eca0';
$msv-adventure-Checkbox-Checked: '\eb85';
$msv-adventure-Checkbox-Circle-Checked: '\eb81';
$msv-adventure-Spinner: '\eec7';
$msv-adventure-BackArrow: '\ea60';
$msv-adventure-times-circle: '\eb97';
$msv-adventure-ToggleLeft: '\f219';
$msv-adventure-ToggleRight: '\f218';
$msv-adventure-roadster: '\f090';
$msv-adventure-list-unordered: '\eebe';
$msv-adventure-Checkbox: '\eb7F';
$msv-adventure-RadioBtnOff: '\eB7d';
$msv-adventure-RadioBtnOn: '\f04f';
$equalizer-line: '\ec9d';
$shopping-bag-3: '\f116';
$msv-adventure-time-line: '\f20f';
$msv-adventure-layout-grid-line: '\ee90';
$msv-adventure-grid: '\eddf';
$msv-adventure-add-circle: '\ea11';
$msv-adventure-calendar-event-line: '\eb25';
$msv-adventure-site: '\ec79';
$msv-adventure-webbrowser: '\edcf';

// Adventure works B2B icons
$msv-adventure-Edit: '\efe0';
$msv-adventure-List: '\eeba';
$msv-adventure-Ellipses-V: '\ef76';
$msv-adventure-Arrow-Down: '\f063';
$msv-adventure-Arrow-Up: '\f062';
$msv-adventure-Arrow-Right-In-Circle: '\ea67';
$msv-adventure-envelope: '\eef9';
$msv-adventure-Plus: '\ea12';
$msv-adventure-PlusSquare: '\ea0e';
$msv-adventure-bank-card-line: '\ea92';
$msv-adventure-group-2-line: '\ede1';
$msv-adventure-money-dollar-circle-line: '\ef65';
$msv-adventure-Invoice: '\eac2';
$msv-adventure-bookmark-line: '\eae5';
$msv-adventure-check-circle-fill: '\eB80';
$msv-adventure-order-template-add: '\f00e';
$msv-adventure-arrow-left-circle: '\ea5c';
$msv-adventure-file-history-line: '\ece6';
$msv-adventure-send-plane-line: '\f0d8';
$msv-adventure-swap-box-line: '\f1c9';
$msv-adventure-swap-box-fill: '\f1c8';
$msv-adventure-checkbox-circle: '\eb81';

@font-face {
    font-family: $msv-adventure-icon-font-family;
    font-family: 'Remix Icon';
    font-style: normal;
    font-weight: $msv-adventure-outline-icon-weight;
    font-display: auto;
    src: url('#{$msv-font-path}/remixicon.woff2') format('woff2'), url('#{$msv-font-path}/remixicon.woff') format('woff');
}

@mixin msv-icon-adventure($icon-weight: $msv-adventure-solid-icon-weight) {
    font-family: $msv-adventure-icon-font-family;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: $icon-weight;
    text-rendering: auto;
    line-height: 1;
}

@mixin add-icon-adventure($icon, $selector: before, $icon-weight: $msv-adventure-solid-icon-weight) {
    &:#{$selector} {
        @include msv-icon-adventure($icon-weight);
        content: $icon;
    }
}
