:root {
    --msv-checkout-gift-card-font-size: var(--msv-body-font-size-l);
    --msv-checkout-gift-card-form-bg: var(--msv-bg-color);
    --msv-checkout-gift-card-form-font-color: #{$msv-gray-900};
}
.ms-checkout-gift-card {
    margin-bottom: $spacer-xl;
    max-width: 600px;

    &__form {
        flex-flow: row wrap;
    }
    &__title {
        font-weight: var(--msv-font-weight-bold);
    }
    &__input-label {
        color: $msv-black;
        display: block;
        flex-basis: 100%;
        margin-bottom: 4px;
    }
    &__input-text {
        font-size: $msv-font-size-l;
        width: 100%;
    }
    &__input-fields {
        width: auto;
    }
    &__input-pin-label,
    &__input-exp-label {
        @include font-content(
            $font-weight: var(--msv-font-weight-normal),
            $font-size: var(--msv-checkout-gift-card-font-size),
            $line-height: $msv-line-height-l
        );
        flex-basis: 100%;
    }
    &__input-pin-num-text {
        @include form-input-el($bg-color: var(--msv-checkout-gift-card-form-bg), $color: var(--msv-checkout-gift-card-form-font-color));
        width: 100%;
    }
    &__input-pin-text,
    &__input-exp-text {
        @include form-input-el($bg-color: var(--msv-checkout-gift-card-form-bg), $color: var(--msv-checkout-gift-card-form-font-color));
        display: block;
        margin-top: 0.25rem;
        min-width: 120px;
        width: 100%;

        &::placeholder {
            color: $msv-gray-300;
        }
    }
    &__input-alert-label {
        @include form-input-alert-message();
        display: block;
    }
    &__input-pin-fields,
    &__input-exp-fields {
        display: inline-block;
        margin-top: 20px;
        min-width: 130px;
        width: 25%;
    }
    &__input-pin-fields {
        padding-right: 20px;
    }
    &__btn-apply {
        @extend %solid-button;
        display: inline-block;
        font-size: 1.25rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        vertical-align: bottom;
    }
    &__input-error {
        @include form-input-error-message();
        display: block;
        width: 100%;
    }
    &__item {
        margin-bottom: 0.5rem;
    }
    &__item-text {
        font-weight: var(--msv-font-weight-normal);
        font-size: $msv-text-size;
        line-height: $msv-text-line-height;
        width: 60%;
    }
    &__btn-remove {
        @extend %text-link;
        display: flex;
        align-self: flex-end;
        margin-left: auto;

        &.msc-btn {
            display: inline-block;
            margin-left: 10px;

            @media screen and (max-width: $msv-breakpoint-m) {
                margin-left: 0;
            }
        }
    }
    @media screen and (min-width: $msv-breakpoint-l) {
        &__form {
            display: flex;
        }
        &__input-text {
            flex-grow: 1;
            max-width: 610px;
            width: auto;
        }
        &__input-fields {
            display: inline-block;
            flex-grow: 1;
            width: auto;

            .ms-checkout-gift-card {
                &__input-num-label {
                    display: flex;
                }
                &__input-text {
                    width: 100%;
                }
            }
        }
        &__btn-apply {
            align-self: flex-end;
        }
        &__input-num-fields {
            display: inline-block;
            width: 100%;

            .ms-checkout-gift-card__input-label {
                display: block;
                text-transform: uppercase;
            }
        }
        &__btn-apply {
            width: auto;
        }
    }
}
