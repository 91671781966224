.msc-btn {
    border: 0;
    font-family: $msv-primary-font-family;
    outline: none;
}
.button {
    @include solid-button($primary);
}
button {
    font-size: $msv-font-size-m;
}
%btn,
.btn {
    @include secondary-button();

    &.fill {
        @extend %solid-button;
    }
}
.solid-button,
%solid-button {
    @include solid-button();
}
.border-button,
%border-button {
    @include border-button();
}
.underlined-button,
%underlined-button {
    @include underlined-button();
}
.text-link,
%text-link {
    @include text-link();
}
.hyperlink,
%hyperlink {
    @include hyperlink();
}
.icon-button,
%icon-button {
    @include underlined-button();
}
a {
    @extend %hyperlink;
}
.msc-cta__primary,
.msc-cta____secondary {
    @extend %underlined-button;
}
