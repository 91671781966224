.msc-price {
    font-weight: $msv-font-weight-bold;

    &.discount-value .msc-price__strikethrough {
        display: block;
        margin-right: 0;
    }
    &__strikethrough {
        color: $beige;
        margin-right: 0.6em;
        text-decoration: line-through;
    }
}
