.image-banner {
    $rootSelector: &;

    &.row-layout,
    &.column-layout {
        > .row {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &.row-layout {
        > .row {
            @media screen and (max-width: 991px) {
                flex-direction: column-reverse;
            }

            @media screen and (min-width: 992px) {
                flex-direction: row;
            }

            #{$rootSelector}__text-wrapper {
                background-color: $light-beige;

                @media screen and (min-width: 992px) {
                    padding: 3rem;
                }

                @media screen and (min-width: 769px) and (max-width: 991px) {
                    padding: 2rem;
                }

                @media screen and (max-width: 768px) {
                    padding: 1.5rem;
                }
            }

            #{$rootSelector}__image-wrapper {
                background-color: $light-beige;
                padding-left: 0px;
                padding-right: 0px;

                @media screen and (min-width: 992px) {
                    margin-left: 0rem;
                }
            }
        }

        .image-left {
            #{$rootSelector}__image-wrapper {
                order: 0;

                @media screen and (max-width: 991px) {
                    order: initial;
                }
            }

            #{$rootSelector}__text-wrapper {
                order: 1;

                @media screen and (max-width: 991px) {
                    order: initial;
                }
            }
        }
    }

    &.column-layout {
        > .row {
            @media screen and (max-width: 991px) {
                flex-direction: column-reverse;
            }

            @media screen and (min-width: 992px) {
                flex-direction: column;
            }

            #{$rootSelector}__text-wrapper {
                min-width: 100%;
                max-width: 100%;
                width: 100%;

                background-color: $light-beige;

                @media screen and (min-width: 992px) {
                    padding: 2.5rem;
                }

                @media screen and (max-width: 991px) {
                    padding: 1.5rem;
                }

                .ms-content-block__cta {
                    padding-bottom: 0.5rem;
                }
            }

            #{$rootSelector}__image-wrapper {
                min-width: 100%;
                max-width: 100%;
                width: 100%;

                background-color: $light-beige;
                padding-left: 0px;
                padding-right: 0px;

                @media screen and (max-width: 767px) {
                    margin-top: 1rem;
                }
            }
        }
    }

    &__title {
        color: $primary;
        text-align: left;
        text-transform: capitalize;
    }

    &__paragraph {
        margin-bottom: 2rem;
    }

    &__image-container {
        width: 100%;
        aspect-ratio: 1.777;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
