.shipping_info_ctn {
    background: rgba($secondary, 0.1);
    color: $secondary;
    padding: $spacer-xl;
    font-weight: $msv-font-weight-normal;

    [class^='icon-'],
    [class*=' icon-'] {
        font-size: 2.6rem;
    }
    .ms-text-block {
        margin-top: 2rem;
        @include media-breakpoint-down(sm) {
            margin-top: 3rem;
        }
    }
}
