footer {
    background: $secondary;
    color: $white;
    overflow: hidden;
    padding-top: $spacer;
    padding-bottom: $spacer;
    position: relative;
    z-index: auto;

    hr {
        border: 0;
        border-top: 1px solid $secondary-80;
        margin: $spacer-l 0;
    }
    a {
        color: $white;
        font-weight: $msv-font-weight-normal;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    nav {
        font-weight: $msv-font-weight-normal;
        padding-top: $spacer;
        padding-bottom: $spacer;

        button,
        button.msc-btn {
            background: transparent;
            border: 0;
            color: $white;
            font-family: $primary-font-family;
            font-weight: $msv-font-weight-normal;
            margin-bottom: 10px;
            outline: none;
            padding: 0;
        }
        ul:focus,
        ul:focus-within {
            appearance: none;
            border: 0;
            outline: none;
        }
        li {
            font-size: $msv-font-size-s;

            a {
                padding: 0.2em 0;
                display: inline-block;
            }
        }
        span {
            display: inline-block;
            margin-top: 1rem;
        }
    }
    nav {
        button[aria-expanded='false'] {
            + ul {
                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }
        .collapse:not(.show) {
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }
    a {
        color: $white;
        display: block;
    }
    .ms-footer {
        .ms-back-to-top {
            z-index: 90;
            background: rgba($secondary, 0.5);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            position: fixed;
            right: 1rem;
            bottom: 4.5rem;
            cursor: pointer;
            display: none;
            opacity: 0;
            padding: 0;

            &:hover,
            &:focus {
                background: $secondary-hover;
                outline: none;
            }
            .fa-arrow-up {
                @include add-icon-icomoon($chevron);
                &:before {
                    transform: rotate(180deg);
                }
            }
        }
        .ms-back-to-top[aria-hidden='false'] {
            display: block;
            opacity: 1;
            transform: translateX(0%);
            padding: 0.5rem 0.6rem 0.3rem 0.6rem;
        }
        .ms-text-block {
            margin: 0;
            padding-top: $spacer;
            padding-bottom: $spacer;

            p {
                font-size: $msv-font-size-m;
                font-weight: $msv-font-weight-normal;

                &.copyright {
                    font-size: $msv-font-size-s;
                }
            }
            .social {
                margin-bottom: $spacer;

                a {
                    color: $white;
                    display: inline-block;
                    font-size: $msv-font-size-xl;
                    text-decoration: none;

                    @include media-breakpoint-down(md) {
                        margin-right: 15px;
                    }
                    span {
                        &:after {
                            border: 1px solid $msv-white;
                            border-radius: 100%;
                            display: inline-block;
                            content: '';
                            height: 1.5rem;
                            opacity: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                            transform: scale(1.2);
                            transition: all 0.1s;
                            width: 1.5rem;
                        }
                    }
                    &:hover span:after,
                    &:focus span:after {
                        opacity: 1;
                        transform: scale(1.5);
                    }
                }
            }
            @media screen and (max-width: $msv-container-max-width-m) {
                &.alignment__right {
                    text-align: left;
                }
            }
        }
        @include media-breakpoint-down(md) {
            nav a {
                font-size: $msv-font-size-s;
                padding: 0.3rem 0;
            }
        }
        @include media-breakpoint-down(sm) {
            nav {
                padding-bottom: 0;

                button,
                button.msc-btn {
                    @include add-icon-icomoon($chevron, $selector: after, $next-to-text: true, $margin: $layout-gutter, $scale: false);
                    color: $white;
                    display: block;
                }
                a {
                    display: block;
                    font-size: $msv-font-size-s;
                    padding: 0.5rem 0;
                }
            }
        }
    }
}
