.loyalty-compact-layout .ms-account-loyalty .main-col {
    @media screen and (max-width: $msv-breakpoint-sm) {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
}

.loyalty-wide-layout,
.loyalty-compact-layout {
    .ms-account-loyalty {
        .main-col {
            background-color: $light-secondary;
            padding-top: 0.5rem;
            border: 0.25rem solid white;

            @media screen and (max-width: $msv-breakpoint-sm) {
                h2 {
                    text-transform: none;
                }

                h2,
                p {
                    font-size: 1.125rem;
                }
            }
        }

        &__wallet-div {
            margin-bottom: 0.5rem;

            @media screen and (max-width: 1000px) {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;

                a {
                    margin-bottom: 0.5rem;
                }
            }

            @media screen and (min-width: 1001px) {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;

                a {
                    &:first-child {
                        margin-right: 1rem;
                    }
                }
            }
        }

        &__join-date,
        &__points-exp-reminder {
            font-size: 1rem;
            text-align: center;
        }

        h2 {
            text-align: center;
            margin-bottom: 0.5rem;
            font-size: 1.5rem;

            @media screen and (min-width: $msv-breakpoint-sm) {
                padding-top: 0.5rem;
            }

            &.tier-label {
                margin-bottom: 0.625rem;
            }
        }

        a {
            font-size: 1.125rem;
            text-decoration: none;
            // @include underline-link-animation($secondary);
        }

        .tier-name,
        &__card-number,
        &__total-points {
            font-weight: 400;
            text-align: center;
            margin-bottom: 0.5rem;
        }

        .tier-name {
            font-size: 1.5rem;

            .tier-container {
                padding-right: 1.25rem;
                padding-left: 1.25rem;
                border-radius: 2rem;
            }

            &__Green,
            &__GREEN {
                .tier-container {
                    background-color: #1a5022;
                    color: white;
                }
            }

            &__Pink,
            &__PINK {
                .tier-container {
                    background-color: #e5718d;
                }
            }

            &__Red,
            &__RED {
                .tier-container {
                    background-color: #cb3345;
                    color: white;
                }
            }

            &__Gold,
            &__GOLD {
                .tier-container {
                    background-color: #fda619;
                }
            }

            @media screen and (min-width: $msv-breakpoint-sm) {
                margin-bottom: 1rem;
            }
        }

        &__main {
            border: $border-separator;
            max-width: 480px;
            padding: $layout-gutter;
        }

        .back-to-shopping {
            width: auto;
        }

        &__home-link {
            @extend %hyperlink;
            text-transform: uppercase;
        }

        &__reward-points {
            @include font-content-m();
        }

        &__info-label,
        &__total-points-label {
            font-weight: $msv-font-weight-bold;
        }

        &__card-number {
            font-size: 1.75rem;
            display: inherit;
        }

        &__total-points {
            font-size: 1.75rem;
        }

        &__breakdown {
            background: $secondary-10;
            padding: $spacer;
            font-size: $msv-font-size-s;
            color: $secondary;
            margin-bottom: $spacer;
        }

        &__point-breakdown {
            display: flex;
            justify-content: space-between;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }

        &__available-points-label,
        &__expiring-points-label {
            font-weight: $msv-font-weight-light;
            margin-bottom: 0;
        }

        &__points-trigger {
            @extend %hyperlink;
        }

        &__points-modal-body-top {
            border-bottom: $border-separator;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $spacer;

            p {
                @extend %h3;
            }
        }

        &__points-modal-body-header {
            display: flex;
            justify-content: space-between;
            padding: 0 $layout-gutter;

            p {
                @extend %h5;
            }
        }

        &__points-modal-activity {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
            padding: $spacer $layout-gutter;

            &:nth-child(odd) {
                background: $secondary-10;
            }

            p {
                margin-bottom: 0;
            }
        }

        &__points-modal-type {
            flex-grow: 1;
        }

        &__points-modal-date {
            margin-right: $layout-gutter;
            margin-left: $layout-gutter;
            width: 15rem;
        }

        &__points-modal-points {
            text-align: right;
            width: 6rem;
        }

        &__points-modal .msc-modal__content {
            @media screen and (max-width: 450px) {
                padding: $spacer;
            }
        }
    }
}

.loyalty-card-layout {
    margin-bottom: $spacer-m;
    padding-top: $spacer-l;
    padding-bottom: $spacer-l;

    .loyalty-label {
        font-weight: 400;
        color: #542f08;
    }

    .loyalty-col {
        &__points {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 1.5rem;
        }
    }

    @media screen and (max-width: 1199px) {
        .loyalty-col {
            display: inline-flex;
            flex-direction: row;
            align-items: baseline;
            padding-left: 0px;
            padding-right: 0px;
            justify-content: center;

            &__points {
                padding-top: 0rem;
            }
        }

        .h3-doppelganger {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .loyalty-label {
            font-size: 1.125rem;
        }

        .inline-div {
            display: contents;
        }
    }

    @media screen and (min-width: 1200px) {
        .loyalty-label {
            text-align: center;
            font-size: 1.25rem;
        }

        .h3-doppelganger {
            display: flex;
            flex-direction: row;
            align-items: baseline;
        }

        .loyalty-col {
            .h3-doppelganger,
            &__points {
                align-items: baseline;
                color: black;
            }

            .inline-div {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }

    .tier-name {
        font-weight: 400;
        font-size: 2rem;
        text-align: center;
        margin-bottom: $spacer;

        .tier-container {
            padding-right: 1rem;
            padding-left: 1rem;
            border-radius: 2rem;
        }

        &__Green,
        &__GREEN {
            .tier-container {
                background-color: #1a5022;
                color: white;
            }
        }

        &__Pink,
        &__PINK {
            .tier-container {
                background-color: #e5718d;
            }
        }

        &__Red,
        &__RED {
            .tier-container {
                background-color: #cb3345;
                color: white;
            }
        }

        &__Gold,
        &__GOLD {
            .tier-container {
                background-color: #fda619;
            }
        }

        @media screen and (max-width: 1199px) {
            font-size: 1.125rem;
        }
    }

    .ms-account-loyalty {
        &__main {
            border: $border-separator;
            max-width: 480px;
            padding: $layout-gutter;
            padding-right: 10px;
            padding-left: 10px;
            margin: auto;
        }

        .back-to-shopping {
            width: auto;
        }

        &__home-link {
            @extend %hyperlink;
            text-transform: uppercase;
        }

        &__reward-points {
            @include font-content-m();
        }

        &__info-label {
            font-weight: $msv-font-weight-bold;
        }

        &__card-number {
            font-weight: 400;
            margin-bottom: $spacer;

            @media screen and (max-width: 1199px) {
                text-align: left;
                font-size: 1.125rem;
            }

            @media screen and (min-width: 1200px) {
                text-align: center;
                font-size: 2rem;
            }
        }

        &__total-points-label {
            font-weight: $msv-font-weight-bold;
        }

        &__total-points {
            font-weight: 400;
            color: black;

            @media screen and (max-width: 1199px) {
                text-align: left;
                font-size: 1.125rem;
            }

            @media screen and (min-width: 1200px) {
                text-align: center;
                font-size: 2rem;
                padding-bottom: 0px;
            }
        }

        &__breakdown {
            background: $secondary-10;
            padding: $spacer;
            font-size: $msv-font-size-s;
            color: $secondary;
            margin-bottom: $spacer;
        }

        &__point-breakdown {
            display: flex;
            justify-content: space-between;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }

        &__available-points-label,
        &__expiring-points-label {
            font-weight: $msv-font-weight-light;
            margin-bottom: 0;
        }

        &__points-trigger {
            @extend %hyperlink;
        }

        &__points-modal-body-top {
            border-bottom: $border-separator;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $spacer;

            p {
                @extend %h3;
            }
        }

        &__points-modal-body-header {
            display: flex;
            justify-content: space-between;
            padding: 0 $layout-gutter;

            p {
                @extend %h5;
            }
        }

        &__points-modal-activity {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
            padding: $spacer $layout-gutter;

            &:nth-child(odd) {
                background: $secondary-10;
            }

            p {
                margin-bottom: 0;
            }
        }

        &__points-modal-type {
            flex-grow: 1;
        }

        &__points-modal-date {
            margin-right: $layout-gutter;
            margin-left: $layout-gutter;
            width: 15rem;
        }

        &__points-modal-points {
            text-align: right;
            width: 6rem;
        }

        &__points-modal .msc-modal__content {
            @media screen and (max-width: 450px) {
                padding: $spacer;
            }
        }
    }
}
