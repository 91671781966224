.ms-loyalty-signup {
    display: none;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    // background-color: $light-secondary;

    // display: flex;
    // align-items: baseline;
    // @media screen and (max-width: 888px) {
    //     align-items: center;
    // }
    // flex-direction: row;
    // justify-content: center;
    // background-color: $light-secondary;

    // &__text {
    //     margin-right: 2rem;
    //     @media screen and (max-width: 888px) {
    //         margin-bottom: 0;
    //         margin-right: 0;
    //     }
    // }

    // &__signin {
    //     // @extend %underlined-button;
    //     margin-top: $spacer;
    //     @media screen and (max-width: 888px) {
    //         margin-top: 0.25rem;
    //     }
    //     text-transform: uppercase;
    // }

    // &__text,
    // &__signin {
    //     text-align: center;

    //     @media screen and (min-width:993px) {
    //         font-size: 1.75rem;
    //     }

    //     @media screen and (min-width:889px) and (max-width: 992px) {
    //         font-size: 1.5rem;
    //     }

    //     @media screen and (max-width: 888px) {
    //         font-size: 1.25rem;
    //     }
    // }

    // @media screen and (max-width: 888px) {
    //     padding: 1rem;
    //     flex-direction: column;
    // }
}

.loyalty-join {
    padding-left: 2rem;
    padding-right: 2rem;

    @media screen and (max-width: $msv-breakpoint-m) {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &-header {
        padding-bottom: 2rem;

        @media screen and (max-width: $msv-breakpoint-m) {
            padding-bottom: 1rem;
        }
    }

    &-paragraph {
        padding-bottom: 1rem;

        > p {
            font-size: 1.5rem;

            @media screen and (max-width: $msv-breakpoint-m) {
                font-size: 1rem;
            }
        }
    }

    &-balance-check-link {
        padding-bottom: 2.5rem;
        font-size: 1.5rem;

        @media screen and (max-width: $msv-breakpoint-m) {
            font-size: 1rem;
        }
    }
}
