$carousel-width: 100%;
$carousel-strip-width: 100%;
$flipper-color: $secondary;
$flipper-top-position: 50%;
$flipper-right-position: 0;
$flipper-next-right-position: 0.5px;
$flipper-width: 2rem;
$flipper-disabled-opacity: 0.4;
$flipper-outline-offset: 2px;
$flipper-border-radius: 0;
$flipper-border-color: transparent;
$vert-carousel-height: 100%;
$vert-carousel-strip-width: 100%;
$vert-flipper-height: 40px;
$carousel-control-hover-opacity: 0.9;

.msc-ss-carousel {
    height: auto;
    position: relative;
    touch-action: pan-y pinch-zoom;
    width: $carousel-width;

    .msc-flipper {
        position: absolute;

        .msi-chevron-left {
            @include add-icon-icomoon($arrow);
            display: inline-block;
            transform: rotate(180deg);
        }
        .msi-chevron-right {
            @include add-icon-icomoon($arrow);
        }
        &:not(.disabled) {
            @include hover-focus {
                color: darken($flipper-color, 20%);
                outline: none;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
    &__flipper {
        background: inherit;
        border: 0;
        color: $flipper-color;
        font-size: $msv-font-size-xl;
        left: 0;
        outline-offset: $flipper-outline-offset;
        top: $flipper-top-position;
    }
    &__flipper:disabled {
        opacity: $flipper-disabled-opacity;
    }
    &__flipper {
        transform: translate(0, -50%);
        z-index: 1;
    }
    &__flipper--next {
        right: $flipper-next-right-position;
        left: auto;
    }
    &-strip {
        height: auto;
        overflow: hidden;
        width: $carousel-strip-width;
    }
    &-slide {
        display: flex;
        padding-inline-start: 0;
        position: relative;
        left: 0;
        transition: left cubic-bezier(0.16, 1, 0.29, 0.99) 0.667s;
        white-space: nowrap;
    }
}
.msc-ss-carousel-vert {
    position: relative;
    width: auto;
    height: $vert-carousel-height;
    display: inline-block;
    touch-action: pan-x pinch-zoom;

    &-slide {
        white-space: nowrap;
        height: auto;
        position: relative;
    }
    &-item {
        display: block;
        white-space: normal;
    }
    &-strip {
        height: $vert-carousel-strip-width;
        overflow: hidden;
        width: auto;

        img {
            max-width: initial;
        }
    }
    &__flipper {
        @include add-icon($msv-ChevronUp);
        background: inherit;
        border: $flipper-border-color;
        border-radius: $flipper-border-radius;
        color: $flipper-color;
        position: absolute;
        top: -($vert-flipper-height / 2);
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 1;

        &:disabled {
            opacity: $flipper-disabled-opacity;
        }
    }
    &__flipper--next {
        @include add-icon($msv-ChevronDown);
        top: auto;
        bottom: -($vert-flipper-height / 2);
    }
}
