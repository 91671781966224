:root {
    --msv-search-font-size: var(--msv-body-font-size-m);
    --msv-search-font-family: #{$msv-primary-font-family};
    --msv-search-form-cancel-btn-color: #{$primary};
    --msv-autoSuggest-bg: var(--msv-bg-color);
    --msv-autoSuggest-border: #e6e6e6;
}

//ADVENTURE
$search-autoSuggest-results-item-font-size: 14px;
$search-autoSuggest-categoryResults-item-height: 40px;
$search-autoSuggest-categoryResults-item-padding: 0 24px;
$search-autoSuggest-categoryResults-title-padding: 16px 24px 8px 12px;
$search-autoSuggest-keywordResults-item-height: 40px;
$search-autoSuggest-keywordResults-item-padding: 0 12px;
$search-autoSuggest-productResults-item-height: 60px;
$search-autoSuggest-productResults-item-font-size: 16px;
$search-autoSuggest-productResults-item-padding-right: 20px;
$search-autoSuggest-productResults-title-padding: 20px 12px 14px 12px;
$search-searchForm-min-width: 240px;
$search-searchForm-height: 40px;
$search-autoSuggest-hover-color: $secondary;
$search-autoSuggest-font-hover-background-color: #fff;
$search-placeholder-text-padding-left: 8px;
$search-form-control-padding: 8px;
$search-form-icon-height: $search-searchForm-height;
$search-form-icon-size: 24px;
$search-form-icon-margin-right: 9px;
$search-form-icon-text-size: 14px;
$search-form-icon-text-line-height: 20px;
$search-form-submitBtn-height: 28px;
$search-form-submitBtn-width: 48px;
$search-form-inactive-width-desktop: 94px;
$search-form-inactive-width-mobile: 48px;
$search-form-padding-right: 20px;
$search-form-padding-left: 5px;
$search-form-submitBtn-border-radius: 0;
$search-autoSuggest-loading-item-height: 48px;
$search-autoSuggest-loading-item-padding: 13px 12px 14px 0;
$search-spinner-circle-border-color: #ffffff rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4);

.layout2021 {
    .ms-search {
        display: flex;
        justify-content: flex-end;
        flex-basis: 0;
        flex-grow: 1;

        .bx-hide {
            display: none;
        }

        button {
            border: 0;
        }

        picture {
            grid-column: 1 / 2;
            grid-row-end: span 2;
            height: 2.5rem;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 0.75rem;
        }

        &__icon {
            @include add-icon-icomoon($search, after);
            background: transparent;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-family: var(--msv-search-font-family);
            font-size: var(--msv-search-font-size);
            height: $topbar--height;
            line-height: $msv-line-height-m;
            width: 3rem;

            &:before {
                vertical-align: text-bottom;
            }

            &-text {
                display: none;
            }
        }

        &__form {
            position: absolute;
            left: 0;
            width: 100%;
            z-index: 1001;
        }

        &__form-cancelSearch {
            background-color: var(--msv-search-form-cancel-btn-color);
            display: flex;
            align-items: center;
            height: $topbar--height;
            order: 1;
            width: 3rem;

            &:before {
                @include msv-icon();
                content: $msv-Cancel;
                text-align: center;
                width: 3rem;
            }
        }

        &__form-submitSearch {
            background-color: $secondary;
            color: $white;
            display: flex;
            align-items: center;
            height: $topbar--height;
            order: 2;
        }

        &__autoSuggest {
            background: var(--msv-autoSuggest-bg);
            box-sizing: border-box;
            color: $msv-black;
            justify-content: flex-end;
            margin-top: $topbar--height;
            position: absolute;
            left: 0;
            text-align: left;
            width: 100%;
            z-index: 1001;

            .headerIsSticky & {
                display: none;
            }
        }

        &__searchForm {
            display: flex;
            height: $topbar--height;
            justify-content: flex-end;
            min-width: 240px;
        }

        &__form-control {
            align-self: flex-end;
            background-color: $white;
            border: 1px solid $secondary;
            border-radius: 0;
            font-size: $msv-font-size-l;
            height: calc(2.5rem + 1px);
            outline: none;
            order: 0;
            padding: 0.5rem 2.5rem 0.5rem 0.5rem;
            width: 100%;

            @include media-breakpoint-down(lg) {
                height: 2.5rem;
            }

            &::-ms-clear {
                display: none;
            }
        }
    }

    a.msc-autoSuggest__productResults-item {
        @include underline-link-animation($orange);
        display: grid;
        font-size: var(--msv-body-font-size-m);
        grid-template-columns: 52px;
        padding-bottom: $spacer/2;
        text-decoration: none;
        width: 100%;
    }

    li.msc-autoSuggest__productResults-item {
        margin-bottom: $spacer/2;
        padding: 0 $layout-gutter;
    }

    .msc-autoSuggest {
        &__categoryResults-item {
            display: flex;
            padding: 0 $layout-gutter;
            margin-bottom: 0;

            a {
                @include vfi();
                @include underline-link-animation($orange);
                color: $msv-link-color;
                display: flex;
                padding: $spacer/2 0;
                text-decoration: none;
                width: 100%;
            }
        }

        &__categoryResults-title {
            @extend %h4;
            padding: 1rem 1.5rem 0.5rem 0.75rem;
            text-transform: none;
        }

        &__keywordResults-item {
            display: flex;
            padding: 0 $layout-gutter;

            a {
                @include vfi();
                @include underline-link-animation($orange);
                color: $msv-link-color;
                display: flex;
                padding: $spacer/2 0;
                text-decoration: none;
                width: 100%;
            }
        }

        &__productResults-item {
            @include vfi();
            display: flex;
            color: $msv-link-color;

            &-price {
                color: $primary;
                grid-column: 2 / 2;
            }

            &-text {
                grid-column: 2 / 2;
            }
        }

        &__productResults-title {
            @extend %h4;
            line-height: $msv-line-height-s;
            padding: 1rem 0.75rem 0.875rem 0.75rem;
            text-transform: none;
        }

        &__screen-reader {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }
    }
}

.layout2022 {
    .ms-search {
        display: flex;
        justify-content: flex-end;
        flex-basis: 0;
        flex-grow: 1;

        .bx-hide {
            display: none;
        }

        picture {
            grid-column: 1 / 2;
            grid-row-end: span 2;
            height: 2.5rem;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 0.75rem;
        }

        &__icon {
            @include add-icon-icomoon($search, after);
            background: transparent;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-family: var(--msv-search-font-family);
            font-size: var(--msv-search-font-size);
            height: $topbar--height;
            line-height: $msv-line-height-m;
            width: 3rem;

            &:before {
                vertical-align: text-bottom;
            }

            &-text {
                display: none;
            }
        }

        &__form {
            position: static;
            left: 0;
            width: 100%;
            z-index: 3000;
        }

        &__form-cancelSearch {
            background-color: var(--msv-search-form-cancel-btn-color);
            display: flex;
            align-items: center;
            height: $topbar--height;
            order: 1;
            width: 3rem;

            // &:before {
            //     @include msv-icon();
            //     content: $msv-Cancel;
            //     text-align: center;
            //     width: 3rem;
            // }
        }

        &__form-submitSearch {
            background: $secondary;
            color: $msv-white;
            display: flex;
            align-items: center;
            height: $topbar--height;
            order: 2;
        }

        &__autoSuggest {
            background: var(--msv-autoSuggest-bg);
            box-sizing: border-box;
            color: $msv-black;
            justify-content: flex-end;
            margin-top: $topbar--height;
            position: absolute;
            //right: 0;
            text-align: left;
            // width: 100%;
            z-index: 3000;
            border: 1px solid #d1d1d1;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
            //min-width: 20rem;

            // .headerIsSticky & {
            //     display: none;
            // }
        }

        &__searchForm {
            display: flex;
            height: $topbar--height;
            justify-content: flex-end;
            min-width: 240px;
        }

        &__form-control {
            align-self: flex-end;
            background-color: $white;
            border: 1px solid $secondary;
            border-radius: 0;
            font-size: $msv-font-size-l;
            height: 2.5rem;
            outline: none;
            order: 0;
            padding: 0.5rem 2.5rem 0.5rem 0.5rem;
            width: 100%;

            @include media-breakpoint-down(lg) {
                height: 2.5rem;
            }

            &::-ms-clear {
                display: none;
            }
        }
    }

    a.msc-autoSuggest__productResults-item {
        @include underline-link-animation($orange);
        display: grid;
        font-size: var(--msv-body-font-size-m);
        grid-template-columns: 52px;
        padding-bottom: $spacer/2;
        text-decoration: none;
        width: 100%;
    }

    li.msc-autoSuggest__productResults-item {
        margin-bottom: $spacer/2;
        padding: 0 $layout-gutter;
    }

    .msc-autoSuggest {
        &__categoryResults-item {
            display: flex;
            padding: 0 $layout-gutter;
            margin-bottom: 0;

            a {
                @include vfi();
                @include underline-link-animation($orange);
                color: $msv-link-color;
                display: flex;
                padding: $spacer/2 0;
                text-decoration: none;
                width: 100%;
            }
        }

        &__categoryResults-title {
            @extend %h4;
            padding: 1rem 1.5rem 0.5rem 0.75rem;
            text-transform: none;
        }

        &__keywordResults-item {
            display: flex;
            padding: 0 $layout-gutter;

            a {
                @include vfi();
                @include underline-link-animation($orange);
                color: $msv-link-color;
                display: flex;
                padding: $spacer/2 0;
                text-decoration: none;
                width: 100%;
            }
        }

        &__productResults-item {
            @include vfi();
            display: flex;
            color: $msv-link-color;

            &-price {
                color: $primary;
                grid-column: 2 / 2;
            }

            &-text {
                grid-column: 2 / 2;
            }
        }

        &__productResults-title {
            @extend %h4;
            line-height: $msv-line-height-s;
            padding: 1rem 0.75rem 0.875rem 0.75rem;
            text-transform: none;
        }

        &__screen-reader {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }
    }
}

// .layout2025 {
//     .ms-search {
//         display: flex;
//         justify-content: flex-end;
//         flex-basis: 0;
//         flex-grow: 1;

//         .bx-hide {
//             display: none;
//         }

//         button {
//             border: 0;
//         }

//         picture {
//             grid-column: 1 / 2;
//             grid-row-end: span 2;
//             height: 2.5rem;
//             margin-top: auto;
//             margin-bottom: auto;
//             margin-right: .75rem;
//         }

//         &__icon {
//             @include add-icon-icomoon($search, after);
//             background: transparent;
//             cursor: pointer;
//             display: inline-flex;
//             align-items: center;
//             justify-content: center;
//             font-family: var(--msv-search-font-family);
//             font-size: var(--msv-search-font-size);
//             height: $topbar--height;
//             line-height: $msv-line-height-m;
//             width: 3rem;

//             &:before {
//                 vertical-align: text-bottom;
//             }

//             &-text {
//                 display: none;
//             }
//         }

//         &__form {
//             position: absolute;
//             left: 0;
//             width: 100%;
//             z-index: 1001;
//         }

//         &__form-cancelSearch {
//             background-color: var(--msv-search-form-cancel-btn-color);
//             display: flex;
//             align-items: center;
//             height: $topbar--height;
//             order: 1;
//             width: 3rem;

//             &:before {
//                 @include msv-icon();
//                 content: $msv-Cancel;
//                 text-align: center;
//                 width: 3rem;
//             }
//         }

//         &__form-submitSearch {
//             background-color: $secondary;
//             color: $white;
//             display: flex;
//             align-items: center;
//             height: $topbar--height;
//             order: 2;
//         }

//         &__autoSuggest {
//             background: var(--msv-autoSuggest-bg);
//             box-sizing: border-box;
//             color: $msv-black;
//             justify-content: flex-end;
//             margin-top: $topbar--height;
//             position: absolute;
//             left: 0;
//             text-align: left;
//             width: 100%;
//             z-index: 1001;

//             .headerIsSticky & {
//                 display: none;
//             }
//         }

//         &__searchForm {
//             display: flex;
//             height: $topbar--height;
//             justify-content: flex-end;
//             min-width: 240px;
//         }

//         &__form-control {
//             align-self: flex-end;
//             background-color: $white;
//             border: 1px solid $secondary;
//             border-radius: 0;
//             font-size: $msv-font-size-l;
//             height: calc(2.5rem + 1px);
//             outline: none;
//             order: 0;
//             padding: .5rem 2.5rem .5rem 0.5rem;
//             width: 100%;

//             @include media-breakpoint-down(lg) {
//                 height: 2.5rem;
//             }

//             &::-ms-clear {
//                 display: none;
//             }
//         }
//     }

//     a.msc-autoSuggest__productResults-item {
//         @include underline-link-animation($orange);
//         display: grid;
//         font-size: var(--msv-body-font-size-m);
//         grid-template-columns: 52px;
//         padding-bottom: $spacer/2;
//         text-decoration: none;
//         width: 100%;
//     }

//     li.msc-autoSuggest__productResults-item {
//         margin-bottom: $spacer/2;
//         padding: 0 $layout-gutter;
//     }

//     .msc-autoSuggest {

//         &__categoryResults-item {
//             display: flex;
//             padding: 0 $layout-gutter;
//             margin-bottom: 0;

//             a {
//                 @include vfi();
//                 @include underline-link-animation($orange);
//                 color: $msv-link-color;
//                 display: flex;
//                 padding: $spacer/2 0;
//                 text-decoration: none;
//                 width: 100%;
//             }
//         }

//         &__categoryResults-title {
//             @extend %h4;
//             padding: 1rem 1.5rem .5rem .75rem;
//             text-transform: none;
//         }

//         &__keywordResults-item {
//             display: flex;
//             padding: 0 $layout-gutter;

//             a {
//                 @include vfi();
//                 @include underline-link-animation($orange);
//                 color: $msv-link-color;
//                 display: flex;
//                 padding: $spacer/2 0;
//                 text-decoration: none;
//                 width: 100%;
//             }
//         }

//         &__productResults-item {
//             @include vfi();
//             display: flex;
//             color: $msv-link-color;

//             &-price {
//                 color: $primary;
//                 grid-column: 2 / 2;
//             }

//             &-text {
//                 grid-column: 2 / 2;
//             }
//         }

//         &__productResults-title {
//             @extend %h4;
//             line-height: $msv-line-height-s;
//             padding: 1rem .75rem .875rem .75rem;
//             text-transform: none;
//         }

//         &__screen-reader {
//             border: 0;
//             clip: rect(0, 0, 0, 0);
//             height: 1px;
//             overflow: hidden;
//             padding: 0;
//             position: absolute;
//             white-space: nowrap;
//             width: 1px;
//         }
//     }
// }

// .layout2024 {

//     .ms-search {
//         button {
//             border: 1px solid transparent;

//             @include vfi();
//         }

//         picture {
//             grid-column: 1 / 2;
//             grid-row-end: span 2;
//             margin-bottom: auto;
//             margin-top: auto;
//         }

//         &__icon {
//             background: transparent;
//             cursor: pointer;
//             display: inline-flex;
//             font-family: $msv-primary-font-family;
//             font-size: $search-form-icon-text-size;
//             height: $search-form-icon-height;
//             line-height: $search-form-icon-text-line-height;
//             width: $search-form-inactive-width-mobile;

//             &::before {
//                 @include msv-icon();
//                 content: $msv-Search;
//                 font-size: $search-form-icon-size;
//                 vertical-align: text-bottom;
//             }

//             &-text {
//                 display: none;
//             }
//         }

//         &__form-cancelSearch {
//             order: 2;

//             &::before {
//                 @include msv-icon();
//                 content: $msv-Cancel;
//                 width: 14px;
//                 text-align: center;
//                 color: $msv-white;
//                 font-size: $search-form-icon-size;
//                 vertical-align: middle;
//             }
//         }

//         &__form-submitSearch {
//             color: var(--msv-search-font-color);
//             -moz-osx-font-smoothing: grayscale;
//             -webkit-font-smoothing: antialiased;
//             margin-left: 12px;
//             background: none;
//             border: none;
//             order: 0;
//             height: 40px;

//             &::before {
//                 @include msv-icon();
//                 content: $msv-Search;
//                 text-align: center;
//                 font-size: 1rem;
//                 vertical-align: middle;
//             }
//         }

//         &__searchForm {
//             display: flex;
//             height: $search-searchForm-height;
//             justify-content: flex-end;
//             min-width: $search-searchForm-min-width;
//             padding-left: $search-form-padding-left;
//             padding-right: $search-form-padding-right;

//             @media (min-width: $msv-breakpoint-m) {
//                 align-items: center;
//             }
//         }

//         &__form-control {
//             @include vfi();
//             border: 1px solid brown;
//             order: 1;
//             margin-left: $search-placeholder-text-padding-left;
//             width: 100%;
//             background-color: var(--msv-search-bg);
//             color: var(--msv-search-font-color);
//             white-space: nowrap;
//             overflow: hidden;
//             text-overflow: ellipsis;

//             &:focus {
//                 outline-offset: -1px;
//                 border: none;
//                 outline: none;
//             }

//             &::-ms-clear {
//                 display: none;
//             }

//             &::placeholder {
//                 color: var(--msv-search-form-placeholder-color);
//             }
//         }

//         @media (max-width: $msv-breakpoint-m) {
//             &__form.bx-hide {
//                 display: none;
//             }

//             &__form.bx-show {
//                 z-index: 1001;
//                 left: 0;
//                 position: fixed;
//                 width: 100%;
//                 top: 0;
//                 background-color: var(--ms-search-autosuggest-background-color);

//                 .ms-search__form-cancelSearch {
//                     align-items: center;
//                     background-color: var(--msv-search-form-cancel-btn-color);
//                     order: 1;
//                     display: flex;
//                     float: right;
//                     width: 20px;
//                     height: 20px;
//                     margin-top: 12px;
//                     margin-right: 12px;
//                     padding: 0;

//                     &::before {
//                         @include msv-icon();
//                         content: $msv-Cancel;
//                         width: 17px;
//                         text-align: center;
//                         color: var(--ms-search-autosuggest-results-font-color);
//                         font-size: 1.25rem;
//                     }
//                 }

//                 .ms-search__searchForm {
//                     display: flex;
//                     justify-content: flex-end;
//                     min-width: $search-searchForm-min-width;
//                     padding: 0;
//                     clear: both;
//                     height: 48px;
//                     border-bottom: 1px solid var(--ms-search-autosuggest-font-color);
//                     margin-bottom: 8px;
//                     margin-left: 16px;
//                     margin-right: 16px;

//                     .msc-autoSuggest__productResults-empty-mobile-content {
//                         background: tomato;
//                         margin-top: 55px;
//                         width: 100%;
//                         justify-content: flex-end;
//                         clear: both;
//                         height: 87vh;
//                         margin-bottom: 8px;
//                         margin-left: 16px;
//                         margin-right: -16px;
//                         position: absolute;

//                         .msc-autoSuggest__productResults-empty-mobile-no-results-image {
//                             margin-top: 56px;

//                             img {
//                                 margin: 0 auto;
//                             }
//                         }

//                         .msc-autoSuggest__productResults-empty-mobile-text-content {
//                             text-align: center;
//                             margin-top: 28px;
//                         }

//                         .msc-autoSuggest__productResults-empty-mobile-heading {
//                             font-family: $msv-primary-font-family;
//                             font-size: 18px;
//                             line-height: 24px;
//                             letter-spacing: 0;
//                             color: $msv-white;
//                         }

//                         .msc-autoSuggest__productResults-empty-mobile-text {
//                             margin-top: 20px;
//                             line-height: 20px;
//                             letter-spacing: 0;
//                             text-align: center;
//                             color: $primary;
//                         }
//                     }
//                 }

//                 .ms-search__form-control {
//                     @include vfi();
//                     background-color: var(--msv-search-form-bg);
//                     font-size: var(--msv-search-font-size);
//                     color: var(--msv-search-font-color);
//                     order: 0;
//                     width: 100%;
//                     border: none;
//                     outline: none;
//                     height: 20px;
//                     padding: 0;
//                     margin-top: 24px;

//                     &:focus {
//                         border: none;
//                         outline: none;
//                     }

//                     &::-ms-clear {
//                         display: none;
//                     }

//                     @media screen and (-webkit-min-device-pixel-ratio: 0) {
//                         font-size: $msv-font-size-m;
//                     }
//                 }

//                 .ms-search__form-control__clear.msc-btn {
//                     color: var(--ms-search-autosuggest-results-font-color);
//                     letter-spacing: 0.1em;
//                     text-decoration-line: underline;
//                     text-transform: uppercase;
//                     border: none;
//                     padding: 0;
//                     height: 20px;
//                     margin-top: 24px;

//                     &:focus {
//                         border: 1px dashed $msv-white;
//                     }
//                 }
//             }

//             &__icon {
//                 &::before {
//                     font-size: 1rem;
//                 }
//             }

//             .ms-search__form-submitSearch {
//                 display: block;
//             }
//         }

//         @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
//             border: none;

//             &__label.bx-show {
//                 display: block;
//             }

//             &__form.bx-hide {
//                 display: none;
//             }

//             &__icon-text {
//                 display: none;
//             }

//             &__label.bx-hide {
//                 display: none;
//             }

//             &__form.bx-show {
//                 display: block;
//                 width: 100%;
//                 background: transparent;
//                 border: 1.5px solid $primary;
//                 border-radius: 30px;
//                 position: initial;
//                 height: 40px;

//                 .ms-search__searchForm {
//                     margin: 0;
//                     border: none;
//                     height: 100%;
//                     padding-left: 5px;
//                     padding-right: 20px;
//                 }

//                 .ms-search__form-control {
//                     border: none;
//                     order: 1;
//                     padding-left: 8px;
//                     padding-right: 40px;
//                     width: 100%;
//                     background: transparent;
//                     color: $msv-white;
//                     height: $search-form-submitBtn-height;
//                     margin-top: 0;
//                 }

//                 .ms-search__form-control:focus {
//                     border: none;
//                     outline: none;
//                 }

//                 .ms-search__form-cancelSearch {
//                     background: transparent;
//                     order: 2;
//                     height: $search-form-submitBtn-height;
//                     margin: 0;
//                 }

//                 .ms-search__form-submitSearch {
//                     order: 0;
//                     align-items: center;
//                     background-color: var(--msv-search-form-search-btn-color);
//                     border-radius: $search-form-submitBtn-border-radius;
//                     color: var(--msv-font-secondary-color);
//                     height: $search-form-submitBtn-height;
//                     width: $search-form-submitBtn-width;
//                     padding: 0;
//                     margin-left: 0;
//                     bottom: 1px;
//                     position: relative;

//                     &::before {
//                         @include msv-icon();
//                         content: $msv-Search;
//                         text-align: center;
//                         transform: scaleX(-1);
//                         font-size: 1rem;
//                     }
//                 }
//             }

//             &__icon {
//                 &::before {
//                     font-size: 1rem;
//                 }
//             }
//         }

//         @media (min-width: $msv-breakpoint-l) {

//             .ms-search__label.bx-show,
//             .ms-search__label.bx-hide {
//                 display: none;
//             }

//             &__icon {
//                 width: $search-form-inactive-width-desktop;

//                 &::before {
//                     margin-right: $search-form-icon-margin-right;
//                     font-size: 1rem;
//                 }
//             }
//         }
//     }

//     li.msc-autoSuggest__productResults-item {
//         padding-right: $search-autoSuggest-productResults-item-padding-right;
//     }

//     a.msc-autoSuggest__productResults-item {
//         font-size: $search-autoSuggest-productResults-item-font-size;
//         width: 100%;

//         .msc-autoSuggest__productResults-item-img {
//             margin: 0 auto;
//         }
//     }

//     .msc-autoSuggest {
//         &__categoryResults-item {
//             display: flex;
//             font-size: $search-autoSuggest-results-item-font-size;
//             height: $search-autoSuggest-categoryResults-item-height;

//             a {
//                 @include vfi();
//                 display: flex;
//                 height: $search-autoSuggest-categoryResults-item-height;
//                 line-height: $search-autoSuggest-categoryResults-item-height;
//                 padding: $search-autoSuggest-categoryResults-item-padding;
//                 width: 100%;
//             }

//             :hover {
//                 background-color: $search-autoSuggest-hover-color;
//             }
//         }

//         &__categoryResults-title {
//             color: var(--msv-font-primary-color);
//             padding: $search-autoSuggest-categoryResults-title-padding;
//             text-transform: none;
//         }

//         &__keywordResults-item {
//             display: flex;
//             font-size: $search-autoSuggest-results-item-font-size;
//             height: $search-autoSuggest-keywordResults-item-height;

//             a {
//                 @include vfi();
//                 display: flex;
//                 height: $search-autoSuggest-keywordResults-item-height;
//                 line-height: $search-autoSuggest-keywordResults-item-height;
//                 padding: $search-autoSuggest-keywordResults-item-padding;
//                 width: 100%;
//             }

//             :hover {
//                 background-color: $search-autoSuggest-hover-color;
//             }
//         }

//         &__productResults-item {
//             @include vfi();
//             display: flex;
//             height: $search-autoSuggest-productResults-item-height;

//             &-price {
//                 grid-column: 2 / 2;
//             }

//             &-text {
//                 grid-column: 2 / 2;
//             }

//             :hover {
//                 background-color: $search-autoSuggest-font-hover-background-color;
//             }
//         }

//         &__productResults-title {
//             padding: $search-autoSuggest-productResults-title-padding;
//             color: var(--msv-font-primary-color);
//             text-transform: none;
//         }

//         &__screen-reader {
//             border: 0;
//             clip: rect(0, 0, 0, 0);
//             height: 1px;
//             overflow: hidden;
//             padding: 0;
//             position: absolute;
//             white-space: nowrap;
//             width: 1px;
//         }

//         &__loadingResult {
//             display: flex;
//             font-size: var(--msv-search-font-size);
//             height: $search-autoSuggest-loading-item-height;
//             padding: $search-autoSuggest-loading-item-padding;
//             width: 100%;
//             justify-content: flex-start;

//             .ms-Spinner-circle {
//                 border-color: $search-spinner-circle-border-color;
//             }

//             .ms-Spinner-label {
//                 text-transform: lowercase;
//                 color: $msv-white;
//             }
//         }
//     }

//     @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
//         .ms-header__container .ms-header__topbar .ms-search {
//             height: 48px;
//             margin-left: 25px;
//         }
//     }
// }
