.msc-address-form__item--InvoiceCustAccount_CAP,
.ms-checkout-invoice .ms-checkout__guided-card-btn-cancel,
.ms-checkout-invoice .msc-address-detail .msc-address-detail__item.Type_CAP,
.ms-checkout-invoice .msc-address-detail .msc-address-detail__item.InvoiceCustAccount_CAP {
    display: none;
}
.visted .ms-checkout-invoice .ms-checkout__guided-card-btn-cancel {
    display: inline-block;
}
.ms-checkout-invoice {
    label {
        &.hide {
            display: none;
        }
    }
}
.disable-save-billing {
    color: rgba(0, 79, 113, 0.4);
    border-color: rgba(0, 79, 113, 0.4);
    cursor: not-allowed;
}
