@font-face {
    font-family: 'icomoon';
    src: url('#{$msv-font-path}/icomoon.eot?2zpdyg');
    src: url('#{$msv-font-path}/icomoon.eot?2zpdyg#iefix') format('embedded-opentype'),
        url('#{$msv-font-path}/icomoon.ttf?2zpdyg') format('truetype'), url('#{$msv-font-path}/icomoon.woff?2zpdyg') format('woff'),
        url('#{$msv-font-path}/icomoon.svg?2zpdyg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: '\e901';
}
.icon-user:before {
    content: '\e903';
}
.icon-arrow2:before {
    content: '\e906';
}
.icon-arrow:before {
    content: '\e907';
}
.icon-close:before {
    content: '\e90a';
}
.icon-more:before {
    content: '\e90b';
}
.icon-less:before {
    content: '\e90c';
}
.icon-error:before {
    content: '\e90d';
}
.icon-star-half:before {
    content: '\e900';
}
.icon-star-full:before {
    content: '\e902';
}
.icon-star:before {
    content: '\e904';
}
.icon-youtube:before {
    content: '\e905';
}
.icon-instagram:before {
    content: '\e908';
}
.icon-facebook:before {
    content: '\e909';
}
.icon-pinterest:before {
    content: '\e90e';
}
.icon-shipping:before {
    content: '\e90f';
}
.icon-free-next-day:before {
    content: '\e910';
}
.icon-pick-up:before {
    content: '\e911';
}
.icon-cart:before {
    content: '\e912';
}
.icon-cart-btn:before {
    content: '\e913';
}
.icon-heart:before {
    content: '\e914';
}
.icon-heart-full:before {
    content: '\e915';
}
.icon-heart-wishlist:before {
    content: '\e916';
}
.icon-heart-btn:before {
    content: '\e917';
}
.icon-trash:before {
    content: '\e918';
}
.icon-thumbs-up:before {
    content: '\e919';
}
.icon-thumbs-up-full:before {
    content: '\e91a';
}
.icon-thumbs-down:before {
    content: '\e91b';
}
.icon-thumbs-down-full:before {
    content: '\e91c';
}
.icon-check:before {
    content: '\e91d';
}
.icon-newsletter:before {
    content: '\e91e';
}
.icon-user-profile:before {
    content: '\e91f';
}
.icon-loyalty:before {
    content: '\e920';
}
.icon-marker:before {
    content: '\e921';
}
.icon-info:before {
    content: '\e922';
}
.icon-chevron-b:before {
    content: '\e923';
}
.icon-pm-cart:before {
    content: '\e924';
}
.icon-pm-cart-btn:before {
    content: '\e925';
}
.icon-mail:before {
    content: '\e926';
}
.icon-twitter:before {
    content: '\e927';
}
.icon-store-prefered:before {
    content: '\e928';
}
.icon-store-prefered-selected:before {
    content: '\e929';
}
.icon-gift:before {
    content: '\e92a';
}
