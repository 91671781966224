$msc-promo-code-discount-heading-margin-top: 20px;
$msc-promo-code-discount-line-container-border: 1px solid black;
$msc-promo-code-discount-line-container-margin-top: 4px;
$msc-promo-code-discount-line-container-padding-bottom: 10px;
$msc-promo-code-discount-line-remove-btn-padding: 0 12px;
$msc-promo-code-input-border: 1px solid black;
$msc-promo-code-input-outline: 1px dashed transparent;

.msc-promo-code__apply-btn {
    @include solid-button($secondary, true);
}
.msc-promo-code {
    &__discount {
        display: flex;
        justify-content: space-between;
        margin-top: $msc-promo-code-discount-heading-margin-top;

        &-heading {
            font-weight: $msv-font-weight-normal;
            margin-bottom: $spacer/2;
        }

        &-value {
            font-weight: $msv-font-weight-bold;
        }
    }
    &__line {
        &-container {
            border-bottom: 1px solid rgba($secondary, 50%);
            display: flex;
            flex-direction: column;
            margin-bottom: $spacer;
            padding-bottom: 0.5rem;

            @media screen and (max-width: $msv-breakpoint-m) {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: start;
            }
        }
        &-value {
            display: inline-block;
            color: $green;
            font-weight: $msv-font-weight-normal;
            overflow-wrap: break-word; //safari
            word-break: break-word; //safari
            overflow-wrap: anywhere; //others
        }
        &__btn-remove {
            @include add-icon-icomoon($trash, before, 10px);
            @include text-link($secondary, after);
            margin-top: 0.5rem;
            margin-left: 0.25rem;
            padding-top: 0;
            text-align: left;
            text-decoration: none;

            &:hover {
                color: darken($secondary, 10%);
            }
        }
    }
    &__input-box {
        outline: $msc-promo-code-input-outline;
    }
}
