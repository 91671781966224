.content-block-accordion-tile {
    .row {
        .col-12 {
            .ms-content-block__details {
                .ms-content-block__text {
                    p {
                        margin-bottom: 0;
                    }
                }

                padding: 1rem 1rem 0 1rem;
            }

            .ms-accordion {
                padding: 1rem;
                background-color: $light-beige;
            }

            .ms-content-block[data-m-layout='tile'] {
                .ms-content-block__image {
                    margin-bottom: 0;
                }

                @media (max-width: 768px) {
                    padding-bottom: 0;
                    width: 100%;
                }
            }

            .ms-accordion-item__drawer {
                button {
                    font-family: 'DIN 2014', sans-serif;
                    font-weight: 400;
                    text-transform: uppercase;
                    font-style: normal;
                }
            }

            .ms-accordion-item-content {
                .ms-text-block {
                    p {
                        font-size: 1rem;
                        color: #542d00;
                    }
                }
            }
        }
    }
}
