.venchi-gwp {
    .msc-modal__header {
        justify-content: center;
    }
    .row {
        justify-content: center;
    }
    &__free-count-label {
        width: 100%;
        font-weight: 500;
    }
    .ms-product-search-result__item {
        max-width: 250px;
    }
    .msc-modal__title {
        margin: 0px 40px;
    }
}
