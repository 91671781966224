html {
    font-size: $msv-font-size-m;
    font-family: $msv-primary-font-family;
    font-weight: $msv-font-weight-light;

    @media (min-width: 992px) and (max-width: 1500px) {
        font-size: 15px;
    }

    p,
    ul,
    div,
    span,
    button,
    a {
        line-height: 1.3em;
    }

    main {
        margin-bottom: $spacer-l;
        min-height: 100vh;
    }
}

.default-container {
    &.fluid {
        > .row {
            margin-left: 0;
            margin-right: 0;

            > .col-12 {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &-2-slots {
        [class^='col-'] {
            display: flex;
            flex-direction: column;
        }
    }

    .heading {
        &.container-heading {
            @extend %col-12;
        }
    }
}

.section-v-spaced {
    padding-top: $section-v-space;
}

.space-md--v {
    @include media-breakpoint-down(md) {
        margin-bottom: $spacer-l;
    }
}

.no-padding {
    padding-left: 0;
    padding-right: 0;
}

main {
    padding-top: $spacer;
    padding-bottom: $spacer-xl;
}

.page--home {
    main {
        padding-top: 0;
    }
}

.row {
    > h1,
    > h2,
    > h3 {
        padding-left: $layout-gutter;
        padding-right: $layout-gutter;
    }
}

.spaced_ctn {
    padding: $spacer-l $layout-gutter;
}

a {
    [contenteditable] {
        display: inline-block;
    }
}

.flex-justify {
    &--spacebetween {
        justify-content: space-between;
        flex-wrap: nowrap;

        > div {
            padding-left: $layout-gutter;
            padding-right: $layout-gutter;
        }
    }
}

.flex-header {
    &--start {
        flex: 1;
        /* shorthand for: flex-grow: 1, flex-shrink: 1, flex-basis: 0 */
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    &--end {
        flex: 1;
        /* shorthand for: flex-grow: 1, flex-shrink: 1, flex-basis: 0 */
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    &--center {
        flex: 1;
        /* shorthand for: flex-grow: 1, flex-shrink: 1, flex-basis: 0 */
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
    }
}

.no-scroll,
.no-scroll-pick-mix {
    overflow: hidden;
    touch-action: none;
    -ms-touch-action: none;
}

.flex-rows-break {
    flex-basis: 100%;
    align-items: center;
    height: 8px;
    margin: 0;
}

.flex-rows-break-line {
    width: 80%;
    height: 0px;
    border-bottom: 1px solid #e9e5e2;
    margin: 0 auto;
    max-width: 500px;
}

.hidden-block {
    display: none !important;
}
