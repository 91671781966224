.ms-content-block {
    &[data-m-layout='full-width'] {
        .ms-content-block__image {
            position: absolute;
            top: 0;
            left: 0;
        }
        .ms-content-block__details {
            flex-grow: 1;
            position: relative;

            .col-md-6 & {
                margin-left: 8.333333%;
                width: 83.333333%;

                @include media-breakpoint-up(lg) {
                    margin-left: calc(16.666666%);
                    width: 66.66666%;
                }
            }
        }
        .ms-content-block__details,
        &.textalignment__left .ms-content-block__details {
            text-align: left;
            align-items: flex-start;
        }
        &.textalignment__center .ms-content-block__details {
            align-items: center;
            text-align: center;
        }
        .ms-content-block__cta {
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
        .ms-content-block__details {
            display: flex;
            flex-direction: column;
        }
        /**
		* Text over image by default only in modern browsers.
		* We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
		*/
        @supports (object-fit: cover) {
            .ms-content-block__image {
                object-fit: cover;
            }
        }
        &.linkplacement__entirebanner {
            > a {
                position: relative;
                display: flex;
                flex-direction: column;
                height: 100%;
                padding-top: 2rem;
                padding-bottom: 2rem;
                text-decoration: none;

                .ms-content-block__details {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        height: 8px;
                        width: 0;
                        transition: width 0.3s cubic-bezier(0, 0.37, 0.4, 1);
                        will-change: width;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        background: $white;
                    }
                }
                &:hover .ms-content-block__details:before,
                &:focus .ms-content-block__details:before {
                    width: 100%;
                }
            }
            &.backgroundcolor__lightbrown > a .ms-content-block__details:before {
                background: $orange;
            }
        }
        &:not(.linkplacement__entirebanner) {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;

            @include media-breakpoint-down(lg) {
                height: auto;
            }
        }
        .ms-content-block__cta {
            z-index: 1;
            position: relative;
        }
        .ms-content-block__details {
            padding: $spacer-l $spacer-l $spacer-l $spacer-l;
            width: 83.333333%;

            @media screen and (min-width: $msv-container-max-width-m) {
                padding: $spacer-xl $spacer-xl $spacer-l $spacer-xl;
                width: 50%;
            }
            h2 {
                text-align: left;

                @include media-breakpoint-up(xl) {
                    font-size: 2.5rem;
                }
            }
            p {
                font-size: $msv-font-size-xl;
                position: relative;
                z-index: 0;
            }
        }
        &.textplacement {
            &__left {
                text-align: left;

                &.textverticalplacement__middle .ms-content-block__image {
                    transform: translateX($layout-gutter * 3);
                    width: calc(100% - #{$layout-gutter * 3});
                }
            }
            &__right {
                .ms-content-block__details {
                    align-self: flex-end;
                    justify-content: center;
                }
                &.textverticalplacement__middle .ms-content-block__image {
                    width: calc(100% - #{$layout-gutter * 2});

                    @include media-breakpoint-up(md) {
                        img {
                            width: calc(100% - #{$layout-gutter * 2});
                        }
                    }
                }
            }
        }
        &.textverticalplacement {
            &__bottom {
                padding-top: 14rem;

                .ms-content-block__text {
                    flex-grow: 1;
                }
                h2 {
                    .col-md-6 & {
                        min-height: 2.5em;
                    }
                }
                .ms-content-block__image {
                    height: 32rem;

                    @include media-breakpoint-down(lg) {
                        height: 28rem;
                    }
                }
                &.textplacement__left,
                &.textplacement__right {
                    padding-top: $spacer-l;

                    .ms-content-block__details {
                        margin-left: 8.333333%;
                    }
                    .ms-content-block__image {
                        height: calc(100% - #{$spacer-l});
                    }
                }
                &.textplacement__right .ms-content-block__details {
                    margin-left: 0;
                    margin-right: 8.33333%;
                }
            }
            &__middle:not(.linkplacement__entirebanner) {
                padding-top: $spacer-l;
                padding-bottom: $spacer-l;
            }
            &__middle {
                .ms-content-block__details {
                    min-height: 21rem;
                    justify-content: center;

                    @media screen and (max-width: $msv-breakpoint-m) {
                        width: 83.333333%;
                    }
                }
            }
        }
        .blocksize__squarehigh {
            min-height: 32rem;

            &__image,
            &__image img {
                height: 32rem;
            }
        }
        .ms-content-block__text {
            font-size: $msv-font-size-xl;
        }
        .ms-content-block__image,
        .ms-content-block__image img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.ms-content-block.homepage-topbanner-contentblock {
    a {
        width: 100%;
        .ms-content-block__image img {
            height: 30vh;
            object-fit: cover;
            width: 100%;
        }
        .ms-content-block__details {
            display: none;
        }
    }
}

.banner--store {
    [data-m-layout='full-width'] {
        .ms-content-block__details {
            width: calc(41.666667% - #{$layout-gutter});
            margin-bottom: -2rem;
            @include media-breakpoint-down(md) {
                width: 83.333%;
            }
        }
        .store-module__bottom-block {
            background: $secondary-10;
            color: $secondary;
            margin-right: -3rem;
            margin-left: -3rem;
            margin-bottom: -2rem;
            padding: $spacer-l $spacer-xl $spacer $spacer-xl;

            @include media-breakpoint-down(md) {
                margin-right: -2rem;
                margin-left: -2rem;
                padding: $spacer-l 2rem $spacer 2rem;
            }
            p {
                font-size: 1.25rem;
                line-height: 1.1em;
            }
            .icon-pick-up {
                font-size: 2.6rem;
                float: left;
                line-height: 1.1em;
            }
        }
    }
}
