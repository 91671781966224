.ms-product-specification {
    &__heading {
        display: none;
    }
    .specifications_rows {
        display: flex;
        flex-direction: column;
        margin-bottom: $spacer-m;
        width: 50%;
        margin-left: 50%;
        @media screen and (max-width: $msv-breakpoint-m) {
            width: 100%;
            margin-left: 0;
        }
        align-items: flex-end;

        > div {
            &.Productdescription {
                &.-accordion {
                    border-top: 1px solid $blue;
                    @media screen and (max-width: $msv-breakpoint-m) {
                        border-top: 0;
                    }
                }
            }
            &.Productdescription,
            &.Description {
                order: 1;
                color: $blue;
            }
            &.Reassurance {
                order: 2;
                color: $blue;
            }
            &.Ingredients {
                color: $blue;
                order: 3;
                padding: $spacer;

                @include media-breakpoint-up(md) {
                    padding: $spacer-xl;
                }
            }
            &.NutritionalValuesJSON,
            &.NutritionalValuesJSON {
                order: 4;
                color: $blue;
            }
            &.Productlabels {
                order: 5;
                color: $blue;
            }
            &.ms-social-share {
                order: 6;
                & .ms-social-share-ul.caption:before {
                    font-weight: $msv-font-weight-heavy;
                }
            }
            h4 {
                text-transform: uppercase;
                font-size: $msv-icon-size-small;
                font-family: $secondary-font-family;
                font-weight: $msv-font-weight-heavy;
            }
            .content {
                font-size: $msv-font-size-m;
                font-family: $msv-primary-font-family;
                font-weight: $msv-font-weight-normal;
                max-height: 0;
                height: 0;
                /* visibility: hidden; */
                overflow: hidden;
                /* opacity: 0; */
                color: $primary;
                text-align: left;
                transition: max-height 2s;

                & a {
                    font-size: $msv-font-size-m;
                    font-family: $msv-primary-font-family;
                    font-weight: $msv-font-weight-normal;
                    color: $primary;
                    text-decoration: underline;
                }
                &.-expanded {
                    height: auto;
                    max-height: 1000px;
                    /* visibility: visible; */
                    /* opacity: 1; */
                    padding-bottom: 1rem;
                }

                & .reassurance-container {
                    //border: 2px solid $blue;
                    & .reassurance-row {
                        // margin: 0 20px;
                        padding: 20px 0;
                        display: flex;
                        flex-direction: row;
                        &.-border {
                            border-bottom: 1px solid $secondary-20;
                        }
                        & .reassurance-col {
                            &.-image {
                                min-width: 60px;

                                & .msc_image {
                                    width: 42px;
                                    height: auto;
                                }
                            }
                            &.-text {
                                display: flex;
                                flex-direction: column;
                                //padding-left: 20px;
                                color: $primary;
                                & .title {
                                    text-transform: uppercase;
                                    font-size: $msv-icon-size-small;
                                    font-family: $primary-font-family;
                                    font-weight: $msv-font-weight-heavy;
                                }
                                & .description {
                                    font-size: $msv-icon-size-small;
                                    font-family: $primary-font-family;
                                    font-weight: $msv-font-weight-normal;
                                }
                            }
                        }
                    }
                }

                & .nutritional-values-container {
                    width: 50%;
                    @media screen and (max-width: map-get($container-max-widths, 'xl')) {
                        width: 100%;
                    }
                }

                & .nutritional-values-row {
                    display: flex;
                    flex-direction: row;
                    &.-subcategory {
                        padding-left: 0.5rem;
                    }
                    & .nutritional-values-col {
                        color: $primary;
                        font-size: $msv-font-size-m;
                        font-weight: $msv-font-weight-normal;
                        line-height: $msv-line-height-m;
                        &.-spacer {
                            flex: 1;
                            margin: 0 0.3rem;
                            &.-line {
                                border-bottom: 1px solid $light-beige;
                            }
                        }
                        &.-pr {
                            padding-right: 0.3rem;
                        }
                        &.-subcategory {
                            color: $beige;
                            font-size: $msv-font-size-s;
                        }
                    }
                }
            }

            &.-accordion {
                border-bottom: 1px solid $blue;
                padding: 1rem 0 0;
                width: 100%;
            }

            & .accordion-header {
                & > button {
                    border: none;
                    background-color: transparent;
                    padding: 0;
                    color: $blue;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    line-height: 1rem;
                    cursor: pointer;
                }

                & .accordion-button {
                    &.-expanded {
                        @include add-icon($msv-ChevronUp);
                    }
                    &.-collapsed {
                        @include add-icon($msv-ChevronDown);
                    }
                    border: none;
                    background-color: transparent;
                    padding: 0;
                    height: 20px;
                    width: 20px;
                    color: $blue;
                }
            }
        }
    }
}
