.msc-form {
    &__item {
        margin-bottom: $spacer;
    }
    label {
        color: $primary;
        display: block;
        margin-bottom: 0.25rem;
    }
    input,
    textarea,
    select {
        border-color: $primary;
        display: block;
        font-size: $msv-font-size-l;
        outline: none;
        width: 100%;
    }
    .error-message {
        @extend %error-message;
        padding-top: 3px;
    }
    .msc-alert {
        background: none;

        &-error {
            color: $msv-error-color;
        }
        &-success {
            color: $msv-success-color;
        }
    }
    [type='submit'] {
        @include solid-button($primary);

        &.status__success {
            @include add-icon-icomoon($check);
            background: $msv-success-color;

            span {
                display: none;
            }
        }
        + .msc-alert {
            display: inline-block;
        }
    }
    input[type='checkbox'] {
        @include form-input-checkbox($primary);
    }
    [aria-invalid='true'] {
        label {
            @extend %invalid-label;
        }
        input,
        select,
        textarea {
            @extend %invalid-input;
        }
    }
}
