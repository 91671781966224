$msv-address-error-font-color: $red;

$msv-address-form-item-margin-bottom: 10px;
$msv-address-detail-item-margin-right: 5px;
$msv-address-select-item-margin-top: 10px;
$msv-address-select-item-margin-bottom: 10px;
$msv-alert-font-weight: var(--msv-font-weight-bold);
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-icon-margin: 8px;
$msv-address-input-min-height: 32px;
$msv-address-select-item-radio-height: 24px;
$msv-address-select-item-radio-width: 24px;
$msv-address-select-item-address-detail-padding-left: 48px;
$msv-address-button-save-margin-right: 20px;
$msv-account-management-address-margin-bottom: 20px;
$msv-account-management-address-list-button-add-empty-margin-bottom: 16px;
$msv-account-management-address-list-primary-margin-top: 20px;
$msv-account-management-address-list-primary-heading-margin-bottom: 12px;
$msv-account-management-address-list-primary-list-border-color: $msv-gray-300;
$msv-account-management-address-list-primary-list-address-detail-item-margin-right: 5px;
$msv-account-management-address-list-primary-list-address-detail-item-newline-margin-right: 0px;
$msv-account-management-address-list-primary-list-button-edit-background-color: transparent;
$msv-account-management-address-list-primary-list-button-edit-border-color: transparent;
$msv-account-management-address-list-primary-list-phone-icon-margin-right: 5px;
$msv-account-management-address-list-button-primary-margin-top: 15px;
$msv-account-management-address-textbox-height: 48px;

//style presets
:root {
    --msv-address-font-size: var(--msv-body-font-size-l);
    --msv-address-border: #{$msv-gray-300};
    --msv-address-font-family: #{$msv-primary-font-family};

    // error
    --msv-address-error-bg: $red;
    --msv-address-error-border: $red;
}

.address-form__item-invalid {
    label {
        @include add-icon-icomoon($error, after);
        &:after {
            transform: translate(-1rem, 2.5rem);
            float: right;
            color: $red;
        }
    }
    input,
    select {
        border-color: $red;
    }
}
html[lang='it'] {
    .msc-address-form__item.msc-address-form__item-state {
        display: none;
    }
}
.msc-address-select {
    display: flex;
    flex-direction: column;
}
.msc-address-form {
    &__item {
        margin-bottom: $spacer;
        display: flex;
        flex-direction: column;
    }

    &__item-county {
    }

    &__label {
        display: block;
        margin-bottom: 4px;
    }

    &__input-text {
    }

    &__input,
    &__dropdown {
        display: block;
        width: 100%;
        font-size: $msv-font-size-l;
    }

    &__alert {
        display: block;
        order: 3;
        padding-top: 3px;
        font-weight: $msv-font-weight-normal;
    }

    &__button-save {
        @extend %solid-button;
        margin-right: $layout-gutter;
    }

    &__button-cancel {
        @extend %hyperlink;
    }

    .address-form__item-invalid &__alert {
        text-transform: none;
        color: $msv-address-error-font-color;
    }

    &__error {
        margin-bottom: $spacer;
        color: $msv-red;
    }
}

.msc-address-detail {
    &__item {
        margin-right: $msv-address-detail-item-margin-right;
        .msc-address-detail__item-address-detail_Phone & {
            margin-right: 0;
        }
        &-state {
            html[lang='it'] & {
                display: none;
            }
        }
    }

    &__item-newline {
        margin-right: 0px;

        &:after {
            content: '\A';
            white-space: pre;
        }
    }
    &__item-address-detail_Phone {
        display: block;
    }
    &__item-phone {
        &-label {
            @include visually-hidden();
        }

        &.msc-address-detail__item-empty {
            display: none;
        }
    }
}

.msc-address-select {
    &__input-radio {
        height: $msv-address-select-item-radio-height;
        width: $msv-address-select-item-radio-width;
        position: absolute;
        top: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: $spacer;
        position: relative;
        .msc-address-detail {
            span:not(:nth-child(1)) {
                padding-left: 2.5rem;
                display: block;
            }
            span.msc-address-detail__item-empty {
                display: none;
            }
        }
    }

    &__input {
        @include vfi();
        @include form-input-radio();
    }

    &__button-add {
        @include vfi();
        @extend %solid-button;
    }
}

// mixin
@mixin address-button {
    cursor: pointer;
}

.ms-account-management-address {
    margin-bottom: $msv-account-management-address-margin-bottom;
    .ms-address-list {
        overflow: hidden;
        padding-right: $layout-gutter;
    }

    .msc-address-list__heading {
    }

    .msc-address-list__add-empty {
        margin-bottom: $msv-account-management-address-list-button-add-empty-margin-bottom;
    }

    .msc-address-list__button-add {
        @extend %solid-button;
        @include add-icon-icomoon($more, after, true, 10px, false);
        margin-bottom: $spacer;
    }

    .msc-address-list__primary {
        border: 2px solid $secondary;
        padding: $spacer-xl 0;
        margin-bottom: $spacer-xl;
        width: 100%;
        @include media-breakpoint-up(sm) {
            width: 60%;
        }
        @include media-breakpoint-up(lg) {
            width: 80%;
        }
        float: right;
        font-size: 1.25rem;
        @include make-row();
        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
        }
        &-heading {
            color: $secondary;
            flex: 0 0 100%;
            padding: 0 $spacer;
            @include media-breakpoint-up(sm) {
                padding: 0 $spacer-xl;
            }
        }
        .msc-address-list__primary-list {
            width: 100%;
            padding: 0 $spacer;
            display: flex;
            flex-direction: column;
            @include media-breakpoint-up(sm) {
                margin-bottom: $spacer;
                padding: 0 $spacer-xl;
            }

            @include media-breakpoint-down(md) {
                &:after {
                    border-bottom: $border-separator;
                }
                padding-bottom: $spacer;
                margin-bottom: $spacer;
            }

            &::nth-child(2) {
                padding-top: 0px;
                padding-top: 0px;
            }

            &::last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
            .msc-address-detail {
                flex-grow: 1;
                margin-bottom: $spacer;

                .msc-address-detail__item {
                }
                .msc-address-detail__item-newline {
                    &::after {
                        content: '\A';
                        white-space: pre;
                    }
                }
            }
            .msc-address-list__button-primary {
                @include border-button($secondary, true);
                margin-bottom: $spacer;
                @include media-breakpoint-down(sm) {
                    margin-right: $layout-gutter;
                }
            }

            .msc-address-list__button-edit {
                @extend %hyperlink;
                align-self: flex-start;
            }
            .msc-address-list__button-remove {
                @include text-link($secondary, after);
                text-decoration: none;
                margin-left: $layout-gutter;
                @include add-icon-icomoon($trash, before, true, 10px);
                align-self: flex-end;
                margin-top: -2rem;
            }
        }
        .msc-address-list__primary-list:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
        .msc-address-list__primary-list {
            @include media-breakpoint-up(lg) {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
                display: flex;
                flex-direction: column;
                margin-bottom: $spacer;
                padding: 0 $spacer-xl;
                border-right: $border-separator;

                &:nth-child(3n + 1) {
                    border-right: 0;
                }
            }
        }
        &:last-child {
            border: $border-separator;
            h3 {
                color: $msv-black;
            }
        }
    }
}
