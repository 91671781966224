:root {
    --msv-gift-card-balance-check-font-size: 1em;
    --msv-gift-card-balance-check-font-color: var(--msv-font-primary-color);
    --msv-gift-card-balance-error-color: #a80000;
    --msv-gift-card-balance-error-bg: transparent;
    --msv-gift-card-balance-error-border: transparent;
}

.ms-gift-card-balance-check {
    max-width: 600px;
    padding-bottom: 32px;
    padding-top: 32px;
    padding-left: 1em;
    padding-right: 1em;
    background-color: #e6edf1;
}

.ms-gift-card-balance-check__form {
    flex-flow: row wrap;
    display: flex;
}

.ms-gift-card-balance-check__input-label,
.ms-gift-card-balance-check__input-pin-label,
.ms-gift-card-balance-check__input-exp-label {
    font-weight: var(--msv-font-weight-bold);
    font-style: normal;
    color: #004f71;
    font-size: 1.125rem;
    line-height: 1.3em;
    display: block;
    margin-bottom: 8px;
    flex-basis: 100%;
}

.ms-gift-card-balance-check__input-text {
    font-size: var(--msv-gift-card-balance-check-font-size);
    width: 100%;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    line-height: 20px;
    background: #fff;
    border: 0.5px solid #c4c4c4;
    box-sizing: border-box;
    display: block;
    height: 52px;
    outline: none;
    padding: 14px;
    width: 100%;
    -moz-appearance: textfield;
    padding: 6px 8px;
}

.ms-gift-card-balance-check__input-text::placeholder {
    color: #595959;
}

.ms-gift-card-balance-check__input-text:focus {
    filter: drop-shadow(0 0 4px var(--msv-form-control-box-color));
    border: 0.5px solid #004f71;
    outline: none;
}

.ms-gift-card-balance-check__input-text:active {
    border: 0.5px solid #004f71;
    box-shadow: 4px 4px 10px var(--msv-form-control-box-color);
}

.ms-gift-card-balance-check__input-fields {
    width: 100%;
    display: inline-block;
    flex-grow: 1;
}

.ms-gift-card-balance-check__input-pin-num-text {
    font-weight: 400;
    font-style: normal;
    font-size: var(--msv-body-font-size-m);
    line-height: var(--msv-body-line-height-m);
    color: var(--msv-body-font-color);
    width: 100%;
}

.ms-gift-card-balance-check__input-pin-text,
.ms-gift-card-balance-check__input-exp-text {
    font-size: var(--msv-gift-card-balance-check-font-size);
    display: block;
    margin-top: 4px;
    min-width: 120px;
    width: 100%;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    line-height: 20px;
    background: #fff;
    border: 0.5px solid #c4c4c4;
    box-sizing: border-box;
    display: block;
    height: 52px;
    outline: none;
    padding: 14px;
    width: 100%;
    -moz-appearance: textfield;
}

.ms-gift-card-balance-check__input-pin-text::placeholder,
.ms-gift-card-balance-check__input-exp-text::placeholder {
    color: #595959;
}

.ms-gift-card-balance-check__input-pin-text:focus,
.ms-gift-card-balance-check__input-exp-text:focus {
    filter: drop-shadow(0 0 4px var(--msv-form-control-box-color));
    border: 0.5px solid #004f71;
    outline: none;
}

.ms-gift-card-balance-check__input-pin-text:active,
.ms-gift-card-balance-check__input-exp-text:active {
    border: 0.5px solid #004f71;
    box-shadow: 4px 4px 10px var(--msv-form-control-box-color);
}

.ms-gift-card-balance-check__input-pin-text::placeholder,
.ms-gift-card-balance-check__input-exp-text::placeholder {
    color: #d1d1d1;
}

.ms-gift-card-balance-check__input-alert-label {
    font-weight: normal;
    font-size: 1em;
    line-height: 28px;
    text-transform: none;
    color: #2c262d;
    font-style: normal;
    margin-bottom: 4px;
    display: block;
}

.ms-gift-card-balance-check__input-pin-fields,
.ms-gift-card-balance-check__input-exp-fields {
    display: inline-block;
    margin-top: 20px;
    min-width: 130px;
    width: 25%;
}

.ms-gift-card-balance-check__input-pin-fields {
    padding-right: 20px;
}

.ms-gift-card-balance-check__btn-apply {
    background-color: #004f71;
    color: #fff;
    border: 0;
    padding: 1rem 1.25rem;
    font-family: 'DIN 2014', sans-serif;
    font-size: 1rem !important;
    text-transform: uppercase;
    font-weight: 500;
    outline: none;
    transition: all 0.25s;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.25rem;
    transition: all 0.25s;
    margin-top: 20px;
    order: 3;
}

.ms-gift-card-balance-check__btn-apply:hover,
.ms-gift-card-balance-check__btn-apply:focus {
    color: #fff;
    background: #002b3e;
    text-decoration: none;
}

.ms-gift-card-balance-check__btn-apply:disabled,
.ms-gift-card-balance-check__btn-apply[disabled] {
    cursor: default;
    background-color: rgba(0, 79, 113, 0.4);
    border-color: transparent;
    color: var(--msv-font-secondary-color);
}

.ms-gift-card-balance-check__input-error {
    width: 100%;
    display: flex;
    order: 2;
    font-weight: 400;
    font-style: normal;
    font-size: var(--msv-body-font-size-s);
    line-height: var(--msv-body-line-height-s);
    color: var(--msv-body-font-color);
    color: var(--msv-gift-card-balance-error-color);
    border: 1px solid var(--msv-gift-card-balance-error-bg);
    background-color: var(--msv-gift-card-balance-error-border);
    margin-top: 13px;
}

.ms-gift-card-balance-check__item {
    margin-top: 25px;
}

.ms-gift-card-balance-check__item-text {
    font-weight: var(--msv-font-weight-normal);
    font-size: 16px;
    line-height: 24px;
    width: 60%;
}

@media screen and (min-width: 992px) {
    .ms-gift-card-balance-check__input-text {
        flex-grow: 1;
    }

    .ms-gift-card-balance-check__input-fields {
        width: 100%;
    }

    .ms-gift-card-balance-check__input-fields .ms-checkout-gift-card__input-num-label {
        display: flex;
    }

    .ms-gift-card-balance-check__input-fields .ms-checkout-gift-card__input-text {
        width: 100%;
    }

    .ms-gift-card-balance-check__input-num-fields {
        display: inline-block;
        width: 80%;
        padding-right: 20px;
    }

    .ms-gift-card-balance-check__input-num-fields .ms-checkout-gift-card__input-label {
        display: block;
    }

    .ms-gift-card-balance-check__btn-apply {
        margin-left: 0;
        margin-top: 20px;
        width: auto;
    }
}
