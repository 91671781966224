:root {
    --msv-product-collection-font-family: #{$msv-primary-font-family};
    --msv-product-collection-font-size: var(--msv-body-font-size-m);
}

.ms-product-collection {
    padding-top: $spacer-xl;
    padding-bottom: 0;

    &.separator_sub {
        border-bottom: 1px solid $primary;
    }

    &__heading {
        color: $primary;
    }

    &__items {
        @extend %row;
    }

    &__item {
        @extend %basic-product-card;
        @extend %col;
        @extend %col-12;
        display: inline-block;
        max-width: none;
        padding: $layout-gutter/2;
        text-align: left;
        vertical-align: top;

        .msc-add-to-cart {
            bottom: $layout-gutter * 1.5;
            right: $layout-gutter * 1.5;
        }

        @media (max-width: 370px) {
            flex: 0 0 100%;
            max-width: 100% !important;
        }

        @media (min-width: 370px) {
            flex: 0 0 70%;
            max-width: 70% !important;
        }

        @media (min-width: 470px) {
            flex: 0 0 50%;
            max-width: 50% !important;
        }

        @include media-breakpoint-up(sm) {
            flex: 0 0 40%;
            max-width: 40% !important;
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 33.33%;
            max-width: 33.33% !important;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 24%;
            max-width: 24% !important;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 19%;
            max-width: 19% !important;
        }
    }

    .msc-ss-carousel {
        padding: 0 $layout-gutter * 2;
        margin-bottom: $spacer * 2;
        overflow: hidden;

        // @media (max-width: $msv-breakpoint-m) {
        // 	overflow: hidden;

        // }

        .msc-ss-carousel__flipper {
            z-index: 5;

            background: linear-gradient(
                to left,
                hsla(0, 0%, 100%, 0) 0%,
                hsla(0, 0%, 100%, 0.023) 5.8%,
                hsla(0, 0%, 100%, 0.056) 10.7%,
                hsla(0, 0%, 100%, 0.097) 14.8%,
                hsla(0, 0%, 100%, 0.147) 18.5%,
                hsla(0, 0%, 100%, 0.204) 21.9%,
                hsla(0, 0%, 100%, 0.267) 25.4%,
                hsla(0, 0%, 100%, 0.336) 29.2%,
                hsla(0, 0%, 100%, 0.41) 33.5%,
                hsla(0, 0%, 100%, 0.488) 38.6%,
                hsla(0, 0%, 100%, 0.57) 44.7%,
                hsla(0, 0%, 100%, 0.654) 52.2%,
                hsla(0, 0%, 100%, 0.74) 61.2%,
                hsla(0, 0%, 100%, 0.826) 72%,
                hsla(0, 0%, 100%, 0.913) 84.8%,
                hsl(0, 0%, 100%) 100%
            );
        }

        .msc-ss-carousel__flipper--next {
            right: 0px;

            background: linear-gradient(
                to right,
                hsla(0, 0%, 100%, 0) 0%,
                hsla(0, 0%, 100%, 0.023) 5.8%,
                hsla(0, 0%, 100%, 0.056) 10.7%,
                hsla(0, 0%, 100%, 0.097) 14.8%,
                hsla(0, 0%, 100%, 0.147) 18.5%,
                hsla(0, 0%, 100%, 0.204) 21.9%,
                hsla(0, 0%, 100%, 0.267) 25.4%,
                hsla(0, 0%, 100%, 0.336) 29.2%,
                hsla(0, 0%, 100%, 0.41) 33.5%,
                hsla(0, 0%, 100%, 0.488) 38.6%,
                hsla(0, 0%, 100%, 0.57) 44.7%,
                hsla(0, 0%, 100%, 0.654) 52.2%,
                hsla(0, 0%, 100%, 0.74) 61.2%,
                hsla(0, 0%, 100%, 0.826) 72%,
                hsla(0, 0%, 100%, 0.913) 84.8%,
                hsl(0, 0%, 100%) 100%
            );
        }

        .msc-flipper {
            height: 100%;
            margin: 0;

            // &:disabled {
            // 	opacity: 0;
            // }
        }

        .msc-ss-carousel-strip {
            overflow: visible;

            // @media (max-width: $msv-breakpoint-m) {
            // 	overflow: visible;
            // }
        }
    }

    .msc-rating__count {
        border-bottom: none;
    }

    .msc-tooltip {
        display: none;
        // left: 0;
        // position: absolute;
        // top: 0;

        .msc-arrow {
            display: none;
        }

        .msc-tooltip-inner {
            display: none;
            // background-color: $secondary;
            // color: $white;
            // font-size: $msv-font-size-s;
            // font-weight: $msv-font-weight-bold;
            // margin-top: 0;
            // max-width: 200px;
            // padding: .5rem $layout-gutter;
            // text-align: center;
        }
    }
}

.vnc-titleBestseller-dp {
    & .ms-product-collection__heading {
        text-transform: capitalize;
        text-align: left;
    }
}
