$msv-rating-count-border: 1px solid $msv-black;
$msv-rating-margin-right: 10px;
$msv-rating-margin-right-mobile: 5px;
$msv-rating-range-star-font-size: var(--msv-body-font-size-m);

$rating-container-width: 50%;
$rating-container-padding-left: 50%;

@media (min-width: 992px) {
    & .rating_ctn {
        & .col-12 {
            margin-left: $rating-container-width;
            width: $rating-container-width;
            max-width: $rating-container-width;
            padding-right: 5px;
        }
    }
}
@media screen and (max-width: 768px) {
    .rating_ctn {
        padding: 0 $spacer-l $section-v-space--xl 1rem !important;
    }
}

.rating_ctn {
    /* background: rgba($primary, 0.1); */
    padding: 0 $spacer-l $section-v-space--xl 0;

    & .col-12 {
        & .ms-write-review.msc-modal-input-required {
            & .ms-write-review__sign-in-btn.msc-btn {
                padding-left: 0;
                color: $secondary;
                background: transparent;
                text-decoration: underline;
            }
        }
        & .ms-write-review.msc-btn:focus {
            color: $secondary;
            background: transparent;
            text-decoration: underline;
        }
    }
    h4.ms-ratings-histogram__heading {
        color: $blue;
        font-family: $secondary-font-family;
        font-weight: $msv-font-weight-heavy;
        font-size: $msv-font-size-l;
        text-transform: uppercase;
        margin-bottom: 0 !important;
    }

    & .ms-ratings-histogram {
        padding-right: 0 !important;

        & .ms-ratings-histogram__histogram {
            margin-right: 0;

            & .ms-ratings-histogram__bar {
                height: 5px;
                background-color: #e5edf1;

                & .msc-progress__bar {
                    height: 5px;
                }
            }
        }

        & .ms-ratings-histogram__item {
            width: 100%;
            padding: 0;
            pointer-events: none;
        }
    }

    & .msc-average-rating-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: $msv-primary-font-family;
        margin-bottom: 1.5rem;

        & .value {
            color: $blue;
            font-weight: $msv-font-weight-normal;
            line-height: $msv-title-line-height-xs;
            &__avg {
                font-size: $msv-title-font-size-s;
                line-height: $msv-title-line-height-s;
                font-weight: $msv-font-weight-light;
            }
        }

        & .stars {
            color: $blue;
            font-weight: $msv-font-weight-normal;
            line-height: $msv-title-line-height-xs;
            &__ratings {
                margin-right: 10px;
            }
        }
    }
}
.msc-rating {
    position: relative;

    &__range {
        background: transparent;
        border: 0 none;
        color: transparent;
        top: 0;
        left: 0;
        opacity: 0;
        overflow: visible;
        position: absolute;
        width: 100%;
        z-index: -1;

        &:focus {
            + .msc-rating__group {
                border: 1px dashed $white;
                outline: 1px dashed $black;
            }
        }
    }
    &__star {
        @include add-icon-icomoon($star-full);
        color: $primary;
        display: inline-block;
    }
    &__half-star {
        @include add-icon-icomoon($star-half, after);
        color: $primary;
        display: inline-block;
        position: relative;
        &.heading {
            color: $blue;
            font-size: 20px;
        }
    }
    &__empty-star {
        @include add-icon-icomoon($star);
        color: $primary;
    }
    &__star,
    &__half-star,
    &__empty-star {
        margin-right: $msv-rating-margin-right;
        @media (max-width: 992px) {
            margin-right: $msv-rating-margin-right-mobile;
            font-size: 12px;
        }
        &.heading {
            color: $blue;
            font-size: 20px;
            @media (max-width: 992px) {
                font-size: 12px;
            }
        }
    }
    &__count {
        display: inline;
        font-size: var(--msv-body-font-size-m);

        &:focus {
            outline: 0;
        }
    }
    &__group {
        display: inline-block;
    }
}
