$msv-store-locator-phone-section-padding-top: 5px;
$msv-store-locator-location-line-item-shop-address-padding-top: 2px;
$msv-store-locator-location-line-item-shop-address-padding-right: 15px;
$msv-store-locator-input-height: 48px;
$msv-store-locator-button-border-radius: 0px;
$msv-store-locator-input-border-radius: 2px 0 0 2px;
$msv-store-locator-input-padding-left: 12px;
$msv-store-locator-button-height: 52px;
$msv-store-locator-button-width: 52px;
$msv-store-locator-search-form-margin-bottom: 30px;
$msv-store-locator-search-form-margin: 15px 0 5px 0;
$msv-store-locator-location-line-item-margin-top: 10px;
$msv-breakpoint-modal-m: 450px;
$msv-store-locator-msc-modal-content-padding: 40px;

$msv-store-locate-selected-background-color: $secondary-10;

$msv-store-locator-height-m: 650px;

$msv-store-locate-heading-font-color: var(--msv-font-primary-color);
$msv-store-locate-heading-font-size: $msv-font-size-xl;

$msv-store-locate-form-bg: $msv-white;

//body title
$msv-store-locate-title-font-size: var(--msv-body-font-size-l);
$msv-store-locate-title-font-color: var(--msv-accent-brand-color);

//body text
$msv-store-locate-text-font-size: var(--msv-body-font-size-m);
$msv-store-locate-text-font-color: var(--msv-font-primary-color);

//Link
$msv-store-locate-link-size: var(--msv-body-font-size-s);
$msv-store-locate-link-color: var(--msv-font-primary-color);

$msv-store-locate-divider-color: $border-separator-color;

// primary button
$msv-store-locate-primary-btn-bg: var(--msv-accent-brand-color);
$msv-store-locate-primary-btn-font-color: var(--msv-font-secondary-color);
$msv-store-locate-primary-btn-border: var(--msv-accent-brand-color);

.ms-store-locate-venchi-row {
    display: flex;
    margin: 0 -15px;
    width: 100%;
}

.ms-store-locate-venchi {
    height: calc(100vh - 14rem);
    top: 0;
    bottom: 0;
    left: 0;
    // display: flex;
    // flex-direction: column;
    border-bottom: $border-separator;

    // &__map-only-search-section{
    //     position: sticky;
    //     top: 3rem;
    //     z-index: 200;
    // }

    .sr-only {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }

    .ms-store-select-venchi {
        &__header {
            display: none;
        }

        &__search {
            // display: flex;
            // flex-direction: column;
            flex-shrink: 0;
            min-height: 58px;
            position: relative;
            z-index: 1;
            background: #fff;

            &:after {
                border-bottom: 1px solid rgba(0, 79, 113, 0.5);
                content: '';
                width: 100%;
                display: block;
                position: absolute;
                height: 10px;
                background: #fff;
                bottom: 0;
                z-index: 1;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px -1px, rgba(0, 0, 0, 0.06) 0px 1px 2px -1px;
            }

            &-form {
                flex: auto;
                display: flex;
                height: 2.5rem;
                margin-bottom: $spacer;
            }

            &-input {
                flex: auto;
                font-size: $msv-font-size-l;
                line-height: 2rem;
                min-height: 2.5rem;
                margin-top: 0;
                margin-bottom: 0;
                height: 2.5rem;
            }

            &-button {
                @include add-icon-icomoon($search);
                @extend %solid-button;
                display: flex;
                align-items: center;
                order: 2;
                margin: 0;
                min-height: 2.5rem;
                height: 2.5rem;
                width: 2.5rem;
                padding: 1rem 0.75rem;
            }

            &-retailers-result-section {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 10px;

                input[type='checkbox'] ~ *:nth-child(2) {
                    align-items: center;
                }
            }

            .search-btn-disabled {
                background-color: $secondary-20;
            }

            &-see-all-stores {
                display: none;
            }
        }

        &__found-locations-listmsg {
            display: none;
        }

        &__body {
            // display: flex;
            // flex-grow: 1;
            // flex-direction: column;
            height: 100%;

            &_wrapper {
                height: 100%;
            }
        }

        &__store_container {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__store-hours-details {
            font-size: 1.125rem;

            .ms-store-locate-venchi__store-hours-day {
                padding-right: 0.5rem;
            }
        }

        &__toggle-view {
            display: none;
        }

        &__locations {
            border: none;
            overflow-y: auto;
            flex-grow: 1;
            position: relative;
            scroll-behavior: smooth;
            @include scrollbar-light;
        }

        &__location {
            border-top: $border-separator;

            &:first-of-type {
                border: none;
            }

            &.selected {
                .ms-store-select-venchi__location-line-item {
                    background-color: $primary-10;

                    &.location-type__dealer {
                        background-color: $secondary-10;
                    }
                }
            }

            &-line-item {
                padding: $spacer-m $spacer-m $spacer-s $spacer-m;

                &-content {
                    display: flex;
                    flex-wrap: wrap;
                }

                &-contact-info {
                    flex: 1 1 45%;
                    margin: 0 0 15px 0;
                    padding-right: 1rem;
                }

                &-store-hours {
                    flex: 1 0 50%;
                    margin: 0 0 15px 0;
                    text-wrap: nowrap;
                }

                &-set-as-preferred-store,
                &-preferred-store {
                    background-color: transparent;
                    border: none;
                    color: $secondary;
                    display: block;
                    font-family: $msv-primary-font-family;
                    cursor: pointer;
                    font-weight: $msv-font-weight-bold;
                    margin-right: $spacer-l;
                    margin-left: 4.2rem;
                    outline: none;
                    padding: 0;
                    text-align: left;
                    width: fit-content;
                    z-index: 0;

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }

                &-set-as-preferred-store {
                    @include add-icon-icomoon($store-prefered, before, true, 0.5rem);
                }

                &-preferred-store {
                    @include add-icon-icomoon($store-prefered-selected, before, true, 0.5rem);
                }

                .go-to-store {
                    display: block;
                    font-size: $msv-font-size-m;
                    padding-top: 0.1em;
                    margin: 1rem 2rem 0 3.95rem;
                    width: 100%;
                }
            }

            &-line-item-header,
            &-line-item-stock-status {
                margin-bottom: 1rem;
            }

            &-line-item-header.venchistore {
                color: $primary;
            }

            &-line-item-header.retailerstore {
                color: $secondary;
            }

            &-line-item-store-name {
                font-size: 1.25rem;
                font-weight: $msv-font-weight-bold;
                margin-bottom: 0;
            }

            &-line-item-store-index {
                text-transform: uppercase;
            }

            &-line-item-store-intro {
                font-size: $msv-font-size-l;
                margin-bottom: 0;
            }

            &-line-item-additional-header {
                color: $msv-black;
                font-size: 1.25rem;
                font-weight: $msv-font-weight-bold;
                margin-bottom: 0.25rem;
            }

            &-line-item-shop-address {
                &-section {
                    display: flex;
                    font-size: 1.125rem;
                    margin-bottom: 4px;
                    //TODO REMOVE
                    //flex-wrap: wrap;
                }

                &-glyph-icon {
                    @include add-icon-adventure($msv-adventure-location);
                    padding-right: 1rem;
                }

                &-text {
                    white-space: pre-line;
                    overflow-wrap: break-word; //safari
                    word-break: break-word; //safari
                    overflow-wrap: anywhere; // others
                    width: auto;
                    flex-shrink: 1;
                    flex-grow: 1;
                }
            }

            .location-type__dealer {
                .ms-store-select-venchi__location-line-item-shop-address-glyph-icon {
                    // @include add-icon-icomoon($marker);

                    &:before {
                        margin-left: -1.25rem;
                    }
                }
            }

            &-line-item-shop-phone {
                &-section {
                    display: flex;
                    font-size: 1.125rem;
                    margin-bottom: 4px;
                }

                &-icon {
                    @include add-icon-adventure($msv-adventure-Phone);
                    padding-right: 1rem;
                }
            }

            &-line-item-shop-storepageurl {
                &-section {
                    display: flex;
                    font-size: 1.125rem;
                    margin-bottom: 4px;

                    a {
                        font-weight: 400;
                    }
                }

                &-icon {
                    @include add-icon-adventure($msv-adventure-webbrowser);
                    padding-right: 1rem;
                }
            }

            &-line-item-locate-store {
                @include primary-button(
                    $msv-store-locate-primary-btn-bg,
                    $msv-store-locate-primary-btn-font-color,
                    $msv-store-locate-primary-btn-border
                );
                @include vfi();
                @include font-content($msv-font-weight-normal, $msv-store-locate-title-font-size, $msv-line-height-l);
            }
        }
    }

    abbr {
        text-decoration: none;
    }
}

.ms-store-select-venchi {
    &__footer-map {
        display: none;
    }

    &__terms-link {
        @include font-content($msv-font-weight-normal, $msv-store-locate-link-size, $msv-line-height-s);
        @include vfi();
        color: $msv-store-locate-link-color;
        cursor: pointer;
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-store-locate-venchi {
        height: auto;
        border: none;

        .ms-store-select-venchi {
            &__search {
                top: 3rem;
                position: sticky;
                padding-top: 1rem;

                .ms-store-select-venchi__found-locations {
                    display: none;
                }
            }

            &__found-locations-listmsg {
                display: block;
                width: 100%;
                padding: 1rem 0;
                font-size: 1.125rem;
            }

            &__locations {
                display: none;
                border-bottom: $border-separator;

                &.show {
                    display: block;
                }

                &__location {
                    &-line-item {
                        &-set-as-preferred-store,
                        &-preferred-store {
                            margin-top: 0.5rem;
                            margin-bottom: 0.25rem;
                            margin-left: 0.25rem;
                        }

                        .go-to-store {
                            margin-left: 0;
                        }
                    }
                }
            }

            &__toggle-view {
                display: block;
                cursor: pointer;
                color: $secondary;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }

                background-color: transparent;
                border: none;
                outline: none;
            }

            &__toggle-view.view-map {
                @include add-icon-adventure($msv-adventure-Shop-Address, after);

                &::after {
                    font-size: 1rem;
                    vertical-align: text-top;
                    padding-left: 0.5rem;
                }
            }

            &__toggle-view.list-view {
                @include add-icon-adventure($msv-adventure-list-line, after);

                &::after {
                    font-size: 1.125rem;
                    vertical-align: text-top;
                    padding-left: 0.5rem;
                }
            }

            &__footer.map-only-view {
                display: none;
            }
        }
    }

    .ms-store-select-venchi__footer-map.map-only-view {
        display: block;
    }
}

/* Note: "!important" are necessary since switching from views of map load some JS everytime and update the DOM with new css */
.ms-store-select-venchi__map_container {
    width: 100%;

    .ms-map {
        height: calc(100vh - 14rem);
        width: 100%;

        // input[type="checkbox"] {
        //     position: relative;
        // }

        // input[type="checkbox"]~*:nth-child(2):before {
        //     content: none;
        // }
    }
}

@media screen and (min-width: $msv-breakpoint-m) {
    .ms-store-select-venchi__map__selected_store__modal.show {
        display: none;
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-store-select-venchi__map__selected_store__body {
        padding: $spacer-m $spacer-m $spacer-s $spacer-m;

        abbr {
            text-decoration: none;
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
        }

        &-contact-info {
            flex: 1 1 45%;
            margin: 0 0 15px 0;
            padding-right: 1rem;
        }

        &-store-hours {
            flex: 1 0 50%;
            margin: 0 0 15px 0;
            text-wrap: nowrap;
        }

        &-set-as-preferred-store,
        &-preferred-store {
            background-color: transparent;
            border: none;
            color: $secondary;
            display: block;
            font-family: $msv-primary-font-family;
            cursor: pointer;
            font-weight: $msv-font-weight-bold;
            margin-right: $spacer-l;
            margin-left: 4.2rem;
            outline: none;
            padding: 0;
            text-align: left;
            width: fit-content;
            z-index: 0;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        &-set-as-preferred-store {
            @include add-icon-icomoon($store-prefered, before, true, 0.5rem);
        }

        &-preferred-store {
            @include add-icon-icomoon($store-prefered-selected, before, true, 0.5rem);
        }

        .go-to-store {
            display: block;
            font-size: $msv-font-size-m;
            padding-top: 0.1em;
            margin: 1rem 2rem 0 3.95rem;
            width: 100%;
        }

        &-header,
        &-stock-status {
            margin-bottom: 1rem;
        }

        &-header.venchistore {
            color: $primary;
        }

        &-header.retailerstore {
            color: $secondary;
        }

        &-store-name {
            font-size: 1.25rem;
            font-weight: $msv-font-weight-bold;
            margin-bottom: 0;
        }

        &-store-index {
            text-transform: uppercase;
        }

        &-store-intro {
            font-size: $msv-font-size-l;
            margin-bottom: 0;
        }

        &-additional-header {
            color: $msv-black;
            font-size: 1.25rem;
            font-weight: $msv-font-weight-bold;
            margin-bottom: 0.25rem;
        }

        &-shop-address {
            &-section {
                display: flex;
                font-size: 1.125rem;
                margin-bottom: 4px;
                //TODO REMOVE
                //flex-wrap: wrap;
            }

            &-glyph-icon {
                @include add-icon-adventure($msv-adventure-location);
                padding-right: 1rem;
            }

            &-text {
                white-space: pre-line;
                overflow-wrap: break-word; //safari
                word-break: break-word; //safari
                overflow-wrap: anywhere; // others
                width: auto;
                flex-shrink: 1;
                flex-grow: 1;
            }
        }

        &-shop-phone {
            &-section {
                display: flex;
                font-size: 1.125rem;
                margin-bottom: 4px;
            }

            &-icon {
                @include add-icon-adventure($msv-adventure-Phone);
                padding-right: 1rem;
            }
        }

        &-shop-storepageurl {
            &-section {
                display: flex;
                font-size: 1.125rem;
                margin-bottom: 4px;

                a {
                    font-weight: 400;
                }
            }

            &-icon {
                @include add-icon-adventure($msv-adventure-webbrowser);
                padding-right: 1rem;
            }
        }

        &-locate-store {
            @include primary-button(
                $msv-store-locate-primary-btn-bg,
                $msv-store-locate-primary-btn-font-color,
                $msv-store-locate-primary-btn-border
            );
            @include vfi();
            @include font-content($msv-font-weight-normal, $msv-store-locate-title-font-size, $msv-line-height-l);
        }
    }

    .ms-store-locate-venchi &.ms-store-select-venchi__locations {
        min-height: 80vh;
    }

    &.ms-store-select-venchi__location-line-item {
        &-set-as-preferred-store,
        &-preferred-store {
            margin-top: 0.5rem;
            margin-bottom: 0.25rem;
            margin-left: 0.25rem;
        }

        .go-to-store {
            margin-left: 0;
        }
    }

    .ms-store-select-venchi__map_container {
        .ms-map {
            display: none;

            &.show {
                height: calc(min(60vh, 100vh - 25rem));
                display: block;
            }
        }
    }
}
