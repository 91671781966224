.msc-greetings {
    border-bottom: $border-separator;
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;
    padding-bottom: $spacer;

    h4 {
        @include add-icon-icomoon($more, after);
        cursor: pointer;
        display: flex;

        &:after {
            justify-self: flex-end;
            margin-left: auto;
        }
        span {
            margin-right: $layout-gutter;
        }
    }
    .greetings_ctn {
        display: none;
    }
    &.is {
        &--editing,
        &--compiled {
            h4:after {
                content: '\e90c';
            }
            .greetings_ctn {
                display: block;
            }
        }
        &--editing {
            form {
                display: block;
            }
            .msc-greetings_compiled {
                display: none;
            }
        }
        &--compiled {
            form {
                display: none;
            }
            .msc-greetings_compiled {
                display: block;

                > div {
                    overflow-wrap: break-word; //safari
                    word-break: break-word; //safari
                    overflow-wrap: anywhere; //others
                }
            }
        }
    }
    &_compiled {
        display: none;
        font-size: 1.25rem;

        &_actions {
            display: flex;
            justify-content: flex-end;

            button {
                @extend %hyperlink;
                margin-left: $layout-gutter;
            }
        }
        label {
            color: $secondary;
            font-weight: $msv-font-weight-bold;
        }
    }
}
.msc-greetings-form {
    padding-bottom: $spacer;

    label {
        display: block;
        margin-bottom: 4px;
    }
    input,
    textarea,
    select {
        display: block;
        font-size: $msv-font-size-l;
        outline: none;
        width: 100%;
    }
    &__item {
        margin-bottom: $spacer;
    }
    &__actions_ctn {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacer;
    }
    [type='submit'] {
        @include solid-button($secondary, true);

        + .msc-alert {
            display: inline-block;
        }
        &.status__success {
            @include add-icon-icomoon($check);
            background: $msv-success-color;

            span {
                display: none;
            }
        }
    }
    .cancel {
        @extend %hyperlink;
    }
}
