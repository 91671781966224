html {
    scroll-behavior: smooth;
}

#timeline {
    margin-left: -15px;
    margin-right: -15px;

    ul {
        padding: 1em 2em;
        display: flex;
        justify-content: space-between;
        gap: 1em;
        flex-flow: column;
        overflow: hidden;
        width: 100%;

        @media screen and (min-width: $msv-breakpoint-m) {
            padding: 3em 2em;
            width: 94%;
            flex-flow: row;
            overflow: hidden;
            margin: 1em auto;
        }

        .arrow {
            display: inline-block;
            position: absolute;
            width: 1em;
            left: 4em;
            top: 4%;
            height: 90%;
            background-color: $primary;
            pointer-events: none;
            z-index: 1;

            @media screen and (min-width: $msv-breakpoint-m) {
                width: 92%;
                height: 1em;
                top: calc(50% - 0.5em);
                bottom: 2em;
                left: 4%;
            }

            &:after {
                border-top: 1em solid $primary;
                border-bottom: 1em solid transparent;
                border-left: 1em solid transparent;
                border-right: 1em solid transparent;
                content: '';
                position: absolute;
                left: -0.5em;
                bottom: -2em;
                width: 0;
                height: 0;
                z-index: 2;

                @media screen and (min-width: $msv-breakpoint-m) {
                    border-top: 1em solid transparent;
                    border-bottom: 1em solid transparent;
                    border-left: 1em solid $primary;
                    border-right: 1em solid transparent;
                    left: auto;
                    right: -1.5em;
                    bottom: -0.5em;
                }
            }
        }

        li {
            all: unset;
            cursor: pointer;
            border: 1px solid black;
            border-radius: 1em;
            padding: 1em;
            transition: ease-in-out 0.15s;
            flex: 1 0 3em;
            z-index: 3;
            background-color: white;
            display: flex;
            align-items: center;
            gap: 1em;

            @media screen and (min-width: $msv-breakpoint-m) {
                flex: 1 0 100px;
            }

            div {
                background-size: 50px;
                display: inline-block;
                width: 50px;
                height: 50px;
                border-radius: 50px;
                background-color: pink;
                flex: 0 0 50px;
            }

            a {
                all: unset;
                width: 70%;
                vertical-align: middle;
                overflow: hidden;
                display: flex;
                flex-direction: column;

                span {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
                .timeline-item-heading {
                    font-weight: 500;
                }
            }
            &:hover {
                box-shadow: 0 0 0 1px black;
                transition: linear 0.15s;
            }
        }
    }
}
