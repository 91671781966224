.ms-social-share {
    margin-top: $spacer-l;

    &-ul {
        display: flex;
        list-style: none;
        justify-content: end;

        &.caption::before {
            color: $secondary;
            content: attr(data-title);
            display: block;
            margin-right: $layout-gutter;
            padding-top: 0.3rem;
            text-transform: uppercase;
        }
        &.vertical {
            display: block;

            li {
                margin-bottom: $spacer;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
        &:not(.vertical) {
            li {
                height: 26px;
                margin-right: $layout-gutter;
                position: relative;

                &:hover,
                &:focus {
                    cursor: pointer;

                    &:before {
                        color: mix($secondary, #000000, 80%);
                    }
                }
                &:before {
                    color: $secondary;
                    font-size: $msv-font-size-xl;
                    margin-top: -3px;
                }
                &:first-of-type {
                    @include add-icon-icomoon($facebook);

                    & + .ms-social-share-li {
                        @include add-icon-icomoon($twitter);
                        margin-right: 0;
                    }
                }
            }
        }
    }
    &-item {
        color: $secondary;
        overflow: hidden;
        height: 1.5rem;
        width: 1.6rem;

        img {
            opacity: 0;
            position: absolute;
            top: 0;
        }
    }
}
