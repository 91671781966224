.ms-breadcrumb {
    // padding-top:$spacer;
    // padding-bottom: $spacer;

    .ms-breadcrumb_list {
        float: left;
        list-style: none;
        padding: 0;
    }
    .ms-breadcrumb_item {
        float: left;
        position: relative;
        margin: 0 4px 0 0;
        a {
            color: $secondary;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            &[data-no-click='true'] {
                cursor: default;
                pointer-events: none;
            }
        }

        [aria-label='Root'] {
            display: none;
            + span {
                display: none;
            }
        }
    }
}
