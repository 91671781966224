.ms-accordion {
    padding-top: $spacer-l;
    padding-bottom: $spacer-l;

    &-header-section-toggle-button-container {
        @include media-breakpoint-down(md) {
            text-align: center;
        }
        button {
            @include hyperlink($primary);
            @include a-reverse;
            font-size: 1.25rem;
            margin: $spacer;

            &:last-child {
                margin-right: 0;
            }
        }
        .ms-accordion_ExpandAll,
        .ms-accordion_CollapseAll {
            background: transparent;
            border: none;
            cursor: pointer;
        }
    }
    @media (min-width: $msv-breakpoint-m) {
        &-header-section {
            display: flex;

            &-container {
                flex-grow: 4;
            }
        }
    }
    &-item {
        &__drawer {
            border-top: $border-separator;
            .drawer__button {
                background: transparent;
                color: $secondary;
                cursor: pointer;
                display: flex;
                font-size: 1.25rem;
                font-weight: $msv-font-weight-bold;
                justify-content: space-between;
                padding: $spacer 0;
                width: 100%;

                &[aria-expanded='true'] {
                    @include add-icon-icomoon($less, after);

                    &:after {
                        font-size: $msv-font-size-m;
                        line-height: 1.8em;
                    }
                }
                &[aria-expanded='false'] {
                    @include add-icon-icomoon($more, after);

                    &:after {
                        font-size: $msv-font-size-m;
                        line-height: 1.8em;
                    }
                }
            }
        }
        &-content {
            padding: 0 0 $spacer-l 0;
        }
    }
}
