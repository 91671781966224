.ms-cart {
    display: flex;
    flex-wrap: wrap;
    padding-top: $section-space;

    .msc-cart {
        &__btn-checkout {
            @include solid-button;
        }
        &__btn-checkout,
        &__btn-guestcheckout,
        &__btn-backtoshopping {
            margin-bottom: $spacer;
            width: 100%;
        }
        &__btn-guestcheckout {
            @extend %border-button;
        }
        &__btn-backtoshopping {
            @extend %hyperlink;
            text-shadow: 0 1px 0 mix($secondary, $white, 10%);
            width: auto;
            margin: auto;
            display: inline-block;
            z-index: 1;
        }
    }
    .row {
        flex-grow: 1;
    }
    .msc-cart__heading {
        flex: 0 0 100%;
    }
    .msc-cartline-wraper {
        flex: 1;
        margin-bottom: $spacer;
        .msc-cart__promotion {
            background: $secondary-10;
            padding: 1.5rem;
            color: $secondary;
            line-height: 1.3em;
            font-size: $msv-font-size-l;
            span:nth-child(2) {
                font-weight: var(--msv-font-weight-bold);
            }
        }
        & .msc-cart-lines-item {
            & .msc-cart-line__product-quantity {
                & .quantity__controls {
                    border: none;
                }
            }
        }
    }
    .msc-cart-line {
        position: relative;

        &__extra-data {
            display: none;
        }

        &.product-type {
            &__pick-mix {
                .msc-cart-line__product-quantity {
                    display: none !important;
                }
                + .msc-cart-line__add-to-wishlist {
                    display: none !important;
                }
            }
        }
        &__product-image {
            position: absolute;

            .msc-empty_image {
                @include image-placeholder(183px);
            }
        }
        &__bopis-container {
            margin-bottom: 0.5rem;
        }
        &__content,
        &__bopis-container,
        &__extra-actions {
            margin-left: 200px;
        }
    }
    .msc-order-summary-wrapper {
        .msc-express-payment-container-in-cart-page {
            .ms-checkout-express__add {
                .checkout-express__iframe {
                    border: none;
                    width: 100%;
                    max-width: 500px;
                    height: 80px;
                }
            }
        }
    }
    .msc-promo-code {
        &__form-container {
            margin-bottom: $spacer;
        }
        &__input-box {
            font-size: $msv-font-size-l;
        }
    }

    @include media-breakpoint-up(xl) {
        .msc-order-summary-column {
            position: sticky;
            top: 10rem;
            align-self: flex-start;
        }
    }
}
@media screen and (max-width: 767px) {
    .ms-cart {
        display: block;

        .msc-cartline-wraper {
            margin-right: 0px;
            width: 100%;
        }
        .msc-cart-line {
            &__content {
                display: block;
                margin-left: 100px;
            }
            &__bopis-container {
                margin-left: 111px;
                padding-top: 0;
                text-align: left;
            }
            &__extra-actions {
                margin-left: 111px;
            }
            &__add-to-wishlist {
                margin-left: 0.25rem;
            }
            &__remove-item {
                margin-left: 0.5rem;
            }
            &__product {
                padding-bottom: 10px;

                &-quantity {
                    padding-bottom: 10px;
                }
                &-savings {
                    margin-left: 0px;
                    text-align: left;
                }
                &-price {
                    margin-left: 0;
                    padding-top: 10px;
                    text-align: left;
                }
            }
        }
        .msc-promo-code {
            &__input-box {
                width: 170px;
            }
            &__apply-btn {
                min-width: auto;
            }
        }
        .msc-order-summary-wrapper {
            width: 100%;
        }
    }
}
@media screen and (max-width: 450px) {
    .ms-cart .msc-cart-line__product-image .msc-empty_image {
        @include image-placeholder(80px);
    }
}
