$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;

.msc-add-to-cart {
    @extend %solid-button;
    @include add-icon-icomoon($cart-btn, after, $next-to-text: true);

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}
.msc-modal .msc-add-to-cart__dialog {
    max-width: $msv-dialog-width;

    .msc-modal__content {
        padding: $msv-dialog-modal-content-padding;
    }
    &__header {
        .msc-modal__title {
            @include font-content-l();

            span {
                &:nth-child(1) {
                    font-weight: var(--msv-font-weight-bold);
                }
            }
        }
    }
    &__body {
        @include font-content-l();
        text-align: center;

        .msc-empty_image {
            @include image-placeholder($msv-dialog-msc-empty_image-size);
            padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
        }
    }
    &__image {
        margin-right: auto;
        margin-left: auto;
    }
    &__product-title {
        @extend %h3;
        text-align: center;
        font-size: $msv-font-size-xl;
    }
    &__product-variants {
        @include font-content-m();
        padding: $msv-dialog-modal-content-body-text-padding;
    }
    &__product-price {
        font-weight: $msv-font-weight-normal;
        margin-bottom: $spacer;
    }
    &__go-to-cart {
        @extend %solid-button;
        margin: $msv-dialog-modal-content-button-margin;
        width: $msv-dialog-button-width;
    }
    &__back-to-shopping {
        @extend %hyperlink;
        margin: $msv-dialog-modal-content-button-margin;
        width: $msv-dialog-button-width;
    }
}
