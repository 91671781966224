.loyalty-store-sign-up-form {
    display: flex;
    justify-content: center;

    form {
        max-width: calc(min(100%, 800px));
    }

    .btn-disabled {
        background-color: #bee3f4 !important;
    }

    .prominent-fields {
        border: 1px solid $secondary;
        // TODO Replace with secondary light (from the theme) or whatever it was called
        background-color: #effaff;
        padding-top: 0.75rem;
        margin-bottom: 1rem;
        font-weight: 400;
        padding-left: 0.75rem;
        padding-right: 0.75rem;

        .thirdparty {
            font-weight: 300;
        }

        // .dotcon,
        // .email-error {
        //     padding: 0.25rem;
        //     font-size: 1.125rem;
        // }

        // .dotcon {
        //     border: 1px solid yellow;
        //     background-color: lightgoldenrodyellow;
        // }

        // US ONLY

        .phone-div {
            display: flex;
            align-items: stretch;
            max-width: 100%;

            .phone-prefix {
                background-color: #f8f8f8;
                border: 1px solid $secondary;
                border-right: 0;
                padding: 0 1rem;
                display: flex;
                align-items: center;

                span {
                    font-size: 1.25rem;
                }
            }

            input {
                border-left: 0;
                flex: 1;
                width: 0;
                // padding-left: 0.5rem;
            }
        }
    }

    .phone-error,
    .email-error {
        padding: 0.25rem;
        font-size: 1.125rem;
        border: 1px solid lightcoral;
        background-color: #ffcccb; // light red
    }

    .secondary-fields {
        padding-top: 1rem;
        margin-bottom: 1rem;

        h4 {
            font-weight: 300;
            font-size: 1.25rem;

            @media screen and (min-width: 576px) {
                padding-left: 1rem;
            }
        }

        .row label,
        .row input {
            font-size: 1.125rem;
            max-width: 100%;
        }
    }

    .optional-subtext {
        font-size: 14px;
        font-weight: 300;
    }

    .loyalty-store-signup-thankyou {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }

    .row {
        display: flex;
        flex-direction: column;

        // @media screen and (max-width: 1199px) {
        //     flex-direction: column;
        // }

        // @media screen and (min-width: 1200px) {
        //     flex-direction: row;
        //     align-items: baseline;
        // }

        @media screen and (max-width: 575px) {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
        }

        @media screen and (min-width: 576px) and (max-width: 767px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 1rem;
        }

        @media screen and (min-width: 768px) {
            padding-left: 3rem;
            padding-right: 3rem;
            padding-bottom: 2rem;
        }

        label {
            font-size: 1.25rem;
            color: $secondary;
        }

        select,
        option,
        input {
            font-size: 1.25rem;
        }

        .agreements {
            font-size: 1.125rem;
            color: black;
        }

        .mandatory-agreement-div {
            padding-top: 1.5rem;
        }

        button {
            background-color: $secondary;
            color: white;
            border: 0;
            padding: 1rem 1.25rem;
            font-family: 'DIN 2014', sans-serif;
            font-size: 1.25rem;
            text-transform: uppercase;
            font-weight: 500;
            outline: none;
            transition: all 0.25s;
            text-decoration: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
        }
    }

    .row .phone-number-div {
        padding-bottom: 1rem;
    }

    .msc-alert {
        text-align: center;
        font-size: 1.5rem;

        &-error {
            background-color: #fdedec;
            color: firebrick;
        }
        &-generic {
            background-color: #e6edf1;
            color: black;
        }
        &-success {
            background-color: #eafaf1;
            color: darkgreen;
        }
    }

    .loyalty-store-signup-success-container,
    .wallet-buttons-container,
    .barcode-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .loyalty-store-signup-success-container,
    .wallet-buttons-container {
        gap: 20px;
    }

    .wallet-buttons-container {
        flex-direction: row;
        margin-block-start: 20px;
    }

    .enrollment-date-container,
    .coupon-code-container,
    .barcode-container {
        text-align: center;
    }

    .enrollment-date-container {
        p,
        time {
            font-size: 0.9em;
        }
    }

    .qr-code-image {
        max-height: '100%';
        box-sizing: 'border-box';
        display: 'block';
        border-radius: 0;
        width: '115px';
        max-width: '100%';
    }

    .loyalty-card-number,
    .coupon-code,
    .enrollment-date {
        font-weight: 500;
    }

    .barcode-image {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }

    .wallet-button {
        width: 50%;
        text-align: center;
    }

    .wallet-button-link {
        text-decoration: 'none';
        font-family: 'Arial,Verdana,sans-serif';
        font-size: 'inherit';
        font-weight: 400;
        color: '#0082dd';
    }

    .wallet-button-image {
        text-decoration: 'none';
        border: 0;
        max-height: '100%';
        box-sizing: 'border-box';
        display: 'block';
        border-radius: 0;
        margin-left: 'auto';
        margin-right: 0;
        max-width: '100% !important';
    }
}
