:root {
    --msv-sign-in-font-size: var(--msv-body-font-size-m);
    --msv-sign-in-heading-font-size: var(--msv-body-font-size-xl);
    --msv-sign-in-heading-font-color: #{$msv-gray-900};
    --msv-sign-in-error-bg: var(--msv-error-color);
    --msv-sign-in-error-font-color: var(--msv-font-secondary-color);
    --msv-sign-in-error-border: var(--msv-error-color);
    --msv-sign-in-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-sign-in-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-sign-in-primary-btn-border: var(--msv-accent-brand-color);
}

$padding-x: 60px; // horizontal spacing (padding right left, ...)

.ms-sign-in {
    label {
        font-size: $msv-font-size-l;
    }
    input {
        font-size: $msv-font-size-l;
    }
    &__container {
        display: flex;
        margin-top: $spacer-xl;
        margin-bottom: $spacer-xl;
    }
    &__social-account-picture {
        display: inline-block;
        vertical-align: middle;
    }
    &__sign-in-heading,
    &__sign-up-heading {
        @extend %h3;
        color: $secondary;
        text-align: left;
    }

    &__sign-in-section {
        padding: 0;

        @media screen and (min-width: $msv-breakpoint-l) {
            padding: 0 $padding-x;
            margin: 0;
        }
    }
    &__sign-up-section {
        border-left: $border-separator;
        display: flex;
        flex-direction: column;
        padding: 0;

        @media screen and (min-width: $msv-breakpoint-l) {
            padding: 0 $padding-x;
            margin: 0;
        }
    }

    &__vclub-section {
        border-left: $border-separator;
        display: flex;
        flex-direction: column;
        padding: 0;

        @media screen and (min-width: $msv-breakpoint-l) {
            padding: 0 $padding-x;
            margin: 0;
        }
    }

    &__account-item {
        margin-bottom: $spacer;

        &-input {
            @include form-input-el();
            width: 100%;
        }
    }
    &__forget-password {
        @extend %hyperlink;
        display: inline-block;
        margin-bottom: $spacer;
    }
    &__sign-in-button {
        @extend %solid-button;
        width: 100%;
    }
    &__sign-up-link {
        @extend %solid-button;
        width: 100%;
    }
    &__sign-up-description {
        color: $secondary;
        flex-grow: 1;
        font-weight: $msv-font-weight-bold;
        margin-bottom: $spacer;
    }
    &__sign-up-link {
        text-align: center;
    }
    &__page-error {
        color: $red;
        display: none;
        padding: $spacer 0;
    }
    .msc-cart__btn-guestcheckout {
        // @extend %border-button;
        text-align: center;
        margin-bottom: $spacer;
        font-size: 1.125rem;
        margin-top: $spacer;
        width: 100%;
    }
    @media screen and (max-width: $msv-breakpoint-l) {
        &__container {
            display: block;
            margin: 1.25rem;
        }
        &__sign-in-section {
            max-width: 400px;
            margin: 0 auto;
            padding-bottom: $spacer-xl;
        }
        &__vclub-section {
            max-width: 400px;
            margin: 0 auto;
            border-top: $border-separator;
            border-left: none;
            padding-top: 3rem;
            padding-bottom: $spacer-xl;
        }
        &__sign-up-section {
            max-width: 400px;
            margin: 0 auto;
            border-top: $border-separator;
            border-left: none;
            padding-top: 3rem;
        }
    }

    // 768px fix
    @media screen and (width: $msv-breakpoint-m) {
        &__sign-up-section {
            padding-left: 15px;
        }
    }
}
