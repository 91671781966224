.ms-order-confirmation {
    @extend .col-xl-9;
    padding-top: $section-space;
    padding-bottom: $section-space;

    &__order-information {
        &-channel-reference-id {
            display: block;
            font-size: 1.25rem;
            font-weight: $msv-font-weight-bold;
            margin-bottom: $spacer;

            span {
                display: block;
                font-size: 2rem;
                font-weight: $msv-font-weight-light;
            }
        }
        &-receipt-email {
            display: block;
            font-size: 1.25rem;
            margin-bottom: $spacer;

            span {
                font-weight: $msv-font-weight-bold;
            }
        }
        &-sales-id {
            padding-right: 0.5rem;
        }
        &-created-date {
            display: none;
        }
        &-count {
            border-right: 1px solid $msv-black;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
        &-amount {
            padding-left: 0.5rem;
        }
    }
    &__btn-keep-shopping {
        @extend %hyperlink;
        margin-bottom: $spacer;
    }
    &__sales-lines {
        @include make-col-ready();
        @extend .col-md-6;
        @extend .offset-md-1;

        &:first-child {
            border-top: none;
        }
        &-shipping-items-label {
            display: none;
        }
        &-emailing-items-label {
            display: none;
        }
    }
    &__groups {
        border-top: 4px solid $secondary;
        border-bottom: 4px solid $secondary;
        margin-bottom: $spacer-l;
    }
    &__group {
        &-deliveryType-ship,
        &-deliveryType-email {
            @include make-row();
            display: flex;
            flex-wrap: wrap;
            padding: $spacer-l 0;
        }
        &-delivery {
            @include make-col-ready();
            @extend .col-md-5;
            font-size: 1.25rem;
            padding-right: $layout-gutter;

            &-heading {
                color: $secondary;
                font-size: 2rem;
                margin-right: 5px;
            }
            &-total-items {
                color: $secondary;
                font-size: 2rem;
            }
            &-processing-label {
                display: block;
            }
        }
        &:first-child {
            .ms-order-details__sales-lines {
                border-top: none;
            }
        }
    }
    &__tracking-link {
        text-decoration: underline;
    }
    &__sales-line {
        padding: $spacer-l 0;

        &:not(:last-child) {
            border-bottom: $border-separator;
        }
        .msc-cart-line {
            &__product {
                flex: none;
            }
            &__product-image img {
                height: 147px;
                max-width: 147px;
                width: 147px;
            }
            &__content {
                display: flex;
                flex: auto;
                flex-direction: column;
                position: relative;
            }
            &__product-title {
                @extend %h3;
                min-height: 0;
            }
            &__quantity {
                order: 10;
                font-size: 1.25rem;
                padding-left: $layout-gutter;
                padding-right: $layout-gutter;
                padding-top: $spacer;
            }
            &__product-savings {
                text-align: left;
                width: auto;

                &-actual {
                    min-height: 0;
                    margin-bottom: 0;

                    span {
                        display: inline-block;
                        font-size: $msv-font-size-xl;
                        font-weight: $msv-font-weight-normal;
                    }
                }
            }
            &-item-product-discount {
                display: none;
                color: var(--msv-success-color);
            }
            &-btn-buy-it-again {
                margin-top: 20px;
                width: 140px;
                @include secondary-button();
            }
        }
    }
    &__order-summary {
        background: $secondary-10;
        color: $secondary;
        padding: $spacer;

        @include media-breakpoint-up(lg) {
            float: right;
            padding: $spacer-xl;
            width: 44%;
        }
        &-heading {
            @extend %h3;
            color: $secondary;
            font-size: 2rem;
        }
        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            font-size: $msv-font-size-l;

            span:nth-child(2) {
                font-weight: $msv-font-weight-bold;
            }
        }
        &-line-tax-amount {
            display: none;

            html[lang='en-US'] &,
            html[lang="en-GB"] [data-plat*="chanId:5637153578"] + body & /*doesn't work in local, but uat and prod*/ {
                display: block;
            }
        }
        &-line-total-amount {
            border-top: $border-separator;
            font-size: $msv-font-size-l;
            font-weight: $msv-font-weight-normal;
            margin-bottom: 0;
            padding-top: $spacer;

            span:nth-child(2) {
                font-size: $msv-font-size-xl;
            }
        }
        &-line-earned-points {
            .ms-order-details__order-summary-label {
                font-weight: var(--msv-font-weight-bold);
            }
        }
        &-price {
            float: right;
        }
    }
    &__help {
        float: left;
        margin-top: 1.25rem;

        &-title {
            margin-bottom: 0.5rem;
        }
        &-content-number {
            @include vfi();
        }
    }
    &__payment-methods {
        margin-top: $spacer-l;
        margin-bottom: $spacer-l;

        @include media-breakpoint-up(lg) {
            float: left;
            padding-top: $spacer;
            width: 50%;
        }
        &-title {
            @extend %h3;
            color: $secondary;
            font-size: 2rem;
        }
        &-line {
            margin-bottom: 0.5rem;
        }
        &-loyalty-amount,
        &-gift-card-amount {
            margin-left: 4px;
        }
    }
    &__address {
        margin-top: $spacer;

        &-header {
            display: none;
        }
        &-name {
            font-weight: $msv-font-weight-bold;
        }
        &-name,
        &-phone {
            display: block;
        }
    }
    &__alert {
        display: block;
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
    }
    @media screen and (max-width: $msv-breakpoint-m) {
        &__group {
            display: block;

            &_delivery {
                padding-right: 0;
            }
        }
        &__sales-lines {
            width: 100%;
        }
        &__order-summary,
        &__payment-methods,
        &__help {
            float: none;
            width: 100%;
            position: relative;
            margin-left: 0;
        }
        .msc-cart-line__product-price {
            position: unset;
        }
    }
    .msc-empty_image {
        @include image-placeholder(147px);
    }
}
