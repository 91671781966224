:root {
    --msv-ratings-histogram-heading-font-size: var(--msv-body-font-size-xl);
    --msv-ratings-histogram-heading-font-color: #{$msv-gray-900};
    --msv-ratings-histogram-progress-bg: #{$primary-20};
    --msv-ratings-histogram-progress-bar-bg: #{$primary};
}
.msc-average-rating-container {
    width: 100%;
}
.ms-ratings-histogram {
    margin-bottom: 1.25rem;
    padding-left: $layout-gutter;
    padding-right: $layout-gutter;

    &.-uppercase {
        text-transform: uppercase;
    }

    & .msc-average-rating-container {
        &.hidden {
            display: none;
        }
        & .stars {
            text-align: right;
            padding-top: 14px;
            @media (max-width: 992px) {
                max-width: 50%;
                padding-top: 0;
                line-height: 23px;
            }
        }
        & .stars__ratings {
            text-transform: capitalize;
        }
    }

    &.modal-summary {
        padding-left: 0;
        & h4 {
            display: none;
        }
        & .msc-average-rating-container {
            margin-bottom: 0.5rem;
        }
        & .ms-ratings-histogram__histogram {
            @media screen and (max-width: $msv-breakpoint-m) {
                display: none;
            }
        }
    }

    .msc-progress {
        background-color: var(--msv-ratings-histogram-progress-bg);
        flex-grow: 1;
        height: 0.5rem;
        margin: 0 0.5rem;
        position: relative;
        width: 100%;

        @media screen and (max-width: $msv-breakpoint-m) {
            width: 50%;
        }
        @include media-breakpoint-up(sm) {
            width: 200px;
        }
        &__bar {
            background-color: var(--msv-ratings-histogram-progress-bar-bg);
            height: 0.5rem;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .msc-rating {
        display: inline-block;
    }
    &__histogram {
        margin-right: 1.25rem;
    }
    &__average {
        display: inline-block;
    }
    &__total {
        margin-top: 0.75rem;
    }
    &__item {
        background-color: transparent;
        border: 0;
        border-color: transparent;
        color: $primary;
        display: flex;
        align-items: center;
        font-family: $primary-font-family;
        font-size: $msv-font-size-m;
        font-weight: $msv-font-weight-bold;
        margin-bottom: $spacer/2;

        &:hover {
            cursor: pointer;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    &__star {
        @include add-icon($msv-FavoriteStarFill, after);
        display: flex;
        line-height: 1;
        text-align: right;
        width: 4rem;

        &:after {
            color: $yellow;
            margin-left: 0.25rem;
        }
    }
    &__percentage {
        text-align: right;
        width: 2rem;
    }
}
