%move-down-animation {
    animation-name: icon-hang-sink, icon-hang;
    animation-duration: 0.3s, 1.5s;
    animation-delay: 0s, 0.3s;
    animation-timing-function: ease-out, ease-in-out;
    animation-iteration-count: 1, infinite;
    animation-fill-mode: forwards;
    animation-direction: normal, alternate;
}
@keyframes icon-hang {
    0% {
        transform: translateY(0.3em);
    }
    50% {
        transform: translateY(0.1em);
    }
    100% {
        transform: translateY(0.3em);
    }
}

@keyframes icon-hang-sink {
    100% {
        transform: translateY(0.3em);
    }
}

%move-down-animation-once {
    animation-name: icon-hang-sink;
    animation-duration: 0.3s;
    animation-delay: 0s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-direction: normal;
}

%move-up-animation {
    animation-name: icon-hang-sink-up, icon-hang-up;
    animation-duration: 0.3s, 1.5s;
    animation-delay: 0s, 0.3s;
    animation-timing-function: ease-out, ease-in-out;
    animation-iteration-count: 1, infinite;
    animation-fill-mode: forwards;
    animation-direction: normal, alternate;
}
@keyframes icon-hang-up {
    0% {
        transform: translateY(-0.3em);
    }
    50% {
        transform: translateY(-0.1em);
    }
    100% {
        transform: translateY(-0.3em);
    }
}

@keyframes icon-hang-sink-up {
    100% {
        transform: translateY(-0.3em);
    }
}

%underline-link-animation-before-expanded {
    position: relative;
    &:before {
        content: '';
        display: block;
        height: 2px;
        width: 0;
        transition: width 0.3s cubic-bezier(0, 0.37, 0.4, 1);
        will-change: width;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    &:hover,
    &:focus,
    &[aria-expanded='true'] {
        &:before {
            width: 100%;
        }
    }
}

%underline-link-animation-before-not-expanded {
    position: relative;
    &:before {
        content: '';
        display: block;
        height: 2px;
        width: 0;
        transition: width 0.3s cubic-bezier(0, 0.37, 0.4, 1);
        will-change: width;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    &:hover,
    &:focus {
        &:before {
            width: 100%;
        }
    }
    &[aria-expanded='true'] {
        &:before {
            width: 0;
        }
    }
}

%underline-link-animation-before {
    position: relative;
    &:before {
        content: '';
        display: block;
        height: 2px;
        width: 0;
        transition: width 0.3s cubic-bezier(0, 0.37, 0.4, 1);
        will-change: width;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    &:hover,
    &:focus {
        &:before {
            width: 100%;
        }
    }
}
%underline-link-animation-after {
    position: relative;
    &:after {
        content: '';
        display: block;
        height: 2px;
        width: 0;
        transition: width 0.3s cubic-bezier(0, 0.37, 0.4, 1);
        will-change: width;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    &:hover,
    &:focus {
        &:after {
            width: 100%;
        }
    }
}
@mixin underline-link-animation($color: $orange, $height: 2px, $selector: before) {
    @extend %underline-link-animation-#{$selector};
    &:#{$selector} {
        background: $color;
        height: $height;
    }
}

@mixin underline-link-animation-expanded($color: $orange, $height: 2px, $selector: before) {
    @extend %underline-link-animation-#{$selector}-expanded;
    &:#{$selector} {
        background: $color;
        height: $height;
    }
}

@mixin underline-link-animation-not-expanded($color: $orange, $height: 2px, $selector: before) {
    @extend %underline-link-animation-#{$selector}-not-expanded;
    &:#{$selector} {
        background: $color;
        height: $height;
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}
