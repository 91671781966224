// Base class
$tooltip-arrow-color: $secondary;
$tooltip-opacity: 1;
$tooltip-bg: $secondary;

// Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
// So reset our font and text properties to avoid inheriting weird values.
// Allow breaking very long words so they don't overflow the tooltip's bounds
.msc-tooltip {
    display: block;
    margin: $tooltip-margin;
    position: absolute;
    opacity: 0;
    word-wrap: break-word;
    z-index: $zindex-tooltip;

    @media screen and (max-width: $msv-breakpoint-l) {
        // Since we've added a custom tooltip, we display:none the original one in case
        // for some reason it would appear on some devices
        display: none;
    }
    &.show {
        opacity: $tooltip-opacity;
    }
    .msc-arrow {
        display: block;
        height: $tooltip-arrow-height;
        position: absolute;
        width: $tooltip-arrow-width;

        &::before {
            border-color: transparent;
            border-style: solid;
            position: absolute;
            content: '';
        }
    }
}
.msc-bs-tooltip-top {
    padding: $tooltip-arrow-height 0;

    .msc-arrow {
        bottom: 0;

        &::before {
            border-top-color: $tooltip-arrow-color;
            border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
            top: 0;
        }
    }
}
.msc-bs-tooltip-right {
    padding: 0 $tooltip-arrow-height;

    .msc-arrow {
        height: $tooltip-arrow-width;
        left: 0;
        width: $tooltip-arrow-height;

        &::before {
            border-right-color: $tooltip-arrow-color;
            border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
            right: 0;
        }
    }
}
.msc-bs-tooltip-bottom {
    padding: $tooltip-arrow-height 0;

    .msc-arrow {
        top: 0;

        &::before {
            border-bottom-color: $tooltip-arrow-color;
            border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
            bottom: 0;
        }
    }
}
.msc-bs-tooltip-left {
    padding: 0 $tooltip-arrow-height;

    .msc-arrow {
        height: $tooltip-arrow-width;
        right: 0;
        width: $tooltip-arrow-height;

        &::before {
            border-left-color: $tooltip-arrow-color;
            border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
            left: 0;
        }
    }
}
.msc-bs-tooltip-auto {
    &[x-placement^='top'] {
        @extend .msc-bs-tooltip-top;
    }
    &[x-placement^='right'] {
        @extend .msc-bs-tooltip-right;
    }
    &[x-placement^='bottom'] {
        @extend .msc-bs-tooltip-bottom;
    }
    &[x-placement^='left'] {
        @extend .msc-bs-tooltip-left;
    }
}
// Wrapper for the tooltip content
.msc-tooltip-inner,
.custom-mobile-tooltip {
    @include border-radius($tooltip-border-radius);
    background-color: $tooltip-bg;
    color: $tooltip-color;
    font-size: $msv-font-size-s;
    font-weight: $msv-font-weight-bold;
    max-width: $tooltip-max-width;
    padding: $tooltip-padding-y $tooltip-padding-x;
    text-align: center;
}
.custom-mobile-tooltip {
    min-width: 40px;
    position: absolute;
    top: -35px;
    left: -14px;

    &::before {
        border-color: transparent;
        border-style: solid;
        border-top-color: $tooltip-arrow-color;
        border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
        content: '';
        position: absolute;
        bottom: -5px;
        left: 14px;
    }
    @include media-breakpoint-up(lg) {
        display: none;
    }
}
