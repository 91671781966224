.ms-content-block {
    &[data-m-layout='left-right'] {
        display: flex;
        align-items: flex-start;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }
        .ms-content-block__image {
            width: 100%;
        }
        h2 {
            text-align: left;
        }
        .ms-content-block__details {
            font-weight: $msv-font-weight-normal;
            margin-top: 3rem;
            position: relative;
            width: 100%;
            z-index: 1;

            @include media-breakpoint-up(md) {
                margin-left: -16.66666%;
                padding: $spacer-xl;
                width: 33.333333%;
            }
            p {
                font-size: $msv-font-size-l;
            }
        }
        .ms-content-block__image {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: 83.333333%;
            }
        }
        &.imagefit__cover {
            align-items: unset;

            .ms-content-block__image img {
                height: 100%;
                object-fit: cover;
            }
            .ms-content-block__details {
                margin-bottom: 2rem;
            }
        }
        &.textimageproportions {
            &__half {
                .ms-content-block__image {
                    @include media-breakpoint-up(md) {
                        width: 66.66666%;
                    }
                }
                .ms-content-block__details {
                    @include media-breakpoint-up(md) {
                        width: 50%;
                    }
                }
            }
            &__t_wide_i_wide {
                .ms-content-block__details {
                    @include media-breakpoint-up(md) {
                        margin-left: -36.66666%;
                        width: 53.66666%;
                    }
                }
                .ms-content-block__image {
                    @include media-breakpoint-up(md) {
                        width: 83.66666%;
                    }
                }
            }
        }
        .ms-content-block__cta {
            display: flex;
            align-items: flex-end;
        }
        &.textplacement__right .ms-content-block__details .card-cta {
            justify-content: flex-start;
        }
        &.textplacement__left {
            .ms-content-block__details {
                margin-left: 0;

                .card-cta {
                    justify-content: flex-end;
                }
            }
            .ms-content-block__image {
                @include media-breakpoint-up(md) {
                    margin-left: -16.66666%;
                    order: 2;
                    width: 83.333333%;
                }
                p {
                    font-size: $msv-font-size-l;
                }
            }
            &.textimageproportions {
                &__half {
                    .ms-content-block__image {
                        @include media-breakpoint-up(md) {
                            width: 66.66666%;
                        }
                    }
                    .ms-content-block__details {
                        @include media-breakpoint-up(md) {
                            margin-left: 0;
                            width: 50%;
                        }
                    }
                }
                &__t_wide_i_wide {
                    .ms-content-block__details {
                        @include media-breakpoint-up(md) {
                            margin-left: 0;
                            width: 53.66666%;
                        }
                    }
                    .ms-content-block__image {
                        @include media-breakpoint-up(md) {
                            margin-left: -36.66666%;
                            width: 83.66666%;
                        }
                    }
                }
            }
        }
        &.textplacement__center {
            .ms-content-block__details {
                text-align: center;

                .card-cta {
                    justify-content: center;
                }
            }
        }
        .ms-content-block__image img {
            width: 100%;
        }
        /* body should have top margin only when there's something above it */
        * + .ms-content-block__text {
            margin-top: 21px;
        }
        /* links should have top margin only when there's something above it */
        * + .ms-content-block__cta {
            margin-top: $spacer-l;
        }
        @media screen and (max-width: $msv-breakpoint-m) {
            .ms-content-block__details {
                margin-top: 21px;
            }
            .ms-content-block__image img {
                width: 100%;
            }
        }
    }
}
