.shipping-block {
    @media (max-width: $msv-container-max-width-m) {
        table {
            display: flex;
            flex-direction: column;
            margin-right: -0.5rem;
            margin-left: -0.5rem;
            width: calc(100% + 1rem);

            tr {
                display: flex;
                justify-content: space-between;

                td {
                    flex-basis: 25%;
                }
            }
        }
    }
}
