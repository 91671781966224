:root {
    --msv-checkout-delivery-options-font-size: var(--msv-body-font-size-m);
    --msv-checkout-delivery-options-font-color: #{msv-gray-900};
}
.ms-checkout-delivery-options {
    &__price {
        @include font-content(
            $font-weight: var(--msv-font-weight-normal),
            $font-size: var(--msv-checkout-delivery-options-font-size),
            $line-height: $msv-line-height-m
        );
        color: var(--msv-checkout-delivery-options-font-color);
        margin-left: auto;
    }
    &__description {
        @include font-content(
            $font-weight: var(--msv-font-weight-normal),
            $font-size: var(--msv-checkout-delivery-options-font-size),
            $line-height: $msv-line-height-m
        );
        color: var(--msv-checkout-delivery-options-font-color);
    }
    &__option {
        color: $secondary;
        display: flex;
        align-items: center;
        margin-bottom: $spacer;
        position: relative;

        &-selected {
            .ms-checkout-delivery-options__price {
                margin-left: 12px;
            }
            .ms-checkout-delivery-options__description {
                margin-left: 0;
            }
        }
        > * {
            min-height: 1.5rem;
            margin-top: -3px;
        }
        input {
            margin-top: -1.5em;

            &[type='radio'] ~ :nth-child(2) {
                margin-left: 2rem;
            }
        }
        span:before {
            position: absolute;
            top: -0.4em;
            left: 0;
        }
    }
    @media screen and (min-width: $msv-breakpoint-l) {
        &__description {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        &__price {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
            margin-left: 1.25rem;
        }
    }
}
