:root {
    --msv-order-summary-bg: var(--msv-bg-color);
    --msv-order-summary-heading-font-color: #{$msv-gray-900};
    --msv-order-summary-heading-font-size: var(--msv-body-font-size-xl);
}
.msc-order-summary {
    margin-bottom: $spacer;
    padding: $spacer;

    &-wrapper {
        background-color: mix($secondary, $white, 10%);
        color: $secondary;
        padding: $spacer;
        text-align: center;

        > *:last-child {
            margin-bottom: 0;
        }
        @include media-breakpoint-up(md) {
            padding: $spacer-xl;
            margin-bottom: 0;
        }
    }
    @media screen and (max-width: $msv-breakpoint-xs) {
        margin: unset;
    }
    &__heading {
        color: $secondary;
        font-size: 2rem;
        text-align: left;
        text-transform: none;
    }
    &__items {
        font-size: $msv-font-size-l;
        text-align: left;
        width: 100%;

        p {
            font-size: $msv-font-size-l;

            &[class*='-summary__line'] {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &.msc-order-summary__line-tax-amount {
                display: none;

                html[lang="en-US"] &,
				html[lang="en-GB"] [data-plat*="chanId:5637153578"] + body & /*doesn't work in local, but uat and prod*/ {
                    display: block;
                }
            }
        }
        .msc-promo-code {
            &-heading {
                margin-bottom: $spacer/2;
            }
            &__group {
                display: flex;
            }
            &__input-box {
                flex: auto;
            }
        }
    }
    &__line {
        &-total-discounts {
            color: $green;
            font-weight: $msv-font-weight-normal;
        }
        &-total {
            border-top: 1px solid rgba($secondary, 0.5);
            font-weight: $msv-font-weight-normal;
            padding: $spacer 0;
            text-transform: none;

            .msc-order-summary__value {
                font-size: $msv-font-size-xl;
            }
        }
    }
    &__value {
        float: right;
        font-weight: var(--msv-font-weight-bold);
        text-align: right;
    }
}
