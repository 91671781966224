@mixin heading() {
    line-height: 1.2em;
    font-weight: $msv-font-weight-light;
    margin-bottom: $spacer;
    display: block;
    overflow-wrap: break-word; //safari
    word-break: break-word; //safari
    overflow-wrap: anywhere; //others
}
h1,
%h1,
h2,
%h2,
h3,
%h3,
h4,
%h4,
h5,
%h5,
h6,
%h6,
p {
    overflow-wrap: break-word;
    word-break: break-word;
}
strong,
span {
    text-overflow: ellipsis;
}
h1,
%h1 {
    @include heading;
    font-size: 2.5rem;
    color: $primary;
    @include media-breakpoint-down(sm) {
        font-size: 2rem;
    }
}
h2,
%h2 {
    @include heading;
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
    @include media-breakpoint-down(sm) {
        font-size: $msv-font-size-xl;
    }
}
h3,
%h3 {
    @include heading;
    text-transform: none;
    font-size: 2rem;
    @include media-breakpoint-down(sm) {
        font-size: $msv-font-size-xl;
    }
}
h4,
%h4 {
    @include heading;
    font-size: 1.25rem;
    font-weight: $msv-font-weight-bold;
    @include media-breakpoint-down(sm) {
        font-size: 1.15rem;
    }
}
h5,
%h5 {
    @include heading;
    font-size: $msv-font-size-l;
    font-weight: $msv-font-weight-normal;
}
h6,
%h6 {
    @include heading;
    font-size: $msv-font-size-s;
    font-weight: $msv-font-weight-bold;
}
p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    overflow-wrap: break-word; //safari
    word-break: break-word; //safari
    overflow-wrap: anywhere; //others

    &.small {
        font-size: $msv-font-size-m !important;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
strong {
    font-weight: $msv-font-weight-bold;
}
.uppercase {
    text-transform: uppercase;
}

.bigger {
    font-size: $msv-font-size-xl;
}

.text-center {
    text-align: center;
}
