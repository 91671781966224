@import '../00-settings/colors';
@import '../00-settings/font';
@import '../00-settings/responsive-breakpoints';

$msv-btn-side-padding: $spacer $layout-gutter;
$msv-btn-vertical-padding: $spacer $layout-gutter;
$button-font: $secondary-font-family;
$secondary-hover: darken($secondary, 10%);

@mixin text-link($color: $secondary, $underline-position: before) {
    border-color: transparent;
    background-color: transparent;
    color: $color;
    font-family: $primary-font-family;
    font-size: $msv-font-size-m;
    font-weight: $msv-font-weight-bold;
    padding: 0.6rem 0;
    height: auto;
    text-decoration: underline;
    @include underline-link-animation($orange, 2px, $underline-position);
}

@mixin primary-button(
    $bg-color: var(--msv-accent-brand-color),
    $color: var(--msv-font-secondary-color),
    $border-color: var(--msv-accent-brand-color)
) {
    background-color: $bg-color;
    border: 1px solid $border-color;
    color: $color;
    padding: $msv-btn-vertical-padding $msv-btn-side-padding;
    font-family: 'DIN', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    &:hover,
    &:focus {
        background-color: $msv-primary-hover;
        border-color: $msv-primary-hover;
    }

    &:disabled,
    &[disabled] {
        cursor: default;
        border-color: $msv-gray-300;
        background-color: $msv-gray-300;
        color: var(--msv-font-secondary-color);
    }
}

@mixin secondary-button($bg-color: $white, $color: $secondary, $border-color: $secondary, $disableBtn: true) {
    border: 4px solid $border-color;
    color: $color;
    padding: calc(#{$msv-btn-vertical-padding} - 4px) calc(#{$msv-btn-side-padding} - 4px);
    font-family: $button-font;
    text-transform: uppercase;
    font-weight: $msv-font-weight-bold;
    text-align: center;
    &:hover,
    &:focus {
        border-color: $border-color;
        color: $border-color;
    }

    @if $disableBtn {
        &:disabled,
        &[disabled] {
            cursor: default;
            background-color: $msv-gray-300;
            color: var(--msv-font-secondary-color);
        }
    }
}

@mixin border-button($border-color: $secondary, $small: false, $disableBtn: false) {
    border: 4px solid $border-color;
    color: $border-color;
    font-family: $button-font;
    text-transform: uppercase;
    font-weight: $msv-font-weight-bold;
    padding: calc(1rem - 4px);
    font-size: 1.25rem;
    text-decoration: none;
    text-align: center;
    @if $small {
        padding: 0.5rem 1rem;
        font-size: $msv-font-size-m;
    }
    background: transparent;
    transition: all 0.25s;
    &:not(:disabled):hover,
    &:not(:disabled):not(.disabled):active:focus {
        border-color: darken($border-color, 10%);
        color: darken($border-color, 10%);
        cursor: pointer;
        text-decoration: none;
    }
    @if $disableBtn {
        &:disabled,
        &[disabled] {
            cursor: default;
            background: transparent;
            color: lighten($border-color, 10%);
        }
    }
}

@mixin solid-button($bg-color: $secondary, $small: false, $disableBtn: true, $negative: false) {
    background-color: $bg-color;
    color: $white;
    border: 0;
    padding: 1rem 1.25rem;
    font-family: $button-font;
    font-size: 1rem !important;
    text-transform: uppercase;
    font-weight: $msv-font-weight-bold;
    outline: none;
    transition: all 0.25s;
    text-decoration: none;
    cursor: pointer;
    &:hover,
    &:focus {
        color: $white;
        background: darken($bg-color, 10%);
        text-decoration: none;
    }
    @if $negative {
        &:hover,
        &:focus {
            color: darken($bg-color, 10%);
            background: $white;
        }
    }
    font-size: 1.25rem;
    @if $small {
        font-size: $msv-font-size-m;
        padding: 0.5rem 1rem;
    }
    @if $negative {
        background: $white;
        color: $bg-color;
    }
    @if $disableBtn {
        &:disabled,
        &[disabled] {
            cursor: default;
            background-color: rgba($bg-color, 0.4);
            border-color: transparent;
            color: var(--msv-font-secondary-color);
        }
    }
}

@mixin underlined-button($color: $primary, $border-color: $orange, $text-hover: $white) {
    background-color: transparent;
    color: $color;
    border: 0;
    padding: 1rem 0.5rem 1.5rem 0.5rem;
    font-family: $button-font;
    text-transform: uppercase;
    font-weight: $msv-font-weight-bold;
    outline: none;
    transition: all 0.25s;
    text-decoration: none;
    position: relative;
    display: inline-block;
    font-size: 1.25rem;
    transition: all 0.25s;
    &:before {
        content: '';
        background: $border-color;
        height: 0.5rem;
        width: 100%;
        display: block;
        position: absolute;
        bottom: 0.5em;
        left: 0;
        transition: all 0.25s;
        z-index: -1;
    }

    @include add-icon-icomoon($chevron-b, $selector: after, $next-to-text: true, $margin: $layout-gutter/2, $scale: false);
    &:after {
    }
    &:hover,
    &:focus {
        color: $text-hover;
        text-decoration: none;
        &:hover,
        &:focus {
            &:before {
                //background: mix($bg-hover,#000000,10%);
                height: 100%;
                bottom: 0;
            }
        }
    }
}

@mixin hyperlink($color: $secondary, $disableBtn: false) {
    background-color: transparent;
    color: $color;
    border: 0;
    padding: 0;
    font-family: $primary-font-family;
    font-weight: $msv-font-weight-bold;
    outline: none;
    transition: all 0.1s linear;
    display: inline-block;
    text-decoration: underline;
    position: relative;
    cursor: pointer;
    &:hover,
    &:focus {
        //text-decoration-color: $orange;
        text-decoration: none;
    }

    @if $disableBtn {
        &:disabled,
        &[disabled] {
            cursor: default;
            color: lighten($secondary, 20%);
        }
    }
}

@mixin button-default() {
    @include vfi();
    cursor: pointer;
    padding: 1rem 1.25rem;
    line-height: 1.3em;
    text-align: center;
    font-family: $primary-font-family;
}

@mixin button-icon {
    width: 48px;
    height: 48px;
}

@mixin a-reverse {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.more-skewed {
    display: inline-block;
    position: relative;
    color: rgb(77, 146, 44);
    text-decoration: none;
    border-left: solid 2px rgb(201, 42, 42);
    box-sizing: border-box;
    padding: 15px 40px;
    letter-spacing: 3px;
    transition: all 1s ease-out;
    overflow: hidden;
}
.more-skewed:before {
    content: '';
    position: absolute;
    left: -2px;
    top: 0px;
    bottom: 50%;
    border-right: solid 2px rgb(37, 122, 192);
    border-top: solid 2px rgb(156, 202, 70);
    transform: skewX(25deg);
    transform-origin: right bottom;
    width: 100%;
    transition: all 1s ease-out;
    z-index: -1;
}
.more-skewed:after {
    content: '';
    position: absolute;
    left: -2px;
    bottom: 0px;
    top: 50%;
    border-right: solid 2px rgb(143, 212, 160);
    border-bottom: solid 2px rgb(23, 39, 112);
    transform: skewX(-25deg);
    transform-origin: right top;
    width: 100%;
    transition: all 1s ease-out;
    z-index: -1;
}
.more-skewed:hover {
    color: black;
}
.more-skewed:hover:before,
.more-skewed:hover:after {
    background: rgb(51, 223, 208);
}
