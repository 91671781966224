:root {
    --msv-sign-up-font-size: var(--msv-body-font-size-m);
    --msv-sign-up-heading-font-size: var(--msv-body-font-size-xl);
    --msv-sign-up-heading-font-color: #{$msv-gray-900};
    --msv-sign-up-success-alert-bg: #{$msv-gray-300};
    --msv-sign-up-success-alert-font-color: #{$msv-gray-900};
    --msv-sign-up-success-alert-border: #{$msv-gray-900};
    --msv-sign-up-error-alert-bg: var(--msv-error-color);
    --msv-sign-up-error-alert-font-color: var(--msv-font-secondary-color);
    --msv-sign-up-error-alert-border: var(--msv-error-color);
    --msv-sign-up-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-sign-up-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-sign-up-primary-btn-border: var(--msv-accent-brand-color);
    --msv-sign-up-secondary-btn-bg: var(--msv-bg-color);
    --msv-sign-up-secondary-btn-font-color: #{$msv-gray-900};
    --msv-sign-up-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-sign-up {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .region_crm_ctn,
    .lastConsentSource_ctn,
    .ecomSignUpLegalEntity_ctn {
        display: none;
    }

    label {
        font-size: $msv-font-size-l;
        margin-bottom: 4px;
    }

    input {
        font-size: $msv-font-size-l;
    }

    &__container {
        margin-top: $spacer-xl;
        margin-bottom: $spacer-xl;
        width: 40%;
    }

    &__heading {
        @extend %h3;
        color: $secondary;
    }

    &__account-items {
        display: flex;
        flex-direction: column;

        form {
            display: flex;
            flex-direction: column;
        }
    }

    &__account-item {
        &.entry-item {
            display: flex;
            flex-direction: column;
        }

        &-input {
            @include form-input-el();
            width: 100%;
        }

        &-birthday {
            margin-bottom: 0rem;

            input {
                margin-bottom: 0.5rem;
            }

            .hidden {
                display: none;
            }

            p {
                font-size: $msv-font-size-m;
            }
        }

        &-phone_number_field {
            width: 70%;
        }

        &-country_code {
            width: 30%;
        }
    }

    &__page-success,
    &__page-error {
        display: none;
        margin-top: 1.25rem;
        padding: 1.25rem;

        &[aria-hidden='false'] {
            display: block !important;
        }
    }

    &__page-success {
        background-color: var(--msv-sign-up-success-alert-bg);
        color: var(--msv-sign-up-success-alert-font-color);
        border: 1px solid var(--msv-sign-up-success-alert-border);
    }

    &__page-error {
        background-color: var(--msv-sign-up-error-alert-bg);
        border: 1px solid var(--msv-sign-up-error-alert-border);
        color: var(--msv-sign-up-error-alert-font-color);
    }

    &__item-error,
    &__item-error p {
        color: $red;
        font-size: $msv-font-size-l;
        font-weight: $msv-font-weight-normal;
        margin-top: 0.25rem;
        margin-bottom: 0;
        order: 10;
    }

    &__item-error--postal-code,
    &__item-error--postal-code p {
        margin-top: 0;
        order: 0;
    }

    &__item-error-text {
        color: $red;
    }

    #text-error-backup,
    #password-text-error-backup,
    #password-text-error {
        font-weight: 400;
        font-size: 1.125rem;
    }

    &__email-verification-button {
        margin-bottom: $spacer-xl;
    }

    &__email-verification-button,
    &__create-button {
        @extend %solid-button;
        width: 100%;
    }

    &__cancel-button {
        @extend %hyperlink;
        align-self: center;
        margin-top: 1.25rem;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            width: 80%;
        }
    }

    [class^='extra-text'] {
        padding-bottom: 2rem;

        span {
            font-size: 1.125rem;
        }
    }

    &__sign-up-disclaimer {
        padding-bottom: 1rem;
    }

    .privacy_field_ctn {
        padding-bottom: 1rem;

        &.hidden {
            display: none;
        }

        > label {
            [class^='privacy-disclaimer'] {
                display: block;
            }
        }
    }
}

html[lang='en-us'] {
    .ms-sign-up {
        .privacy_field_ctn {
            > label {
                [class^='privacy-disclaimer'] {
                    display: flex;
                }
            }
        }
    }
}
