:root {
    --msv-account-profile-alert-bg: #{$msv-gray-300};
    --msv-account-profile-alert-font-color: #{$msv-gray-900};
    --msv-account-profile-alert-border: #{$msv-gray-900};
}
.ms-account-profile-edit {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: $spacer-xl;
    padding-bottom: $spacer-xl;

    &__container {
        width: 40%;

        @media screen and (max-width: $msv-breakpoint-m) {
            width: 80%;
        }
    }
    &__heading {
        color: $primary;
    }
    &__account-item {
        display: flex;
        flex-direction: column;
        margin-bottom: $spacer;

        &-input {
            @include form-input-el();
            font-size: $msv-font-size-l;
            width: 100%;
        }
    }
    &__page-success {
        background-color: var(msv-account-profile-alert-bg);
        border: 1px solid var(--msv-account-profile-alert-border);
        color: var(--msv-account-profile-alert-font-color);
        display: none;
        margin-top: 20px;
        padding: 20px;
    }
    &__page-error {
        background-color: var(msv-account-profile-alert-bg);
        border: 1px solid var(--msv-account-profile-alert-border);
        color: var(--msv-account-profile-alert-font-color);
        display: none;
        margin-top: 20px;
        padding: 20px;
    }
    &__item-error {
        color: $red;
        order: 10;
    }
    &__save-button {
        @extend %solid-button;
        margin-bottom: $spacer;
        width: 100%;
    }
    &__cancel-button {
        @extend %border-button;
        width: 100%;
    }
    &__verifying-modal,
    &__verifying-modal-message {
        display: none;
    }
}
