$msv-alert-success-color: $msv-text-color;
$msv-alert-success-background-color: $msv-gray-300;

$msv-alert-close-button-color: var(--msv-font-primary-color);
$msv-alert-close-button-right: 10px;
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-border: 1px solid transparent;

$msv-alert-icon: $msv-IncidentTriangle;
$msv-alert-icon-margin: 8px;
$msv-alert-line-margin-top: 8px;

.msc-alert {
    border: 0;
    font-weight: $msv-font-weight-bold;
    margin-bottom: $msv-alert-margin-bottom;
    padding: $msv-alert-padding;
    position: relative;
    text-transform: none;

    &:before {
        color: $msv-alert-close-button-color;
        position: absolute;
        right: $msv-alert-close-button-right;
    }
    .msi-exclamation-triangle {
        @include add-icon-icomoon($error, before, true, 10px);
    }
    .msc-alert__line {
        margin-top: $msv-alert-line-margin-top;
    }
    .close {
        @include add-icon-icomoon($close);
        color: $secondary;
    }
    &__header {
        color: $red;
        padding-top: 5pxr;
        font-weight: $msv-font-weight-bold;
    }
    button.close {
        background-color: transparent;
        border: none;
        position: absolute;
        top: 0.1rem;
        right: 0;
    }
}
.msc-alert-success {
    background-color: $secondary-10;
    color: $secondary;
}
.msc-alert-error {
    background-color: $secondary-10;
    color: $msv-error-color;
}
.msc-alert-danger {
    color: $red;
    font-weight: $msv-font-weight-normal;
    padding: 0.5rem 0;
}
.msc-alert-noborder {
    background-color: transparent;
    border-color: transparent;
    color: var(--msv-error-color);
    margin-bottom: 0;
    padding: 0;
}
