$tags: (
    'Glutenfree': $primary,
    'Palmoilfree': $primary,
    'Veganfriendly': $green,
    'Light': $primary
);
.product-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: $section-v-space--xl;
    margin-bottom: $spacer-m;

    .tag {
        background: transparent no-repeat left;
        background-size: contain;
        color: $primary;
        display: flex;
        align-items: center;
        font-size: $msv-font-size-m;
        font-weight: $msv-font-weight-bold;
        height: 46px;
        margin-right: $layout-gutter;
        margin-bottom: $spacer;
        padding-left: 54px;
        text-transform: uppercase;

        @each $tag, $color in $tags {
            &_#{$tag} {
                background-image: url('../../../images/#{$tag}.png');
                color: $color;
            }
        }
    }
}
