$msv-store-locator-phone-section-padding-top: 5px;
$msv-store-locator-location-line-shop-address-padding-top: 2px;
$msv-store-locator-location-line-shop-address-padding-right: 15px;
$msv-store-locator-input-height: 48px;
$msv-store-locator-button-border-radius: 0px;
$msv-store-locator-input-border-radius: 2px 0 0 2px;
$msv-store-locator-input-padding-left: 12px;
$msv-store-locator-button-height: 52px;
$msv-store-locator-button-width: 52px;
$msv-store-locator-search-form-margin-bottom: 30px;
$msv-store-locator-search-form-margin: 15px 0 5px 0;
$msv-store-locator-location-line-item-margin-top: 10px;
$msv-breakpoint-modal-m: 450px;
$msv-store-locator-msc-modal-content-padding: 40px;

$msv-store-locate-selected-background-color: $secondary-10;

$msv-store-locator-height-m: 650px;

$msv-store-locate-heading-font-color: var(--msv-font-primary-color);
$msv-store-locate-heading-font-size: $msv-font-size-xl;

$msv-store-locate-form-bg: $msv-white;

//body title
$msv-store-locate-title-font-size: var(--msv-body-font-size-l);
$msv-store-locate-title-font-color: var(--msv-accent-brand-color);

//body text
$msv-store-locate-text-font-size: var(--msv-body-font-size-m);
$msv-store-locate-text-font-color: var(--msv-font-primary-color);

//Link
$msv-store-locate-link-size: var(--msv-body-font-size-s);
$msv-store-locate-link-color: var(--msv-font-primary-color);

$msv-store-locate-divider-color: $border-separator-color;

// primary button
$msv-store-locate-primary-btn-bg: var(--msv-accent-brand-color);
$msv-store-locate-primary-btn-font-color: var(--msv-font-secondary-color);
$msv-store-locate-primary-btn-border: var(--msv-accent-brand-color);

.ms-store-locate {
    height: $msv-store-locator-height-m;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    border-bottom: $border-separator;

    .ms-store-select {
        &__header {
            display: none;
        }

        &__body {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            min-height: 0;
        }

        &__search {
            display: flex;
            flex-direction: column;

            &-form {
                flex: auto;
                display: flex;
                margin-bottom: $spacer;
            }

            &-input {
                flex: auto;
                font-size: $msv-font-size-l;
                line-height: 2rem;
                min-height: 58px;
            }

            &-button {
                @include add-icon-icomoon($search);
                @extend %solid-button;
                display: flex;
                align-items: center;
                order: 2;
                margin: 0;
                min-height: 58px;
            }

            .search-btn-disabled {
                background-color: $secondary-20;
            }
        }

        &__store-hours-details {
            display: flex;
            flex-wrap: wrap;

            .ms-store-locate__store-hours-day {
                // flex: 0 0 40%;
                display: inline-block;
            }

            .ms-store-locate__store-hours-time {
                // flex: 0 0 60%;
                display: inline-block;
                &:before {
                    content: ', ';
                }
            }
        }

        &__toggle-view {
            display: none;
            cursor: pointer;
            color: $msv-link-color;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        &__locations {
            border: none;
            overflow-y: auto;
            position: relative;
        }

        &__location {
            padding: $spacer-m 0;
            border-top: $border-separator;

            &:first-of-type {
                border: none;
            }

            &.selected {
                .ms-store-select__location-line-item {
                    background-color: $primary-10;
                    &.location-type__dealer {
                        background-color: $secondary-10;
                    }
                }
            }

            &-line-item {
                padding: $spacer-m;

                &-content {
                    display: flex;
                    flex-wrap: wrap;
                }

                &-contact-info,
                &-store-hours {
                    flex: 0 0 50%;
                    margin: 0 0 15px 0;
                }
                &-set-as-preferred-store,
                &-preferred-store {
                    background-color: transparent;
                    border: none;
                    color: $secondary;
                    display: block;
                    font-family: $msv-primary-font-family;
                    cursor: pointer;
                    font-weight: $msv-font-weight-bold;
                    margin-right: $spacer-l;
                    margin-left: 4.2rem;
                    outline: none;
                    padding: 0;
                    text-align: left;
                    width: fit-content;
                    z-index: 0;
                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
                &-set-as-preferred-store {
                    @include add-icon-icomoon($store-prefered, before, true, 0.5rem);
                }

                &-preferred-store {
                    @include add-icon-icomoon($store-prefered-selected, before, true, 0.5rem);
                }
                .go-to-store {
                    display: block;
                    font-size: $msv-font-size-m;
                    padding-top: 0.1em;
                    margin: 1rem 2rem 0 3.95rem;
                    width: 100%;
                }
            }

            &-line-item-header,
            &-line-header,
            &-line-stock-status {
                margin-bottom: 10px;
            }

            &-line-item-store-name,
            &-line-item-store-index {
                font-size: 1.25rem;
            }

            &-line-item-store-index {
                font-weight: $msv-font-weight-bold;
            }

            &-line-item-store-distance {
            }

            &-line-header {
                color: $msv-black;
                font-size: 1.25rem;
                font-weight: $msv-font-weight-bold;
            }

            &-line-store-distance {
                color: $border-separator-color;
            }

            &-line-shop-address {
                &-section {
                    display: flex;
                    flex-wrap: wrap;
                }
                &-glyph-icon {
                    @include add-icon-icomoon($pick-up);
                }
                &-text {
                    white-space: pre-wrap;
                    overflow-wrap: break-word; //safari
                    word-break: break-word; //safari
                    overflow-wrap: anywhere; // others
                    padding-right: $spacer;
                    width: calc(100% - 4rem);
                }
            }
            .location-type__dealer {
                .ms-store-select__location-line-shop-address-glyph-icon {
                    @include add-icon-icomoon($marker);

                    &:before {
                        margin-left: -1.25rem;
                    }
                }
            }
            &-line-shop-phone {
                &-section {
                    display: flex;
                }
                &-text {
                    margin-left: 4rem;
                }
                &-icon {
                    display: none;
                }
            }

            &-line-locate-store {
                @include primary-button(
                    $msv-store-locate-primary-btn-bg,
                    $msv-store-locate-primary-btn-font-color,
                    $msv-store-locate-primary-btn-border
                );
                @include vfi();
                @include font-content($msv-font-weight-normal, $msv-store-locate-title-font-size, $msv-line-height-l);
            }
        }

        &__footer {
            position: absolute;
            bottom: -15px;
            width: 100%;
        }

        &__terms-link {
            @include font-content($msv-font-weight-normal, $msv-store-locate-link-size, $msv-line-height-s);
            @include vfi();
            color: $msv-store-locate-link-color;
            cursor: pointer;
        }
    }

    abbr {
        text-decoration: none;
    }
}
@media screen and (max-width: $msv-breakpoint-l) {
    .ms-store-locate {
        height: auto;
        border: none;

        .ms-store-select {
            &__search {
                padding: 0 0 15px 0;
            }
            &__locations {
                display: none;
                border-bottom: $border-separator;

                &.show {
                    display: block;
                }
                &__location {
                    &-line-item {
                        &-set-as-preferred-store,
                        &-preferred-store {
                            margin-top: 0.5rem;
                            margin-bottom: 0.25rem;
                            margin-left: 0.25rem;
                        }
                        .go-to-store {
                            margin-left: 0;
                        }
                    }
                }
            }
            &__toggle-view {
                display: block;
                background-color: transparent;
                float: right;
                border: none;
                outline: none;
            }
            &__footer .map-only-view {
                bottom: -660px;
            }
        }
    }
}
/* Note: "!important" are necessary since switching from views of map load some JS everytime and update the DOM with new css */
.ms-map {
    height: 500px;
    width: 100%;

    .MicrosoftMap {
        .labelToggle_Container {
            position: relative;
            width: 100%;
        }
        input.labelToggle_Input {
            min-height: 2.5rem;
        }
        input.labelToggle_Input:empty {
            margin-left: 0;
        }

        .NavBar_Container {
            margin-right: -14px;
            @media screen and (max-width: $msv-breakpoint-s) {
                margin-right: -30px;
            }
            z-index: 1;

            .NavBar_DropDown {
                z-index: 10;
            }
            &.Light {
                .NavBar_MapTypeButtonContainerWrapper,
                #MapStyleSelector.withBackground {
                    background-color: rgba(0, 79, 113, 0.6);

                    @media screen and (max-width: 414px) {
                        background-color: transparent !important;
                    }
                    &:focus {
                        outline: none;
                    }
                }
                #NavBar_MapTypeText {
                    @media screen and (max-width: 414px) {
                        color: $msv-black !important;
                    }
                }
                .slot.selected,
                .slot.selected:hover {
                    background: #bfd3db;
                }
            }
            &.Dark {
                .NavBar_MapTypeButtonContainerWrapper,
                #MapStyleSelector.withBackground {
                    background-color: $secondary !important;

                    @media screen and (max-width: 414px) {
                        background-color: transparent !important;
                    }
                    &:focus {
                        outline: none;
                    }
                }
                #NavBar_MapTypeText {
                    @media screen and (max-width: 414px) {
                        color: $msv-white !important;
                    }
                }
                .slot.selected,
                .slot.selected:hover {
                    background: $secondary !important;
                    color: $msv-white !important;
                }
            }
            &.Light,
            &.Dark {
                -webkit-tap-highlight-color: transparent;

                .NavBar_MapTypeButtonContainerWrapper {
                    &:focus {
                        outline: none;
                    }
                    #NavBar_MapTypeIcon {
                        margin-left: 0.75rem;
                    }
                    #NavBar_MapTypeText {
                        color: $msv-white;
                        font-family: $msv-primary-font-family;
                        font-weight: $msv-font-weight-normal;
                        line-height: 3rem;

                        @media screen and (max-width: 414px) {
                            line-height: inherit;
                        }
                    }
                }
                .slot {
                    padding: 0;

                    &.birdseye {
                        display: none;
                    }
                }
                .labelStyle,
                .labelStyleDescription {
                    font-family: $msv-primary-font-family;
                }
                .labelStyle {
                    font-size: $msv-font-size-m;
                    font-weight: $msv-font-weight-normal;
                }
                .labelStyleDescription {
                    font-size: $msv-font-size-s;
                    opacity: 1;
                }
                .switchSlot.labelToggle {
                    display: flex;
                    height: auto;

                    &.disabled {
                        display: none !important;
                    }
                    > div {
                        display: flex !important;
                        width: 100% !important;

                        input.labelToggle_Input:checked ~ label.labelToggle_label::before {
                            background: $secondary;
                        }
                        label {
                            color: $msv-white;

                            &.labelToggle_label {
                                left: 18px !important;
                            }
                        }
                    }
                }
                .labelToggle_Container {
                    border-top: 1px solid #bfd3db;
                    position: relative;

                    .labelStyleSwitch {
                        font-family: $msv-primary-font-family;
                        font-weight: $msv-font-weight-normal;
                        position: static;
                        width: 100%;
                    }
                }
            }
        }
    }
    input[type='checkbox'] {
        position: relative;
    }
    input[type='checkbox'] ~ *:nth-child(2):before {
        content: none;
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-store-locate .ms-store-select__location-line-item {
        &-set-as-preferred-store,
        &-preferred-store {
            margin-top: 0.5rem;
            margin-bottom: 0.25rem;
            margin-left: 0.25rem;
        }
        .go-to-store {
            margin-left: 0;
        }
    }
    .ms-map {
        display: none;

        &.show {
            display: block;
        }
    }
}
