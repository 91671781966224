:root {
    --msv-tab-heading-font-size: var(--msv-body-font-size-xl);
    --msv-tab-heading-font-color: var(--msv-font-primary-color);
    --msv-tab-font-size: var(--msv-body-font-size-s);
    --msv-tab-font-color: var(--msv-font-primary-color);
}
.ms-tab {
    &-header-section {
        margin: 5px 5px 1.25rem 5px;

        &__heading {
            @include font-content(var(--msv-font-weight-heavy), var(--msv-tab-heading-font-size), $msv-line-height-xl);
            color: var(--msv-tab-heading-font-color);
        }
    }
    @media (min-width: $msv-breakpoint-m) {
        &-header-section {
            display: flex;
            margin: 5px 5px 1.25rem 5px;
        }
    }
    &-tabs-section {
        display: flex;

        &-li {
            cursor: pointer;
            margin-right: 10px;
            padding: 10px;
            position: relative;

            :hover,
            :focus {
                text-decoration: none;
            }
            ::before {
                background-color: transparent;
                bottom: 0;
                content: '';
                height: 2px;
                position: absolute;
                right: 0.5rem;
                left: 0.5rem;
                transition: background-color 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9);
            }
            &[aria-selected='true']::before {
                background-color: $msv-blue;
            }
            .ms-tab-item-header {
                @include font-content(var(--msv-font-weight-normal), var(--msv-tab-font-size), $msv-line-height-s);
                color: var(--msv-tab-font-color);
            }
        }
        li:last-of-type {
            margin-right: 0;
        }
    }
    &-content-section {
        margin-top: 1.25rem;
    }
    &-item-content__content[aria-hidden='true'] {
        display: none;
    }
}
