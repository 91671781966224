$msc-account-welcome-title-margin-bottom: 40px;
$msc-account-welcome-title-margin-top: 32px;
$msc-account-welcome-title-border-bottom-color: $msv-gray-500;
$msc-account-welcome-title-border-bottom-style: solid;
$msc-account-welcome-title-border-bottom-thickness: 1px;
$msc-account-welcome-title-links-padding-top: 12px;
$msc-account-tile-heading-icon-padding: 5px;
$msc-generic-tile-heading-margin-bottom: 0.5rem;

:root {
    --msv-account-landing-title-font-color: var(--msv-font-primary-color);
    --msv-account-landing-title-font-size: var(--msv-body-font-size-xl);
    --msv-account-landing-title-desktop-font-size: var(--msv-body-font-size-xxl);
    --msv-account-landing-tile-heading-size: var(--msv-body-font-size-l);
    --msv-account-landing-tile-text-size: var(--msv-body-font-size-m);
}

.account-tiles.tile {
    border-bottom: $border-separator;
    color: $secondary;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 3rem;
    padding-top: $spacer-l;
    padding-bottom: $spacer-l;
    position: relative;

    &:before {
        font-size: 1.75rem;
        position: absolute;
        top: calc(#{$spacer-l} + 0.15rem);
        left: 0;
    }

    h3,
    p {
        flex-grow: 1;
    }

    a {
        @extend %hyperlink;
    }

    &.order-tile {
        @include add-icon-icomoon($cart);
    }

    &.profile-tile {
        @include add-icon-icomoon($user-profile);
    }

    &.ms-account-wishlist-tile {
        @include add-icon-icomoon($heart);
    }

    &.ms-account-address-tile {
        @include add-icon-icomoon($marker);
    }

    &.ms-account-loyalty-tile {
        @include add-icon-icomoon($loyalty);
    }
}

// This selects all tiles in the profile page
.account-tiles {
    [class$='tile']:not(.ms-account-welcome-tile) {
        background-color: $light-secondary;
        padding: 1rem;
        margin-bottom: 1.25rem;
        // border-radius: 16px;

        [class$='links'] {
            [class$='link'] {
                div {
                    font-size: 1.125rem;
                    @include underline-link-animation($secondary);
                }
            }
        }
    }
}

.ms-account {
    padding-top: $spacer-xl;
    padding-bottom: $spacer-xl;

    &-welcome-tile {
        color: $primary;
        display: block;
        margin-top: $msc-account-welcome-title-margin-top;
    }

    &-generic-tile__heading,
    &-wishlist-tile__heading,
    &-address-tile__heading,
    &-loyalty-tile__heading {
        @extend %h3;
        color: $secondary;
        text-align: left;
    }
}
