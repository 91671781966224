// Variables
$carousel-control-transition: opacity 0.15s ease;
$carousel-indicator-transition: opacity 0.6s ease;
$carousel-caption-width: 70%;
$carousel-transition-duration: 0.6s;
$carousel-transition: opacity $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
$carousel-transition-base: all 0.2s ease-in-out;
$carousel-transition-fade: opacity 0.15s linear;
$carousel-transition-collapse: height 0.35s ease;
$carousel-enable-transitions: true;
$carousel-enable-prefers-reduced-motion-media-query: true;

@mixin carousel-transition($transition...) {
    @if $carousel-enable-transitions {
        @if length($transition) == 0 {
            transition: $carousel-transition-base;
        } @else {
            transition: $transition;
        }
    }
    @if $carousel-enable-prefers-reduced-motion-media-query {
        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }
}
.screen-reader-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}
.carousel--hero {
    overflow: hidden;

    @include media-breakpoint-up(md) {
        height: 34rem;
    }
    .msc-carousel {
        &:before {
            background: $primary-10;
            height: calc(100% + #{$spacer});
            position: absolute;
            bottom: -$spacer-xl;
            left: $layout-gutter;
            width: 25%;
            z-index: 10;
        }
        &.slide {
            overflow: hidden;
        }
        &__inner {
            position: static;
            // max-width:1440px;
            align-items: flex-start;
        }
        &__item {
            float: none;
            margin: 0 auto;
            @include media-breakpoint-down(sm) {
                padding-left: $layout-gutter;
                padding-right: $layout-gutter;
            }
            opacity: 0;
            width: 100%;

            picture {
                display: flex;
                justify-content: center;
            }
        }
        .ms-content-block,
        .ms-content-block[data-m-layout='full-width'] {
            @include media-breakpoint-up(md) {
                height: 33rem;
            }
        }
        .ms-content-block[data-m-layout='full-width'] .ms-content-block__details {
            background: $primary-10;
            color: $primary;
            justify-content: flex-start;
            margin-top: -2rem;
            margin-left: 8.333333%;
            top: 0;
            padding-top: 4rem;
            padding-bottom: $spacer-l;
            transition: all 0.5s;
            width: 83.333333%;
            z-index: 101;

            @media screen and (min-width: $msv-breakpoint-m) {
                margin-top: $spacer-xl;
                margin-left: #{'max(16px, (100% - 1410px) / 2)'};
                top: auto;
                bottom: 0;
                padding-top: $spacer-xl;
                padding-bottom: $spacer-l * 2;
            }
            @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                width: 50%;
            }
            @media screen and (min-width: $msv-breakpoint-l) {
                width: 40%;
            }
            @media screen and (min-width: $msv-breakpoint-xl) {
                width: 33.3333%;
            }
        }
        .msc-cta__primary {
            @extend %underlined-button;
            display: flex;
            align-items: center;

            &:focus,
            &:hover {
                padding-bottom: 1rem;
                margin-bottom: 0.5rem;
                transition: none;

                &:before {
                    transition: none;
                }
            }
        }
        .ms-content-block,
        .ms-content-block[data-m-layout='full-width'] {
            .ms-content-block__image {
                img {
                    height: 16rem;

                    @include media-breakpoint-up(md) {
                        height: 30rem;
                    }
                }
            }
            .ms-content-block__image {
                @include media-breakpoint-down(sm) {
                    position: static;
                }
            }
        }
        &__control__prev,
        &__control__next {
            display: none;
        }
        .msc-tooltip {
            display: none !important;
        }
    }
}
.carousel--testimonials .msc-carousel {
    background: $secondary url('../../../images/bg_testimonials.png') no-repeat center;
    padding: 0 $layout-gutter;
    min-height: 20rem;

    [data-m-layout='full-width'] .ms-content-block__details {
        align-self: center;
        background: transparent;
        color: $msv-white;
        text-align: center;

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }
    .ms-content-block[data-m-layout='full-width'] {
        height: auto;

        .ms-content-block__details {
            min-height: 0;

            p {
                margin-bottom: 1rem;
            }
        }
    }
    .msc-carousel__indicators {
        display: none;
    }
}
.msc-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .ms-content-block {
        height: auto;
        min-height: 0;
        padding-top: 0;
    }
    .ms-content-block__text {
        flex-grow: unset;
        min-height: 2.5em;
    }
    .pointer-event {
        touch-action: pan-y;
    }
    &__dismiss {
        background: $msv-black;
        border-color: $msv-black;
        color: var(--msv-font-secondary-color);
        position: absolute;
        right: 0;
        top: 0;

        &:before {
            @include msv-icon();
            content: $msv-Cancel;
            font-size: $msv-heading-text-size;
            position: unset;
            text-align: center;
            vertical-align: text-bottom;
            visibility: visible;
        }
    }
    &__inner {
        @include clearfix();
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        overflow: hidden;
        position: relative;
        width: 100%;

        @include media-breakpoint-down(lg) {
            display: flex;
            flex-wrap: nowrap;
        }
    }
    &__item {
        @include carousel-transition($carousel-transition);
        backface-visibility: hidden;
        flex: 0 0 100%;
        height: 100%;
        order: 1;
        text-decoration: none;
        width: 100%;
    }
    &__item {
        &.active {
            order: 0;
            opacity: 1;
            text-decoration: none;

            .ms-content-block__details {
                opacity: 1;
                transform: translate(0);
            }
        }
        &.active,
        &__next,
        &__prev {
            display: block;
        }
    }
    &.carousel-fade {
        &__item {
            opacity: 0;
            transition-property: opacity;
            transform: none;
        }

        .msc-carousel__item.active,
        .msc-carousel__item__next.msc-carousel__item__left,
        .msc-carousel__item__prev.msc-carousel__item__right {
            opacity: 1;
            z-index: 1;
        }

        .active.msc-carousel__item__left,
        .active.msc-carousel__item__right {
            @include carousel-transition(0s $carousel-transition-duration opacity);
            opacity: 0;
            z-index: 0;
        }
    }
    &__control__prev,
    &__control__next {
        @include add-icon-icomoon($arrow);
        color: $msv-white;
        cursor: pointer;
        display: block;
        font-size: 1.25rem;
        position: absolute;
        top: 50%;
        left: $layout-gutter;
        transform: translateY(-50%);
        z-index: 10;

        &:focus {
            outline: none;
        }
        @include media-breakpoint-up(md) {
            left: 16.6666%;
        }
    }
    &__control__prev:before {
        transform: rotate(180deg);
    }
    &__control__next {
        right: $layout-gutter;
        left: auto;

        @include media-breakpoint-up(md) {
            right: 16.6666%;
        }
    }
    &__caption {
        padding-top: 20px;
        padding-bottom: 20px;
        position: absolute;
        right: (100% - $carousel-caption-width) / 2;
        bottom: 20px;
        left: (100% - $carousel-caption-width) / 2;
        text-align: center;
        z-index: 10;
    }
    &__indicators {
        display: flex;
        list-style: none;
        position: absolute;
        top: 15rem;
        width: calc(83.3333% - #{$spacer-l * 2});
        z-index: 102;

        @include media-breakpoint-up(md) {
            top: auto;
            transform: translate(-50%, 0);
            bottom: 3rem;
            left: 50%;
            width: 100%;

            &:after {
                .carousel--hero & {
                    content: '';
                    flex: 0 0 66.666667%;
                    max-width: 66.666667%;
                    display: block;
                    margin-left: 4rem;
                }
            }
        }
        li {
            @include carousel-transition($carousel-indicator-transition);
            background: $primary-40;
            cursor: pointer;
            flex-grow: 1;
            height: 0.5rem;
            margin-right: 0.5rem;
            opacity: 1;
            text-indent: -999px;
            outline: none;
            width: 100%;

            &:not(.active):hover {
                background: $primary-60;
            }
            &:first-child {
                @include media-breakpoint-up(md) {
                    .carousel--hero & {
                        margin-left: 4rem;
                    }
                }
            }
            .carousel--hero & {
                height: 1rem;
            }
        }
        .active {
            background: $primary-80;
            opacity: 1;
        }
        .msc-tooltip-inner {
            background-color: $primary;
        }
        .msc-bs-tooltip-top {
            .msc-arrow {
                &::before {
                    border-top-color: $primary;
                }
            }
        }
    }
}
.msc-carousel__item__prev:not(.msc-carousel__item__right),
.msc-carousel__item__next:not(.msc-carousel__item__left),
.active.msc-carousel__item__right,
.active.msc-carousel__item__left {
    opacity: 0;
}
@include media-breakpoint-up(md) {
    ol.msc-carousel__indicators {
        margin-left: #{'max(16px, (100% - 1410px) / 2)'};
    }
}
