$white: #ffffff;
//corporate colors
$primary: #542d00;
$secondary: #004f71;
$light-secondary: #effaff;

$brown: $primary;
$blue: $secondary;
$orange: #e87722;
$beige: #86704d;
$yellow: #ffbf28;

$light-beige: #fff0df;
$pink: #f0c5c3;
$coral: #e08d6b;
$orange2: #d47d23;
$red: #c13533;
$light-blue: #90c6e2;
$light-brown: #eeeae5;
$acquamarine: #62cbc9;
$green: #46915e;

$orange-80: rgba($orange, 0.8);

$primary-80: mix($primary, $white, 80%);
$primary-60: mix($primary, $white, 60%);
$primary-40: mix($primary, $white, 40%);
$primary-20: mix($primary, $white, 20%);
$primary-10: mix($primary, $white, 10%);

$secondary-80: mix($secondary, $white, 80%);
$secondary-40: mix($secondary, $white, 40%);
$secondary-20: mix($secondary, $white, 20%);
$secondary-10: mix($secondary, $white, 10%);

$bg_main_color: #fff;

// bootstrap color variables
$msv-white: #fff;
$msv-black: #000;
$msv-blue: #0062af;
$msv-green: $green;
$msv-red: $red;
$msv-pink: #fcdfdf;
$msv-cyan: #0776d1;

$msv-gray-100: #f8f7f6;
$msv-gray-200: #e8e8e8;
$msv-gray-300: #d1d1d1;
$msv-gray-400: #6b727a;
$msv-gray-500: #616365;
$msv-gray-800: #8f8f8f;
$msv-gray-900: #1d1d1d;
$msv-gray-1000: #2d2d2d;

$msv-primary: $primary;
$msv-primary-hover: mix($primary, #000, 20%);
$msv-secondary: #6c757d;
$msv-secondary-hover: $secondary-80;

$msv-text-color: #000000;
$msv-link-color: $msv-gray-900;
$msv-divider-color: $msv-gray-300;
$msv-error-color: $msv-red;
$msv-success-color: $msv-green;
$modal-input-background: $msv-gray-100;
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);

// Social button color variables
$facebook-bg-color: #1877f2;
$facebook-hover-color: #0b5ac1;
$microsoft-bg-color: #3c3c41;
$microsoft-hover-color: #000000;

$msv-unfilled-rating-color: $msv-gray-800;

//Header
$msv-bg-color-header: #fffffff0;
$msv-bg-color-backdrop: saturate(180%) blur(20px);

// Style presets
:root {
    // Background
    --msv-bg-color: #{$msv-white};

    // Font
    --msv-font-primary-color: #{$msv-black};
    --msv-font-secondary-color: #{$msv-white};

    // Borders
    --msv-border-color: #{$msv-white};

    // Brand accents
    --msv-accent-brand-color: #{$primary};

    // Status
    --msv-error-color: #{$msv-red};
    --msv-success-color: #{$msv-green};

    // Modal
    --msv-modal-bg-color: #{$msv-white};
    --msv-modal-font-color: #{$msv-black};
}

$brand-colors: (
    'brown': $primary,
    'blue': $secondary,
    'white': #fff,
    'black': #000000,
    'orange': $orange,
    'beige': $beige,
    'yellow': $yellow,
    'lightbeige': $light-beige,
    'pink': $pink,
    'coral': $coral,
    'orange2': $orange2,
    'lightblue': $light-blue,
    'lightbrown': $light-brown,
    'acquamarine': $acquamarine,
    'green': $green,
    'red': $red
);
$success: red;
$info: #000000;
$warning: #000;
$danger: #000;
$light: #000;
$dark: #000;
$theme-colors: () !default;
$theme-colors: map-merge(
    (
        'primary': $primary,
        'secondary': $secondary,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'dark': $dark
    ),
    $theme-colors
);
