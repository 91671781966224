:root {
    --msv-cookie-compliance-bg-color: var(--msv-bg-color);
    --msv-cookie-compliance-font-color: var(--msv-font-primary-color);
    --msv-cookie-compliance-font-size: var(--msv-body-font-size-m);
    --msv-cookie-compliance-link-color: var(--msv-font-primary-color);
}
.ms-cookie-compliance {
    background-color: $secondary;
    color: $white;
    font-size: $msv-font-size-l;
    position: fixed;
    bottom: 0;
    width: 100%;

    p {
        margin: 0;
    }
    &__container {
        display: flex;
        font-weight: normal;
        margin-bottom: 0;
        max-width: $msv-breakpoint-xl;
        margin: 0 auto;

        @media screen and (min-width: $msv-breakpoint-xs) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        @media (min-width: $msv-breakpoint-m) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }
    &__content {
        width: 100%;
    }
    &__accept-button {
        @include solid-button($bg-color: $secondary, $small: true, $disableBtn: false, $negative: true);
        align-self: center;
        margin-left: 3rem;
    }
    &__cta-layer .link {
        color: $white;
        text-decoration: underline;
    }
}
@media (max-width: $msv-breakpoint-m) {
    .ms-cookie-compliance {
        &__content {
            margin-bottom: 1.5rem;
        }
        &__accept-button {
            float: none;
            margin: 0;
            width: 243px;
        }
        &__container {
            align-items: center;
            flex-direction: column;
        }
    }
}
.ot-floating-button__front {
    button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    [type='button'] {
        padding: 0;
    }
}
