.loyalty-balance-check {
    .row {
        @media screen and (max-width: 576px) {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
        }

        @media screen and (min-width: 577px) {
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 1rem;
        }

        justify-content: space-evenly;

        form {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
        }

        .input-div {
            display: flex;
            flex-direction: column;
            padding-bottom: 1rem;

            label,
            input {
                font-size: 1.25rem;
            }
        }

        .points {
            display: none;
            margin: 1rem;
            padding: 1rem;
            // TODO Replace with light-secondary
            background-color: #effaff;
            p {
                font-size: 2rem;
                font-weight: 400;
            }
        }

        .solid-button {
            width: 100%;
        }
    }

    .error {
        color: firebrick;
    }
}
