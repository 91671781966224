.ms-order-details {
    @extend .col-md-8;

    &__heading {
        display: none;
    }
    &__order-information {
        font-size: 1.25rem;
        margin-bottom: $spacer;

        &-channel-name {
            display: block;
            margin-bottom: $spacer;
        }
        &-channel-address {
            display: block;
            font-size: $msv-heading-text-size;
            font-weight: var(--msv-font-weight-heavy);
            line-height: $msv-heading-line-height;
            margin-bottom: 0.75rem;
            margin-top: -0.75rem;
        }
        &-channel-reference-id,
        &-receipt-email {
            display: block;
        }
        &-sales-id {
            display: block;
            font-size: 2rem;
            margin-bottom: $spacer;
        }
        &-created-date,
        &-count {
            border-right: 1px solid $msv-black;
            display: inline-block;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        &-created-date {
            padding-left: 0;
        }
        &-channel-reference-id {
            font-weight: $msv-font-weight-bold;
        }
        &-amount {
            padding-left: 0.5rem;
        }
        &-qr-code {
            display: none;
        }
        @media screen and (max-width: $msv-breakpoint-s) {
            &-amount {
                padding-left: 0;
            }
        }
    }
    &__btn-keep-shopping {
        @extend %hyperlink;
        margin-bottom: 1.25rem;
    }
    &__sales-lines {
        display: flex;
        flex-wrap: wrap;

        &:first-child {
            border-top: none;
        }
    }
    &__sales-status {
        border-top: $border-separator;
        color: var(--msv-success-color);
        font-size: $msv-font-size-m;
        font-weight: $msv-font-weight-bold;
        padding: $spacer-l 0;
        text-align: right;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            flex: 0 0 16.666667%;
            max-width: 16.666667%;
            padding-top: 6rem;
        }
        @include media-breakpoint-down(sm) {
            border: 0;
            display: block;
            padding-top: 0;
            padding-left: 115px;
            text-align: left;
            width: 100%;
        }
    }
    &__groups {
        border-bottom: $border-separator;
        margin-bottom: $spacer-l;
        padding-bottom: $spacer-l;
    }
    &__group {
        flex-wrap: wrap;

        &-delivery {
            font-size: 1.25rem;
            margin-bottom: $spacer;
            padding-bottom: $spacer;

            &-heading {
                display: none;
            }
            &-total-items {
                display: none;
            }
        }
        &:first-child {
            border-top: none;

            .ms-order-details__sales-lines {
                border-top: none;
            }
        }
    }
    &__tracking-link {
        text-decoration: underline;
    }
    &__tracking-info {
        display: block;
    }
    &__sales-line {
        border-top: $border-separator;
        padding: $spacer-l 0;
        width: 100%;

        @include media-breakpoint-up(md) {
            flex: 0 0 83.333333%;
            max-width: 83.333333%;
        }
        .msc-cart-line {
            &__product {
                flex: none;
            }
            &__product-image img {
                max-width: 147px;
                width: 147px;
                height: 147px;
            }
            &__content {
                display: flex;
                flex: auto;
                flex-direction: column;
                position: relative;
            }
            &__product-title {
                font-size: $msv-font-size-xl;
                @include media-breakpoint-down(md) {
                    min-height: 1rem;
                }
            }
            &__quantity {
                padding-left: $layout-gutter;
                padding-right: $layout-gutter;

                .quantity-label {
                    font-size: $msv-text-size;
                    line-height: $msv-text-line-height;
                }
                .quantity-value {
                    margin-left: 5px;
                    display: inline;
                }
            }
            &__product-savings {
                display: none;
            }
            &__product-price {
                display: inline;
                font-size: $msv-font-size-xl;
                font-weight: $msv-font-weight-bold;
                position: absolute;
                right: -25%;
                top: 0;
                text-align: right;
                width: 25%;

                .discount-value .msc-price__strikethrough {
                    display: none;
                }
            }
            &-item-product-discount {
                display: none;
            }
        }
        &-btn-buy-it-again {
            @extend %border-button;
            margin-left: calc(#{147px} + #{$layout-gutter * 2});

            &.msc-btn {
                padding-right: 33px;
                padding-left: 33px;
            }
        }
    }
    &__order-summary {
        border-bottom: 1px solid rgba(0, 79, 113, 0.5);
        color: $secondary;
        font-size: $msv-font-size-l;
        margin-bottom: 2rem;
        padding-bottom: 2rem;

        p {
            font-size: $msv-font-size-l;
        }
        &-heading {
            display: none;
        }
        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            margin-bottom: 1.25rem;
        }
        &-line-tax-amount {
            display: none;
            html[lang='en-US'] &,
            html[lang="en-GB"] [data-plat*="chanId:5637153578"] + body & /*doesn't work in local, but uat and prod*/ {
                display: block;
            }
        }
        &-line-total-amount {
            .ms-order-details__order-summary-label {
                font-size: 1.25rem;
                font-weight: $msv-font-weight-bold;
            }
            .msc-price__actual {
                font-size: $msv-font-size-xl;
            }
        }
        &-line-earned-points {
            border-top: 1px solid $msv-divider-color;
            padding-top: 1.25rem;

            .ms-order-details__order-summary-label {
                font-weight: $msv-font-weight-bold;
            }
        }
        &-price {
            float: right;
        }
    }
    &__help {
        @include font-content-m();
        float: left;
        margin-bottom: 1.75rem;
        width: 100%;

        &-title {
            font-weight: var(--msv-font-weight-bold);
            margin-top: 1.25rem;
            margin-bottom: 0.5rem;
        }
        &-label:after {
            content: ' ';
        }
        &-content-number {
            @include vfi();
            text-decoration: underline;
        }
    }
    &__payment-methods {
        margin-right: 1.25rem;

        &-title {
            @extend %h4;
            color: $secondary;
        }
        &-line {
            margin-bottom: 1.25rem;
        }
        &-loyalty-amount:before {
            content: ' ';
        }
    }
    &__address {
        &-header {
            display: none;
        }
        &-name,
        &-phone {
            display: block;
        }
    }
    &__alert {
        display: block;
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
    }
    .msc-empty_image {
        @include image-placeholder(147px);
    }
    &__tracking {
        border-top: 1px solid rgba(0, 79, 113, 0.5);
        padding-top: 1.25rem;

        &-title {
            @extend %h4;
            color: $secondary;
        }

        &-row {
            display: flex;
            align-items: baseline;
            column-gap: 1.25rem;
        }

        &-button {
            font-size: inherit;
        }
    }
    @media screen and (max-width: $msv-breakpoint-l) {
        &__sales-lines {
            width: 100%;
        }
        &__sales-line .msc-cart-line {
            width: 100%;
        }
    }
    @media screen and (max-width: $msv-breakpoint-m) {
        &__group {
            display: block;

            &_delivery {
                padding-right: 0;
            }
        }
        &__sales-lines {
            width: 100%;
        }
        &__order-summary,
        &__payment-methods,
        &__help {
            float: none;
            margin-top: 1.25rem;
            padding-right: 0;
            width: 100%;
        }
        &__btn-keep-shopping {
            width: 100%;
        }
        &__sales-line-btn-buy-it-again {
            display: block;
            margin-left: 100px;
            width: auto;
        }
        .msc-cart-line {
            width: 100%;

            &__product-price {
                position: relative;
                text-align: left;
                margin-left: 0;
                margin-bottom: $spacer/2;
                width: 0;
                left: 0;
                display: block;
            }
            &__quantity {
                margin-bottom: $spacer;
            }
            &__product-image img {
                max-width: 80px;
                width: 80px;
                height: 80px;
            }
        }
        .msc-empty_image {
            @include image-placeholder(80px);
        }
    }
}
