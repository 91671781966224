.webchat__bubble__content {
    a {
        display: inline;
        color: rgb(84, 45, 0);
        img {
            display: inline;
        }
    }
    .webchat__link-definitions {
        display: none;
    }
}

.unshrink {
    flex-shrink: 0;
}

.transcript {
    flex: 1;
}

.webchat_root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.footer_disclaimer {
    -webkit-box-align: center;
    align-items: center;
    color: rgb(94, 94, 94);
    display: flex;
    font-family: 'Brandon Grotesque', Arial, 'sans-serif';
    font-size: 75%;
    margin: 8px 14px;

    a {
        color: inherit;
        display: inline;
    }
}

#oc-lcw-chat-button-text-container {
    display: none;
}

.css-112 {
    display: none;
}
