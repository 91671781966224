.msc-add-to-wishlist {
    @include add-icon-icomoon($heart-btn);

    & .wishlist-button-text {
        font-size: $msv-font-size-m;
        text-decoration: underline;
        //vertical-align: text-top;
        margin-left: 0.7rem;
        text-underline-offset: 4px;
    }

    &__removing {
        @include add-icon-icomoon($heart-full);
    }

    @media (hover: hover) and (pointer: fine) {
        &:not(:disabled) {
            &:hover {
                @include add-icon-icomoon($heart-full);
                cursor: pointer;
            }
        }
    }
}
